import { StopAddress } from 'shared/models/stop-address.model';

export class LoadSummaryAddress extends StopAddress {
  location: string;     // "City, ST CN"
  fullLocation: string; // "Sadf↵Cheesetown, AL 55555↵US↵"
  companyName: string;

  constructor(json: LoadSummaryAddressJSON) {
    super();
    if (json) {
      if (json.stateCode === json.country) {
        this.stateCode = '';
        if (json.cityName !== '' && json.country !== '') {
          this.fullLocation = json.cityName + ', ' +  json.country;
        } else {
          this.fullLocation = json.fullLocation;
        }
      } else {
        this.stateCode = json.stateCode;
        this.fullLocation = json.fullLocation;
      }
      this.address1 = json.streetName;
      this.address2 = json.street2;
      this.city = json.cityName;
      this.county = json.county;
      this.zipCode = json.zipcode;
      this.countryCode = json.country;
      this.location = json.location;
      this.companyName = json.companyName;
    }
  }

  toJson(): LoadSummaryAddressJSON {
    return {
      streetName: this.address1,
      street2: this.address2,
      cityName: this.city,
      stateCode: this.stateCode,
      zipcode: this.zipCode,
      country: this.countryCode,
      county: this.county,
      location: this.location,
      fullLocation: this.fullLocation,
      companyName: this.companyName
    };
  }
}
