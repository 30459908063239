import { BaseModel } from 'shared/models/base.model';
import { LoadSummaryAddress } from 'shared/models/loads/load-summaries/load-summary-address.model';
import { SpotBidCommodity } from 'shared/models/spot-bids/spot-bid-extended-commodity.model';

export class SpotBidExtendedProperties extends BaseModel<SpotBidExtendedPropertiesJSON> {
  comments: string;
  customerName: string;
  deliveryAddress: LoadSummaryAddress;
  equipmentMode: string;
  incoTerms: string;
  initiatedDate: Date;
  requestedDeliveryDate: Date;
  licenseRequired: string;
  otherPortsAllowedDischarge: string;
  otherPortsAllowedLoading: string;
  pickUpAddress: LoadSummaryAddress;
  portOfDischarge: string;
  portOfLoading: string;
  typeOfService: string;
  private readonly items: SpotBidCommodity[];

  get commodities(): SpotBidCommodity[] {
    return this.items;
  }

  constructor(json?: SpotBidExtendedPropertiesJSON) {
    super(json);
    if (!json) {
      return;
    }
    this.initiatedDate = this.processDate(json.initiatedDate);
    this.requestedDeliveryDate = this.processDate(json.requestedDeliveryDate);
    this.pickUpAddress = new LoadSummaryAddress(json.pickUpAddress);
    this.deliveryAddress = new LoadSummaryAddress(json.deliveryAddress);
    this.items = json.items.map(item => new SpotBidCommodity(item));
  }

  toJson() {
    return Object.assign(super.toJson(), {
      initiatedDate: this.convertDateToDateString(this.initiatedDate),
      requestedDeliveryDate: this.convertDateToDateString(this.requestedDeliveryDate),
      pickUpAddress: this.pickUpAddress.toJson(),
      deliveryAddress: this.deliveryAddress.toJson(),
      items: this.items.map(item => item.toJson())
    });
  }
}
