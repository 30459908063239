import { combineEpics } from 'redux-observable';
import { Cookie } from 'ng2-cookies';
import { Action, AnyAction } from 'redux';
import { Container } from 'typedi';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/pluck';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/withLatestFrom';

import * as a from 'app/i18n/culture.actions';
import { Culture, initCulture } from 'app/i18n/culture.actions';
import { User } from 'shared/models/user.model';
import { COMPLETE_LOGIN, completeLogin, CompleteLoginAction, STORE_CREDENTIALS, StoreCredentialsAction } from 'features/security/auth.actions';
import { WebsiteRepository } from 'app/repositories/website.repository';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import { ReferenceDataRepository } from 'app/repositories/reference-data.repository';
import { UserRepository } from 'app/repositories/user.repository';

interface Deps {
  websiteRepo: WebsiteRepository;
  refDataRepo: ReferenceDataRepository;
  userRepo: UserRepository;
}

type CultureEpic<OutputAction extends AnyAction> = NavCarrierEpic<OutputAction, Deps>;

const DEFAULT_CULTURE: a.CultureCookie = { locale: 'en-US', country: 'US', default: true };

export const initCultureOnLoginEpic: NavCarrierEpic<Action> = action$ =>
  action$.ofType<StoreCredentialsAction>(STORE_CREDENTIALS)
    .mapTo(initCulture());

export const storeCultureEpic: CultureEpic<a.SetCultureAction> = (action$, state$) =>
  action$.ofType<a.SaveCultureAction>(a.SAVE_CULTURE)
    .filter(() => !Boolean(state$.value.auth.user))
    .do(({ culture }) => Cookie.set('culture', JSON.stringify(culture.toCookie())))
    .map(({ culture }) => a.setCulture(culture));

export const updateUserCultureEpic: CultureEpic<CompleteLoginAction> = (action$, state$, { userRepo }) =>
  action$.ofType<a.SaveCultureAction>(a.SAVE_CULTURE)
    .filter(() => Boolean(state$.value.auth.user))
    .mergeMap(({ culture }) =>
      userRepo.updateUserProperties({
        userId: state$.value.auth.user.userId,
        properties: { country: culture.country.code, culture: culture.locale.locale }
      })
        .map(userJSON => completeLogin(userJSON, state$.value.auth.carrier))
    );

export const initializeCultureEpic: CultureEpic<a.SetCultureAction> = (action$, state$, { websiteRepo, refDataRepo }) =>
  action$.ofType(a.INIT_CULTURE)
    // user culture, or cookie culture, or default culture
    .map(() => getStoredCultureFromUser(state$.value.auth.user) || getStoredCultureFromCookie())
    .mergeMap(culture =>
      culture
        ? hydrateCulture(culture, refDataRepo) // user has culture or culture is stored in cookie.
        : getBrowserCulture(websiteRepo, refDataRepo) // nothing stored, check the browser's language settings.
    )
    .map(a.setCulture);

// noinspection JSUnusedLocalSymbols
export const applyUserCultureEpic: CultureEpic<a.SetCultureAction> = (action$, state$, { refDataRepo }) =>
  action$.ofType<CompleteLoginAction>(COMPLETE_LOGIN)
    .withLatestFrom(state$.pluck<NavCarrierState, User>('auth', 'user'))
    .map(([action, user]) => getStoredCultureFromUser(user))
    .filter(culture => Boolean(culture))
    .mergeMap(culture => hydrateCulture(culture, refDataRepo))
    .map(a.setCulture);

const getBrowserCulture = (websiteRepo: WebsiteRepository, refDataRepo: ReferenceDataRepository): Observable<Culture> =>
  websiteRepo.getGlobalizationCultures(true)
    .map(locales => locales.find(locale => locale.culture.indexOf('-') > -1) || locales[0])
    .map(locale => locale ? [locale, locale.displayName.replace(/^.*\((.*)\)$/, '$1')] : [])
    .mergeMap(([locale, countryName]: [GlobalizationCulture, string]) =>
      (locale && (locale.displayName !== countryName))
        ? mapCultureToLocale(countryName, locale, refDataRepo)
        : hydrateCulture(DEFAULT_CULTURE, refDataRepo) // Fallback to the default.  We have no way of picking a locale based on only "en" or "fr" or whatever.
    ).catch(() => hydrateCulture(DEFAULT_CULTURE, refDataRepo));

const mapCultureToLocale = (countryName: string, locale: GlobalizationCulture, repo: ReferenceDataRepository): Observable<Culture> =>
  repo.getGlobalizationLocales()
    .map(countries => countries.find(country => country.name === countryName) || countries.find(item => locale.culture.includes(item.code)))
    .map(country => [country, country?.locales.find(inner => inner.locale === locale.culture)])
    .mergeMap(([country, language]: [GlobalizationCountry, GlobalizationCultureLanguage]) =>
      (country && language)
        ? Observable.of(new Culture(country, language, true))
        : hydrateCulture(DEFAULT_CULTURE, repo) // We found a country in the display name, but that country didn't match any in our list. Use fallback.
    )
    .catch(() => hydrateCulture(DEFAULT_CULTURE, repo));

export const hydrateCulture = (culture: a.CultureCookie, repo: ReferenceDataRepository): Observable<Culture> =>
  repo.getGlobalizationLocales()
    .map(countries => {
      const country = countries.find(item => item.code === culture.country);
      const locale = country.locales.find(item => item.locale === culture.locale);

      if (locale) {
        return new Culture(country, locale, culture.default);
      }
    })
    .mergeMap((hydratedCulture: Culture) =>
      hydratedCulture
        ? Observable.of(hydratedCulture)
        : hydrateCulture(DEFAULT_CULTURE, repo)
    )
    .catch(() => Observable.empty());

export const getStoredCultureFromUser = (user: User): a.CultureCookie => {
  if (user?.properties) {
    const locale = user.properties.culture || DEFAULT_CULTURE.locale;
    const country = user.properties.country || (locale ? locale.split('-')[1] : DEFAULT_CULTURE.country);
    return {
      locale,
      country
    };
  }
};

export const getStoredCultureFromCookie = (): a.CultureCookie => {
  const storedCultureStr = Cookie.get('culture');
  if (storedCultureStr) {
    return JSON.parse(storedCultureStr);
  }
};

export const cultureEpic: NavCarrierEpic<AnyAction> = (action$, store) =>
  combineEpics(
    storeCultureEpic,
    initCultureOnLoginEpic,
    initializeCultureEpic,
    applyUserCultureEpic,
    updateUserCultureEpic
  )(action$, store, {
    websiteRepo: Container.get(WebsiteRepository),
    refDataRepo: Container.get(ReferenceDataRepository),
    userRepo: Container.get(UserRepository)
  });
