import { AnyAction } from 'redux';
import { combineEpics } from 'redux-observable';
import { Container } from 'typedi';

import { AuthRepository } from 'app/repositories/auth.repository';
import { loginEpics } from '@features/security/auth/login.epics';
import { logoutEpics } from '@features/security/auth/logout.epics';
import { userSessionEpics } from '@features/security/auth/user-session.epics';
import { carrierEpics } from 'store/epics/carrier/carrier.epics';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';

export const authEpic: NavCarrierEpic<AnyAction> = (action$, state$) => combineEpics(
  loginEpics,
  logoutEpics,
  userSessionEpics,
  carrierEpics
)(action$, state$, {auth: Container.get(AuthRepository)});
