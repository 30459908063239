import { BaseModel } from 'shared/models/base.model';

import { AccessorialStopType } from './accessorial-stop-type.enum';
import { AccessorialWarning } from 'shared/enums/financials/accessorial-warning.enum';
import { StateChange } from 'shared/enums/financials/state-change.enum';

export class AccessorialStop extends BaseModel<AccessorialStopJSON> {
  sequenceNumber: number;
  description: string;
  requestedAccessorialStopType: AccessorialStopType;
  accessorialChargeTypes: AccessorialChargeTypeJSON[];
  openTime: string;
  closeTime: string;
  accessorialWarning: AccessorialWarning;
  stateChanges: StateChange[];
}
