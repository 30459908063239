import { BaseModel } from 'shared/models/base.model';
import { Dimension } from 'shared/models/my-loads/measurements/dimension.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Measurements.LoadItemDimension */
export class LoadItemDimension extends BaseModel<MyLoads.LoadItemDimensionJSON> {
  readonly isOverDimensional: boolean;
  readonly exactLengthInches?: number;
  readonly length: Dimension|null;
  readonly width: Dimension|null;
  readonly height: Dimension|null;

  constructor(json: MyLoads.LoadItemDimensionJSON) {
    super(json);

    this.length = json.length && new Dimension(json.length);
    this.width = json.width && new Dimension(json.width);
    this.height = json.height && new Dimension(json.height);
  }
}
