import { BaseModel } from 'shared/models/base.model';
import { StopAddress } from 'shared/models/stop-address.model';

export class Warehouse extends BaseModel {
  code: string;
  name: string;
  address: StopAddress;
  phone?: {
    dialingCode?: string;
    number?: string;
    extension?: string
  };
  openingDateTime: Date;
  closingDateTime: Date;
  notes?: string;
  directions?: string;

  constructor(json: WarehouseJSON) {
    super(json);

    if (json.address) {
      this.address = new StopAddress(json.address);
    }
    if (json.warehouseHoursStart) {
      this.openingDateTime = this.processTime(json.warehouseHoursStart);
    } else {
      this.openingDateTime = null;
    }
    if (json.warehouseHoursEnd) {
      this.closingDateTime = this.processTime(json.warehouseHoursEnd);
    } else {
      this.closingDateTime = null;
    }
  }

  toJson(): WarehouseJSON {
    return {
      address: this.address.toJson(),
      warehouseHoursStart: this.convertDateToDateString(this.openingDateTime, true),
      warehouseHoursEnd: this.convertDateToDateString(this.closingDateTime, true),
      notes: this.notes,
      directions: this.directions,
      code: this.code,
      name: this.name,
      phone: this.phone,
    };
  }
}
