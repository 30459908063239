import { TrackingIdentifierType } from 'shared/enums/tracking-identifier-type.enum';
import { TrackingMessageType } from 'shared/enums/tracking-message-type.enum';

export const trackingErrorsMapping: { [key: number]: ResourceKey } = {
  [TrackingMessageType.ArrivalPickupException]: 'ARRIVAL_PICKUP_EXCEPTION',
  [TrackingMessageType.DeparturePickupException]: 'DEPARTURE_PICKUP_EXCEPTION',
  [TrackingMessageType.ArrivalDropOffException]: 'ARRIVAL_DROPOFF_EXCEPTION',
  [TrackingMessageType.DepartureDropOffException]: 'DEPARTURE_DROPOFF_EXCEPTION',
  [TrackingMessageType.LicensePlatesIsMissing]: 'MESSAGE_LICENSE_PLATES_MISSING',
  [TrackingMessageType.LicensePlatesIsInvalid]: 'MESSAGE_LICENSE_PLATES_INVALID',
  [TrackingMessageType.ProNumberIsMissing]: 'MESSAGE_PRO_NUMBER_MISSING',
  [TrackingMessageType.ProNumberIsInvalid]: 'MESSAGE_PRO_NUMBER_INVALID',
  [TrackingMessageType.DriversPhoneNumberIsMissing]: 'MESSAGE_PHONE_NUMBER_MISSING',
  [TrackingMessageType.DriversPhoneNumberIsInvalid]: 'MESSAGE_PHONE_NUMBER_INVALID',
  [TrackingMessageType.TractorNumberIsMissing]: 'MESSAGE_TRACTOR_NUMBER_MISSING',
  [TrackingMessageType.TractorNumberIsInvalid]: 'MESSAGE_TRACTOR_NUMBER_INVALID',
  [TrackingMessageType.TrailerNumberIsMissing]: 'MESSAGE_TRAILER_NUMBER_MISSING',
  [TrackingMessageType.TrailerNumberIsInvalid]: 'MESSAGE_TRAILER_NUMBER_INVALID',
  [TrackingMessageType.IncompatiblePhone]: 'MESSAGE_INCOMPATIBLE_PHONE',
  [TrackingMessageType.InvalidNumberNotTrackable]: 'MESSAGE_INVALID_NUMBER_NOT_TRACKABLE',
  [TrackingMessageType.LandlineNotTrackable]: 'MESSAGE_LANDLINE_NOT_TRACKABLE',
  [TrackingMessageType.RequestingInstallation]: 'MESSAGE_REQUESTING_INSTALLATION',
  [TrackingMessageType.StoppedEarlyByDriver]: 'MESSAGE_STOPPED_EARLY',
  [TrackingMessageType.DeniedByDriver]: 'MESSAGE_DENIED',
  [TrackingMessageType.LocationHiddenByDriver]: 'MESSAGE_LOCATION_HIDDEN',
  [TrackingMessageType.DriverRefusedInstallation]: 'MESSAGE_DRIVER_REFUSED',
  [TrackingMessageType.EnterDriversPhone]: 'MESSAGE_ENTER_DRIVERS_PHONE',
  [TrackingMessageType.LandlineEntered]: 'MESSAGE_LANDLINE_ENTERED',
  [TrackingMessageType.DriverHasOptdOutOfSms]: 'MESSAGE_DRIVER_HAS_OPTED_OUT_OF_SMS',
  [TrackingMessageType.DriverHasOptdInForSms]: 'MESSAGE_DRIVER_HAS_OPTED_IN_FOR_SMS',
  [TrackingMessageType.SmsSentToDriver]: 'MESSAGE_SMS_SENT',
  [TrackingMessageType.DriverHasNotInstalledApplication]: 'MESSAGE_DRIVER_HAS_NOT_INSTALLED_APP',
  [TrackingMessageType.TermsHaveNotBeenAccepted]: 'MESSAGE_TERMS_NOT_ACCEPTED',
  [TrackingMessageType.CommunicationHasNotBeenAccepted]: 'MESSAGE_COMMUNICATION_NOT_ACCEPTED',
  [TrackingMessageType.LoadNotOpened]: 'MESSAGE_LOAD_NOT_OPENED',
  [TrackingMessageType.LocationServiceIsOff]: 'MESSAGE_LOCATION_SERVICE_OFF',
  [TrackingMessageType.DriverHasAcceptedCommunication]: 'MESSAGE_DRIVER_ACCEPTED_COMMUNICATION',
  [TrackingMessageType.DriverHasAcceptedTerms]: 'MESSAGE_DRIVER_ACCEPTED_TERMS',
  [TrackingMessageType.DriverHasLocationServicesOn]: 'MESSAGE_DRIVER_LOCATION_SERVICES_ON',
  [TrackingMessageType.DriverInstalledApplication]: 'MESSAGE_DRIVER_INSTALLED_APPLICATION',
  [TrackingMessageType.TruckerToolErrorPhoneNumberMissing]: 'MESSAGE_TRUCKER_TOOL_PHONE_NUMBER',
  [TrackingMessageType.TruckerToolError]: 'MESSAGE_TRUCKER_TOOL_SETUP',
  [TrackingMessageType.TruckerToolSetupPhoneNumberMissing]: 'MESSAGE_TRUCKER_TOOL_PHONE_NUMBER',
  [TrackingMessageType.TruckerToolSetup]: 'MESSAGE_TRUCKER_TOOL_SETUP',
  [TrackingMessageType.GPSWarning]: 'MESSAGE_GPS_WARNING',
  [TrackingMessageType.SelectUserName]: 'MESSAGE_SELECT_USERNAME',
  [TrackingMessageType.Undetected]: 'MESSAGE_CHECK_CALL_REQUIRED',
  [TrackingMessageType.InTransitException]: 'MESSAGE_CHECK_CALL_REQUIRED',
  [TrackingMessageType.LoadArrivalPickupException]: 'MESSAGE_LOAD_ARRIVAL_TIME_REQUIRED',
  [TrackingMessageType.LoadDeparturePickupException]: 'MESSAGE_LOAD_DEPARTURE_TIME_REQUIRED',
  [TrackingMessageType.LoadArrivalDropOffException]: 'MESSAGE_LOAD_ARRIVAL_TIME_REQUIRED',
  [TrackingMessageType.LoadDepartureDropOffException]: 'MESSAGE_LOAD_DEPARTURE_TIME_REQUIRED',
};

export const trackingAdditionalInfoMapping: { [key: number]: ResourceKey } = {
  [TrackingMessageType.LicensePlatesIsMissing] : 'ADDITIONAL_LICENSE_PLATES_MISSING',
  [TrackingMessageType.LicensePlatesIsInvalid] : 'ADDITIONAL_LICENSE_PLATES_INVALID',
  [TrackingMessageType.ProNumberIsMissing] : 'ADDITIONAL_PRO_NUMBER_MISSING',
  [TrackingMessageType.ProNumberIsInvalid] : 'ADDITIONAL_PRO_NUMBER_INVALID',
  [TrackingMessageType.DriversPhoneNumberIsMissing] : 'ADDITIONAL_PHONE_NUMBER_MISSING',
  [TrackingMessageType.DriversPhoneNumberIsInvalid] : 'ADDITIONAL_PHONE_NUMBER_INVALID',
  [TrackingMessageType.TractorNumberIsMissing] : 'ADDITIONAL_TRACTOR_NUMBER_MISSING',
  [TrackingMessageType.TractorNumberIsInvalid] : 'ADDITIONAL_TRACTOR_NUMBER_INVALID',
  [TrackingMessageType.TrailerNumberIsMissing] : 'ADDITIONAL_TRAILER_NUMBER_MISSING',
  [TrackingMessageType.TrailerNumberIsInvalid] : 'ADDITIONAL_TRAILER_NUMBER_INVALID',
  [TrackingMessageType.IncompatiblePhone] : 'ADDITIONAL_INCOMPATIBLE_PHONE',
  [TrackingMessageType.InvalidNumberNotTrackable] : 'ADDITIONAL_INVALID_NUMBER_NOT_TRACKABLE',
  [TrackingMessageType.LandlineNotTrackable] : 'ADDITIONAL_LANDLINE_NOT_TRACKABLE',
  [TrackingMessageType.RequestingInstallation] : 'ADDITIONAL_REQUESTING_INSTALLATION',
  [TrackingMessageType.StoppedEarlyByDriver] : 'ADDITIONAL_STOPPED_EARLY',
  [TrackingMessageType.DeniedByDriver] : 'ADDITIONAL_DENIED',
  [TrackingMessageType.LocationHiddenByDriver] : 'ADDITIONAL_LOCATION_HIDDEN',
  [TrackingMessageType.DriverRefusedInstallation] : 'ADDITIONAL_DRIVER_REFUSED',
  [TrackingMessageType.EnterDriversPhone] : 'ADDITIONAL_ENTER_DRIVERS_PHONE',
  [TrackingMessageType.LandlineEntered] : 'ADDITIONAL_LANDLINE_ENTERED',
  [TrackingMessageType.DriverHasOptdOutOfSms] : 'ADDITIONAL_DRIVER_HAS_OPTED_OUT_OF_SMS',
  [TrackingMessageType.DriverHasOptdInForSms] : 'ADDITIONAL_DRIVER_HAS_OPTED_IN_FOR_SMS',
  [TrackingMessageType.SmsSentToDriver] : 'ADDITIONAL_SMS_SENT',
  [TrackingMessageType.DriverHasNotInstalledApplication] : 'ADDITIONAL_DRIVER_HAS_NOT_INSTALLED_APP',
  [TrackingMessageType.TermsHaveNotBeenAccepted] : 'ADDITIONAL_TERMS_NOT_ACCEPTED',
  [TrackingMessageType.CommunicationHasNotBeenAccepted] : 'ADDITIONAL_COMMUNICATION_NOT_ACCEPTED',
  [TrackingMessageType.LoadNotOpened] : 'ADDITIONAL_LOAD_NOT_OPENED',
  [TrackingMessageType.LocationServiceIsOff] : 'ADDITIONAL_LOCATION_SERVICE_OFF',
  [TrackingMessageType.DriverHasAcceptedCommunication] : 'ADDITIONAL_DRIVER_ACCEPTED_COMMUNICATION',
  [TrackingMessageType.DriverHasAcceptedTerms] : 'ADDITIONAL_DRIVER_ACCEPTED_TERMS',
  [TrackingMessageType.DriverHasLocationServicesOn] : 'ADDITIONAL_DRIVER_LOCATION_SERVICES_ON',
  [TrackingMessageType.DriverInstalledApplication] : 'ADDITIONAL_DRIVER_INSTALLED_APPLICATION',
  [TrackingMessageType.TruckerToolErrorPhoneNumberMissing] : 'ADDITIONAL_TRUCKER_TOOL_PHONE_NUMBER',
  [TrackingMessageType.TruckerToolError] : 'ADDITIONAL_TRUCKER_TOOL_SETUP',
  [TrackingMessageType.TruckerToolSetupPhoneNumberMissing] : 'ADDITIONAL_TRUCKER_TOOL_PHONE_NUMBER',
  [TrackingMessageType.TruckerToolSetup] : 'ADDITIONAL_TRUCKER_TOOL_SETUP',
  [TrackingMessageType.GPSWarning]: 'ADDITIONAL_GPS_WARNING',
  [TrackingMessageType.SelectUserName]: 'ADDITIONAL_SELECT_USERNAME'
};

export const fixProNumberMessageCodes = [
  TrackingMessageType.ProNumberIsMissing, // 6
];

export const fixTractorNumberMessageCodes = [
  TrackingMessageType.TractorNumberIsMissing, // 10
  TrackingMessageType.TractorNumberIsInvalid, // 11
];

export const fixTrailerNumberMessageCodes = [
  TrackingMessageType.TrailerNumberIsMissing, // 12
  TrackingMessageType.TrailerNumberIsInvalid, // 13
];

export const fixPhoneMessageCodes = [
  TrackingMessageType.DriversPhoneNumberIsMissing, // 8
  TrackingMessageType.DriversPhoneNumberIsInvalid, // 9
  TrackingMessageType.IncompatiblePhone, // 19,
  TrackingMessageType.InvalidNumberNotTrackable, // 20
  TrackingMessageType.LandlineNotTrackable, // 21
  TrackingMessageType.EnterDriversPhone, // 37
  TrackingMessageType.LandlineEntered, // 38
  TrackingMessageType.TruckerToolSetupPhoneNumberMissing, // 56
];

export const fixCheckCallMessageCodes = [
  TrackingMessageType.Undetected, // 70
  TrackingMessageType.InTransitException, // 75
];

export const stopActivitiesMessageCodes = [
  TrackingMessageType.ArrivalPickupException, // 71
  TrackingMessageType.DeparturePickupException, // 72
  TrackingMessageType.ArrivalDropOffException, // 73
  TrackingMessageType.DepartureDropOffException, // 74
];

export const fixDriverMessageCodes = [
  TrackingMessageType.SelectUserName, // 59
];

export const insideOfAppMessageCodes = [
  ...fixProNumberMessageCodes,
  ...fixTractorNumberMessageCodes,
  ...fixTrailerNumberMessageCodes,
  ...fixDriverMessageCodes,
  ...fixPhoneMessageCodes,
  ...fixCheckCallMessageCodes,
  ...stopActivitiesMessageCodes,
];

export const outsideOfAppMessageCodes = [
  TrackingMessageType.LicensePlatesIsMissing, // 4
  TrackingMessageType.LicensePlatesIsInvalid, // 5
  TrackingMessageType.ProNumberIsInvalid, // 7
  TrackingMessageType.RequestingInstallation, // 22
  TrackingMessageType.StoppedEarlyByDriver, // 23
  TrackingMessageType.DeniedByDriver, // 24
  TrackingMessageType.LocationHiddenByDriver, // 25,
  TrackingMessageType.DriverRefusedInstallation, // 26,
  TrackingMessageType.DriverHasOptdOutOfSms, // 39
  TrackingMessageType.DriverHasOptdInForSms, // 40
  TrackingMessageType.SmsSentToDriver, // 41
  TrackingMessageType.DriverHasNotInstalledApplication, // 42
  TrackingMessageType.TermsHaveNotBeenAccepted, // 43
  TrackingMessageType.CommunicationHasNotBeenAccepted, // 44
  TrackingMessageType.LoadNotOpened, // 45
  TrackingMessageType.LocationServiceIsOff, // 46
  TrackingMessageType.DriverHasAcceptedCommunication, // 47
  TrackingMessageType.DriverHasAcceptedTerms, // 48
  TrackingMessageType.DriverHasLocationServicesOn, // 49
  TrackingMessageType.DriverInstalledApplication, // 50
  TrackingMessageType.TruckerToolErrorPhoneNumberMissing, // 54
  TrackingMessageType.TruckerToolError, // 55
  TrackingMessageType.TruckerToolSetup, // 57
  TrackingMessageType.GPSWarning, // 60
];

export const resolveTypeMappings: { [key: number]: TrackingMessageType[] } = {
  [TrackingIdentifierType.None]: outsideOfAppMessageCodes,
  [TrackingIdentifierType.TractorNumber] : fixTractorNumberMessageCodes,
  [TrackingIdentifierType.TrailerNumber] : fixTrailerNumberMessageCodes,
  [TrackingIdentifierType.CellPhoneNumber] : fixPhoneMessageCodes,
  [TrackingIdentifierType.Name] : fixDriverMessageCodes,
  [TrackingIdentifierType.LicensePlate]: [],
  [TrackingIdentifierType.ProNumber] : fixProNumberMessageCodes,
  [TrackingIdentifierType.CheckCall] : fixCheckCallMessageCodes,
  [TrackingIdentifierType.ArrivalPickUp]: [TrackingMessageType.ArrivalPickupException],
  [TrackingIdentifierType.DeparturePickUp]: [TrackingMessageType.DeparturePickupException],
  [TrackingIdentifierType.ArrivalDropOff]: [TrackingMessageType.ArrivalDropOffException],
  [TrackingIdentifierType.DepartureDropOff]: [TrackingMessageType.DepartureDropOffException],
};

export const getTrackingIdentifier = (trackingMessageType: TrackingMessageType): TrackingIdentifierType => {
  for (const [identifierKey, value] of Object.entries(resolveTypeMappings)) {
    if (value.indexOf(trackingMessageType) !== -1) {
      return Number(identifierKey) as TrackingIdentifierType;
    }
  }

  return TrackingIdentifierType.None;
};

