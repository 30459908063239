export enum ContactDepartment {
  Accounting = 1,
  Auditor = 2,
  AutotenderNotificationRecipient = 3,
  TransmitBillingErrorToCarrier = 4,
  CustomsBroker = 5,
  Dispatch = 6,
  EdiContact = 7,
  ExecutiveLevel = 8,
  InsuranceCompanyRepresentative = 9,
  ManagementLevel = 10,
  GeneralSwitchboard = 11,
  OperationsLevel = 12,
  PaymentService = 13,
  Personal = 14,
  ProofOfDelivery = 15,
  WarehouseReceivingContact = 16,
  SafetyDirector = 17,
  SalesManager = 18,
  Sales = 19,
  WarehouseShippingContact = 20,
  TransportationManagementCenter = 21,
  CompanyMain = 22,
  Driver = 23,
  InternationalTrans = 24,
  ApprovedContractSigner = 25,
  WebAdministrator = 26,
  Purchasing = 28,
  TSAContact = 29,
  Agent = 30,
  Assistant = 31,
  TransportBuyer = 32,
  ShippingManager = 33,
  OnlineContact = 38
}
