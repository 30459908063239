export const GlobalizationCountries = [
  {
    'code': 'AF',
    'iso3166Alpha3Code': 'AFG',
    'name': 'Afghanistan',
    'locales': [
      {
        'countryCode': 'AF',
        'displayName': 'English',
        'locale': 'en-AF'
      }
    ]
  },
  {
    'code': 'AX',
    'iso3166Alpha3Code': 'ALA',
    'name': 'Aland Islands',
    'locales': [
      {
        'countryCode': 'AX',
        'displayName': 'English',
        'locale': 'en-AX'
      }
    ]
  },
  {
    'code': 'AL',
    'iso3166Alpha3Code': 'ALB',
    'name': 'Albania',
    'locales': [
      {
        'countryCode': 'AL',
        'displayName': 'English',
        'locale': 'sq-AL'
      }
    ]
  },
  {
    'code': 'DZ',
    'iso3166Alpha3Code': 'DZA',
    'name': 'Algeria',
    'locales': [
      {
        'countryCode': 'DZ',
        'displayName': 'English',
        'locale': 'ar-DZ'
      }
    ]
  },
  {
    'code': 'AS',
    'iso3166Alpha3Code': 'ASM',
    'name': 'American Samoa',
    'locales': [
      {
        'countryCode': 'AS',
        'displayName': 'English',
        'locale': 'en-AS'
      }
    ]
  },
  {
    'code': 'AD',
    'iso3166Alpha3Code': 'AND',
    'name': 'Andorra',
    'locales': [
      {
        'countryCode': 'AD',
        'displayName': 'English',
        'locale': 'en-AD'
      }
    ]
  },
  {
    'code': 'AO',
    'iso3166Alpha3Code': 'AGO',
    'name': 'Angola',
    'locales': [
      {
        'countryCode': 'AO',
        'displayName': 'English',
        'locale': 'en-AO'
      }
    ]
  },
  {
    'code': 'AI',
    'iso3166Alpha3Code': 'AIA',
    'name': 'Anguilla',
    'locales': [
      {
        'countryCode': 'AI',
        'displayName': 'English',
        'locale': 'en-AI'
      }
    ]
  },
  {
    'code': 'AQ',
    'iso3166Alpha3Code': 'ATA',
    'name': 'Antarctica',
    'locales': [
      {
        'countryCode': 'AQ',
        'displayName': 'English',
        'locale': 'en-AQ'
      }
    ]
  },
  {
    'code': 'AG',
    'iso3166Alpha3Code': 'ATG',
    'name': 'Antigua and Barbuda',
    'locales': [
      {
        'countryCode': 'AG',
        'displayName': 'English',
        'locale': 'en-AG'
      }
    ]
  },
  {
    'code': 'AR',
    'iso3166Alpha3Code': 'ARG',
    'name': 'Argentina',
    'locales': [
      {
        'countryCode': 'AR',
        'displayName': 'Spanish',
        'locale': 'es-AR'
      }
    ]
  },
  {
    'code': 'AM',
    'iso3166Alpha3Code': 'ARM',
    'name': 'Armenia',
    'locales': [
      {
        'countryCode': 'AM',
        'displayName': 'English',
        'locale': 'hy-AM'
      }
    ]
  },
  {
    'code': 'AW',
    'iso3166Alpha3Code': 'ABW',
    'name': 'Aruba',
    'locales': [
      {
        'countryCode': 'AW',
        'displayName': 'English',
        'locale': 'en-AW'
      }
    ]
  },
  {
    'code': 'AU',
    'iso3166Alpha3Code': 'AUS',
    'name': 'Australia',
    'locales': [
      {
        'countryCode': 'AU',
        'displayName': 'English',
        'locale': 'en-AU'
      }
    ]
  },
  {
    'code': 'AT',
    'iso3166Alpha3Code': 'AUT',
    'name': 'Austria',
    'locales': [
      {
        'countryCode': 'AT',
        'displayName': 'English',
        'locale': 'de-AT'
      }
    ]
  },
  {
    'code': 'AZ',
    'iso3166Alpha3Code': 'AZE',
    'name': 'Azerbaijan',
    'locales': [
      {
        'countryCode': 'AZ',
        'displayName': 'English',
        'locale': 'en-AZ'
      }
    ]
  },
  {
    'code': 'BS',
    'iso3166Alpha3Code': 'BHS',
    'name': 'Bahamas',
    'locales': [
      {
        'countryCode': 'BS',
        'displayName': 'English',
        'locale': 'en-BS'
      }
    ]
  },
  {
    'code': 'BH',
    'iso3166Alpha3Code': 'BHR',
    'name': 'Bahrain',
    'locales': [
      {
        'countryCode': 'BH',
        'displayName': 'English',
        'locale': 'ar-BH'
      }
    ]
  },
  {
    'code': 'BD',
    'iso3166Alpha3Code': 'BGD',
    'name': 'Bangladesh',
    'locales': [
      {
        'countryCode': 'BD',
        'displayName': 'English',
        'locale': 'en-BD'
      }
    ]
  },
  {
    'code': 'BB',
    'iso3166Alpha3Code': 'BRB',
    'name': 'Barbados',
    'locales': [
      {
        'countryCode': 'BB',
        'displayName': 'English',
        'locale': 'en-BB'
      }
    ]
  },
  {
    'code': 'BY',
    'iso3166Alpha3Code': 'BLR',
    'name': 'Belarus',
    'locales': [
      {
        'countryCode': 'BY',
        'displayName': 'English',
        'locale': 'en-BY'
      }
    ]
  },
  {
    'code': 'BE',
    'iso3166Alpha3Code': 'BEL',
    'name': 'Belgium',
    'locales': [
      {
        'countryCode': 'BE',
        'displayName': 'French',
        'locale': 'fr-BE'
      },
      {
        'countryCode': 'BE',
        'displayName': 'English',
        'locale': 'en-US'
      }
    ]
  },
  {
    'code': 'BZ',
    'iso3166Alpha3Code': 'BLZ',
    'name': 'Belize',
    'locales': [
      {
        'countryCode': 'BZ',
        'displayName': 'English',
        'locale': 'en-BZ'
      }
    ]
  },
  {
    'code': 'BJ',
    'iso3166Alpha3Code': 'BEN',
    'name': 'Benin',
    'locales': [
      {
        'countryCode': 'BJ',
        'displayName': 'English',
        'locale': 'en-BJ'
      }
    ]
  },
  {
    'code': 'BM',
    'iso3166Alpha3Code': 'BMU',
    'name': 'Bermuda',
    'locales': [
      {
        'countryCode': 'BM',
        'displayName': 'English',
        'locale': 'en-BM'
      }
    ]
  },
  {
    'code': 'BT',
    'iso3166Alpha3Code': 'BTN',
    'name': 'Bhutan',
    'locales': [
      {
        'countryCode': 'BT',
        'displayName': 'English',
        'locale': 'en-BT'
      }
    ]
  },
  {
    'code': 'BO',
    'iso3166Alpha3Code': 'BOL',
    'name': 'Bolivia',
    'locales': [
      {
        'countryCode': 'BO',
        'displayName': 'Spanish',
        'locale': 'es-BO'
      }
    ]
  },
  {
    'code': 'BA',
    'iso3166Alpha3Code': 'BIH',
    'name': 'Bosnia and Herzegovina',
    'locales': [
      {
        'countryCode': 'BA',
        'displayName': 'English',
        'locale': 'en-BA'
      }
    ]
  },
  {
    'code': 'BW',
    'iso3166Alpha3Code': 'BWA',
    'name': 'Botswana',
    'locales': [
      {
        'countryCode': 'BW',
        'displayName': 'English',
        'locale': 'en-BW'
      }
    ]
  },
  {
    'code': 'BV',
    'iso3166Alpha3Code': 'BVT',
    'name': 'Bouvet Island',
    'locales': [
      {
        'countryCode': 'BV',
        'displayName': 'English',
        'locale': 'en-BV'
      }
    ]
  },
  {
    'code': 'BR',
    'iso3166Alpha3Code': 'BRA',
    'name': 'Brazil',
    'locales': [
      {
        'countryCode': 'BR',
        'displayName': 'Portuguese',
        'locale': 'pt-BR'
      }
    ]
  },
  {
    'code': 'IO',
    'iso3166Alpha3Code': 'IOT',
    'name': 'British Indian Ocean Territory',
    'locales': [
      {
        'countryCode': 'IO',
        'displayName': 'English',
        'locale': 'en-IO'
      }
    ]
  },
  {
    'code': 'BN',
    'iso3166Alpha3Code': 'BRN',
    'name': 'Brunei Darussalam',
    'locales': [
      {
        'countryCode': 'BN',
        'displayName': 'English',
        'locale': 'en-BN'
      }
    ]
  },
  {
    'code': 'BG',
    'iso3166Alpha3Code': 'BGR',
    'name': 'Bulgaria',
    'locales': [
      {
        'countryCode': 'BG',
        'displayName': 'Bulgarian',
        'locale': 'bg-BG'
      }
    ]
  },
  {
    'code': 'BF',
    'iso3166Alpha3Code': 'BFA',
    'name': 'Burkina Faso',
    'locales': [
      {
        'countryCode': 'BF',
        'displayName': 'English',
        'locale': 'en-BF'
      }
    ]
  },
  {
    'code': 'BI',
    'iso3166Alpha3Code': 'BDI',
    'name': 'Burundi',
    'locales': [
      {
        'countryCode': 'BI',
        'displayName': 'English',
        'locale': 'en-BI'
      }
    ]
  },
  {
    'code': 'KH',
    'iso3166Alpha3Code': 'KHM',
    'name': 'Cambodia',
    'locales': [
      {
        'countryCode': 'KH',
        'displayName': 'English',
        'locale': 'en-KH'
      }
    ]
  },
  {
    'code': 'CM',
    'iso3166Alpha3Code': 'CMR',
    'name': 'Cameroon',
    'locales': [
      {
        'countryCode': 'CM',
        'displayName': 'English',
        'locale': 'en-CM'
      }
    ]
  },
  {
    'code': 'CA',
    'iso3166Alpha3Code': 'CAN',
    'name': 'Canada',
    'locales': [
      {
        'countryCode': 'CA',
        'displayName': 'English',
        'locale': 'en-CA'
      },
      {
        'countryCode': 'CA',
        'displayName': 'French',
        'locale': 'fr-CA'
      }
    ]
  },
  {
    'code': 'IC',
    'iso3166Alpha3Code': null,
    'name': 'Canary Islands',
    'locales': [
      {
        'countryCode': 'IC',
        'displayName': 'English',
        'locale': 'en-IC'
      }
    ]
  },
  {
    'code': 'CV',
    'iso3166Alpha3Code': 'CPV',
    'name': 'Cape Verde',
    'locales': [
      {
        'countryCode': 'CV',
        'displayName': 'English',
        'locale': 'en-CV'
      }
    ]
  },
  {
    'code': 'KY',
    'iso3166Alpha3Code': 'CYM',
    'name': 'Cayman Islands',
    'locales': [
      {
        'countryCode': 'KY',
        'displayName': 'English',
        'locale': 'en-KY'
      }
    ]
  },
  {
    'code': 'CF',
    'iso3166Alpha3Code': 'CAF',
    'name': 'Central African Republic',
    'locales': [
      {
        'countryCode': 'CF',
        'displayName': 'English',
        'locale': 'en-CF'
      }
    ]
  },
  {
    'code': 'TD',
    'iso3166Alpha3Code': 'TCD',
    'name': 'Chad',
    'locales': [
      {
        'countryCode': 'TD',
        'displayName': 'English',
        'locale': 'en-TD'
      }
    ]
  },
  {
    'code': 'CL',
    'iso3166Alpha3Code': 'CHL',
    'name': 'Chile',
    'locales': [
      {
        'countryCode': 'CL',
        'displayName': 'Spanish',
        'locale': 'es-CL'
      }
    ]
  },
  {
    'code': 'CN',
    'iso3166Alpha3Code': 'CHN',
    'name': 'China',
    'locales': [
      {
        'countryCode': 'CN',
        'displayName': 'Chinese (Simplified)',
        'locale': 'zh-CN'
      }
    ]
  },
  {
    'code': 'CX',
    'iso3166Alpha3Code': 'CXR',
    'name': 'Christmas Island',
    'locales': [
      {
        'countryCode': 'CX',
        'displayName': 'English',
        'locale': 'en-CX'
      }
    ]
  },
  {
    'code': 'CC',
    'iso3166Alpha3Code': 'CCK',
    'name': 'Cocos (keeling) Islands',
    'locales': [
      {
        'countryCode': 'CC',
        'displayName': 'English',
        'locale': 'en-CC'
      }
    ]
  },
  {
    'code': 'CO',
    'iso3166Alpha3Code': 'COL',
    'name': 'Colombia',
    'locales': [
      {
        'countryCode': 'CO',
        'displayName': 'Spanish',
        'locale': 'es-CR'
      }
    ]
  },
  {
    'code': 'KM',
    'iso3166Alpha3Code': 'COM',
    'name': 'Comoros',
    'locales': [
      {
        'countryCode': 'KM',
        'displayName': 'English',
        'locale': 'en-KM'
      }
    ]
  },
  {
    'code': 'CG',
    'iso3166Alpha3Code': 'COG',
    'name': 'Congo',
    'locales': [
      {
        'countryCode': 'CG',
        'displayName': 'English',
        'locale': 'en-CG'
      }
    ]
  },
  {
    'code': 'CD',
    'iso3166Alpha3Code': 'COD',
    'name': 'Congo, The Democratic Republic Of The',
    'locales': [
      {
        'countryCode': 'CD',
        'displayName': 'English',
        'locale': 'en-CD'
      }
    ]
  },
  {
    'code': 'CK',
    'iso3166Alpha3Code': 'COK',
    'name': 'Cook Islands',
    'locales': [
      {
        'countryCode': 'CK',
        'displayName': 'English',
        'locale': 'en-CK'
      }
    ]
  },
  {
    'code': 'CR',
    'iso3166Alpha3Code': 'CRI',
    'name': 'Costa Rica',
    'locales': [
      {
        'countryCode': 'CR',
        'displayName': 'Spanish',
        'locale': 'es-CR'
      }
    ]
  },
  {
    'code': 'CI',
    'iso3166Alpha3Code': 'CIV',
    'name': 'Cote d\'Ivoire',
    'locales': [
      {
        'countryCode': 'CI',
        'displayName': 'English',
        'locale': 'en-CI'
      }
    ]
  },
  {
    'code': 'HR',
    'iso3166Alpha3Code': 'HRV',
    'name': 'Croatia',
    'locales': [
      {
        'countryCode': 'HR',
        'displayName': 'English',
        'locale': 'hr-HR'
      }
    ]
  },
  {
    'code': 'CU',
    'iso3166Alpha3Code': 'CUB',
    'name': 'Cuba',
    'locales': [
      {
        'countryCode': 'CU',
        'displayName': 'English',
        'locale': 'en-CU'
      }
    ]
  },
  {
    'code': 'CW',
    'iso3166Alpha3Code': 'CUW',
    'name': 'Curacao',
    'locales': [
      {
        'countryCode': 'CW',
        'displayName': 'English',
        'locale': 'en-CW'
      }
    ]
  },
  {
    'code': 'CY',
    'iso3166Alpha3Code': 'CYP',
    'name': 'Cyprus',
    'locales': [
      {
        'countryCode': 'CY',
        'displayName': 'English',
        'locale': 'en-CY'
      }
    ]
  },
  {
    'code': 'CZ',
    'iso3166Alpha3Code': 'CZE',
    'name': 'Czech Republic',
    'locales': [
      {
        'countryCode': 'CZ',
        'displayName': 'Czech',
        'locale': 'cs-CZ'
      }
    ]
  },
  {
    'code': 'DK',
    'iso3166Alpha3Code': 'DNK',
    'name': 'Denmark',
    'locales': [
      {
        'countryCode': 'DK',
        'displayName': 'English',
        'locale': 'da-DK'
      }
    ]
  },
  {
    'code': 'DJ',
    'iso3166Alpha3Code': 'DJI',
    'name': 'Djibouti',
    'locales': [
      {
        'countryCode': 'DJ',
        'displayName': 'English',
        'locale': 'en-DJ'
      }
    ]
  },
  {
    'code': 'DM',
    'iso3166Alpha3Code': 'DMA',
    'name': 'Dominica',
    'locales': [
      {
        'countryCode': 'DM',
        'displayName': 'English',
        'locale': 'en-DM'
      }
    ]
  },
  {
    'code': 'DO',
    'iso3166Alpha3Code': 'DOM',
    'name': 'Dominican Republic',
    'locales': [
      {
        'countryCode': 'DO',
        'displayName': 'Spanish',
        'locale': 'es-DO'
      }
    ]
  },
  {
    'code': 'TP',
    'iso3166Alpha3Code': 'TMP',
    'name': 'East Timor',
    'locales': [
      {
        'countryCode': 'TP',
        'displayName': 'English',
        'locale': 'en-TP'
      }
    ]
  },
  {
    'code': 'EC',
    'iso3166Alpha3Code': 'ECU',
    'name': 'Ecuador',
    'locales': [
      {
        'countryCode': 'EC',
        'displayName': 'Spanish',
        'locale': 'es-EC'
      }
    ]
  },
  {
    'code': 'EG',
    'iso3166Alpha3Code': 'EGY',
    'name': 'Egypt',
    'locales': [
      {
        'countryCode': 'EG',
        'displayName': 'English',
        'locale': 'ar-EG'
      }
    ]
  },
  {
    'code': 'SV',
    'iso3166Alpha3Code': 'SLV',
    'name': 'El Salvador',
    'locales': [
      {
        'countryCode': 'SV',
        'displayName': 'Spanish',
        'locale': 'es-SV'
      }
    ]
  },
  {
    'code': 'GQ',
    'iso3166Alpha3Code': 'GNQ',
    'name': 'Equatorial Guinea',
    'locales': [
      {
        'countryCode': 'GQ',
        'displayName': 'English',
        'locale': 'en-GQ'
      }
    ]
  },
  {
    'code': 'ER',
    'iso3166Alpha3Code': 'ERI',
    'name': 'Eritrea',
    'locales': [
      {
        'countryCode': 'ER',
        'displayName': 'English',
        'locale': 'en-ER'
      }
    ]
  },
  {
    'code': 'EE',
    'iso3166Alpha3Code': 'EST',
    'name': 'Estonia',
    'locales': [
      {
        'countryCode': 'EE',
        'displayName': 'English',
        'locale': 'et-EE'
      }
    ]
  },
  {
    'code': 'ET',
    'iso3166Alpha3Code': 'ETH',
    'name': 'Ethiopia',
    'locales': [
      {
        'countryCode': 'ET',
        'displayName': 'English',
        'locale': 'en-ET'
      }
    ]
  },
  {
    'code': 'FK',
    'iso3166Alpha3Code': 'FLK',
    'name': 'Falkland Islands (Malvinas)',
    'locales': [
      {
        'countryCode': 'FK',
        'displayName': 'English',
        'locale': 'en-FK'
      }
    ]
  },
  {
    'code': 'FO',
    'iso3166Alpha3Code': 'FRO',
    'name': 'Faroe Islands',
    'locales': [
      {
        'countryCode': 'FO',
        'displayName': 'English',
        'locale': 'en-FO'
      }
    ]
  },
  {
    'code': 'FM',
    'iso3166Alpha3Code': 'FSM',
    'name': 'Federated States of Micronesia',
    'locales': [
      {
        'countryCode': 'FM',
        'displayName': 'English',
        'locale': 'en-FM'
      }
    ]
  },
  {
    'code': 'FJ',
    'iso3166Alpha3Code': 'FJI',
    'name': 'Fiji',
    'locales': [
      {
        'countryCode': 'FJ',
        'displayName': 'English',
        'locale': 'en-FJ'
      }
    ]
  },
  {
    'code': 'FI',
    'iso3166Alpha3Code': 'FIN',
    'name': 'Finland',
    'locales': [
      {
        'countryCode': 'FI',
        'displayName': 'English',
        'locale': 'sv-FI'
      }
    ]
  },
  {
    'code': 'FR',
    'iso3166Alpha3Code': 'FRA',
    'name': 'France',
    'locales': [
      {
        'countryCode': 'FR',
        'displayName': 'French',
        'locale': 'fr-FR'
      }
    ]
  },
  {
    'code': 'GF',
    'iso3166Alpha3Code': 'GUF',
    'name': 'French Guiana',
    'locales': [
      {
        'countryCode': 'GF',
        'displayName': 'English',
        'locale': 'en-GF'
      }
    ]
  },
  {
    'code': 'PF',
    'iso3166Alpha3Code': 'PYF',
    'name': 'French Polynesia',
    'locales': [
      {
        'countryCode': 'PF',
        'displayName': 'English',
        'locale': 'en-PF'
      }
    ]
  },
  {
    'code': 'TF',
    'iso3166Alpha3Code': 'ATF',
    'name': 'French Southern Territories',
    'locales': [
      {
        'countryCode': 'TF',
        'displayName': 'English',
        'locale': 'en-TF'
      }
    ]
  },
  {
    'code': 'GA',
    'iso3166Alpha3Code': 'GAB',
    'name': 'Gabon',
    'locales': [
      {
        'countryCode': 'GA',
        'displayName': 'English',
        'locale': 'en-GA'
      }
    ]
  },
  {
    'code': 'GM',
    'iso3166Alpha3Code': 'GMB',
    'name': 'Gambia',
    'locales': [
      {
        'countryCode': 'GM',
        'displayName': 'English',
        'locale': 'en-GM'
      }
    ]
  },
  {
    'code': 'GE',
    'iso3166Alpha3Code': 'GEO',
    'name': 'Georgia',
    'locales': [
      {
        'countryCode': 'GE',
        'displayName': 'English',
        'locale': 'ka-GE'
      }
    ]
  },
  {
    'code': 'DE',
    'iso3166Alpha3Code': 'DEU',
    'name': 'Germany',
    'locales': [
      {
        'countryCode': 'DE',
        'displayName': 'German',
        'locale': 'de-DE'
      }
    ]
  },
  {
    'code': 'GH',
    'iso3166Alpha3Code': 'GHA',
    'name': 'Ghana',
    'locales': [
      {
        'countryCode': 'GH',
        'displayName': 'English',
        'locale': 'en-GH'
      }
    ]
  },
  {
    'code': 'GI',
    'iso3166Alpha3Code': 'GIB',
    'name': 'Gibraltar',
    'locales': [
      {
        'countryCode': 'GI',
        'displayName': 'English',
        'locale': 'en-GI'
      }
    ]
  },
  {
    'code': 'GR',
    'iso3166Alpha3Code': 'GRC',
    'name': 'Greece',
    'locales': [
      {
        'countryCode': 'GR',
        'displayName': 'English',
        'locale': 'el-GR'
      }
    ]
  },
  {
    'code': 'GL',
    'iso3166Alpha3Code': 'GRL',
    'name': 'Greenland',
    'locales': [
      {
        'countryCode': 'GL',
        'displayName': 'English',
        'locale': 'en-GL'
      }
    ]
  },
  {
    'code': 'GD',
    'iso3166Alpha3Code': 'GRD',
    'name': 'Grenada',
    'locales': [
      {
        'countryCode': 'GD',
        'displayName': 'English',
        'locale': 'en-GD'
      }
    ]
  },
  {
    'code': 'GP',
    'iso3166Alpha3Code': 'GLP',
    'name': 'Guadeloupe',
    'locales': [
      {
        'countryCode': 'GP',
        'displayName': 'English',
        'locale': 'en-GP'
      }
    ]
  },
  {
    'code': 'GU',
    'iso3166Alpha3Code': 'GUM',
    'name': 'Guam',
    'locales': [
      {
        'countryCode': 'GU',
        'displayName': 'English',
        'locale': 'en-GU'
      }
    ]
  },
  {
    'code': 'GT',
    'iso3166Alpha3Code': 'GTM',
    'name': 'Guatemala',
    'locales': [
      {
        'countryCode': 'GT',
        'displayName': 'English',
        'locale': 'en-GT'
      }
    ]
  },
  {
    'code': 'GG',
    'iso3166Alpha3Code': 'GGY',
    'name': 'Guernsey',
    'locales': [
      {
        'countryCode': 'GG',
        'displayName': 'English',
        'locale': 'en-GG'
      }
    ]
  },
  {
    'code': 'GN',
    'iso3166Alpha3Code': 'GIN',
    'name': 'Guinea',
    'locales': [
      {
        'countryCode': 'GN',
        'displayName': 'English',
        'locale': 'en-GN'
      }
    ]
  },
  {
    'code': 'GW',
    'iso3166Alpha3Code': 'GNB',
    'name': 'Guinea-Bissau',
    'locales': [
      {
        'countryCode': 'GW',
        'displayName': 'English',
        'locale': 'en-GW'
      }
    ]
  },
  {
    'code': 'GY',
    'iso3166Alpha3Code': 'GUY',
    'name': 'Guyana',
    'locales': [
      {
        'countryCode': 'GY',
        'displayName': 'English',
        'locale': 'en-GY'
      }
    ]
  },
  {
    'code': 'HT',
    'iso3166Alpha3Code': 'HTI',
    'name': 'Haiti',
    'locales': [
      {
        'countryCode': 'HT',
        'displayName': 'English',
        'locale': 'en-HT'
      }
    ]
  },
  {
    'code': 'HM',
    'iso3166Alpha3Code': 'HMD',
    'name': 'Heard Island And Mcdonald Islands',
    'locales': [
      {
        'countryCode': 'HM',
        'displayName': 'English',
        'locale': 'en-HM'
      }
    ]
  },
  {
    'code': 'HN',
    'iso3166Alpha3Code': 'HND',
    'name': 'Honduras',
    'locales': [
      {
        'countryCode': 'HN',
        'displayName': 'Spanish',
        'locale': 'es-HN'
      }
    ]
  },
  {
    'code': 'HK',
    'iso3166Alpha3Code': 'HKG',
    'name': 'Hong Kong, China',
    'locales': [
      {
        'countryCode': 'HK',
        'displayName': 'Chinese (Traditional)',
        'locale': 'zh-TW'
      }
    ]
  },
  {
    'code': 'HU',
    'iso3166Alpha3Code': 'HUN',
    'name': 'Hungary',
    'locales': [
      {
        'countryCode': 'HU',
        'displayName': 'Hungarian',
        'locale': 'hu-HU'
      }
    ]
  },
  {
    'code': 'IS',
    'iso3166Alpha3Code': 'ISL',
    'name': 'Iceland',
    'locales': [
      {
        'countryCode': 'IS',
        'displayName': 'English',
        'locale': 'is-IS'
      }
    ]
  },
  {
    'code': 'IN',
    'iso3166Alpha3Code': 'IND',
    'name': 'India',
    'locales': [
      {
        'countryCode': 'IN',
        'displayName': 'English',
        'locale': 'en-GB'
      }
    ]
  },
  {
    'code': 'ID',
    'iso3166Alpha3Code': 'IDN',
    'name': 'Indonesia',
    'locales': [
      {
        'countryCode': 'ID',
        'displayName': 'English',
        'locale': 'id-ID'
      }
    ]
  },
  {
    'code': 'IR',
    'iso3166Alpha3Code': 'IRN',
    'name': 'Iran',
    'locales': [
      {
        'countryCode': 'IR',
        'displayName': 'English',
        'locale': 'en-IR'
      }
    ]
  },
  {
    'code': 'IQ',
    'iso3166Alpha3Code': 'IRQ',
    'name': 'Iraq',
    'locales': [
      {
        'countryCode': 'IQ',
        'displayName': 'English',
        'locale': 'en-IQ'
      }
    ]
  },
  {
    'code': 'IE',
    'iso3166Alpha3Code': 'IRL',
    'name': 'Ireland',
    'locales': [
      {
        'countryCode': 'IE',
        'displayName': 'English',
        'locale': 'en-IE'
      }
    ]
  },
  {
    'code': 'IM',
    'iso3166Alpha3Code': 'IMN',
    'name': 'Isle of Man',
    'locales': [
      {
        'countryCode': 'IM',
        'displayName': 'English',
        'locale': 'en-IM'
      }
    ]
  },
  {
    'code': 'IL',
    'iso3166Alpha3Code': 'ISR',
    'name': 'Israel',
    'locales': [
      {
        'countryCode': 'IL',
        'displayName': 'English',
        'locale': 'he-IL'
      }
    ]
  },
  {
    'code': 'IT',
    'iso3166Alpha3Code': 'ITA',
    'name': 'Italy',
    'locales': [
      {
        'countryCode': 'IT',
        'displayName': 'Italian',
        'locale': 'it-IT'
      }
    ]
  },
  {
    'code': 'JM',
    'iso3166Alpha3Code': 'JAM',
    'name': 'Jamaica',
    'locales': [
      {
        'countryCode': 'JM',
        'displayName': 'English',
        'locale': 'en-JM'
      }
    ]
  },
  {
    'code': 'JP',
    'iso3166Alpha3Code': 'JPN',
    'name': 'Japan',
    'locales': [
      {
        'countryCode': 'JP',
        'displayName': 'Japanese',
        'locale': 'ja-JP'
      }
    ]
  },
  {
    'code': 'JE',
    'iso3166Alpha3Code': 'JEY',
    'name': 'Jersey',
    'locales': [
      {
        'countryCode': 'JE',
        'displayName': 'English',
        'locale': 'en-JE'
      }
    ]
  },
  {
    'code': 'JO',
    'iso3166Alpha3Code': 'JOR',
    'name': 'Jordan',
    'locales': [
      {
        'countryCode': 'JO',
        'displayName': 'English',
        'locale': 'ar-JO'
      }
    ]
  },
  {
    'code': 'KZ',
    'iso3166Alpha3Code': 'KAZ',
    'name': 'Kazakhstan',
    'locales': [
      {
        'countryCode': 'KZ',
        'displayName': 'English',
        'locale': 'kk-KZ'
      }
    ]
  },
  {
    'code': 'KE',
    'iso3166Alpha3Code': 'KEN',
    'name': 'Kenya',
    'locales': [
      {
        'countryCode': 'KE',
        'displayName': 'English',
        'locale': 'sw-KE'
      }
    ]
  },
  {
    'code': 'KI',
    'iso3166Alpha3Code': 'KIR',
    'name': 'Kiribati',
    'locales': [
      {
        'countryCode': 'KI',
        'displayName': 'English',
        'locale': 'en-KI'
      }
    ]
  },
  {
    'code': 'XK',
    'iso3166Alpha3Code': 'XKX',
    'name': 'Kosovo',
    'locales': [
      {
        'countryCode': 'XK',
        'displayName': 'English',
        'locale': 'en-XK'
      }
    ]
  },
  {
    'code': 'KW',
    'iso3166Alpha3Code': 'KWT',
    'name': 'Kuwait',
    'locales': [
      {
        'countryCode': 'KW',
        'displayName': 'English',
        'locale': 'ar-KW'
      }
    ]
  },
  {
    'code': 'KG',
    'iso3166Alpha3Code': 'KGZ',
    'name': 'Kyrgyzstan',
    'locales': [
      {
        'countryCode': 'KG',
        'displayName': 'English',
        'locale': 'ky-KZ'
      }
    ]
  },
  {
    'code': 'LA',
    'iso3166Alpha3Code': 'LAO',
    'name': 'Lao',
    'locales': [
      {
        'countryCode': 'LA',
        'displayName': 'English',
        'locale': 'en-LA'
      }
    ]
  },
  {
    'code': 'LV',
    'iso3166Alpha3Code': 'LVA',
    'name': 'Latvia',
    'locales': [
      {
        'countryCode': 'LV',
        'displayName': 'English',
        'locale': 'lv-LV'
      }
    ]
  },
  {
    'code': 'LB',
    'iso3166Alpha3Code': 'LBN',
    'name': 'Lebanon',
    'locales': [
      {
        'countryCode': 'LB',
        'displayName': 'English',
        'locale': 'ar-LB'
      }
    ]
  },
  {
    'code': 'LS',
    'iso3166Alpha3Code': 'LSO',
    'name': 'Lesotho',
    'locales': [
      {
        'countryCode': 'LS',
        'displayName': 'English',
        'locale': 'en-LS'
      }
    ]
  },
  {
    'code': 'LR',
    'iso3166Alpha3Code': 'LBR',
    'name': 'Liberia',
    'locales': [
      {
        'countryCode': 'LR',
        'displayName': 'English',
        'locale': 'en-LR'
      }
    ]
  },
  {
    'code': 'LY',
    'iso3166Alpha3Code': 'LBY',
    'name': 'Libyan Arab Jamahiriya',
    'locales': [
      {
        'countryCode': 'LY',
        'displayName': 'English',
        'locale': 'en-LY'
      }
    ]
  },
  {
    'code': 'LI',
    'iso3166Alpha3Code': 'LIE',
    'name': 'Liechtenstein',
    'locales': [
      {
        'countryCode': 'LI',
        'displayName': 'English',
        'locale': 'en-LI'
      }
    ]
  },
  {
    'code': 'LT',
    'iso3166Alpha3Code': 'LTU',
    'name': 'Lithuania',
    'locales': [
      {
        'countryCode': 'LT',
        'displayName': 'English',
        'locale': 'lt-LT'
      }
    ]
  },
  {
    'code': 'LU',
    'iso3166Alpha3Code': 'LUX',
    'name': 'Luxembourg',
    'locales': [
      {
        'countryCode': 'LU',
        'displayName': 'English',
        'locale': 'fr-LU'
      }
    ]
  },
  {
    'code': 'MO',
    'iso3166Alpha3Code': 'MAC',
    'name': 'Macau',
    'locales': [
      {
        'countryCode': 'MO',
        'displayName': 'English',
        'locale': 'en-MO'
      }
    ]
  },
  {
    'code': 'MK',
    'iso3166Alpha3Code': 'MKD',
    'name': 'Macedonia',
    'locales': [
      {
        'countryCode': 'MK',
        'displayName': 'English',
        'locale': 'en-MK'
      }
    ]
  },
  {
    'code': 'MG',
    'iso3166Alpha3Code': 'MDG',
    'name': 'Madagascar',
    'locales': [
      {
        'countryCode': 'MG',
        'displayName': 'English',
        'locale': 'en-MG'
      }
    ]
  },
  {
    'code': 'MW',
    'iso3166Alpha3Code': 'MWI',
    'name': 'Malawi',
    'locales': [
      {
        'countryCode': 'MW',
        'displayName': 'English',
        'locale': 'en-MW'
      }
    ]
  },
  {
    'code': 'MY',
    'iso3166Alpha3Code': 'MYS',
    'name': 'Malaysia',
    'locales': [
      {
        'countryCode': 'MY',
        'displayName': 'English',
        'locale': 'ms-MY'
      }
    ]
  },
  {
    'code': 'MV',
    'iso3166Alpha3Code': 'MDV',
    'name': 'Maldives',
    'locales': [
      {
        'countryCode': 'MV',
        'displayName': 'English',
        'locale': 'en-MV'
      }
    ]
  },
  {
    'code': 'ML',
    'iso3166Alpha3Code': 'MLI',
    'name': 'Mali',
    'locales': [
      {
        'countryCode': 'ML',
        'displayName': 'English',
        'locale': 'en-ML'
      }
    ]
  },
  {
    'code': 'MT',
    'iso3166Alpha3Code': 'MLT',
    'name': 'Malta',
    'locales': [
      {
        'countryCode': 'MT',
        'displayName': 'English',
        'locale': 'en-MT'
      }
    ]
  },
  {
    'code': 'MH',
    'iso3166Alpha3Code': 'MHL',
    'name': 'Marshall Islands',
    'locales': [
      {
        'countryCode': 'MH',
        'displayName': 'English',
        'locale': 'en-MH'
      }
    ]
  },
  {
    'code': 'MQ',
    'iso3166Alpha3Code': 'MTQ',
    'name': 'Martinique',
    'locales': [
      {
        'countryCode': 'MQ',
        'displayName': 'English',
        'locale': 'en-MQ'
      }
    ]
  },
  {
    'code': 'MR',
    'iso3166Alpha3Code': 'MRT',
    'name': 'Mauritania',
    'locales': [
      {
        'countryCode': 'MR',
        'displayName': 'English',
        'locale': 'en-MR'
      }
    ]
  },
  {
    'code': 'MU',
    'iso3166Alpha3Code': 'MUS',
    'name': 'Mauritius',
    'locales': [
      {
        'countryCode': 'MU',
        'displayName': 'English',
        'locale': 'en-MU'
      }
    ]
  },
  {
    'code': 'YT',
    'iso3166Alpha3Code': 'MYT',
    'name': 'Mayotte',
    'locales': [
      {
        'countryCode': 'YT',
        'displayName': 'English',
        'locale': 'en-YT'
      }
    ]
  },
  {
    'code': 'MX',
    'iso3166Alpha3Code': 'MEX',
    'name': 'Mexico',
    'locales': [
      {
        'countryCode': 'MX',
        'displayName': 'Spanish',
        'locale': 'es-MX'
      }
    ]
  },
  {
    'code': 'MC',
    'iso3166Alpha3Code': 'MCO',
    'name': 'Monaco',
    'locales': [
      {
        'countryCode': 'MC',
        'displayName': 'English',
        'locale': 'fr-MC'
      }
    ]
  },
  {
    'code': 'MN',
    'iso3166Alpha3Code': 'MNG',
    'name': 'Mongolia',
    'locales': [
      {
        'countryCode': 'MN',
        'displayName': 'English',
        'locale': 'mn-MN'
      }
    ]
  },
  {
    'code': 'ME',
    'iso3166Alpha3Code': 'MNE',
    'name': 'Montenegro',
    'locales': [
      {
        'countryCode': 'ME',
        'displayName': 'English',
        'locale': 'en-ME'
      }
    ]
  },
  {
    'code': 'MS',
    'iso3166Alpha3Code': 'MSR',
    'name': 'Montserrat',
    'locales': [
      {
        'countryCode': 'MS',
        'displayName': 'English',
        'locale': 'en-MS'
      }
    ]
  },
  {
    'code': 'MA',
    'iso3166Alpha3Code': 'MAR',
    'name': 'Morocco',
    'locales': [
      {
        'countryCode': 'MA',
        'displayName': 'English',
        'locale': 'ar-MA'
      }
    ]
  },
  {
    'code': 'MZ',
    'iso3166Alpha3Code': 'MOZ',
    'name': 'Mozambique',
    'locales': [
      {
        'countryCode': 'MZ',
        'displayName': 'English',
        'locale': 'en-MZ'
      }
    ]
  },
  {
    'code': 'MM',
    'iso3166Alpha3Code': 'MMR',
    'name': 'Myanmar (Burma)',
    'locales': [
      {
        'countryCode': 'MM',
        'displayName': 'English',
        'locale': 'en-MM'
      }
    ]
  },
  {
    'code': 'NA',
    'iso3166Alpha3Code': 'NAM',
    'name': 'Namibia',
    'locales': [
      {
        'countryCode': 'NA',
        'displayName': 'English',
        'locale': 'en-NA'
      }
    ]
  },
  {
    'code': 'NR',
    'iso3166Alpha3Code': 'NRU',
    'name': 'Nauru',
    'locales': [
      {
        'countryCode': 'NR',
        'displayName': 'English',
        'locale': 'en-NR'
      }
    ]
  },
  {
    'code': 'NP',
    'iso3166Alpha3Code': 'NPL',
    'name': 'Nepal',
    'locales': [
      {
        'countryCode': 'NP',
        'displayName': 'English',
        'locale': 'en-NP'
      }
    ]
  },
  {
    'code': 'NL',
    'iso3166Alpha3Code': 'NLD',
    'name': 'Netherlands',
    'locales': [
      {
        'countryCode': 'NL',
        'displayName': 'Dutch',
        'locale': 'nl-NL'
      }
    ]
  },
  {
    'code': 'NC',
    'iso3166Alpha3Code': 'NCL',
    'name': 'New Caledonia',
    'locales': [
      {
        'countryCode': 'NC',
        'displayName': 'English',
        'locale': 'en-NC'
      }
    ]
  },
  {
    'code': 'NZ',
    'iso3166Alpha3Code': 'NZL',
    'name': 'New Zealand',
    'locales': [
      {
        'countryCode': 'NZ',
        'displayName': 'English',
        'locale': 'en-NZ'
      }
    ]
  },
  {
    'code': 'NI',
    'iso3166Alpha3Code': 'NIC',
    'name': 'Nicaragua',
    'locales': [
      {
        'countryCode': 'NI',
        'displayName': 'Spanish',
        'locale': 'es-NI'
      }
    ]
  },
  {
    'code': 'NE',
    'iso3166Alpha3Code': 'NER',
    'name': 'Niger',
    'locales': [
      {
        'countryCode': 'NE',
        'displayName': 'English',
        'locale': 'en-NE'
      }
    ]
  },
  {
    'code': 'NG',
    'iso3166Alpha3Code': 'NGA',
    'name': 'Nigeria',
    'locales': [
      {
        'countryCode': 'NG',
        'displayName': 'English',
        'locale': 'en-NG'
      }
    ]
  },
  {
    'code': 'NU',
    'iso3166Alpha3Code': 'NIU',
    'name': 'Niue',
    'locales': [
      {
        'countryCode': 'NU',
        'displayName': 'English',
        'locale': 'en-NU'
      }
    ]
  },
  {
    'code': 'NF',
    'iso3166Alpha3Code': 'NFK',
    'name': 'Norfolk Island',
    'locales': [
      {
        'countryCode': 'NF',
        'displayName': 'English',
        'locale': 'en-NF'
      }
    ]
  },
  {
    'code': 'KP',
    'iso3166Alpha3Code': 'PRK',
    'name': 'North Korea',
    'locales': [
      {
        'countryCode': 'KP',
        'displayName': 'English',
        'locale': 'en-KP'
      }
    ]
  },
  {
    'code': 'MP',
    'iso3166Alpha3Code': 'MNP',
    'name': 'Northern Mariana Islands',
    'locales': [
      {
        'countryCode': 'MP',
        'displayName': 'English',
        'locale': 'en-MP'
      }
    ]
  },
  {
    'code': 'NO',
    'iso3166Alpha3Code': 'NOR',
    'name': 'Norway',
    'locales': [
      {
        'countryCode': 'NO',
        'displayName': 'English',
        'locale': 'nb-NO'
      }
    ]
  },
  {
    'code': 'OM',
    'iso3166Alpha3Code': 'OMN',
    'name': 'Oman',
    'locales': [
      {
        'countryCode': 'OM',
        'displayName': 'English',
        'locale': 'ar-OM'
      }
    ]
  },
  {
    'code': 'PK',
    'iso3166Alpha3Code': 'PAK',
    'name': 'Pakistan',
    'locales': [
      {
        'countryCode': 'PK',
        'displayName': 'English',
        'locale': 'ur-PK'
      }
    ]
  },
  {
    'code': 'PW',
    'iso3166Alpha3Code': 'PLW',
    'name': 'Palau',
    'locales': [
      {
        'countryCode': 'PW',
        'displayName': 'English',
        'locale': 'en-PW'
      }
    ]
  },
  {
    'code': 'PS',
    'iso3166Alpha3Code': 'PSE',
    'name': 'Palestinian Territory, Occupied',
    'locales': [
      {
        'countryCode': 'PS',
        'displayName': 'English',
        'locale': 'en-PS'
      }
    ]
  },
  {
    'code': 'PA',
    'iso3166Alpha3Code': 'PAN',
    'name': 'Panama',
    'locales': [
      {
        'countryCode': 'PA',
        'displayName': 'Spanish',
        'locale': 'es-PA'
      }
    ]
  },
  {
    'code': 'PG',
    'iso3166Alpha3Code': 'PNG',
    'name': 'Papua New Guinea',
    'locales': [
      {
        'countryCode': 'PG',
        'displayName': 'English',
        'locale': 'en-PG'
      }
    ]
  },
  {
    'code': 'PY',
    'iso3166Alpha3Code': 'PRY',
    'name': 'Paraguay',
    'locales': [
      {
        'countryCode': 'PY',
        'displayName': 'Spanish',
        'locale': 'es-PY'
      }
    ]
  },
  {
    'code': 'PE',
    'iso3166Alpha3Code': 'PER',
    'name': 'Peru',
    'locales': [
      {
        'countryCode': 'PE',
        'displayName': 'Spanish',
        'locale': 'es-PE'
      }
    ]
  },
  {
    'code': 'PH',
    'iso3166Alpha3Code': 'PHL',
    'name': 'Philippines',
    'locales': [
      {
        'countryCode': 'PH',
        'displayName': 'English',
        'locale': 'en-PH'
      }
    ]
  },
  {
    'code': 'PN',
    'iso3166Alpha3Code': 'PCN',
    'name': 'Pitcairn',
    'locales': [
      {
        'countryCode': 'PN',
        'displayName': 'English',
        'locale': 'en-PN'
      }
    ]
  },
  {
    'code': 'PL',
    'iso3166Alpha3Code': 'POL',
    'name': 'Poland',
    'locales': [
      {
        'countryCode': 'PL',
        'displayName': 'Polish',
        'locale': 'pl-PL'
      }
    ]
  },
  {
    'code': 'PT',
    'iso3166Alpha3Code': 'PRT',
    'name': 'Portugal',
    'locales': [
      {
        'countryCode': 'PT',
        'displayName': 'Portuguese',
        'locale': 'pt-PT'
      }
    ]
  },
  {
    'code': 'PR',
    'iso3166Alpha3Code': 'PRI',
    'name': 'Puerto Rico',
    'locales': [
      {
        'countryCode': 'PR',
        'displayName': 'Spanish',
        'locale': 'es-PR'
      }
    ]
  },
  {
    'code': 'QA',
    'iso3166Alpha3Code': 'QAT',
    'name': 'Qatar',
    'locales': [
      {
        'countryCode': 'QA',
        'displayName': 'English',
        'locale': 'en-QA'
      }
    ]
  },
  {
    'code': 'MD',
    'iso3166Alpha3Code': 'MDA',
    'name': 'Republic of Moldova',
    'locales': [
      {
        'countryCode': 'MD',
        'displayName': 'English',
        'locale': 'en-MD'
      }
    ]
  },
  {
    'code': 'RE',
    'iso3166Alpha3Code': 'REU',
    'name': 'Reunion',
    'locales': [
      {
        'countryCode': 'RE',
        'displayName': 'English',
        'locale': 'en-RE'
      }
    ]
  },
  {
    'code': 'RO',
    'iso3166Alpha3Code': 'ROU',
    'name': 'Romania',
    'locales': [
      {
        'countryCode': 'RO',
        'displayName': 'Romanian',
        'locale': 'ro-RO'
      }
    ]
  },
  {
    'code': 'RU',
    'iso3166Alpha3Code': 'RUS',
    'name': 'Russian Federation',
    'locales': [
      {
        'countryCode': 'RU',
        'displayName': 'Russian',
        'locale': 'ru-RU'
      }
    ]
  },
  {
    'code': 'RW',
    'iso3166Alpha3Code': 'RWA',
    'name': 'Rwanda',
    'locales': [
      {
        'countryCode': 'RW',
        'displayName': 'English',
        'locale': 'en-RW'
      }
    ]
  },
  {
    'code': 'BL',
    'iso3166Alpha3Code': 'BLM',
    'name': 'Saint Barthelemy',
    'locales': [
      {
        'countryCode': 'BL',
        'displayName': 'English',
        'locale': 'en-BL'
      }
    ]
  },
  {
    'code': 'SH',
    'iso3166Alpha3Code': 'SHN',
    'name': 'Saint Helena',
    'locales': [
      {
        'countryCode': 'SH',
        'displayName': 'English',
        'locale': 'en-SH'
      }
    ]
  },
  {
    'code': 'KN',
    'iso3166Alpha3Code': 'KNA',
    'name': 'Saint Kitts And Nevis',
    'locales': [
      {
        'countryCode': 'KN',
        'displayName': 'English',
        'locale': 'en-KN'
      }
    ]
  },
  {
    'code': 'LC',
    'iso3166Alpha3Code': 'LCA',
    'name': 'Saint Lucia',
    'locales': [
      {
        'countryCode': 'LC',
        'displayName': 'English',
        'locale': 'en-LC'
      }
    ]
  },
  {
    'code': 'MF',
    'iso3166Alpha3Code': 'MAF',
    'name': 'Saint Martin (French part)',
    'locales': [
      {
        'countryCode': 'MF',
        'displayName': 'English',
        'locale': 'en-MF'
      }
    ]
  },
  {
    'code': 'PM',
    'iso3166Alpha3Code': 'SPM',
    'name': 'Saint Pierre And Miquelon',
    'locales': [
      {
        'countryCode': 'PM',
        'displayName': 'English',
        'locale': 'en-PM'
      }
    ]
  },
  {
    'code': 'VC',
    'iso3166Alpha3Code': 'VCT',
    'name': 'Saint Vincent And The Grenadines',
    'locales': [
      {
        'countryCode': 'VC',
        'displayName': 'English',
        'locale': 'en-VC'
      }
    ]
  },
  {
    'code': 'WS',
    'iso3166Alpha3Code': 'WSM',
    'name': 'Samoa',
    'locales': [
      {
        'countryCode': 'WS',
        'displayName': 'English',
        'locale': 'en-WS'
      }
    ]
  },
  {
    'code': 'SM',
    'iso3166Alpha3Code': 'SMR',
    'name': 'San Marino',
    'locales': [
      {
        'countryCode': 'SM',
        'displayName': 'English',
        'locale': 'en-SM'
      }
    ]
  },
  {
    'code': 'ST',
    'iso3166Alpha3Code': 'STP',
    'name': 'Sao Tome and Principe',
    'locales': [
      {
        'countryCode': 'ST',
        'displayName': 'English',
        'locale': 'en-ST'
      }
    ]
  },
  {
    'code': 'SA',
    'iso3166Alpha3Code': 'SAU',
    'name': 'Saudi Arabia',
    'locales': [
      {
        'countryCode': 'SA',
        'displayName': 'English',
        'locale': 'ar-SA'
      }
    ]
  },
  {
    'code': 'SN',
    'iso3166Alpha3Code': 'SEN',
    'name': 'Senegal',
    'locales': [
      {
        'countryCode': 'SN',
        'displayName': 'English',
        'locale': 'en-SN'
      }
    ]
  },
  {
    'code': 'RS',
    'iso3166Alpha3Code': 'SRB',
    'name': 'Serbia',
    'locales': [
      {
        'countryCode': 'RS',
        'displayName': 'English',
        'locale': 'sr-Latn-SP'
      }
    ]
  },
  {
    'code': 'SC',
    'iso3166Alpha3Code': 'SYC',
    'name': 'Seychelles',
    'locales': [
      {
        'countryCode': 'SC',
        'displayName': 'English',
        'locale': 'en-SC'
      }
    ]
  },
  {
    'code': 'SL',
    'iso3166Alpha3Code': 'SLE',
    'name': 'Sierra Leone',
    'locales': [
      {
        'countryCode': 'SL',
        'displayName': 'English',
        'locale': 'en-SL'
      }
    ]
  },
  {
    'code': 'SG',
    'iso3166Alpha3Code': 'SGP',
    'name': 'Singapore',
    'locales': [
      {
        'countryCode': 'SG',
        'displayName': 'English',
        'locale': 'en-GB'
      },
      {
        'countryCode': 'SG',
        'displayName': 'Chinese (Simplified)',
        'locale': 'zh-CN'
      }
    ]
  },
  {
    'code': 'SK',
    'iso3166Alpha3Code': 'SVK',
    'name': 'Slovakia',
    'locales': [
      {
        'countryCode': 'SK',
        'displayName': 'Slovak',
        'locale': 'sk-SK'
      }
    ]
  },
  {
    'code': 'SI',
    'iso3166Alpha3Code': 'SVN',
    'name': 'Slovenia',
    'locales': [
      {
        'countryCode': 'SI',
        'displayName': 'English',
        'locale': 'sl-SI'
      }
    ]
  },
  {
    'code': 'SB',
    'iso3166Alpha3Code': 'SLB',
    'name': 'Solomon Islands',
    'locales': [
      {
        'countryCode': 'SB',
        'displayName': 'English',
        'locale': 'en-SB'
      }
    ]
  },
  {
    'code': 'SO',
    'iso3166Alpha3Code': 'SOM',
    'name': 'Somalia',
    'locales': [
      {
        'countryCode': 'SO',
        'displayName': 'English',
        'locale': 'en-SO'
      }
    ]
  },
  {
    'code': 'ZA',
    'iso3166Alpha3Code': 'ZAF',
    'name': 'South Africa',
    'locales': [
      {
        'countryCode': 'ZA',
        'displayName': 'English',
        'locale': 'en-ZA'
      }
    ]
  },
  {
    'code': 'GS',
    'iso3166Alpha3Code': 'SGS',
    'name': 'South Georgia And The South Sandwich Islands',
    'locales': [
      {
        'countryCode': 'GS',
        'displayName': 'English',
        'locale': 'en-GS'
      }
    ]
  },
  {
    'code': 'KR',
    'iso3166Alpha3Code': 'KOR',
    'name': 'South Korea',
    'locales': [
      {
        'countryCode': 'KR',
        'displayName': 'Korean',
        'locale': 'ko-KR'
      }
    ]
  },
  {
    'code': 'SS',
    'iso3166Alpha3Code': 'SSD',
    'name': 'South Sudan',
    'locales': [
      {
        'countryCode': 'SS',
        'displayName': 'English',
        'locale': 'en-SS'
      }
    ]
  },
  {
    'code': 'ES',
    'iso3166Alpha3Code': 'ESP',
    'name': 'Spain',
    'locales': [
      {
        'countryCode': 'ES',
        'displayName': 'Spanish',
        'locale': 'es-ES'
      }
    ]
  },
  {
    'code': 'LK',
    'iso3166Alpha3Code': 'LKA',
    'name': 'Sri Lanka',
    'locales': [
      {
        'countryCode': 'LK',
        'displayName': 'English',
        'locale': 'en-LK'
      }
    ]
  },
  {
    'code': 'SD',
    'iso3166Alpha3Code': 'SDN',
    'name': 'Sudan',
    'locales': [
      {
        'countryCode': 'SD',
        'displayName': 'English',
        'locale': 'en-SD'
      }
    ]
  },
  {
    'code': 'SR',
    'iso3166Alpha3Code': 'SUR',
    'name': 'Suriname',
    'locales': [
      {
        'countryCode': 'SR',
        'displayName': 'English',
        'locale': 'en-SR'
      }
    ]
  },
  {
    'code': 'SJ',
    'iso3166Alpha3Code': 'SJM',
    'name': 'Svalbard And Jan Mayen',
    'locales': [
      {
        'countryCode': 'SJ',
        'displayName': 'English',
        'locale': 'en-SJ'
      }
    ]
  },
  {
    'code': 'SZ',
    'iso3166Alpha3Code': 'SWZ',
    'name': 'Swaziland',
    'locales': [
      {
        'countryCode': 'SZ',
        'displayName': 'English',
        'locale': 'en-SZ'
      }
    ]
  },
  {
    'code': 'SE',
    'iso3166Alpha3Code': 'SWE',
    'name': 'Sweden',
    'locales': [
      {
        'countryCode': 'SE',
        'displayName': 'English',
        'locale': 'sv-SE'
      }
    ]
  },
  {
    'code': 'CH',
    'iso3166Alpha3Code': 'CHE',
    'name': 'Switzerland',
    'locales': [
      {
        'countryCode': 'CH',
        'displayName': 'English',
        'locale': 'fr-CH'
      }
    ]
  },
  {
    'code': 'SY',
    'iso3166Alpha3Code': 'SYR',
    'name': 'Syrian Arab Republic',
    'locales': [
      {
        'countryCode': 'SY',
        'displayName': 'English',
        'locale': 'ar-SY'
      }
    ]
  },
  {
    'code': 'TW',
    'iso3166Alpha3Code': 'TWN',
    'name': 'Taiwan, China',
    'locales': [
      {
        'countryCode': 'TW',
        'displayName': 'Chinese (Traditional)',
        'locale': 'zh-TW'
      }
    ]
  },
  {
    'code': 'TJ',
    'iso3166Alpha3Code': 'TJK',
    'name': 'Tajikistan',
    'locales': [
      {
        'countryCode': 'TJ',
        'displayName': 'English',
        'locale': 'en-TJ'
      }
    ]
  },
  {
    'code': 'TZ',
    'iso3166Alpha3Code': 'TZA',
    'name': 'Tanzania',
    'locales': [
      {
        'countryCode': 'TZ',
        'displayName': 'English',
        'locale': 'en-TZ'
      }
    ]
  },
  {
    'code': 'TH',
    'iso3166Alpha3Code': 'THA',
    'name': 'Thailand',
    'locales': [
      {
        'countryCode': 'TH',
        'displayName': 'English',
        'locale': 'th-TH'
      }
    ]
  },
  {
    'code': 'TL',
    'iso3166Alpha3Code': 'TLS',
    'name': 'Timor-Leste',
    'locales': [
      {
        'countryCode': 'TL',
        'displayName': 'English',
        'locale': 'en-TL'
      }
    ]
  },
  {
    'code': 'TG',
    'iso3166Alpha3Code': 'TGO',
    'name': 'Togo',
    'locales': [
      {
        'countryCode': 'TG',
        'displayName': 'English',
        'locale': 'en-TG'
      }
    ]
  },
  {
    'code': 'TK',
    'iso3166Alpha3Code': 'TKL',
    'name': 'Tokelau',
    'locales': [
      {
        'countryCode': 'TK',
        'displayName': 'English',
        'locale': 'en-TK'
      }
    ]
  },
  {
    'code': 'TO',
    'iso3166Alpha3Code': 'TON',
    'name': 'Tonga',
    'locales': [
      {
        'countryCode': 'TO',
        'displayName': 'English',
        'locale': 'en-TO'
      }
    ]
  },
  {
    'code': 'TT',
    'iso3166Alpha3Code': 'TTO',
    'name': 'Trinidad and Tobago',
    'locales': [
      {
        'countryCode': 'TT',
        'displayName': 'English',
        'locale': 'en-TT'
      }
    ]
  },
  {
    'code': 'TN',
    'iso3166Alpha3Code': 'TUN',
    'name': 'Tunisia',
    'locales': [
      {
        'countryCode': 'TN',
        'displayName': 'English',
        'locale': 'en-TN'
      }
    ]
  },
  {
    'code': 'TR',
    'iso3166Alpha3Code': 'TUR',
    'name': 'Turkey',
    'locales': [
      {
        'countryCode': 'TR',
        'displayName': 'Turkish',
        'locale': 'tr-TR'
      }
    ]
  },
  {
    'code': 'TM',
    'iso3166Alpha3Code': 'TKM',
    'name': 'Turkmenistan',
    'locales': [
      {
        'countryCode': 'TM',
        'displayName': 'English',
        'locale': 'en-TM'
      }
    ]
  },
  {
    'code': 'TC',
    'iso3166Alpha3Code': 'TCA',
    'name': 'Turks and Caicos Islands',
    'locales': [
      {
        'countryCode': 'TC',
        'displayName': 'English',
        'locale': 'en-TC'
      }
    ]
  },
  {
    'code': 'TV',
    'iso3166Alpha3Code': 'TUV',
    'name': 'Tuvalu',
    'locales': [
      {
        'countryCode': 'TV',
        'displayName': 'English',
        'locale': 'en-TV'
      }
    ]
  },
  {
    'code': 'VI',
    'iso3166Alpha3Code': 'VIR',
    'name': 'U.S. Virgin Islands',
    'locales': [
      {
        'countryCode': 'VI',
        'displayName': 'English',
        'locale': 'en-VI'
      }
    ]
  },
  {
    'code': 'UG',
    'iso3166Alpha3Code': 'UGA',
    'name': 'Uganda',
    'locales': [
      {
        'countryCode': 'UG',
        'displayName': 'English',
        'locale': 'en-UG'
      }
    ]
  },
  {
    'code': 'UA',
    'iso3166Alpha3Code': 'UKR',
    'name': 'Ukraine',
    'locales': [
      {
        'countryCode': 'UA',
        'displayName': 'English',
        'locale': 'uk-UA'
      }
    ]
  },
  {
    'code': 'AE',
    'iso3166Alpha3Code': 'ARE',
    'name': 'United Arab Emirates',
    'locales': [
      {
        'countryCode': 'AE',
        'displayName': 'English',
        'locale': 'ar-AE'
      }
    ]
  },
  {
    'code': 'GB',
    'iso3166Alpha3Code': 'GBR',
    'name': 'United Kingdom',
    'locales': [
      {
        'countryCode': 'GB',
        'displayName': 'English',
        'locale': 'en-GB'
      }
    ]
  },
  {
    'code': 'US',
    'iso3166Alpha3Code': 'USA',
    'name': 'United States',
    'locales': [
      {
        'countryCode': 'US',
        'displayName': 'English',
        'locale': 'en-US'
      }
    ]
  },
  {
    'code': 'UM',
    'iso3166Alpha3Code': 'UMI',
    'name': 'United States Minor Outlying Islands',
    'locales': [
      {
        'countryCode': 'UM',
        'displayName': 'English',
        'locale': 'en-UM'
      }
    ]
  },
  {
    'code': 'UY',
    'iso3166Alpha3Code': 'URY',
    'name': 'Uruguay',
    'locales': [
      {
        'countryCode': 'UY',
        'displayName': 'Spanish',
        'locale': 'es-UY'
      }
    ]
  },
  {
    'code': 'UZ',
    'iso3166Alpha3Code': 'UZB',
    'name': 'Uzbekistan',
    'locales': [
      {
        'countryCode': 'UZ',
        'displayName': 'English',
        'locale': 'uz-Latn-UZ'
      }
    ]
  },
  {
    'code': 'VU',
    'iso3166Alpha3Code': 'VUT',
    'name': 'Vanuatu',
    'locales': [
      {
        'countryCode': 'VU',
        'displayName': 'English',
        'locale': 'en-VU'
      }
    ]
  },
  {
    'code': 'VA',
    'iso3166Alpha3Code': 'VAT',
    'name': 'Vatican',
    'locales': [
      {
        'countryCode': 'VA',
        'displayName': 'English',
        'locale': 'en-VA'
      }
    ]
  },
  {
    'code': 'VE',
    'iso3166Alpha3Code': 'VEN',
    'name': 'Venezuela',
    'locales': [
      {
        'countryCode': 'VE',
        'displayName': 'Spanish',
        'locale': 'es-VE'
      }
    ]
  },
  {
    'code': 'VN',
    'iso3166Alpha3Code': 'VNM',
    'name': 'Vietnam',
    'locales': [
      {
        'countryCode': 'VN',
        'displayName': 'English',
        'locale': 'vi-VN'
      }
    ]
  },
  {
    'code': 'VG',
    'iso3166Alpha3Code': 'VGB',
    'name': 'Virgin Islands, British',
    'locales': [
      {
        'countryCode': 'VG',
        'displayName': 'English',
        'locale': 'en-VG'
      }
    ]
  },
  {
    'code': 'WF',
    'iso3166Alpha3Code': 'WLF',
    'name': 'Wallis and Futuna',
    'locales': [
      {
        'countryCode': 'WF',
        'displayName': 'English',
        'locale': 'en-WF'
      }
    ]
  },
  {
    'code': 'EH',
    'iso3166Alpha3Code': 'ESH',
    'name': 'Western Sahara',
    'locales': [
      {
        'countryCode': 'EH',
        'displayName': 'English',
        'locale': 'en-EH'
      }
    ]
  },
  {
    'code': 'YE',
    'iso3166Alpha3Code': 'YEM',
    'name': 'Yemen',
    'locales': [
      {
        'countryCode': 'YE',
        'displayName': 'English',
        'locale': 'ar-YE'
      }
    ]
  },
  {
    'code': 'ZR',
    'iso3166Alpha3Code': 'ZAR',
    'name': 'Zaire',
    'locales': [
      {
        'countryCode': 'ZR',
        'displayName': 'English',
        'locale': 'en-ZR'
      }
    ]
  },
  {
    'code': 'ZM',
    'iso3166Alpha3Code': 'ZMB',
    'name': 'Zambia',
    'locales': [
      {
        'countryCode': 'ZM',
        'displayName': 'English',
        'locale': 'en-ZM'
      }
    ]
  },
  {
    'code': 'ZW',
    'iso3166Alpha3Code': 'ZWE',
    'name': 'Zimbabwe',
    'locales': [
      {
        'countryCode': 'ZW',
        'displayName': 'English',
        'locale': 'en-ZW'
      }
    ]
  }
];
