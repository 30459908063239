import { LoadSummaryAddress } from 'shared/models/loads/load-summaries/load-summary-address.model';
import { BaseDriverLog } from 'shared/models/loads/driver-logs/base-driver-log.model';

export class ExtendedDriverLog extends BaseDriverLog {

  address: LoadSummaryAddress;
  loadNumber: number;
  internalNotes: string;
  milesToNextStop: number;
  actualDateTime: Date;
  expectedDateTime: Date;
  logDateTime: Date;
  timeZoneDateTime: {
    displayDateTime: string,
    timeZoneAbbreviation: string,
    timeZoneDescription: string
  };
  private informationFrom;
  private enteredBy;

  constructor(json: ExtendedDriverLogJSON) {
    super();

    this.notes = json.notes;
    this.sourceUser = json.enteredBy;
    this.informationFrom = json.informationFrom;
    this.logDateTime = this.processDate(json.entered);

    this.loadNumber = json.loadNumber;
    this.internalNotes = json.internalNotes;
    this.milesToNextStop = json.milesToNextStop;
    this.expectedDateTime = this.processDate(json.expectedDateTime);
    this.actualDateTime = this.processDate(json.actualDateTime);

    this.timeZoneDateTime = json.timeZoneDateTime;

    if (json.address) {
      this.address = new LoadSummaryAddress(json.address);
    }
  }
  // @ts-ignore
  get sourceSystem() {
    return this.informationFrom;
  }
  set sourceSystem(value: string) {
    this.informationFrom = value;
  }
  // @ts-ignore
  get sourceUser() {
    return this.enteredBy;
  }
  set sourceUser(value: string) {
    this.enteredBy = value;
  }

  toJson(): ExtendedDriverLogJSON {
    return {
      notes: this.notes,
      enteredBy: this.enteredBy,
      loadNumber: this.loadNumber,
      internalNotes: this.internalNotes,
      informationFrom: this.informationFrom,
      milesToNextStop: this.milesToNextStop,
      expectedDateTime: this.convertDateToDateString(this.expectedDateTime),
      actualDateTime: this.convertDateToDateString(this.actualDateTime),
      address: this.address?.toJson(),
      entered: this.convertDateToDateString(this.logDateTime),
      timeZoneDateTime: {
       displayDateTime: this.timeZoneDateTime?.displayDateTime,
       timeZoneAbbreviation: this.timeZoneDateTime?.timeZoneAbbreviation,
       timeZoneDescription: this.timeZoneDateTime?.timeZoneDescription
      }
    };
  }
}
