import { APIErrorCode } from 'app/repositories/errors/api-error-code';

export class APIError {
  readonly message: string;
  readonly code: APIErrorCode;
  constructor(error: API.Error) {
    this.message = error.message;
    this.code = new APIErrorCode(error.code);
  }
}
