import { Action } from 'redux';

import { ExtendedLoad } from 'shared/models/loads/extended-load.model';
import { CashAdvanceOptions } from 'shared/models/cash-advances/cash-advance-options.model';

export const FETCH_CASH_ADVANCE_OPTIONS = 'FETCH_CASH_ADVANCE_OPTIONS';
export const FETCH_CASH_ADVANCE_OPTIONS_SUCCESS = 'FETCH_CASH_ADVANCE_OPTIONS_SUCCESS';

export interface FetchCashAdvanceOptionsAction extends Action {
  load: ExtendedLoad;
}

export interface FetchCashAdvanceOptionsSuccessAction extends Action {
  payload: CashAdvanceOptions;
}

export const fetchCashAdvanceOptions = (load: ExtendedLoad): FetchCashAdvanceOptionsAction => ({type: FETCH_CASH_ADVANCE_OPTIONS, load});
export const fetchCashAdvanceOptionsSuccess =
  (payload: CashAdvanceOptions): FetchCashAdvanceOptionsSuccessAction => ({type: FETCH_CASH_ADVANCE_OPTIONS_SUCCESS, payload});
