import qs from 'qs';
import { Container, Inject, Service } from 'typedi';
import 'rxjs/add/operator/map';

import { CacheableRepository } from 'app/repositories/cacheable.repository';
import { Configuration } from 'shared/models/config/configuration.model';
import { BranchLinkType } from 'shared/enums/branch-link-type.enum';

@Service()
export class SettingsRepository extends CacheableRepository {
  @Inject('apiConfig.settingsAPI')
  protected baseUrl;

  public getConfig() {
    return this.get(`/configuration?cb=${Math.random()}`, {'App-Version': Container.get('appConstants.buildNumber')})
      .map(json => new Configuration(json));
  }

  public getBranchData(id: string, type: BranchLinkType) {
    const query = qs.stringify({branchLinkType: BranchLinkType[type]}, {addQueryPrefix: true});
    return this.get(`/links/${id}${query}`);
  }
}
