import { Inject, Service } from 'typedi';
import { Observable } from 'rxjs/Observable';

import { CacheableRepository } from 'app/repositories/cacheable.repository';

@Service()
export class WebsiteRepository extends CacheableRepository {
  @Inject('apiConfig.websiteAPI')
  protected baseUrl;

  getGlobalizationCultures(usingAcceptLanguage?: boolean): Observable<GlobalizationCulture[]> {
    return this.getWithCache(`/Globalization/Cultures${usingAcceptLanguage ? '?usingAcceptLanguage=true' : ''}`);
  }
}
