export const EquipmentTypesEU = [
  {
    'code': 'F',
    'description': 'Flatbed',
    'value': 'Flatbed'
  },
  {
    'code': 'L',
    'description': 'Refers to a carrier that commonly consolidates more than one shipment on ',
    'value': 'LTL'
  },
  {
    'code': 'B',
    'description': 'Bulk',
    'value': 'Bulk'
  },
  {
    'code': 'R',
    'description': 'Refrigerated',
    'value': 'Refrigerated'
  },
  {
    'code': 'U',
    'description': 'Taut / Tilt liner',
    'value': 'Taut / Tilt liner'
  },
  {
    'code': 'V',
    'description': 'Box',
    'value': 'Box'
  }
];
