import { Container } from 'typedi';
import { Action, AnyAction } from 'redux';
import { ActionsObservable, combineEpics } from 'redux-observable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/mergeMap';

import * as a from './app.actions';
import * as authActions from '@features/security/auth.actions';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import { InitDefaultCountryAction, initDefaultCountry } from './app.actions';
import { User } from 'shared/models/user.model';
import { HIDE_MODAL, SHOW_MODAL } from 'shared/components/modal/modal.actions';
import { SettingsRepository } from '@app/repositories/settings.repository';

interface Deps {
  settingsRepo: SettingsRepository;
}

type AppEpic<OutputAction extends AnyAction> = NavCarrierEpic<OutputAction, Deps>;

export const initAppEpic: NavCarrierEpic<AnyAction> = action$ =>
  action$.ofType(a.INIT_APP)
    .mergeMap(() =>
      ActionsObservable.from([
        a.initSettings()
      ])
    );

export const getSettingsEpic: AppEpic<AnyAction> = (action$, state$, {settingsRepo}) =>
  action$.ofType(a.GET_SETTINGS)
    .filter(() => !state$.value.app.configuration)
    .mergeMap(() =>
      settingsRepo.getConfig()
        .map(a.storeSettings)
        .catch(e => ActionsObservable.of(a.settingsFailure(e)))
    );

export const continueAppInitializationEpic: NavCarrierEpic<AnyAction> = action$ =>
  action$.ofType(a.STORE_SETTINGS, a.GET_SETTINGS_ERROR)
    .mapTo(authActions.initUser());

export const completeInitializationEpic: NavCarrierEpic<Action> = action$ =>
  action$.ofType(authActions.FETCH_CARRIER_DETAIL_FOR_USER_SUCCESS)
    .map(() => a.completeAppInitialization());

export const initDefaultCountryOnLoginEpic: NavCarrierEpic<InitDefaultCountryAction> = (action$, state$) =>
  action$.ofType<authActions.CompleteLoginAction>(authActions.COMPLETE_LOGIN)
    .map(({userJSON}) => initDefaultCountry(new User(userJSON), state$.value.refData.location.countries));

export const initDefaultCountryEpic: NavCarrierEpic<a.SetDefaultCountryAction> = (action$, state$) =>
  action$.ofType<a.InitDefaultCountryAction>(a.INIT_DEFAULT_COUNTRY)
    .filter(() => state$.value.app.defaultCountry == null)
    .filter(({user, countries}) => Boolean(user && countries.length))
    .map(({user, countries}) => countries.find(country => country.code === user.properties.country) || null)
    .map(a.setDefaultCountry);

// Whenever a modal is shown or hidden, check all modals and figure out if any are visible.
// Whenever at least one modal is visible, the body should have class "modal-open"
const bodyModalClassEpic: NavCarrierEpic<AnyAction> = (action$, store$) =>
  action$.ofType(SHOW_MODAL, HIDE_MODAL)
    .map(() => Object.values(store$.value.modals))
    .map(modals => modals.filter(modal => modal.visible))
    .do(visibleModals =>
      visibleModals.length > 0
        ? document.body.classList.add('modal-open')
        : document.body.classList.remove('modal-open')
    )
    .ignoreElements();

export const appEpic = (action$, store) => combineEpics(
  initAppEpic,
  getSettingsEpic,
  continueAppInitializationEpic,
  completeInitializationEpic,
  initDefaultCountryOnLoginEpic,
  initDefaultCountryEpic,
  bodyModalClassEpic
)(action$, store, {settingsRepo: Container.get(SettingsRepository)});
