export const headerContainer = {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%'
}

export const contentContainer = {
  padding: '24px 32px',
  width: '100%',
  height: '98%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

export const dialog = {
  width: '100% !important',
}

export const body = {
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  maxWidth: '800px',
  justifyContent: 'center'
}

export const bodyShortScreen = {
  ...body,
  justifyContent: 'flex-start'
}

export const bodyBlock = {
  padding: '24px 0px',
  textAlign: 'center'
}

export const checkBoxSection = {
  padding: '24px 0px',
}

export const checkBox = {
  '& .PrivateSwitchBase-input': {
	position: 'absolute',
  }
}

export const dialogContainer = {
  '& .MuiPaper-root': {
	width: '100%',
  },
  zIndex: '1100' // need to display this dialog on top of nav dropdown
}

export const continueButtonContainer = {
  width: '200px',
  height: '56px',
  '& .MuiButtonBase-root': {
	height: '100%'
  }
}

export const membershipContainer = {
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'auto',
  maxHeight: '266px',
}