import { APIError } from 'app/repositories/errors/api-error';

export class APIErrorResponse {
  readonly type: ErrorType;
  readonly errors: APIError[];
  constructor(response: API.ErrorResponse, readonly status: number) {
    this.type = response?.type as any || ErrorType.ExceptionOccurred;
    this.errors = response?.errors?.map(error => new APIError(error));
  }
  includes(code: string) {
    return Boolean(this.errors?.find(error => error.code.code === code));
  }
  includesSpecificError(api: number, category: number, code: string) {
    return Boolean(this.errors?.find(e => e.code.shortCode === code && e.code.apiName === api && e.code.codeCategory === category));
  }
}

export enum ErrorType {
  BatchValidationFailure,
  ValidationFailure,
  ExceptionOccurred,
  TimeOutFailure
}
