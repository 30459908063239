import { BaseModel } from 'shared/models/base.model';

export class Driver extends BaseModel {
  public sequenceNumber?: number;
  public contactId?: number;
  public firstName: string;
  public lastName: string;
  public phoneNumber?: string;

  constructor(json?: DriverJSON) {
    super(json);
  }
}
