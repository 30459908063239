import { BaseModel } from 'shared/models/base.model';

export class Settings extends BaseModel<SettingsJSON> {
  private features_AppSee: string;
  private features_Crashlytics: string;
  private features_GoogleAnalytics: string;
  private features_UrbanAirship: string;
  private services_MyLoads: string;
  private services_FindLoads: string;
  private services_Documents: string;
  private services_Location: string;
  private services_User: string;
  private services_Notifications: string;
  private services_Carrier: string;
  maintenanceStartDate: Date;
  maintenanceEndDate: Date;
  constructor(json?: SettingsJSON) {
    super(json);
    if (json) {
      this.maintenanceStartDate = this.processDate(json.maintenanceStartDate);
      this.maintenanceEndDate = this.processDate(json.maintenanceEndDate);
    }
  }
}
