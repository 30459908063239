import moment from 'moment';

import { BaseModel } from 'shared/models/base.model';

export class DetentionLoadingCharge extends BaseModel<DetentionLoadingJSON> {
  freeTime: string;
  arrivalTime: Date;
  startTime: Date;
  chargePerHour: number;
  chargeableTime: string;
  departureTime: Date;

  constructor(json?: DetentionLoadingJSON) {
    super(json);

    if (json) {
      this.arrivalTime = this.processDate(json.arrivalTime);
      this.startTime = this.processDate(json.startTime);
      this.departureTime = this.processDate(json.departureTime);
    }
  }

  getFreeTimeAsDate(): Date {
    return this.freeTime && moment()
      .set('hours', Number(this.freeTime.split(':')[0]))
      .set('minutes', Number(this.freeTime.split(':')[1]))
      .toDate();
  }

  toJson(): DetentionLoadingJSON {
    return {
      ...super.toJson(),
      arrivalTime: this.convertDateToDateString(this.arrivalTime),
      startTime: this.convertDateToDateString(this.startTime),
      departureTime: this.convertDateToDateString(this.departureTime),
    };
  }
}
