import { HIDE_LOADER, REGISTER_LOADER, SHOW_LOADER, UNREGISTER_LOADER } from 'shared/components/loading/loading.actions';
import { REQUEST_FAILURE, REQUEST_START, REQUEST_SUCCESS } from 'store/requests.actions';

export const loading = (state: { [key: string]: number } = {}, action) => {
  if (action.type.includes(':') && !action.suppressLoader) {
    const [genericType/*, specificType*/] = action.type.split(':');
    switch (genericType) {
      case REQUEST_START:
        return {
          ...state,
          main: (state.main || 0) + 1
        };
      case REQUEST_SUCCESS:
      case REQUEST_FAILURE:
        return {
          ...state,
          main: (state.main || 1) - 1
        };
    }
  }

  switch (action.type) {
    case REGISTER_LOADER:
      return {
        ...state,
        // because of the nature of AppComponent.initApp, loaders may be added to before they are registered.
        // We do not want to erase all showLoader results if the loader registers late.
        [action.name]: state[action.name] || 0
      };
    case UNREGISTER_LOADER:
      const newState = {
        ...state,
      };
      delete newState[action.name];
      return newState;
    case SHOW_LOADER:
      return {
        ...state,
        [action.name]: (state[action.name] || 0) + 1
      };
    case HIDE_LOADER:
      return {
        ...state,
        [action.name]: (state[action.name] > 0) ? state[action.name] - 1 : 0
      };
    default:
      return state;
  }
};
