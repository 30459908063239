/**
 * User migration actions for observability
 */
export enum AuthAction {
 ForcedLogout = 'Auth.ForcedLogout',
 CHRUILogout = 'Auth.CHRUILogout',
 LoginViewIsOkta = 'Auth.LoginView.Okta',
 LoginViewIsHmac = 'Auth.LoginView.Hmac',
 IsOkta = 'Auth.IsOkta',
 IsHmac = 'Auth.IsHmac',
 IsChrEmployee = 'Auth.IsChrEmployee',
 IsLimitedUser = 'Auth.IsLimitedUser',
 OktaLoginError = 'Auth.OktaLoginError',
 OktaLoginSuccess = 'Auth.OktaLoginSuccess',
 ManualLogout = 'Auth.ManualLogout',
 AuthenticationFailure = 'Auth.AuthenticationFailure',
 NoAccessToken = 'Auth.NoAccessToken'
}

export enum OktaContext {
 'mfa-verify',
 'forgot-password',
 'enroll-email',
 'mfa-verify-passcode',
 'primary-auth',
 'password-reset',
 'recovery-loading',
 'account-unlock',
 'error-state',
}

export enum OktaWidgetAction {
 //controller: password-reset : 403
 OktaPasswordComplexityNotMet = 'Auth.Widget.OktaPasswordComplexityNotMet',
 OktaPasswordCannotBeSameAsLast = 'Auth.Widget.OkltaPasswordCannotBeSameAsLast',

 //controller: primary-auth : 401
 OktaInvalidTokenProvided = 'Auth.Widget.OktaInvalidTokenProvided',
 OktaAuthenticationFailed = 'Auth.Widget.OktaAuthenticationFailed',

 //controller: account-unlock
 OktaAccountUnlockNoPermissions = 'Auth.Widget.OktaAccountUnlockNoPermissions',
 //controller: error-state
 OktaError = 'Auth.Widget.OktaError',
 OktaJwtIssuedInFutureError = 'Auth.Widget.OktaJwtIssuedInFutureError',
 // mfa-verify : 403
 OktaMfaVerifyInvalid = 'Auth.Widget.OktaMfaVerifyInvalid',
 OktaMfaCodeDoesNotMatch = 'Auth.Widget.OktaMfaCodeDoesNotMatch',
 OktaApiValidationFailed = 'Auth.Widget.OktaApiValidationFailed', // 400
 OktaMfaEmailAlreadySent = 'Auth.Widget.OktaMfaEmailAlreadySent', // 429

 // recovery-loading: 403
 OktaAccessedExpiredOrUsedLink = 'Auth.Widget.OktaAccessedExpiredOrUsedLink',
}

export const LogAndGetWidgetErrorAction = (ctx: OktaContext = OktaContext['primary-auth'], { message = 'Authentication failed', xhr }: any) => {
 const { errorSummary, errorCode } = xhr?.responseJSON;

 if (OktaContext['password-reset']) {
  return WidgetErrors[ctx][errorSummary | message];
 }

 return WidgetErrors[ctx][errorCode | message | errorSummary];
};

export const WidgetErrors = {
 ['primary-auth']: {
  E00000004: OktaWidgetAction.OktaAuthenticationFailed,
  E00000011: OktaWidgetAction.OktaInvalidTokenProvided,
  'Authentication failed': OktaWidgetAction.OktaAuthenticationFailed,
  'Invalid token provided': OktaWidgetAction.OktaInvalidTokenProvided,
 },
 ['password-reset']: {
  E0000080: OktaWidgetAction.OktaPasswordComplexityNotMet,
  'Password cannot be your current password': OktaWidgetAction.OktaPasswordCannotBeSameAsLast,
  'The password does not meet the complexity requirements of the current password policy.': OktaWidgetAction.OktaPasswordComplexityNotMet,
 },
 ['mfa-verify']: {
  E0000080: OktaWidgetAction.OktaMfaVerifyInvalid,
  E0000068: OktaWidgetAction.OktaMfaCodeDoesNotMatch,
  E0000069: OktaWidgetAction.OktaMfaVerifyInvalid,
  E0000001: OktaWidgetAction.OktaApiValidationFailed,
  E0000118: OktaWidgetAction.OktaMfaEmailAlreadySent,
 },
 ['recovery-loading']: {
  E0000105: OktaWidgetAction.OktaAccessedExpiredOrUsedLink,
 },
 ['account-unlock']: {
  E0000006: OktaWidgetAction.OktaAccountUnlockNoPermissions,
 },
 ['error-state']: {
  'The JWT was issued in the future': OktaWidgetAction.OktaJwtIssuedInFutureError,
 },
};