export const BookType = {
  AirDelivery: 'AD',
  AirShippersAgent: 'ASA',
  Agent: 'AG',
  AirLineHaul: 'ALH',
  AirPickup: 'AP',
  AirTerminalToTerminal: 'ATT',
  EquipmentManagement: 'EQ',
  IntermodalDelivery: 'IDL',
  IntermodalLineHaul: 'ILH',
  IntermodalPickup: 'IPU',
  IntermodalCrosstown: 'IXT',
  LTLDelivery: 'LDL',
  LTLLineHaul: 'LLH',
  LTLPickup: 'LPU',
  OceanPickup: 'OIPU',
  OceanDelivery: 'OIDL',
  OceanIntermodalLineHaul: 'OILH',
  OceanCrosstown: 'OIXT',
  OceanLineHaul: 'OLH',
  OceanShippersAgent: 'OSA',
  RailDoorToDoor: 'RDD',
  RailLineHaul: 'RDD',
  Truckload: 'TL',
  Vendor: 'V',
};

export const LTLBookTypes = [
  BookType.LTLDelivery,
  BookType.LTLPickup,
  BookType.LTLLineHaul
];
