import { IStringifyOptions } from 'qs';
import qs from 'qs';

interface RequestParams {
  [key: string]: any;
}

export const generateQueryString = (params: RequestParams, options?: IStringifyOptions) => {
  return '?' + qs.stringify(params, { skipNulls: true, ...options });
};
