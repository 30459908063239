import { Action, AnyAction, combineReducers } from 'redux';

import * as a from '@features/security/auth.actions';
import { User } from 'shared/models/user.model';

export const user = (state = null, action: Action): User => {
  switch (action.type) {
    case a.STORE_CREDENTIALS:
      return (action as a.StoreCredentialsAction).user;

    case a.LOGOUT:
    case a.LOGIN_ERROR:
      return null;
    default:
      return state;
  }
};

export const isACSUser = (state = false, action: AnyAction) => {
  switch (action.type) {
    case a.SET_IS_ACS_USER:
      return action.isACSUser;
    default:
      return state;
  }
};

export const carrier = (state = null, action: AnyAction) => {
  switch (action.type) {
    case a.COMPLETE_LOGIN:
      return action.carrierDetail;
    case a.SAVE_CARRIER:
      return action.payload;
    case a.LOGOUT:
      return null;
    default:
      return state;
  }
};

export const token = (state = '', action: Action) => {
  switch (action.type) {
    case a.STORE_CREDENTIALS:
      return (action as a.StoreCredentialsAction).token;
    case a.LOGOUT:
    case a.LOGIN_ERROR:
      return null;
    default:
      return state;
  }
};

export const postLoginActionQueue = (state = [], action: AnyAction) => {
  switch (action.type) {
    case a.DEFER_ACTION_FOR_AFTER_LOGIN:
      return [
        ...state,
        action.action
      ];
    case a.CLEAR_POST_LOGIN_QUEUE:
      return [];
    default:
      return state;
  }
};

export const isAuthenticated = (state = false, action) => {
  switch (action.type) {
    case a.STORE_CREDENTIALS:
      return true;
    case a.LOGOUT:
    case a.LOGIN_ERROR:
      return false;
    default:
      return state;
  }
};

export const errors = (state = null, {type, error}) => {
  switch (type) {
    case a.LOGIN_ERROR:
      return error;
    case a.COMPLETE_LOGIN:
    case a.LOGIN_FROM_COOKIE:
    case a.LOGIN_WITH_CREDENTIALS:
    case a.LOGOUT:
      return '';
    default:
      return state;
  }
};

export const pending = (state = {}, action) => {
  switch (action.type) {
    case (a.DISPLAY_INSURANCE_CONFIRMATION):
    case (a.DISPLAY_HAZMAT_CONFIRMATION):
    case (a.DISPLAY_TERMS_AND_CONDITIONS_CONFIRMATION):
      return {
        user: action.user,
        carrier: action.carrierDetail,
        userJSON: action.userJSON
      };
    case (a.LOGOUT):
    case (a.LOGIN_WITH_CREDENTIALS):
    case (a.COMPLETE_LOGIN):
      return {};
    default:
      return state;
  }
};

export const insuranceStatusConfirmed = (state = false, action) => {
  switch (action.type) {
    case a.CONFIRM_INSURANCE_STATUS:
      return true;
    case a.LOGOUT:
      return false;
    default:
      return state;
  }
};

export const loginWithCredentialsPerformed = (state: boolean = false, action) => {
  switch (action.type) {
    case a.LOGIN_WITH_CREDENTIALS:
      return true;
    case a.LOGIN_FROM_COOKIE:
    case a.LOGOUT:
    case a.LOGIN_ERROR:
      return false;
    default:
      return state;
  }
};

export const insuranceStatusRequiresConfirmation = (state = false, action) => {
  switch (action.type) {
    case a.DISPLAY_INSURANCE_CONFIRMATION:
      return true;
    case a.LOGIN_WITH_CREDENTIALS:
    case a.INIT_USER:
    case a.LOGOUT:
      return false;
    default:
      return state;
  }
};

export const hazmatStatusConfirmed = (state = false, action) => {
  switch (action.type) {
    case a.CONFIRM_HAZMAT_STATUS:
      return true;
    case a.LOGOUT:
      return false;
    default:
      return state;
  }
};

export const hazmatStatusRequiresConfirmation = (state = false, action) => {
  switch (action.type) {
    case a.DISPLAY_HAZMAT_CONFIRMATION:
      return true;
    case a.LOGIN_WITH_CREDENTIALS:
    case a.INIT_USER:
    case a.LOGOUT:
      return false;
    default:
      return state;
  }
};

export const auth = combineReducers({
  user,
  carrier,
  isACSUser,
  pending,
  token,
  isAuthenticated,
  errors,
  postLoginActionQueue,
  insuranceStatusConfirmed,
  loginWithCredentialsPerformed,
  insuranceStatusRequiresConfirmation,
  hazmatStatusConfirmed,
  hazmatStatusRequiresConfirmation
});
