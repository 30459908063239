import moment from 'moment';

import { API_DATE_FORMAT } from 'app/globals/constants';

export const defaultSearchCriteria: AvailableLoadSearchCriteriaJSON = {
  originLatitude: null,
  originLongitude: null,
  originCountryCode: null,
  originStateProvinceCode: null,
  originCity: null,
  originStateCodes: null,
  originStateCodesByCountryCode: null,
  originRadiusMiles: 100,
  destinationLatitude: null,
  destinationLongitude: null,
  destinationCountryCode: null,
  destinationStateProvinceCode: null,
  destinationCity: null,
  destinationStateCodes: null,
  destinationStateCodesByCountryCode: null,
  destinationRadiusMiles: null,
  pickupStart: moment().hours(0).minutes(0).seconds(0).format(API_DATE_FORMAT),
  pickupEnd: moment().hours(0).minutes(0).seconds(0).add(1, 'day').format(API_DATE_FORMAT),
  milesMin: null,
  milesMax: null,
  weightMin: null,
  weightMax: null,
  mode: null,
  specializedEquipmentCode: null,
  teamLoad: null,
  carrierTierAvailable: null,
  stfLoad: null,
  equipmentLengthMax: null,
  loadNumber: null,
  isStfBookable: null,
  webExclusive: null,
  pageSize: 5000
};

// This can be used to create a complete list of valid property keys for a given object.
// A practical use for this will be to validate which url query string parameters should be a part of the search criteria.
type KeysEnum<T> = { [P in keyof Required<T>]: true };

const emptyAvailableLoadSearchCriteria: KeysEnum<AvailableLoadSearchCriteriaJSON> = {
  // If any new properties are added to AvailableLoadSearchCriteriaJSON, typescript will require that they are added here as well (use null value)
  originLatitude: null,
  originLongitude: null,
  originCountryCode: null,
  originCountryName: null,
  originStateProvinceCode: null,
  originCity: null,
  originStateCodes: null,
  originStateCodesByCountryCode: null,
  originRadiusMiles: null,
  destinationLatitude: null,
  destinationLongitude: null,
  destinationCountryCode: null,
  destinationCountryName: null,
  destinationStateProvinceCode: null,
  destinationCity: null,
  destinationStateCodes: null,
  destinationStateCodesByCountryCode: null,
  destinationRadiusMiles: null,
  pickupStart: null,
  pickupEnd: null,
  activityStart: null,
  activityEnd: null,
  milesMin: null,
  milesMax: null,
  weightMin: null,
  weightMax: null,
  loadNumber: null,
  mode: null,
  isPartial: null,
  specializedEquipmentCode: null,
  teamLoad: null,
  carrierTierAvailable: null,
  stfLoad: null,
  equipmentLengthMax: null,
  hazmatLoad: null,
  pageSize: null,
  reloadsCriteria: null,
  isStfBookable: null,
  webExclusive: null,
  originExampleZipCode: null,
  destinationExampleZipCode: null,
};
export const validSearchCriteriaPropertyKeys: string[] = Object.keys(emptyAvailableLoadSearchCriteria);
