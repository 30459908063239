export const IMDLBookTypes = [
  {
    'code': 'IPU',
    'description': 'IPU',
    'value': 'IPU'
  },
  {
    'code': 'IDL',
    'description': 'IDL',
    'value': 'IDL'
  },
  {
    'code': 'IXT',
    'description': 'IXT',
    'value': 'IXT'
  }
];
