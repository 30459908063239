import { AnyAction } from 'redux';
import { DismissToastAction, ShowToastAction, ToastActionTypes } from 'shared/components/toast/toast-interfaces';

export const toasts = (state = [], action: AnyAction) => {
  switch (action.type) {
    case ToastActionTypes.SHOW_TOAST:
      return [
        (action as ShowToastAction).toast,
        ...state

      ];
    case ToastActionTypes.DISMISS_TOAST:
      return state.filter(toast => toast.id !== (action as DismissToastAction).id);
    default:
      return state;
  }
};
