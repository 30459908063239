import { REQUEST_SUCCESS } from '../../requests.actions';
import { Action } from 'redux';

export const SEARCH_AVAILABLE_LOADS_SUCCESS = `${REQUEST_SUCCESS}:SEARCH_AVAILABLE_LOADS_SUCCESS`;

export interface ProcessAvailableLoadsSearchAction extends Action {
  search: string;
}

export const REFRESH_AVAILABLE_LOADS = 'REFRESH_AVAILABLE_LOADS';
export const refreshAvailableLoads = () => ({type: REFRESH_AVAILABLE_LOADS});

export interface PushURLAction extends Action {
  criteria: AvailableLoadSearchCriteriaJSON;
  saveAsPreferredLane: boolean;
  laneNotification: boolean;
}

export interface PushReloadsUrlAction extends Action {
  criteria: AvailableLoadSearchCriteriaJSON;
}

export const PUSH_AVAILABLE_LOAD_SEARCH_URL = 'PUSH_AVAILABLE_LOAD_SEARCH_URL';

export const PUSH_AVAILABLE_LOAD_SEARCH_lOAD_NUMBER_URL = 'PUSH_AVAILABLE_LOAD_SEARCH_LOAD_NUMBER_URL';

export const PUSH_VIEW_ALL_POST_BOOK_RELOADS_URL = 'PUSH_RELOADS_SEARCH_URL';

export const pushAvailableLoadsSearchLoadNumberURL = (criteria: AvailableLoadSearchCriteriaJSON, saveAsPreferredLane = false, laneNotification = false): PushURLAction => ({type: PUSH_AVAILABLE_LOAD_SEARCH_lOAD_NUMBER_URL, criteria, saveAsPreferredLane, laneNotification});

export const pushAvailableLoadsSearchURL = (criteria: AvailableLoadSearchCriteriaJSON, saveAsPreferredLane = false, laneNotification = false): PushURLAction => ({type: PUSH_AVAILABLE_LOAD_SEARCH_URL, criteria, saveAsPreferredLane, laneNotification});

export const pushViewAllPostBookReloadsURL = (criteria: ReloadsSearchCriteriaJSON): PushReloadsUrlAction => ({
  type: PUSH_VIEW_ALL_POST_BOOK_RELOADS_URL,
  criteria: {reloadsCriteria: criteria}
});

export const PROCESS_AVAILABLE_LOADS_SEARCH_PARAMS = 'PROCESS_AVAILABLE_LOADS_SEARCH_PARAMS';

export const processAvailableLoadsSearchParams = (search: string): ProcessAvailableLoadsSearchAction => ({type: PROCESS_AVAILABLE_LOADS_SEARCH_PARAMS, search});

export interface ProcessAvailableLoadsSearchAction extends Action {
  search: string;
}
