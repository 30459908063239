import { BaseModel } from 'shared/models/base.model';
import { EquipmentType } from 'shared/enums/equipment-type.enum';
import { Cost } from 'shared/models/rates/cost.model';

export class CostQuote extends BaseModel {
  quoteId: number;
  customerName: string;
  carrierCode: string;
  origin: ILocation;
  destination: ILocation;
  costPerUnit: string;
  costBasis: string;
  minCharge: number;
  extendedCost: number;
  miles: number;
  maxLoadsPerDay: number;
  equipmentType: EquipmentType;
  equipmentHeight: number;
  equipmentLength: number;
  equipmentWidth: number;
  lanePercent: string;
  lpad: string;
  effectiveDate: Date;
  expirationDate: Date;
  currencyCode: string;
  costs: Cost[];

  constructor(json?) {
    super(json);

    this.costs = json.costs.map(costJson => new Cost(costJson));

    if (json.carrierCode) {
      this.carrierCode = json.carrierCode.trim();
    }

    if (json.lanePercent) {
      this.lanePercent = json.lanePercent.trim();
    }

    if (json.effectiveDate) {
      this.effectiveDate = this.processDate(json.effectiveDate);
    }

    if (json.expirationDate) {
      this.expirationDate = this.processDate(json.expirationDate);
    }
  }

  toJson() {
    return {
      ...super.toJson(),
      effectiveDate: this.convertDateToDateString(this.effectiveDate),
      expirationDate: this.convertDateToDateString(this.expirationDate),
    };
  }
}
