import { BaseModel } from 'shared/models/base.model';

export class InvoiceItem extends BaseModel<InvoiceItemJSON> {
  packagingFormCode: string;
  description: string;
  commodityCode: string;
  freightClass: string;
  quantity: number;
  weight: number;
  rate: number;
  charge: number;
  billToCode: string;
  private accesorialCode: string;

  constructor(json?: InvoiceItemJSON) {
    super(json);
  }

  get accessorialCode() {
    return this.accesorialCode;
  }

  set accessorialCode(code: string) {
    this.accesorialCode = code;
  }
}
