export const Countries = [
  {
    'code': 'AF',
    'id': 3,
    'name': 'Afghanistan'
  },
  {
    'code': 'AX',
    'id': 243,
    'name': 'Aland Islands'
  },
  {
    'code': 'AL',
    'id': 6,
    'name': 'Albania'
  },
  {
    'code': 'DZ',
    'id': 61,
    'name': 'Algeria'
  },
  {
    'code': 'AS',
    'id': 12,
    'name': 'American Samoa'
  },
  {
    'code': 'AD',
    'id': 1,
    'name': 'Andorra'
  },
  {
    'code': 'AO',
    'id': 9,
    'name': 'Angola'
  },
  {
    'code': 'AI',
    'id': 5,
    'name': 'Anguilla'
  },
  {
    'code': 'AQ',
    'id': 10,
    'name': 'Antarctica'
  },
  {
    'code': 'AG',
    'id': 4,
    'name': 'Antigua and Barbuda'
  },
  {
    'code': 'AR',
    'id': 11,
    'name': 'Argentina'
  },
  {
    'code': 'AM',
    'id': 7,
    'name': 'Armenia'
  },
  {
    'code': 'AW',
    'id': 15,
    'name': 'Aruba'
  },
  {
    'code': 'AU',
    'id': 14,
    'name': 'Australia'
  },
  {
    'code': 'AT',
    'id': 13,
    'name': 'Austria'
  },
  {
    'code': 'AZ',
    'id': 16,
    'name': 'Azerbaijan'
  },
  {
    'code': 'BS',
    'id': 30,
    'name': 'Bahamas'
  },
  {
    'code': 'BH',
    'id': 23,
    'name': 'Bahrain'
  },
  {
    'code': 'BD',
    'id': 19,
    'name': 'Bangladesh'
  },
  {
    'code': 'BB',
    'id': 18,
    'name': 'Barbados'
  },
  {
    'code': 'BY',
    'id': 35,
    'name': 'Belarus'
  },
  {
    'code': 'BE',
    'id': 20,
    'name': 'Belgium'
  },
  {
    'code': 'BZ',
    'id': 36,
    'name': 'Belize'
  },
  {
    'code': 'BJ',
    'id': 25,
    'name': 'Benin'
  },
  {
    'code': 'BM',
    'id': 26,
    'name': 'Bermuda'
  },
  {
    'code': 'BT',
    'id': 31,
    'name': 'Bhutan'
  },
  {
    'code': 'BO',
    'id': 28,
    'name': 'Bolivia'
  },
  {
    'code': 'BA',
    'id': 17,
    'name': 'Bosnia and Herzegovina'
  },
  {
    'code': 'BW',
    'id': 34,
    'name': 'Botswana'
  },
  {
    'code': 'BV',
    'id': 33,
    'name': 'Bouvet Island'
  },
  {
    'code': 'BR',
    'id': 29,
    'name': 'Brazil'
  },
  {
    'code': 'IO',
    'id': 102,
    'name': 'British Indian Ocean Territory'
  },
  {
    'code': 'BN',
    'id': 27,
    'name': 'Brunei Darussalam'
  },
  {
    'code': 'BG',
    'id': 22,
    'name': 'Bulgaria'
  },
  {
    'code': 'BF',
    'id': 21,
    'name': 'Burkina Faso'
  },
  {
    'code': 'BI',
    'id': 24,
    'name': 'Burundi'
  },
  {
    'code': 'KH',
    'id': 112,
    'name': 'Cambodia'
  },
  {
    'code': 'CM',
    'id': 46,
    'name': 'Cameroon'
  },
  {
    'code': 'CA',
    'id': 37,
    'name': 'Canada'
  },
  {
    'code': 'IC',
    'id': 253,
    'name': 'Canary Islands'
  },
  {
    'code': 'CV',
    'id': 52,
    'name': 'Cape Verde'
  },
  {
    'code': 'KY',
    'id': 119,
    'name': 'Cayman Islands'
  },
  {
    'code': 'CF',
    'id': 40,
    'name': 'Central African Republic'
  },
  {
    'code': 'TD',
    'id': 204,
    'name': 'Chad'
  },
  {
    'code': 'CL',
    'id': 45,
    'name': 'Chile'
  },
  {
    'code': 'CN',
    'id': 47,
    'name': 'China'
  },
  {
    'code': 'CX',
    'id': 53,
    'name': 'Christmas Island'
  },
  {
    'code': 'CC',
    'id': 38,
    'name': 'Cocos (keeling) Islands'
  },
  {
    'code': 'CO',
    'id': 48,
    'name': 'Colombia'
  },
  {
    'code': 'KM',
    'id': 114,
    'name': 'Comoros'
  },
  {
    'code': 'CG',
    'id': 41,
    'name': 'Congo'
  },
  {
    'code': 'CK',
    'id': 44,
    'name': 'Cook Islands'
  },
  {
    'code': 'CR',
    'id': 49,
    'name': 'Costa Rica'
  },
  {
    'code': 'CI',
    'id': 43,
    'name': 'Cote d\'Ivoire'
  },
  {
    'code': 'HR',
    'id': 95,
    'name': 'Croatia'
  },
  {
    'code': 'CU',
    'id': 51,
    'name': 'Cuba'
  },
  {
    'code': 'XC',
    'id': 254,
    'name': 'Cueta'
  },
  {
    'code': 'CW',
    'id': 250,
    'name': 'Curacao'
  },
  {
    'code': 'CY',
    'id': 54,
    'name': 'Cyprus'
  },
  {
    'code': 'CZ',
    'id': 55,
    'name': 'Czech Republic'
  },
  {
    'code': 'DK',
    'id': 58,
    'name': 'Denmark'
  },
  {
    'code': 'DJ',
    'id': 57,
    'name': 'Djibouti'
  },
  {
    'code': 'DM',
    'id': 59,
    'name': 'Dominica'
  },
  {
    'code': 'DO',
    'id': 60,
    'name': 'Dominican Republic'
  },
  {
    'code': 'TP',
    'id': 214,
    'name': 'East Timor'
  },
  {
    'code': 'EC',
    'id': 62,
    'name': 'Ecuador'
  },
  {
    'code': 'EG',
    'id': 64,
    'name': 'Egypt'
  },
  {
    'code': 'SV',
    'id': 200,
    'name': 'El Salvador'
  },
  {
    'code': 'GQ',
    'id': 85,
    'name': 'Equatorial Guinea'
  },
  {
    'code': 'ER',
    'id': 66,
    'name': 'Eritrea'
  },
  {
    'code': 'EE',
    'id': 63,
    'name': 'Estonia'
  },
  {
    'code': 'ET',
    'id': 68,
    'name': 'Ethiopia'
  },
  {
    'code': 'FK',
    'id': 71,
    'name': 'Falkland Islands (Malvinas)'
  },
  {
    'code': 'FO',
    'id': 73,
    'name': 'Faroe Islands'
  },
  {
    'code': 'FM',
    'id': 72,
    'name': 'Federated States of Micronesia'
  },
  {
    'code': 'FJ',
    'id': 70,
    'name': 'Fiji'
  },
  {
    'code': 'FI',
    'id': 69,
    'name': 'Finland'
  },
  {
    'code': 'FR',
    'id': 74,
    'name': 'France'
  },
  {
    'code': 'GF',
    'id': 78,
    'name': 'French Guiana'
  },
  {
    'code': 'PF',
    'id': 167,
    'name': 'French Polynesia'
  },
  {
    'code': 'TF',
    'id': 205,
    'name': 'French Southern Territories'
  },
  {
    'code': 'GA',
    'id': 75,
    'name': 'Gabon'
  },
  {
    'code': 'GM',
    'id': 82,
    'name': 'Gambia'
  },
  {
    'code': 'GE',
    'id': 77,
    'name': 'Georgia'
  },
  {
    'code': 'DE',
    'id': 56,
    'name': 'Germany'
  },
  {
    'code': 'GH',
    'id': 79,
    'name': 'Ghana'
  },
  {
    'code': 'GI',
    'id': 80,
    'name': 'Gibraltar'
  },
  {
    'code': 'GR',
    'id': 86,
    'name': 'Greece'
  },
  {
    'code': 'GL',
    'id': 81,
    'name': 'Greenland'
  },
  {
    'code': 'GD',
    'id': 76,
    'name': 'Grenada'
  },
  {
    'code': 'GP',
    'id': 84,
    'name': 'Guadeloupe'
  },
  {
    'code': 'GU',
    'id': 89,
    'name': 'Guam'
  },
  {
    'code': 'GT',
    'id': 88,
    'name': 'Guatemala'
  },
  {
    'code': 'GG',
    'id': 245,
    'name': 'Guernsey'
  },
  {
    'code': 'GN',
    'id': 83,
    'name': 'Guinea'
  },
  {
    'code': 'GW',
    'id': 90,
    'name': 'Guinea-Bissau'
  },
  {
    'code': 'GY',
    'id': 91,
    'name': 'Guyana'
  },
  {
    'code': 'HT',
    'id': 96,
    'name': 'Haiti'
  },
  {
    'code': 'HN',
    'id': 94,
    'name': 'Honduras'
  },
  {
    'code': 'HK',
    'id': 92,
    'name': 'Hong Kong'
  },
  {
    'code': 'HU',
    'id': 97,
    'name': 'Hungary'
  },
  {
    'code': 'IS',
    'id': 105,
    'name': 'Iceland'
  },
  {
    'code': 'IN',
    'id': 101,
    'name': 'India'
  },
  {
    'code': 'ID',
    'id': 98,
    'name': 'Indonesia'
  },
  {
    'code': 'IR',
    'id': 104,
    'name': 'Iran'
  },
  {
    'code': 'IQ',
    'id': 103,
    'name': 'Iraq'
  },
  {
    'code': 'IE',
    'id': 99,
    'name': 'Ireland'
  },
  {
    'code': 'IM',
    'id': 246,
    'name': 'Isle of Man'
  },
  {
    'code': 'IL',
    'id': 100,
    'name': 'Israel'
  },
  {
    'code': 'IT',
    'id': 106,
    'name': 'Italy'
  },
  {
    'code': 'JM',
    'id': 107,
    'name': 'Jamaica'
  },
  {
    'code': 'JP',
    'id': 109,
    'name': 'Japan'
  },
  {
    'code': 'JE',
    'id': 247,
    'name': 'Jersey'
  },
  {
    'code': 'JO',
    'id': 108,
    'name': 'Jordan'
  },
  {
    'code': 'KZ',
    'id': 120,
    'name': 'Kazakhstan'
  },
  {
    'code': 'KE',
    'id': 110,
    'name': 'Kenya'
  },
  {
    'code': 'KI',
    'id': 113,
    'name': 'Kiribati'
  },
  {
    'code': 'KW',
    'id': 118,
    'name': 'Kuwait'
  },
  {
    'code': 'KG',
    'id': 111,
    'name': 'Kyrgyzstan'
  },
  {
    'code': 'LA',
    'id': 121,
    'name': 'Lao'
  },
  {
    'code': 'LV',
    'id': 130,
    'name': 'Latvia'
  },
  {
    'code': 'LB',
    'id': 122,
    'name': 'Lebanon'
  },
  {
    'code': 'LS',
    'id': 127,
    'name': 'Lesotho'
  },
  {
    'code': 'LR',
    'id': 126,
    'name': 'Liberia'
  },
  {
    'code': 'LY',
    'id': 131,
    'name': 'Libyan Arab Jamahiriya'
  },
  {
    'code': 'LI',
    'id': 124,
    'name': 'Liechtenstein'
  },
  {
    'code': 'LT',
    'id': 128,
    'name': 'Lithuania'
  },
  {
    'code': 'LU',
    'id': 129,
    'name': 'Luxembourg'
  },
  {
    'code': 'MO',
    'id': 140,
    'name': 'Macau'
  },
  {
    'code': 'MK',
    'id': 137,
    'name': 'Macedonia'
  },
  {
    'code': 'MG',
    'id': 135,
    'name': 'Madagascar'
  },
  {
    'code': 'MW',
    'id': 148,
    'name': 'Malawi'
  },
  {
    'code': 'MY',
    'id': 150,
    'name': 'Malaysia'
  },
  {
    'code': 'MV',
    'id': 147,
    'name': 'Maldives'
  },
  {
    'code': 'ML',
    'id': 138,
    'name': 'Mali'
  },
  {
    'code': 'MT',
    'id': 145,
    'name': 'Malta'
  },
  {
    'code': 'MH',
    'id': 136,
    'name': 'Marshall Islands'
  },
  {
    'code': 'MQ',
    'id': 142,
    'name': 'Martinique'
  },
  {
    'code': 'MR',
    'id': 143,
    'name': 'Mauritania'
  },
  {
    'code': 'MU',
    'id': 146,
    'name': 'Mauritius'
  },
  {
    'code': 'YT',
    'id': 237,
    'name': 'Mayotte'
  },
  {
    'code': 'XL',
    'id': 255,
    'name': 'Melilla'
  },
  {
    'code': 'MX',
    'id': 149,
    'name': 'Mexico'
  },
  {
    'code': 'MC',
    'id': 133,
    'name': 'Monaco'
  },
  {
    'code': 'MN',
    'id': 139,
    'name': 'Mongolia'
  },
  {
    'code': 'ME',
    'id': 248,
    'name': 'Montenegro'
  },
  {
    'code': 'MS',
    'id': 144,
    'name': 'Montserrat'
  },
  {
    'code': 'MA',
    'id': 132,
    'name': 'Morocco'
  },
  {
    'code': 'MZ',
    'id': 151,
    'name': 'Mozambique'
  },
  {
    'code': 'MM',
    'id': 32,
    'name': 'Myanmar (Burma)'
  },
  {
    'code': 'NA',
    'id': 152,
    'name': 'Namibia'
  },
  {
    'code': 'NR',
    'id': 161,
    'name': 'Nauru'
  },
  {
    'code': 'NP',
    'id': 160,
    'name': 'Nepal'
  },
  {
    'code': 'NL',
    'id': 158,
    'name': 'Netherlands'
  },
  {
    'code': 'NC',
    'id': 153,
    'name': 'New Caledonia'
  },
  {
    'code': 'NZ',
    'id': 163,
    'name': 'New Zealand'
  },
  {
    'code': 'NI',
    'id': 157,
    'name': 'Nicaragua'
  },
  {
    'code': 'NE',
    'id': 154,
    'name': 'Niger'
  },
  {
    'code': 'NG',
    'id': 156,
    'name': 'Nigeria'
  },
  {
    'code': 'NU',
    'id': 162,
    'name': 'Niue'
  },
  {
    'code': 'NF',
    'id': 155,
    'name': 'Norfolk Island'
  },
  {
    'code': 'KP',
    'id': 116,
    'name': 'North Korea'
  },
  {
    'code': 'MP',
    'id': 141,
    'name': 'Northern Mariana Islands'
  },
  {
    'code': 'NO',
    'id': 159,
    'name': 'Norway'
  },
  {
    'code': 'OM',
    'id': 164,
    'name': 'Oman'
  },
  {
    'code': 'PK',
    'id': 170,
    'name': 'Pakistan'
  },
  {
    'code': 'PW',
    'id': 177,
    'name': 'Palau'
  },
  {
    'code': 'PS',
    'id': 175,
    'name': 'Palestinian Territory, Occupied'
  },
  {
    'code': 'PA',
    'id': 165,
    'name': 'Panama'
  },
  {
    'code': 'PG',
    'id': 168,
    'name': 'Papua New Guinea'
  },
  {
    'code': 'PY',
    'id': 178,
    'name': 'Paraguay'
  },
  {
    'code': 'PE',
    'id': 166,
    'name': 'Peru'
  },
  {
    'code': 'PH',
    'id': 169,
    'name': 'Philippines'
  },
  {
    'code': 'PN',
    'id': 173,
    'name': 'Pitcairn'
  },
  {
    'code': 'PL',
    'id': 171,
    'name': 'Poland'
  },
  {
    'code': 'PT',
    'id': 176,
    'name': 'Portugal'
  },
  {
    'code': 'PR',
    'id': 174,
    'name': 'Puerto Rico'
  },
  {
    'code': 'QA',
    'id': 179,
    'name': 'Qatar'
  },
  {
    'code': 'MD',
    'id': 134,
    'name': 'Republic of Moldova'
  },
  {
    'code': 'RE',
    'id': 180,
    'name': 'Reunion'
  },
  {
    'code': 'RO',
    'id': 181,
    'name': 'Romania'
  },
  {
    'code': 'RU',
    'id': 182,
    'name': 'Russian Federation'
  },
  {
    'code': 'RW',
    'id': 183,
    'name': 'Rwanda'
  },
  {
    'code': 'BL',
    'id': 256,
    'name': 'Saint Barthelemy'
  },
  {
    'code': 'SH',
    'id': 190,
    'name': 'Saint Helena'
  },
  {
    'code': 'KN',
    'id': 115,
    'name': 'Saint Kitts And Nevis'
  },
  {
    'code': 'LC',
    'id': 123,
    'name': 'Saint Lucia'
  },
  {
    'code': 'MF',
    'id': 249,
    'name': 'Saint Martin (French part)'
  },
  {
    'code': 'PM',
    'id': 172,
    'name': 'Saint Pierre And Miquelon'
  },
  {
    'code': 'WS',
    'id': 235,
    'name': 'Samoa'
  },
  {
    'code': 'SM',
    'id': 195,
    'name': 'San Marino'
  },
  {
    'code': 'ST',
    'id': 199,
    'name': 'Sao Tome and Principe'
  },
  {
    'code': 'SA',
    'id': 184,
    'name': 'Saudi Arabia'
  },
  {
    'code': 'SN',
    'id': 196,
    'name': 'Senegal'
  },
  {
    'code': 'RS',
    'id': 50,
    'name': 'Serbia'
  },
  {
    'code': 'SC',
    'id': 186,
    'name': 'Seychelles'
  },
  {
    'code': 'SL',
    'id': 194,
    'name': 'Sierra Leone'
  },
  {
    'code': 'SG',
    'id': 189,
    'name': 'Singapore'
  },
  {
    'code': 'SX',
    'id': 251,
    'name': 'Sint Maarten'
  },
  {
    'code': 'SK',
    'id': 193,
    'name': 'Slovakia'
  },
  {
    'code': 'SI',
    'id': 191,
    'name': 'Slovenia'
  },
  {
    'code': 'SB',
    'id': 185,
    'name': 'Solomon Islands'
  },
  {
    'code': 'SO',
    'id': 197,
    'name': 'Somalia'
  },
  {
    'code': 'ZA',
    'id': 238,
    'name': 'South Africa'
  },
  {
    'code': 'KR',
    'id': 117,
    'name': 'South Korea'
  },
  {
    'code': 'SS',
    'id': 252,
    'name': 'South Sudan'
  },
  {
    'code': 'ES',
    'id': 67,
    'name': 'Spain'
  },
  {
    'code': 'LK',
    'id': 125,
    'name': 'Sri Lanka'
  },
  {
    'code': 'SD',
    'id': 187,
    'name': 'Sudan'
  },
  {
    'code': 'SR',
    'id': 198,
    'name': 'Suriname'
  },
  {
    'code': 'SJ',
    'id': 192,
    'name': 'Svalbard And Jan Mayen'
  },
  {
    'code': 'SZ',
    'id': 202,
    'name': 'Swaziland'
  },
  {
    'code': 'SE',
    'id': 188,
    'name': 'Sweden'
  },
  {
    'code': 'CH',
    'id': 42,
    'name': 'Switzerland'
  },
  {
    'code': 'SY',
    'id': 201,
    'name': 'Syrian Arab Republic'
  },
  {
    'code': 'TW',
    'id': 218,
    'name': 'Taiwan'
  },
  {
    'code': 'TJ',
    'id': 208,
    'name': 'Tajikistan'
  },
  {
    'code': 'TZ',
    'id': 219,
    'name': 'Tanzania'
  },
  {
    'code': 'TH',
    'id': 207,
    'name': 'Thailand'
  },
  {
    'code': 'TL',
    'id': 210,
    'name': 'Timor-Leste'
  },
  {
    'code': 'TG',
    'id': 206,
    'name': 'Togo'
  },
  {
    'code': 'TK',
    'id': 209,
    'name': 'Tokelau'
  },
  {
    'code': 'TO',
    'id': 213,
    'name': 'Tonga'
  },
  {
    'code': 'TT',
    'id': 216,
    'name': 'Trinidad and Tobago'
  },
  {
    'code': 'TN',
    'id': 212,
    'name': 'Tunisia'
  },
  {
    'code': 'TR',
    'id': 215,
    'name': 'Turkey'
  },
  {
    'code': 'TM',
    'id': 211,
    'name': 'Turkmenistan'
  },
  {
    'code': 'TC',
    'id': 203,
    'name': 'Turks and Caicos Islands'
  },
  {
    'code': 'TV',
    'id': 217,
    'name': 'Tuvalu'
  },
  {
    'code': 'VI',
    'id': 231,
    'name': 'U.S. Virgin Islands'
  },
  {
    'code': 'UG',
    'id': 221,
    'name': 'Uganda'
  },
  {
    'code': 'UA',
    'id': 220,
    'name': 'Ukraine'
  },
  {
    'code': 'AE',
    'id': 2,
    'name': 'United Arab Emirates'
  },
  {
    'code': 'GB',
    'id': 222,
    'name': 'United Kingdom'
  },
  {
    'code': 'US',
    'id': 224,
    'name': 'United States'
  },
  {
    'code': 'UY',
    'id': 225,
    'name': 'Uruguay'
  },
  {
    'code': 'UZ',
    'id': 226,
    'name': 'Uzbekistan'
  },
  {
    'code': 'VU',
    'id': 233,
    'name': 'Vanuatu'
  },
  {
    'code': 'VA',
    'id': 227,
    'name': 'Vatican'
  },
  {
    'code': 'VE',
    'id': 229,
    'name': 'Venezuela'
  },
  {
    'code': 'VN',
    'id': 232,
    'name': 'Vietnam'
  },
  {
    'code': 'VG',
    'id': 230,
    'name': 'Virgin Islands, British'
  },
  {
    'code': 'WF',
    'id': 234,
    'name': 'Wallis and Futuna'
  },
  {
    'code': 'EH',
    'id': 65,
    'name': 'Western Sahara'
  },
  {
    'code': 'YE',
    'id': 236,
    'name': 'Yemen'
  },
  {
    'code': 'ZR',
    'id': 240,
    'name': 'Zaire'
  },
  {
    'code': 'ZM',
    'id': 239,
    'name': 'Zambia'
  },
  {
    'code': 'ZW',
    'id': 241,
    'name': 'Zimbabwe'
  }
];
