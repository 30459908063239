export enum UserPermission {
  Admin = 0,
  PostTrucks = 1,
  LoadBoard = 2, // IMPORTANT: Use this for My Loads page
  AR = 3,
  FindLoads = 4,
  FreightAlerts = 5,
  TMC = 6,
  Impersonate = 7,
  ARAdmin = 8,
  DockMgrAdmin = 9,
  DockMgr = 10,
  CHRWTenders = 11,
  Rates = 12,
  ImpersonateAll = 13,
  TMCReports = 14,
  SpotBids = 15,
  CarrierTasks = 16,
  TMCLogo = 17,
  ManageInvoices = 18,
  EmailProfile = 19,
  CarrierProfile = 20,
  CashAdvanceAdmin = 21,
  MyLoads = 22, // IMPORTANT: DO NOT USE this for My Loads page
  CHRTenders = 23,
  PreferredLanes = 24,
  MyShipments = 25
}
