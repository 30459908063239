import { AnyAction } from 'redux';

import { SET_CULTURE } from 'app/i18n/culture.actions';

export const culture = (state = null, action: AnyAction) => {
  switch (action.type) {
    case SET_CULTURE:
      return action.culture;
    default:
      return state;
  }
};
