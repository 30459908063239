import { AnyAction } from 'redux';
import { Cookie } from 'ng2-cookies';
import { ActionsObservable, combineEpics } from 'redux-observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mapTo';
import 'rxjs/add/operator/mergeMap';

import * as a from '@features/security/auth.actions';
import { initCulture } from 'app/i18n/culture.actions';
import { hideModal, HideModalAction, showModal, ShowModalAction } from 'shared/components/modal/modal.actions';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';

const INSURANCE_STATUS_CONFIRMED = 'insurance-status-confirmed';

export const verifyInsuranceEpic: NavCarrierEpic<AnyAction> = action$ =>
 action$
  .ofType<a.CombinedUserDataAction>(a.FETCH_CARRIER_DETAIL_FOR_USER_SUCCESS)
  .filter(() => !Boolean(Cookie.get(INSURANCE_STATUS_CONFIRMED)))
  .mergeMap(({ userJSON, carrierDetail }) =>
   carrierDetail?.isInsuranceRequired && (carrierDetail?.hasExpiringInsurance() || carrierDetail?.hasExpiredInsurance())
    ? ActionsObservable.of(initCulture(), a?.displayInsuranceConfirmation(userJSON, carrierDetail))
    : ActionsObservable.of(a?.verifyHazmatStatus(userJSON, carrierDetail))
  );

export const skipInsuranceConfirmationEpic: NavCarrierEpic<AnyAction> = action$ =>
 action$
  .ofType<a.CombinedUserDataAction>(a.FETCH_CARRIER_DETAIL_FOR_USER_SUCCESS)
  .filter(() => Boolean(Cookie.get(INSURANCE_STATUS_CONFIRMED)))
  .mergeMap(({ userJSON, carrierDetail }) => ActionsObservable.of(initCulture(), a?.verifyHazmatStatus(userJSON, carrierDetail)));

export const displayInsuranceConfirmationModalEpic: NavCarrierEpic<ShowModalAction> = action$ =>
  action$.ofType(a.DISPLAY_INSURANCE_CONFIRMATION)
    .mapTo(showModal('insurance-expired'));

export const confirmInsuranceStatusEpic: NavCarrierEpic<a.CombinedUserDataAction> = (action$, state$) =>
 action$
  .ofType(a.CONFIRM_INSURANCE_STATUS)
  .map(() => state$.value.auth.pending)
  .map(({ userJSON, carrier }) => a?.verifyHazmatStatus(userJSON, carrier));

export const closeInsuranceConfirmationModalEpic: NavCarrierEpic<HideModalAction> = action$ =>
  action$.ofType(a.CONFIRM_INSURANCE_STATUS)
    .mapTo(hideModal('insurance-expired'));

export const setInsuranceConfirmedCookie: NavCarrierEpic<HideModalAction> = action$ =>
  action$.ofType(a.CONFIRM_INSURANCE_STATUS)
    .do(() => Cookie.set(INSURANCE_STATUS_CONFIRMED, new Date().toUTCString(), 1))
    .ignoreElements();

export const insuranceEpics = combineEpics(
  verifyInsuranceEpic,
  skipInsuranceConfirmationEpic,
  displayInsuranceConfirmationModalEpic,
  confirmInsuranceStatusEpic,
  closeInsuranceConfirmationModalEpic,
  setInsuranceConfirmedCookie
);
