import { BaseModel } from 'shared/models/base.model';
import { Dimension } from 'shared/models/my-loads/measurements/dimension.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Equipment.LoadRequiredEquipment */
export class LoadRequiredEquipment extends BaseModel<MyLoads.LoadRequiredEquipmentJSON> {
  readonly customEquipmentTypeId?: number;
  readonly specializedEquipmentCode: string|null;
  readonly calculatedEquipmentType: string|null;
  readonly type: string;
  readonly isExact: boolean;
  readonly length: Dimension|null;
  readonly width: Dimension|null;
  readonly height: Dimension|null;

  constructor(json: MyLoads.LoadRequiredEquipmentJSON) {
    super(json);

    this.length = json.length && new Dimension(json.length);
    this.width = json.width && new Dimension(json.width);
    this.height = json.height && new Dimension(json.height);
  }
}
