export enum TrackingIdentifierType {
  None = 0,
  TractorNumber = 1,
  TrailerNumber = 2,
  CellPhoneNumber = 3,
  Name = 4,
  LicensePlate = 5,
  ProNumber = 6,
  CheckCall = 7,
  ArrivalPickUp = 8,
  DeparturePickUp = 9,
  ArrivalDropOff = 10,
  DepartureDropOff = 11,
}
