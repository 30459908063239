import { StopType } from 'shared/enums/stop-type.enum';
import { Warehouse } from 'shared/models/warehouse.model';
import { ExtendedCommodity } from 'shared/models/loads/commodities/extended-commodity.model';
import { BaseModel } from 'shared/models/base.model';

export class ExtendedStop extends BaseModel {
  sequenceNumber: number;
  stopType: number;
  warehouse: Warehouse;
  calculateETA: boolean;
  completed: Date;
  completedBy: string; // Probably driver name or logged in user name?
  completedByDateTime: Date;
  driverWork: string; // e.g. "Assist + Count" or "No Touch"
  dropTrailer: string; // e.g. "No"
  expressStopType: string; // e.g. "p"
  loadNumber: number;
  loadType: number;
  maxExpectedWeight: number;
  name: string;
  notes: string;
  pallets: number;
  phoneNumber: string;
  product: string;
  reasonCode: string;
  recordedTemperature: number;
  referenceNumber: string;
  referenceNumbers: ReferenceNumberJSON[];
  requestedDateIsAllDay: boolean;
  scheduleRequired: {
    instructions: string;
    instructionType: number;
  };
  sealNumber: string;
  stopDates: string;
  stopHours: string;
  stopIsRamp: boolean;
  stopLoadNumber: number;
  weight: number;

  private infoFrom: string;
  private readonly address: LoadSummaryAddressJSON;
  private scheduleWith: string;
  private scheduleWithFirstName: string;
  private scheduleWithLastName: string;
  private bol: string;
  private pod: string;
  private stopNumber: number;
  private items: ExtendedCommodity[];

  private arrivedAtStop: string;
  private departedStop: string;
  private eta: string;
  private requestedDate: string;
  private scheduledEnd: string;
  private scheduledStart: string;

  private totalVolume: number | '';
  private totalMaxWeight: number | '';

  get bolNumber(): string {
    return this.bol;
  }
  set bolNumber(value: string) {
    this.bol = value.toString();
  }

  get podNumber(): string {
    return this.pod;
  }
  set podNumber(value: string) {
    this.pod = value;
  }

  get number(): number {
    return this.stopNumber;
  }
  set number(value: number) {
    this.stopNumber = value;
  }

  set commodities(items: ExtendedCommodity[]) {
    this.items = items;
  }
  get commodities(): ExtendedCommodity[] {
    return this.items;
  }

  get sourceSystem() {
    return this.infoFrom;
  }
  set sourceSystem(value: string) {
    this.infoFrom = value;
  }

  get schedulingContactName() {
    return this.scheduleWith || (this.scheduleWithFirstName + ' ' + this.scheduleWithLastName);
  }

  get arrivalDateTime(): Date {
    return this.processDate(this.arrivedAtStop);
  }
  set arrivalDateTime(date: Date) {
    this.arrivedAtStop = date ? this.convertDateToDateString(date) : date as null;
  }

  get departureDateTime(): Date {
    return this.processDate(this.departedStop);
  }
  set departureDateTime(date: Date) {
    this.departedStop = date ? this.convertDateToDateString(date) : date as null;
  }

  get etaDateTime(): Date {
    return this.processDate(this.eta);
  }
  set etaDateTime(date: Date) {
    this.eta = this.convertDateToDateString(date);
  }

  get requestedDateTime(): Date {
    return this.processDate(this.requestedDate);
  }
  set requestedDateTime(date: Date) {
    this.requestedDate = this.convertDateToDateString(date);
  }

  get scheduledEndDateTime(): Date {
    return this.processDate(this.scheduledEnd);
  }
  set scheduledEndDateTime(date: Date) {
    this.scheduledEnd = this.convertDateToDateString(date);
  }

  get scheduledStartDateTime(): Date {
    return this.processDate(this.scheduledStart);
  }
  set scheduledStartDateTime(date: Date) {
    this.scheduledStart = this.convertDateToDateString(date);
  }

  get scheduleWithPersonFirstName(): string {
    return this.scheduleWithFirstName;
  }
  set scheduleWithPersonFirstName(value: string) {
    this.scheduleWithFirstName = value;
  }

  get scheduleWithPersonLastName(): string {
    return this.scheduleWithLastName;
  }
  set scheduleWithPersonLastName(value: string) {
    this.scheduleWithLastName = value;
  }

  constructor(json: ExtendedStopJSON) {
    super(json);
    this.address = json.address;

    const warehouseJson = {
      code: json.warehouseCode,
      name: json.name,
      phone: {
        number: json.phoneNumber,
        extension: ''
      },
      address: null,
      warehouseHoursStart: json.openDateTime,
      warehouseHoursEnd: json.closeDateTime,
      notes: json.warehouseNotes,
      directions: json.warehouseDirections
    };

    if (this.address) {
      warehouseJson.address = {
        city: this.address.cityName,
        stateProvinceCode: this.address.stateCode,
        countryCode: this.address.country,
        line1: this.address.streetName,
        line2: this.address.street2,
        zipCode: this.address.zipcode,
        county: this.address.county
      };
    }
    if (json.stopDates && json.stopDates === '1/1/0001') {
      this.stopDates = null;
    }

    this.warehouse = new Warehouse(warehouseJson);

    this.items = [];
    if (json.items && Array.isArray(json.items)) {
      this.items = json.items.map(item => new ExtendedCommodity(item));
    }

    // Dates
    this.completed = this.processDate(json.completed);
    this.completedByDateTime = this.processDate(json.completedByDateTime);
  }

  getAddressJSON() {
    return this.address;
  }

  isPickup(): boolean {
    return this.stopType === StopType.PICKUP;
  }

  isDropOff(): boolean {
    return this.stopType === StopType.DROP_OFF;
  }

  getTotalMaxWeight(): number | '' {
    if (this.commodities) {
      if (this.totalMaxWeight == null) {
        this.totalMaxWeight = this.commodities.reduce((sum, item) => sum + (item.actualWeight || item.expectedMaxWeight), 0) || '';
      }
      return this.totalMaxWeight;
    }
  }

  getTotalVolume(): number | '' {
    if (this.commodities) {
      if (this.totalVolume == null) {
        this.totalVolume = this.commodities.reduce((sum, item) => sum + item.volume, 0) || '';
      }
      return this.totalVolume;
    }
  }

  toJson(): ExtendedStopJSON {
    const baseValues: any = Object.assign({}, this);
    Object.assign(baseValues.address, this.warehouse.address.toLoadSummaryAddressJson());

    delete baseValues.warehouse;
    delete baseValues.totalVolume;
    delete baseValues.totalMaxWeight;

    return Object.assign(baseValues, {
      completed: this.convertDateToDateString(this.completed),
      completedByDateTime: this.convertDateToDateString(this.completedByDateTime),
      items: this.items.map(item => item.toJson()),
      closeDateTime: this.convertDateToDateString(this.warehouse.closingDateTime),
      openDateTime: this.convertDateToDateString(this.warehouse.openingDateTime),
    });
  }

  isComplete(): boolean {
    return Boolean(this.completed);
  }

  hasArrived(): boolean {
    return Boolean(this.arrivalDateTime);
  }

  hasDeparted(): boolean {
    return Boolean(this.departureDateTime);
  }

  getDepartureDateTime(useNullForNullDates: boolean): string {
    return this.convertDateToDateString(this.departureDateTime, useNullForNullDates);
  }

  hasSubsequentPicks(load: ExtendedLoadInterface): boolean {
    const stops: ExtendedStop[] = load.stops.slice().sort((a, b) => a.number - b.number);

    const finalPickup: ExtendedStop = stops.reverse().find(stop => stop.isPickup());
    const previousStop: ExtendedStop = stops.find(stop => stop.number === this.number - 1);

    return Boolean(finalPickup && (this.number < finalPickup.number) && previousStop?.isComplete());
  }

  getReferenceNumberValue(type: string) {
    if (this.referenceNumbers) {
      const referenceNumber = this.referenceNumbers.find(num => num.code === type);
      return referenceNumber?.value;
    }
  }
}
