export const CarrierLoadStatuses = [
  {
    'code': '20',
    'description': 'Assigned',
    'value': 'Assigned'
  },
  {
    'code': '30',
    'description': 'Booked',
    'value': 'Booked'
  },
  {
    'code': '50',
    'description': 'Checked In',
    'value': 'Checked In'
  },
  {
    'code': '60',
    'description': 'Loading',
    'value': 'Loading'
  },
  {
    'code': '70',
    'description': 'Picked Up',
    'value': 'Picked Up'
  },
  {
    'code': '80',
    'description': 'Unloading',
    'value': 'Unloading'
  },
  {
    'code': '90',
    'description': 'Delivered',
    'value': 'Delivered'
  }
];
