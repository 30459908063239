import moment from 'moment';

import { BaseModel } from 'shared/models/base.model';
import { EquipmentType } from 'shared/enums/equipment-type.enum';
import { TruckCapacity } from 'shared/enums/truck-capacity.enum';
import { ContactPhone } from 'shared/models/contacts/contact-phone.model';
import { AddressFormat, formatCityStateCountry } from 'shared/components/formatters/city-state-country.formatter';
import { UnitOfMeasure } from 'shared/enums/unit-of-measure.enum';
import { convertUnits } from 'shared/components/formatters/unit-conversion.formatter';
import { MeasurementType } from 'shared/enums/measurement-type.enum';

export class Truck extends BaseModel<TruckJSON> {
  /**
   *  API notes: This isn't saved anywhere...
   */
  capacity: TruckCapacity;
  /**
   *  If this is populated, then DestinationStates is empty.
   */
  destinationLocation: TruckLocationJSON;
  /**
   *  If this is populated, then DestinationLocation is empty.
   */
  destinationStates: string;
  emptyDateTime: Date;
  emptyLocation: TruckLocationJSON;
  equipmentCode: EquipmentType | string;
  equipmentDescription: string;
  height?: number;
  /**
   *  Also known as TruckId
   */
  id: number;
  isTeam: boolean;
  note: string;
  phone?: ContactPhone;
  /**
   *  The source unit of measure.
   */
  sourceUOM: UnitOfMeasure;
  length: number;
  weight?: number;
  width?: number;

  get weightStandard(): number {
    return convertUnits(Number(this.weight), MeasurementType.Weight, UnitOfMeasure.Standard, this.sourceUOM);
  }

  get lengthStandard(): number {
    return convertUnits(Number(this.length), MeasurementType.Length, UnitOfMeasure.Standard, this.sourceUOM);
  }

  /**
   * Custom fields for formatted addresses
   */
  get emptyLocationString(): string {
    return formatCityStateCountry(this.emptyLocation, AddressFormat.CityStateCountry);
  }

  get destinationLocationString(): string {
    return this.destinationLocation
      ? formatCityStateCountry(this.destinationLocation, AddressFormat.CityStateCountry)
      : this.destinationStates;
  }

  get destinationStateString(): string {
    return this.destinationLocation
      ? formatCityStateCountry(this.destinationLocation, AddressFormat.State)
      : this.destinationStates;
  }

  constructor(json?: TruckJSON) {
    super(json);

    if (!json) {
      return this;
    }

    this.phone = new ContactPhone(json.phone);

    this.emptyDateTime = this.processDate(json.emptyDateTime);
  }

  toJson(): TruckJSON {
    return Object.assign({}, this, {
      emptyDateTime: this.convertDateToDateString(this.emptyDateTime),
      capacity: TruckCapacity[this.capacity],
      phone: (this.phone?.number) ? this.phone.toJson() : null,
    });
  }

  toSearchCriteria(): AvailableLoadSearchCriteriaJSON {
    const startDate = moment(this.emptyDateTime).hours(0).minutes(0).seconds(0);
    // For origin fields, verify there are NOT destination states list, because we can't check for origin states list
    // (since multi-origin doesn't exist on equipment)
    return {
      originCountryCode: this.emptyLocation?.countryCode,
      originStateProvinceCode: this.emptyLocation?.stateProvinceCode,
      originCity: this.emptyLocation?.city,
      originRadiusMiles: this.emptyLocation ? 100 : null,
      originStateCodes: [this.emptyLocation?.stateProvinceCode],
      destinationCountryCode: this.destinationLocation ? this.destinationLocation.countryCode : null,
      destinationStateProvinceCode: this.destinationLocation ? this.destinationLocation.stateProvinceCode : null,
      destinationCity: this.destinationLocation ? this.destinationLocation.city : null,
      destinationStateCodes: this.destinationStates ? this.destinationStates.split(',') : null,
      destinationRadiusMiles: this.destinationStates ? null : 100,
      pickupStart: this.convertDateToDateString(startDate.toDate()),
      pickupEnd: this.convertDateToDateString(startDate.add(1, 'day').toDate()),
      weightMax: this.weight ? this.weightStandard : null,
      teamLoad: this.isTeam || null,
      equipmentLengthMax: this.lengthStandard,
      // there is not a good way to determine these values from an extended equipment type string alone
      // because these rely on reference data returned from the API to map to. It is up to the context calling this
      // method to figure out how to set these values before executing a search using this criteria
      mode: null,
      specializedEquipmentCode: null,
    };
  }
}
