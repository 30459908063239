import { Action } from 'redux';
import { combineEpics } from 'redux-observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/ignoreElements';

import * as a from 'features/security/auth.actions';
import { NavCarrierEpic } from '../nav-carrier-epic.interface';
import { FullStoryAPI } from 'react-fullstory';

export const setUserData: NavCarrierEpic<never> = action$ =>
 action$
  .ofType<a.StoreCredentialsAction>(a.STORE_CREDENTIALS)
  .filter(({ user }) => Boolean(user?.getCarrier()?.regionCode))
  .filter(() => FullStoryAPI != null)
  .do(({ user, carrier }) =>
   FullStoryAPI('identify', user.userId.toString(), {
    displayName: user.firstName + ' ' + user.lastName,
    companyName: carrier.name,
    carrierCode: carrier.carrierCode,
    userName: user.username,
    email: user.email,
   })
  )
  .ignoreElements();

export const cleanupUserOnLogoutEpic: NavCarrierEpic<never> = action$ =>
 action$
  .ofType<Action>(a.LOGOUT)
  .filter(() => FullStoryAPI != null)
  .do(() => FullStoryAPI('identify', false))
  .ignoreElements();

export const fullStoryEpics = combineEpics(
  setUserData,
  cleanupUserOnLogoutEpic,
);
