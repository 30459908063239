import { connectRouter } from 'connected-react-router';
import ssoReducer from 'features/okta/redux/oktaSlice';
import { userManagementReducer } from 'features/permissions';
import userPreferencesReducer from 'features/userPreferences/redux/userPreferencesSlice';
import { app } from './epics/app.reducers';
import { resources } from 'app/i18n/resources.reducers';
import { auth } from 'features/security/auth.reducers';
import { culture } from 'app/i18n/culture.reducers';
import { loading } from 'shared/components/loading/loading.reducers';
import { modals } from 'shared/components/modal/modal.reducers';
import { refData } from 'app/ref-data/ref-data.reducers';
import { toasts } from 'shared/components/toast/toast.reducer';
import { maintenance } from '@containers/layout/maintenance/maintenance.reducer';
import { tenderCount } from './epics/tenders/tenders-base.reducer';
import { offerResultNotifications } from './epics/notifications/notifications.reducer';
import { analytics } from 'features/analytics/redux/reducers/root.reducers';
import { captcha } from 'shared/components/turnstile-captcha/captcha.reducers';
import myLoadsReducer from '@features/my-loads-v2/myLoadsSlice';

export const rootReducer = history => ({
  app,
  resources,
  auth,
  loading,
  modals,
  refData,
  culture,
  maintenance,
  router: connectRouter(history),
  toasts,
  captcha,
  tenderCount,
  offerResultNotifications,
  analytics,
  okta: ssoReducer,
  myLoadsV2: myLoadsReducer,
  userPreferences: userPreferencesReducer,
  userManagement: userManagementReducer,
  temp: (state = null, action) => {
    if (action.type === 'SET_USER_REPO') {
      return action.repo;
    }
    return state;
  }
});
