export const IMPrograms = [
  {
    'code': 'APL',
    'description': 'International APL\'s',
    'value': 'International APL\'s'
  },
  {
    'code': 'BNRU',
    'description': 'BNSF Fleet',
    'value': 'BNSF Fleet'
  },
  {
    'code': 'BNSF',
    'description': 'Dom Con in BNSF Pool',
    'value': 'Dom Con in BNSF Pool'
  },
  {
    'code': 'CMA',
    'description': 'Intl Con in CMA/CGM Pool',
    'value': 'Intl Con in CMA/CGM Pool'
  },
  {
    'code': 'CNRU',
    'description': 'Dom Con in CNRR Pool',
    'value': 'Dom Con in CNRR Pool'
  },
  {
    'code': 'CPRU',
    'description': 'Dom Con in CPRR Pool',
    'value': 'Dom Con in CPRR Pool'
  },
  {
    'code': 'CSXU',
    'description': 'Dom Con in CSX Pool',
    'value': 'Dom Con in CSX Pool'
  },
  {
    'code': 'EMP',
    'description': 'Dom Con in EMP Pool',
    'value': 'Dom Con in EMP Pool'
  },
  {
    'code': 'ESI',
    'description': 'Intl Con in ESI Pool',
    'value': 'Intl Con in ESI Pool'
  },
  {
    'code': 'EVER',
    'description': 'Intl con in Hanjin pool',
    'value': 'Intl con in Hanjin pool'
  },
  {
    'code': 'FECZ',
    'description': 'FEC Trailer',
    'value': 'FEC Trailer'
  },
  {
    'code': 'GOLD',
    'description': 'Intl Con in Goldstar Pool',
    'value': 'Intl Con in Goldstar Pool'
  },
  {
    'code': 'HANJ',
    'description': 'Intl Con in Hanjin Pool',
    'value': 'Intl Con in Hanjin Pool'
  },
  {
    'code': 'HRZN',
    'description': 'Intl Con in Horizon Pool',
    'value': 'Intl Con in Horizon Pool'
  },
  {
    'code': 'HUBU',
    'description': 'Dom Con in HUB Pool',
    'value': 'Dom Con in HUB Pool'
  },
  {
    'code': 'HYUN',
    'description': 'Intl Con in Hyundai Pool',
    'value': 'Intl Con in Hyundai Pool'
  },
  {
    'code': 'ICEZ',
    'description': 'Van Reefer',
    'value': 'Van Reefer'
  },
  {
    'code': 'JBHU',
    'description': 'Dom Con in JB Hunt Pool',
    'value': 'Dom Con in JB Hunt Pool'
  },
  {
    'code': 'KCMU',
    'description': 'Dom Con in KCS Pool',
    'value': 'Dom Con in KCS Pool'
  },
  {
    'code': 'MATS',
    'description': 'Dom Con in Matson Pool',
    'value': 'Dom Con in Matson Pool'
  },
  {
    'code': 'MOL',
    'description': 'Intl Con in Mitsui Pool',
    'value': 'Intl Con in Mitsui Pool'
  },
  {
    'code': 'MRSK',
    'description': 'Intl Con in Maersk Pool',
    'value': 'Intl Con in Maersk Pool'
  },
  {
    'code': 'NACS',
    'description': 'Dom Con in NACS Pool',
    'value': 'Dom Con in NACS Pool'
  },
  {
    'code': 'NYK',
    'description': 'Intl Con in NYK Pool',
    'value': 'Intl Con in NYK Pool'
  },
  {
    'code': 'PACER',
    'description': 'Dom Con in Pacer Pool',
    'value': 'Dom Con in Pacer Pool'
  },
  {
    'code': 'PONL',
    'description': 'Intl Con in P&O Nedlloyd Pool',
    'value': 'Intl Con in P&O Nedlloyd Pool'
  },
  {
    'code': 'PRV',
    'description': 'Private Equipment',
    'value': 'Private Equipment'
  },
  {
    'code': 'RBDC',
    'description': 'Intl Con in Railbridge Pool',
    'value': 'Intl Con in Railbridge Pool'
  },
  {
    'code': 'RBTU',
    'description': 'Dom Con in CHR Pool',
    'value': 'Dom Con in CHR Pool'
  },
  {
    'code': 'SCHN',
    'description': 'Dom Trailer in Schneider Pool',
    'value': 'Dom Trailer in Schneider Pool'
  },
  {
    'code': 'STM',
    'description': 'Steamship Repos',
    'value': 'Steamship Repos'
  },
  {
    'code': 'SWRU',
    'description': 'Dom Con in Swift Pool',
    'value': 'Dom Con in Swift Pool'
  },
  {
    'code': 'TDIS',
    'description': 'Dom Trailer in Thoroughbred Pool',
    'value': 'Dom Trailer in Thoroughbred Pool'
  },
  {
    'code': 'TMPZ',
    'description': 'Trailer in TMPZ Pool',
    'value': 'Trailer in TMPZ Pool'
  },
  {
    'code': 'TMXU',
    'description': 'Con in KCS/NS pool',
    'value': 'Con in KCS/NS pool'
  },
  {
    'code': 'TRIC',
    'description': 'Dom Trailer in TripleCrown Pool',
    'value': 'Dom Trailer in TripleCrown Pool'
  },
  {
    'code': 'UASC',
    'description': 'Intl con in Hanjin pool',
    'value': 'Intl con in Hanjin pool'
  },
  {
    'code': 'UMXU',
    'description': 'Dom Con In UMAX Pool',
    'value': 'Dom Con In UMAX Pool'
  },
  {
    'code': 'VAN',
    'description': 'Trailer in Railroad Pool',
    'value': 'Trailer in Railroad Pool'
  }
];
