import { Action } from 'redux';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';

import { EquipmentType } from 'shared/enums/equipment-type.enum';
import { REQUEST_FAILURE, REQUEST_START, REQUEST_SUCCESS } from 'store/requests.actions';

export const GET_EQUIPMENT_TYPES = `${REQUEST_START}:GET_EQUIPMENT_TYPES`;
export const EQUIPMENT_TYPE_SUCCESS = `${REQUEST_SUCCESS}:EQUIPMENT_TYPE_SUCCESS`;
export const EQUIPMENT_TYPE_FAILURE = `${REQUEST_FAILURE}:EQUIPMENT_TYPE_FAILURE`;

// For getting the LONG LIST of REGULAR types.
export const GET_EXTENDED_EQUIPMENT_TYPES = `${REQUEST_START}:GET_EXTENDED_EQUIPMENT_TYPES`;
export const EXTENDED_EQUIPMENT_TYPE_SUCCESS = `${REQUEST_SUCCESS}:EXTENDED_EQUIPMENT_TYPE_SUCCESS`;
export const EXTENDED_EQUIPMENT_TYPE_FAILURE = `${REQUEST_FAILURE}:EXTENDED_EQUIPMENT_TYPE_FAILURE`;

export const GET_SPECIALIZED_EQUIPMENT_TYPES = `${REQUEST_START}:GET_SPECIALIZED_EQUIPMENT_TYPES`;
export const SPECIALIZED_EQUIPMENT_TYPE_SUCCESS = `${REQUEST_SUCCESS}:SPECIALIZED_EQUIPMENT_TYPE_SUCCESS`;
export const SPECIALIZED_EQUIPMENT_TYPE_FAILURE = `${REQUEST_FAILURE}:SPECIALIZED_EQUIPMENT_TYPE_FAILURE`;

export interface GetSpecializedEquipmentTypesAction extends Action {
  isEU: boolean;
  equipment_type: EquipmentType;
}

// tslint:disable:max-line-length

export const getEquipmentTypes = (isEU = false) => ({type: GET_EQUIPMENT_TYPES, isEU});
export const equipmentTypesSucceeded = (payload: ReferenceDataJSON[]) => ({type: EQUIPMENT_TYPE_SUCCESS, payload});
export const equipmentTypesFailed = (payload: AjaxError) => ({type: EQUIPMENT_TYPE_FAILURE, payload});

export const getExtendedEquipmentTypes = (isEU = false) => ({type: GET_EXTENDED_EQUIPMENT_TYPES, isEU});
export const extendedEquipmentTypesSucceeded = (payload: ReferenceDataJSON[]) => ({type: EXTENDED_EQUIPMENT_TYPE_SUCCESS, payload});
export const extendedEquipmentTypesFailed = (payload: AjaxError) => ({type: EXTENDED_EQUIPMENT_TYPE_FAILURE, payload});

export const getSpecializedEquipmentTypes = (isEU = false, type?: EquipmentType) => (
  {type: GET_SPECIALIZED_EQUIPMENT_TYPES, isEU, equipment_type: type}
);

export const specializedEquipmentTypesSucceeded = (payload: ReferenceDataJSON[]) => (
  {type: SPECIALIZED_EQUIPMENT_TYPE_SUCCESS, payload}
);

export const specializedEquipmentTypesFailed = (payload: AjaxError) => (
  {type: SPECIALIZED_EQUIPMENT_TYPE_FAILURE, payload}
);

export const SET_LOAD_FILTERS = 'SET_LOAD_FILTERS';
export const setLoadFilters = (filters: ReferenceDataJSON[]) => ({type: SET_LOAD_FILTERS, filters});
