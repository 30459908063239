import { AnyAction } from 'redux';
import { ActionsObservable, combineEpics } from 'redux-observable';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

import * as a from '@features/security/auth.actions';
import { hideModal, showModal, ShowModalAction } from 'shared/components/modal/modal.actions';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import { initCulture } from 'app/i18n/culture.actions';

export const verifyTermsAcceptedEpic: NavCarrierEpic<AnyAction> = action$ =>
  action$.ofType<a.CombinedUserDataAction>(a.VERIFY_TERMS_AND_CONDITIONS_STATUS)
    .mergeMap(({ userJSON, carrierDetail }) =>
      userJSON && userJSON.acceptedAgreement === false
        ? ActionsObservable.of(initCulture(), a.displayTermsAndConditionsConfirmation(userJSON, carrierDetail))
        : ActionsObservable.of(a.completeLogin(userJSON, carrierDetail))
    );

export const displayTermsAndConditionsConfirmationModalEpic: NavCarrierEpic<ShowModalAction> = action$ =>
  action$.ofType(a.DISPLAY_TERMS_AND_CONDITIONS_CONFIRMATION)
    .map(({ userJSON, carrierDetail }) => showModal('accept-terms', { userJSON, carrierDetail }));

export const confirmTermsAndConditionsStatusEpic: NavCarrierEpic<AnyAction> = (action$, state$) =>
  action$.ofType<a.CombinedUserDataAction>(a.CONFIRM_TERMS_AND_CONDITIONS_STATUS)
    .mergeMap(({ userJSON, carrierDetail }) =>
      ActionsObservable.of<AnyAction>(hideModal('accept-terms'), a.verifyTermsAndConditionsStatus(userJSON, carrierDetail))
    );

export const termsAndConditionsEpics = combineEpics(
  verifyTermsAcceptedEpic,
  displayTermsAndConditionsConfirmationModalEpic,
  confirmTermsAndConditionsStatusEpic
);
