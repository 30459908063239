import * as a from 'pages/tenders/redux/tenders.actions';

export const tenderCount = (state = null, action) => {
  switch (action.type) {
    case a.FETCH_TENDERS_SUCCESS:
    case a.FETCH_TENDERS_QUIET_SUCCESS:
      //the condition filter is implemented this way to make sure we're filtering out only not-deferred tenders with the reject reason code set
      //reject reason code presence means the rejecting proces is started (on the TREX TenderService reason code is stored into DB before async reject processing)
      return action.payload.reduce((result, load: {tenders: any[]}) => [...result, ...load.tenders
        .filter(tender => tender.deferred || (!tender.deferred && !tender.tenderResponse.rejectionReasonCode))], []).length;
    default:
      return state;
  }
};
