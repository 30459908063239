export enum LoadStatus {
  All = 0,
  FinancialOnly = 5,
  Ordered = 10,
  Consolidated = 12,
  WebLocked = 15,
  Assigned = 20,
  Tendered = 25,
  Booked = 30,
  CarrierDirected = 40, // Load Confirmation Sent??
  CheckedIn = 50,
  WayBilled = 52,
  BookedComplete = 55,
  Loading = 60,
  ArrivedTerminal = 68,
  ReadyToDepart = 69,
  PickedUp = 70,
  DepartedTerminal = 71,
  OnRail = 72,
  DepartedPort = 73,
  ArrivedPort = 74,
  InTransit = 75, // In Transit Rail
  ArrivedRamp = 76,
  RailComplete = 77,
  PortComplete = 78,
  TerminalComplete = 79,
  Unloading = 80,
  CarrierReleased = 82,
  Delivered = 90
}

export const LoadStatusLabels: { [key: number]: ResourceKey } = {
  [LoadStatus.All]: 'ALL_STATUSES',
  [LoadStatus.Tendered]: 'TENDERED',
  [LoadStatus.Booked]: 'BOOKED',
  [LoadStatus.CheckedIn]: 'CHECKED_IN',
  [LoadStatus.WayBilled]: 'WAYBILLED',
  [LoadStatus.Loading]: 'LOADING',
  [LoadStatus.PickedUp]: 'PICKED_UP',
  [LoadStatus.InTransit]: 'IN_TRANSIT',
  [LoadStatus.Unloading]: 'UNLOADING',
  [LoadStatus.Delivered]: 'DELIVERED'
};
