import { getApi } from 'lib/http';
import {
  AxiosInstance,
  InternalAxiosRequestConfig,
  CreateAxiosDefaults,
} from "axios";

const options: CreateAxiosDefaults = {
  baseURL: apiConfig.userManagementAPI,
};

const api: AxiosInstance = getApi(options.baseURL);

export function getUser(userId: string): Promise<GetUserResponse> {
  return api.get(`/Users/${userId}`);
}

export function getMembership(organizationId: string, userId: string): Promise<MembershipResponse> {
  return api.get(`/Organizations/${organizationId}/Users/${userId}/Membership`);
}

export function getUserProfile(userId: string): Promise<UserProfile> {
  return api.get(`/Users/${userId}/Profiles`);
}

export function putUserProfile(userId: string, requestBody: UserProfile): Promise<any> {
  return api.put(`/Users/${userId}/Profiles`, requestBody);
}

export function getMemberships(userId: string): Promise<any> {
  return api.get(`/Users/${userId}/Memberships`);
}

export function putMembership(userId: string, membershipId: string, updateMembership: UpdateMembership): Promise<any> {
  return api.put(`/Users/${userId}/Memberships/${membershipId}`, updateMembership);
}

export function deleteMembership(userId: string, membershipId: string): Promise<any> {
  return api.delete(`/Users/${userId}/Memberships/${membershipId}`);
}

export function putDefaultMembership(userId: string, membershipId: string): Promise<any> {
  return api.put(`/Users/${userId}/Memberships/${membershipId}/default`, {});
}

export function validateEmailForOrganization(organizationId: string, email: string): Promise<any> {
  return api.post(`/Memberships/${organizationId}/ValidateEmail`, { emailAddress: email });
}

export function postUser(postUserRequest: any): Promise<any> {
  return api.post('/Users', postUserRequest);
}

export function getOrganization(organizationId: string): Promise<any> {
  return api.get(`/Organizations/${organizationId}`);
}

export function searchMemberships(args: SearchMembershipRequest): Promise<SearchMembershipResponse> {
  return api.post('/Memberships/Search', args);
}

export function validateInvitationToken(token: string): Promise<ValidateInvitationTokenResponse> {
  return api.get(`/Invitations/Validate?token=${token}`);
}

export function processInvitation(args: AcceptOrRejectRequest): Promise<any> {
  return api.post('/Invitations/Process', args);
}