import { BaseModel } from 'shared/models/base.model';

export enum TenderStatus {
  Tendered,
  Accepted,
  Rejected,
  TimedOut,
  Pending,
  DeferAccepted,
  AutoBooked,
  ManualTender,
  ManualBook
}

/** NavisphereCarrier.MyLoads.ServiceModel.Tenders.TenderResponse */
export class TenderResponse extends BaseModel<MyLoads.TenderResponseJSON> {
  readonly tenderStatus: TenderStatus;
  readonly responseBy: string;
  readonly responseDate: Date;
  readonly rejectionReasonCode: string;

  constructor(json: MyLoads.TenderResponseJSON) {
    super(json);

    this.responseDate = this.processDate(json.responseDate) as Date;
  }
}
