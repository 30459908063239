export enum CertificationType {
    Hazmat = 1
}

export enum CertificationStatus {
    Valid, Expired, ExpiresSoon
}

export enum ToastType {
    Success = 'Success',
    Info = 'Info',
    Warning = 'Warning',
    Error = 'Error'
}
