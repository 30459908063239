import { LoadBase } from 'shared/models/my-loads/loads/load-base.model';
import { StopDetail } from 'shared/models/my-loads/stops/stop-detail.model';
import { BillTo } from 'shared/models/my-loads/bill-tos/bill-to.model';
import { BillToStopRelationship } from 'shared/models/my-loads/relationships/bill-to-stop-relationship.model';
import { LoadRequiredEquipment } from 'shared/models/my-loads/equipment/load-required-equipment.model';
import { Note } from 'shared/models/my-loads/notes/note.model';
import { Temperature } from 'shared/models/my-loads/measurements/temperature.model';
import { Contact } from 'shared/models/my-loads/contacts/contact.model';
import { Stop } from 'shared/models/my-loads/stops/stop.model';
import { Util } from 'app/util/util';

export enum CarrierTier {
  None = 0,
  Base = 1,
  Core = 10,
  Key = 20
}

const bySequence = Util.sortByField('sequenceNumber');
const byStopNumber = Util.sortByField('stopNumber');

/** NavisphereCarrier.MyLoads.ServiceModel.Loads.LoadDetail */
export class LoadDetail<T extends MyLoads.LoadDetailJSON> extends LoadBase<T> {
  readonly stopDetails: StopDetail[];
  readonly billTos: BillTo[];
  readonly billToStops: BillToStopRelationship[];
  readonly carrierTier: CarrierTier;
  readonly condition: string|null;
  readonly requiredEquipment: LoadRequiredEquipment;
  readonly notes: Note[];
  readonly reeferSetting: string|null;
  readonly reeferTemperature: Temperature|null;
  readonly contacts: Contact[];
  readonly rating: string|null;

  constructor(json: T) {
    super(json);

    this.stopDetails = json.stopDetails.sort(byStopNumber).map(item => new StopDetail(item));
    this.billTos = json.billTos.sort(bySequence).map(item => new BillTo(item));
    this.billToStops = json.billToStops.map(item => new BillToStopRelationship(item));
    this.requiredEquipment = new LoadRequiredEquipment(json.requiredEquipment);
    this.reeferTemperature = json.reeferTemperature && new Temperature(json.reeferTemperature);
    this.notes = json.notes ? json.notes.map(item => new Note(item)) : [];
    this.contacts = json.contacts ? json.contacts.map(item => new Contact(item)) : [];
  }

  get firstStop() {
    return this.stopDetails[0];
  }

  get lastStop() {
    return this.stopDetails[this.stopDetails.length - 1];
  }

  get origin() {
    return this.firstStop?.warehouse?.location;
  }

  get destination() {
    return this.lastStop?.warehouse?.location;
  }

  get pickupBy() {
    return this.firstStop?.calculatedArriveByStartDateTime;
  }

  get deliverBy() {
    return this.lastStop?.calculatedArriveByEndDateTime;
  }

  get customerName() {
    return this.billTos[0]?.customerName || null;
  }

  getBillTos(stop: Stop<any>) {
    const billTos = [];
    for (const relationship of this.billToStops) {
      if (relationship.loadNumber === this.loadNumber && relationship.stopSequenceNumber === stop.sequenceNumber) {
        billTos.push(this.billTos.find(item => item.sequenceNumber === relationship.billToSequenceNumber));
      }
    }
    return billTos;
  }

  getStopType(stop: Stop<any>, tenders, stopDetails): boolean {
    const stops = tenders.stopSequenceNumbers.map((item) => stopDetails.find(s => s.sequenceNumber === item));

    if (stops[0].stopNumber === stop.stopNumber) { // First Stop
      return true; // Pick
    } else if (stops[stops.length - 1].stopNumber === stop.stopNumber) {  // Last Stop
      return false; // Drop
    } else {
        switch (stop.stopType) {
          case 0:   // Pick
          case 10:  // OriginAirport
          case 11:  // OriginPort
          case 12:  // OriginRamp
          case 13:  // OriginTerminal
            return true;
          case 1:   // Drop
          case 3:   // DestinationAirport
          case 4:   // DestinationPort
          case 5:   // DestinationRamp
          case 6:   // DestinationTerminal
            return false; // Drop
          default:
            return true; // Pick
        }
    }
  }

  get isHighValue(): boolean {
    return this.rating === 'HV';
  }
}
