import { BaseModel } from '../base.model';
import { CashAdvanceOptionsSourceOld } from './cash-advance-options-source-old.model';

export class CarrierOptions extends BaseModel {
  isCashAdvanceEnabled: boolean;
  enabledCashAdvanceSources: CashAdvanceOptionsSourceOld[];
  fuelBenefitAmount: number;
  sourceCurrencyCode: string;

  constructor(json?: CarrierOptionsJSON) {
    super(json);

    if (json) {
      this.enabledCashAdvanceSources = json.enabledCashAdvanceSources.map(s => new CashAdvanceOptionsSourceOld(s));
    }
  }
}
