import { BaseModel } from 'shared/models/base.model';
import { Temperature } from 'shared/models/my-loads/measurements/temperature.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Items.ItemTemperatureRequirements */
export class ItemTemperatureRequirements extends BaseModel<MyLoads.ItemTemperatureRequirementsJSON> {
  readonly temperatureType: string|null;
  readonly isTemperatureControl: boolean;
  readonly minimum: Temperature|null;
  readonly maximum: Temperature|null;

  constructor(json: MyLoads.ItemTemperatureRequirementsJSON) {
    super(json);

    this.minimum = json.minimum && new Temperature(json.minimum);
    this.maximum = json.maximum && new Temperature(json.maximum);
  }
}
