import { BaseModel } from 'shared/models/base.model';

export class SpotBidDocumentSummary extends BaseModel<SpotBidDocumentSummaryJSON> {
  documentId: number;
  name: string;
  fileExtension: string;
  createdDate: Date;
  partyCode: string;
  spotBidId: number;

  constructor(json?: SpotBidDocumentSummaryJSON) {
    super(json);

    if (!json) {
      return;
    }

    this.createdDate = this.processDate(json.createdDate);
  }
  toJson(): SpotBidDocumentSummaryJSON {
    return Object.assign({}, super.toJson(), {
      createdDate: this.convertDateToDateString(this.createdDate)
    });
  }
}
