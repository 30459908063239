import { BaseModel } from 'shared/models/base.model';
import { CapScoreEvent } from './cap-score-event.model';

export class CapScoreEvents extends BaseModel {
  events: Array<CapScoreEvent>;

  constructor(json?) {
    super(json);
    this.events = json?.map(event => new CapScoreEvent(event));
  }
}
