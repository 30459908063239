import { BaseModel } from 'shared/models/base.model';
import { FormattableAddress } from 'shared/components/formatters/city-state-country.formatter';
import { weakEncrypt, DatetimeUtil } from 'app/util/util';

export class SpotBidSummary extends BaseModel<SpotBidSummaryJSON> {
  loadNumber: number;
  carrierCode: string;
  customerName: string;
  minutesRemaining: number;
  bidStatus: string;
  origin: FormattableAddress;
  destination: FormattableAddress;
  bidCloseDateTime: Date;
  offerStatus: string;
  numberOfTrucksRequested: number;
  isHazMat: boolean;
  isTeamRequired: boolean;
  mode: string;
  timeZone: TimeZone;
  private readonly _origin;
  private readonly _destination;

  get encryptedLoadNumber(): string {
    return weakEncrypt(this.loadNumber.toString());
  }

  constructor(json: SpotBidSummaryJSON) {
    super(json);
    this.bidCloseDateTime = DatetimeUtil.convertTimeFromCSTtoTimeZone(this.processDate(json.bidCloseDateTime), json.timeZone);
    this.origin = this.formatAddress(json.origin);
    this.destination = this.formatAddress(json.destination);
    this._origin = json.origin;
    this._destination = json.destination;
  }

  changeTimeZone(timeZone: TimeZone) {
    this.timeZone = timeZone;
    this.bidCloseDateTime = DatetimeUtil.convertTimeFromCSTtoTimeZone(this.bidCloseDateTime, timeZone);
  }

  toJson(): SpotBidSummaryJSON {
    return Object.assign(
      {},
      this,
      {
        bidCloseDateTime: this.convertDateToDateString(this.bidCloseDateTime),
        origin: this._origin,
        destination: this._destination
      });
  }

  formatAddress(json: SpotBidSummaryJSON['origin']): FormattableAddress {
    return {
      country: json.country,
      stateCode: json.stateCode,
      city: json.cityName,
      postalCode: json.zipcode
    };
  }
}
