import { BaseModel } from 'shared/models/base.model';

export class CarrierCheckReport extends BaseModel {
  loadNumber: number;
  checkId: number;
  checkDate: Date;
  currencyCode: string;
  checkAmount: number;

  constructor(json?) {
    super(json);

    if (json.checkDate) {
      this.checkDate = this.processDate(json.checkDate);
    }
  }

  toJson() {
    return {
      ...super.toJson(),
      checkDate: this.convertDateToDateString(this.checkDate)
    };
  }

}
