import { useContext, useCallback } from 'react';
import { useAnalyticsTrack } from '@chr/react-analytics';

import { SiteSectionContext } from 'shared/components/analytics/site-section.context';
import { PageContext } from 'shared/components/analytics/page.context';
import { BaseModel } from 'shared/models/base.model';

interface TrackClickStreamHookResult<D extends BaseModel> {
  (name);
}

export interface TrackClickStreamProps<D extends BaseModel> {
  // make optional so typescript doesn't complain about it not being passed by HOC.
  trackClickStream: TrackClickStreamHookResult<D>;
}

export const useTrackClickAdobe = <D extends BaseModel>(): TrackClickStreamHookResult<D> => {
  const track = useAnalyticsTrack();
  const { section } = useContext(SiteSectionContext);
  const { page } = useContext(PageContext);
  let location = 'Unknown';
  let category = 'Unknown';
  let sendToAdobe = true;

  if (section != null && page != null && section !== page) {
    location = section + ' - ' + page;
  } else if (section != null) {
    location = section;
  } else if (page != null) {
    location = page;
  }

  return useCallback(
    (name) => {
      if (name.includes('checked')) {
        category = 'checkbox';
      } else if (name.includes('email')) {
        category = 'email link';
      } else if (name.includes('phone')) {
        category = 'phone number link';
      } else if (name.includes('robinson')) {
        category = 'C.H. Robinson website link';
      } else if (name.includes('internal')) {
        category = 'internal link';
      } else if (name.includes('external')) {
        category = 'external link';
      } else if (name.includes('offer')) {
        category = 'Load Offer';
      } else if (name.includes('tender')) {
        category = 'Load Tender';
      } else if (name.includes('lane')) {
        category = 'Preferred Lanes';
      } else if (name.includes('driver')) {
        category = 'Driver on Load';
      } else if (name.includes('trailer')) {
        category = 'Trailer on Load';
      } else if (name.includes('saveCheckCall') || name.includes('check-call')) {
        category = 'Check Call on Load';
      } else if (name.includes('toast')) {
        category = 'toast';
      } else if (name.includes('link')) {
        category = 'link';
      } else if (name.includes('btn-download')) {
        category = 'Download Button';
      } else if (name.includes('print')) {
        category = 'Print';
      } else if (name.includes('btn') || name.includes('button')) {
        category = 'button';
      }

      const ignoredNames = ['book-it-now-btn', 'bin-form-submit-btn', 'find-load-details-link',
        'resolve-tracking-button', 'js-assign-tractor-submit-modal-resolve-btn', 'js-assign-trailer-submit-modal-resolve-btn',
        'resolve-tracking-pro-number-submit-btn', 'resolve-tracking-pro-number-submit-btn'];

      // Book it Now and Find Load Details are being tracked by a Load Info and Booked Load events already
      // Resolve tracking events in My Loads page are also tracked separately
      if (ignoredNames.includes(name)) {
        sendToAdobe = false;
      }

      if (sendToAdobe) {
        track({
          event: 'clickEvent',
          clickEvent: {
            itemIdentification: name,
            linkType: category,
            locationOnWeb: location,
          }
        } as any);
      }
    },
    [section, page]
  );
};
