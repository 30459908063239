import { LOCATION_CHANGE } from 'connected-react-router';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { Observable } from 'rxjs/Observable';
import { Action, AnyAction } from 'redux';
import { combineEpics } from 'redux-observable';
import { Container } from 'typedi';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/switchMap';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import * as a from './tenders-base.actions';
import { Grants } from 'features/security/grants';
import { NavCarrierEpic } from '../../nav-carrier-epic.interface';
import { MyLoadsRepository } from 'app/repositories/my-loads.repository';

interface Deps {
  repo: MyLoadsRepository;
}

type TendersEpic<OutputAction extends Action = AnyAction> = NavCarrierEpic<OutputAction, Deps>;

export const fetchTendersQuietEpic: TendersEpic = (action$, state$, {repo}) =>
  action$.ofType(a.FETCH_TENDERS_QUIET, LOCATION_CHANGE)
    .filter(() => state$.value.auth.isAuthenticated)
    .filter(() => Grants.TendersGrant(state$.value.auth.user))
    .switchMap(() => repo.getTenders())
    .map(tenders => a.fetchTendersQuietSuccess(tenders))
    .catch((res: AjaxError) => Observable.of(a.fetchTendersQuietFailure(res)));

export const baseTendersEpic = (action$, store) => combineEpics(
  fetchTendersQuietEpic
)(action$, store, {repo: Container.get(MyLoadsRepository)});
