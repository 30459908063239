export const StatesCA = [
  {
    'code': 'AB',
    'id': 82,
    'name': 'Alberta'
  },
  {
    'code': 'BC',
    'id': 83,
    'name': 'British Columbia'
  },
  {
    'code': 'MB',
    'id': 84,
    'name': 'Manitoba'
  },
  {
    'code': 'NB',
    'id': 85,
    'name': 'New Brunswick'
  },
  {
    'code': 'NL',
    'id': 86,
    'name': 'Newfoundland and Labrador'
  },
  {
    'code': 'NS',
    'id': 87,
    'name': 'Nova Scotia'
  },
  {
    'code': 'NT',
    'id': 88,
    'name': 'Northwest Territories'
  },
  {
    'code': 'NU',
    'id': 89,
    'name': 'Nunavut'
  },
  {
    'code': 'ON',
    'id': 90,
    'name': 'Ontario'
  },
  {
    'code': 'PE',
    'id': 91,
    'name': 'Prince Edward Island'
  },
  {
    'code': 'QC',
    'id': 92,
    'name': 'Quebec'
  },
  {
    'code': 'SK',
    'id': 93,
    'name': 'Saskatchewan'
  },
  {
    'code': 'YT',
    'id': 94,
    'name': 'Yukon Territory'
  }
];
