import { Container } from 'typedi';
import { AnyAction, combineReducers } from 'redux';

import * as a from 'app/ref-data/ref-data.actions';
import { Countries } from 'shared/data/globalization/countries.data';
import { ReferenceDataRepository } from 'app/repositories/reference-data.repository';

export const equipmentTypes = (state = [], action) => {
  switch (action.type) {
    case a.EQUIPMENT_TYPE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export const specializedEquipmentTypes = (state = [], action) => {
  switch (action.type) {
    case a.SPECIALIZED_EQUIPMENT_TYPE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export const extendedEquipmentTypes = (state = [], action) => {
  switch (action.type) {
    case a.EXTENDED_EQUIPMENT_TYPE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

export const loadFilters = (state = [], action: AnyAction) => {
  switch (action.type) {
    case a.SET_LOAD_FILTERS:
      return action.filters;
    default:
      return state;
  }
};

export const countries = () => Countries;
export const states = () => Container.get(ReferenceDataRepository).statesByCountry;

export const refData = combineReducers({
  equipmentTypes,
  extendedEquipmentTypes,
  specializedEquipmentTypes,
  loadFilters,
  location: combineReducers({countries, states})
});
