import { BaseModel } from 'shared/models/base.model';
import { ValidationWarning } from 'shared/enums/financials/validation-warning.enum';
import { StateChange } from 'shared/enums/financials/state-change.enum';

export class ValidationResult extends BaseModel<ValidationResultJSON> {
  warning: ValidationWarning;
  stateChanges: StateChange[];

  constructor(json?: ValidationResultJSON) {
    super(json);

    if (json) {
      this.stateChanges = json.stateChanges;
    }
  }

  hasStateChange(stateChange: StateChange) {
    return this.stateChanges &&
      this.stateChanges.includes(stateChange);
  }

  hasWarning(warning: ValidationWarning) {
    return this.warning === warning;
  }
}
