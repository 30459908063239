import { BaseModel } from 'shared/models/base.model';

export class CapScoreEvent extends BaseModel<CapScoreEventJSON> {
  loadNumber: number;
  sequenceNumber: number;
  carrierCode: string;
  type: string;
  description: string;
  eventCount: number;
  successfulEventCount: number;
  successfulEventPercentage: number;

  constructor(json?: CapScoreEventJSON) {
    super(json);
    this.successfulEventPercentage = json?.successfulEventPercentage * 100 ?? 0;
  }
}
