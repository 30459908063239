import { BaseModel } from 'shared/models/base.model';
import { LoadProblemLog } from 'shared/models/loads/load-problems/load-problem-log.model';
import { Util } from 'app/util/util';
import { LoadProblemNoteBuilder } from 'shared/models/loads/load-problems/load-problem-note-builder.model';

export class LoadProblem extends  BaseModel {
  carrierCode: string;
  code: string;
  cost?: number;
  notes: string;
  enteredBy?: string;
  enteredDateTime: Date;
  closedBy?: string;
  closedDateTime?: Date;
  loadProblemNoteBuilder: LoadProblemNoteBuilder;
  loadProblemLogs: LoadProblemLog[];
  forceOpenEvent?: boolean;


  private problemSeqNum: number;
  private stopSeqNum: number;
  private displayOnWeb: boolean;

  get problemSequenceNumber(): number {
    return this.problemSeqNum;
  }
  set problemSequenceNumber(value: number) {
    this.problemSeqNum = value;
  }

  get stopSequenceNumber(): number {
    return this.stopSeqNum;
  }
  set stopSequenceNumber(value: number) {
    this.stopSeqNum = value;
  }

  get canDisplay() {
    return this.displayOnWeb !== false;
  }
  set canDisplay(value: boolean) {
    this.displayOnWeb = value;
  }

  set logs(logs: LoadProblemLog[]) {
    this.loadProblemLogs = logs;
  }
  get logs(): LoadProblemLog[] {
    return this.loadProblemLogs;
  }

  constructor(json?: LoadProblemJSON) {
    super(json);

    if (json) {
      if (json.loadProblemLogs && Array.isArray(json.loadProblemLogs)) {
        this.logs = json.loadProblemLogs.map(log => new LoadProblemLog(log));
        this.logs.sort(Util.sortByField('enteredDateTime'));
      }

      this.closedDateTime = this.processDate(json.closedDate);
      this.enteredDateTime = this.processDate(json.problemDateTime);
    }
  }

  toJson(): LoadProblemJSON {
    const baseObj: any = Object.assign({}, this);

    delete baseObj.closedDateTime;
    delete baseObj.enteredDateTime;

    return Object.assign(baseObj, {
      loadProblemLogs: this.loadProblemLogs ? this.loadProblemLogs.map(log => log.toJson()) : this.loadProblemLogs,
      closedDate: this.convertDateToDateString(this.closedDateTime),
      problemDateTime: this.convertDateToDateString(this.enteredDateTime),
    });
  }

  toPostJson(): any {
    return {
      ... this,
      loadProblemLogs: this.loadProblemLogs ? this.loadProblemLogs.map(log => log.toJson()) : this.loadProblemLogs,
      enteredDateTime: this.enteredDateTime ? this.convertDateToDateString(this.enteredDateTime) : this.enteredDateTime
    }
  }
}
