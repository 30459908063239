import { BaseModel } from 'shared/models/base.model';

export enum WeightUnit {
  Pound,
  Kilogram,
  Ton,
  Gram,
  MetricTon,
  TonUK
}

/** NavisphereCarrier.MyLoads.ServiceModel.Measurements.Weight */
export class Weight extends BaseModel<MyLoads.WeightJSON> {
  readonly metric: number;
  readonly metricUom: WeightUnit;
  readonly standard: number;
  readonly standardUom: WeightUnit;
}
