import { Subject } from 'rxjs/Subject';

import { HIDE_MODAL, REGISTER_MODAL, SHOW_MODAL, UNREGISTER_MODAL } from 'shared/components/modal/modal.actions';

export interface Modal {
  visible: boolean;
  visibilityChanges: Subject<boolean>;
  context: {
    [prop: string]: any
  };
}

export const modals = (state: { [key: string]: Modal } = {}, action) => {
  const oldModalState = state[action.name];
  switch (action.type) {
    case REGISTER_MODAL:
      // sometimes a modal might be "shown" before it is registered.  If that is the case, we don't want to hide it upon register
      return {
        ...state,
        [action.name]: { visible: Boolean(oldModalState?.visible), context: {}, visibilityChanges: action.visibilityChanges }
      };
    case UNREGISTER_MODAL:
      const newState = {
        ...state
      };
      delete newState[action.name];
      return newState;
    case SHOW_MODAL:
      return {
        ...state,
        [action.name]: {...oldModalState, visible: true, context: action.context }
      };
    case HIDE_MODAL:
      return {
        ...state,
        [action.name]: {...oldModalState, visible: false, context: {}}
      };
    default:
      return state;
  }
};
