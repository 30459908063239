import { BaseModel } from 'shared/models/base.model';
import { ClaimStatus } from 'shared/enums/claim-status.enum';

export class Claim extends BaseModel {
  claimId: number;
  loadNumber: number;
  carrierCode: string;
  createdDateTime: Date;
  claimAmount: number;
  currencyCode: string;
  status: ClaimStatus;
  claimAdmin: ClaimContactJSON;
  claimRep: string;
  repPhoneNumber: string;
  repEmailAddress: string;

  constructor(json?) {
    super(json);
    if (json.carrierCode) {
      this.carrierCode = json.carrierCode.trim();
    }

    if (json.createdDateTime) {
      this.createdDateTime = this.processDate(json.createdDateTime);
    }

    if (json.claimAdmin) {
      this.claimRep = this.claimAdmin.firstName + ' ' + this.claimAdmin.lastName;
      this.repEmailAddress = this.claimAdmin.email;
      this.repPhoneNumber = this.phoneNumberManipulation(this.claimAdmin.workPhone);
    }
  }

  toJson() {
    return {
      ...super.toJson(),
      createdDateTime: this.convertDateToDateString(this.createdDateTime),
    };
  }

}
