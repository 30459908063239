import { AnyAction } from 'redux';
import { combineEpics } from 'redux-observable';
import { Container } from 'typedi';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

import * as a from 'app/i18n/resources.actions';
import { LoadResourcesAction, LoadResourcesFailureAction, LoadResourcesSuccessAction } from 'app/i18n/resources.actions';
import { SET_CULTURE, SetCultureAction } from 'app/i18n/culture.actions';
import { ResourceRepository } from 'app/repositories/resource.repository';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import { WebsiteRepository } from 'app/repositories/website.repository';
import i18next from 'i18next';

interface Deps {
  repo: ResourceRepository;
  websiteRepo: WebsiteRepository;
}

type ResourceEpic<OutputAction extends AnyAction> = NavCarrierEpic<OutputAction, Deps>;
type LoadResourcesResponseAction = LoadResourcesSuccessAction | LoadResourcesFailureAction;

export const triggerLoadResourcesEpic: ResourceEpic<LoadResourcesAction> = (action$, state$, { websiteRepo }) =>
  action$.ofType<SetCultureAction>(SET_CULTURE)
    .mergeMap(({ culture }) =>
      culture.locale
        ? Observable.of(culture.locale.locale)
        : websiteRepo.getGlobalizationCultures(true)
          .map(cultures => cultures[0]?.culture)
    )
    .pipe(tap((culture) => {
      document.documentElement.lang = culture; // set lang attribute of application's html element
      i18next
        .changeLanguage(culture)
        .then((t) => { })
        .catch((error) => {
          console.error('Language change failed:', error);
        });
    }))
    .map(a.loadResources);

export const loadResourcesEpic: ResourceEpic<LoadResourcesResponseAction> = (action$, store, {repo}) =>
  action$.ofType<LoadResourcesAction>(a.LOAD_RESOURCES)
    .mergeMap(({locale}) =>
      repo.loadResources(locale)
        .map(a.loadResourcesSuccess)
        .catch(err => Observable.of(a.loadResourcesFailure(err)))
    );

export const resourcesEpic = (action$, store) =>
  combineEpics<ResourceEpic<AnyAction>>(
    triggerLoadResourcesEpic,
    loadResourcesEpic
  )(action$, store, {
    repo: Container.get(ResourceRepository),
    websiteRepo: Container.get(WebsiteRepository)
  });
