export const FlatbedExtendedTypes = [
  {
    'code': 'DD',
    'description': 'Double Drop',
    'value': 'Double Drop'
  },
  {
    'code': 'SD',
    'description': 'Step Deck Equipment',
    'value': 'Step Deck (F)'
  },
  {
    'code': 'SDC',
    'description': 'Step Deck Conestoga',
    'value': 'Step Deck Conestoga'
  },
  {
    'code': 'TP',
    'description': 'Tandem Pipe',
    'value': 'Tandem Pipe (F)'
  },
  {
    'code': 'TM',
    'description': 'Tandem Machinery',
    'value': 'Tandem Machinery (F)'
  },
  {
    'code': 'SSD',
    'description': 'Stretch Step Deck',
    'value': 'Stretch Step Deck (F)'
  },
  {
    'code': 'BT',
    'description': 'B Train',
    'value': 'B Train (F)'
  },
  {
    'code': 'CN',
    'description': 'Conestoga',
    'value': 'Conestoga (F)'
  },
  {
    'code': 'CV',
    'description': 'Curtain Van/Tautliner',
    'value': 'Curtain Van/Tautliner (F)'
  },
  {
    'code': 'DDC',
    'description': 'Double Drop Curtain',
    'value': 'Double Drop Curtain (F)'
  },
  {
    'code': 'FA',
    'description': 'Flat Air Ride',
    'value': 'Flat Air Ride (F)'
  },
  {
    'code': 'FS',
    'description': 'Flatbed With Sides ',
    'value': 'Flatbed With Sides (F)'
  },
  {
    'code': 'MX',
    'description': 'Maxi',
    'value': 'Maxi (F)'
  },
  {
    'code': 'RGN',
    'description': 'Removable Gooseneck',
    'value': 'Removable Gooseneck (F)'
  },
  {
    'code': 'SDD',
    'description': 'Stretch Double Drop (F)',
    'value': 'Stretch Double Drop (F)'
  },
  {
    'code': 'ST',
    'description': 'Stretch Trailer',
    'value': 'Stretch Trailer (F)'
  },
  {
    'code': 'HS',
    'description': 'Flat Hotshot',
    'value': 'Flat Hotshot (F)'
  },
  {
    'code': 'SF',
    'description': 'Specialized Flats',
    'value': 'Specialized Flats (F)'
  }
];
