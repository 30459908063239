import { NotificationActionTypes } from './notifications.actions';

export const offerResultNotifications = (state = [] as OfferResultNotification[], action): any => {
  switch (action.type) {
    case NotificationActionTypes.OFFER_RESULT_NOTIFICATION_RECEIVED:
      return [
        ...state,
        action.payload
      ];
    default:
      return state;
  }
};
