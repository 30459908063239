import { REQUEST_SUCCESS } from 'store/requests.actions';
import { Reducer, AnyAction } from 'redux';
import { AnalyticsActionTypes } from '@features/analytics/analytics-action-types';
import { UUID } from 'angular2-uuid';

export interface UserEngagementState {
  lastTimeEventTriggered: Date;
  sessionId: string;
  userId: number;
}

export const initialState: UserEngagementState = {
  lastTimeEventTriggered: undefined,
  sessionId: undefined,
  userId: undefined,
}


export const UserEngagement: Reducer = (state: UserEngagementState = initialState, action: AnyAction): UserEngagementState => {
  if (action.type === `${REQUEST_SUCCESS}:LOAD_USER_DATA_SUCCESS`) {
    const user = action.userJSON;
    if (user) {
      return {
        sessionId: UUID.UUID(),
        lastTimeEventTriggered: new Date(),
        userId: user.userID,
      }
    } else {
      return state
    }
  }
  else if (state.userId && Object.values(AnalyticsActionTypes).includes(action.type)) {
    if (LastEventWasOverThrityMintuesAgo(state.lastTimeEventTriggered)) {
      return {
        ...state,
        lastTimeEventTriggered: new Date(),
        sessionId: UUID.UUID(),
      }
    }
    return {
      ...state,
      lastTimeEventTriggered: new Date(),
    }
  } else {
    return state;
  }
}

const LastEventWasOverThrityMintuesAgo = (lastEventTime: Date): boolean => {
  if (!lastEventTime) {
    return false;
  }

  const timeDifference = new Date().getTime() - lastEventTime.getTime();
  const timeDifferenceInMinutes = timeDifference / 60000;
  return timeDifferenceInMinutes > 30;
}
