export enum TrackingMessageType {
  /// <summary>
  /// No error
  /// </summary>
  None = 0,

  /// <summary>
  /// Ready To Track
  /// </summary>
  ReadyToTrack = 1,

  /// <summary>
  /// TrackingMethodType = 1 (OK/Tracking or Ready to Track)
  /// Tracking
  /// </summary>
  Tracking = 2,

  /// <summary>
  /// Delivered
  /// </summary>
  Delivered = 3,

  /// <summary>
  /// License Plates is missing.
  /// </summary>
  LicensePlatesIsMissing = 4,

  /// <summary>
  /// License Plates is invalid.
  /// </summary>
  LicensePlatesIsInvalid = 5,

  /// <summary>
  /// PRO Number is missing.
  /// </summary>
  ProNumberIsMissing = 6,

  /// <summary>
  /// PRO Number is invalid.
  /// </summary>
  ProNumberIsInvalid = 7,

  /// <summary>
  /// Driver's phone number is missing.
  /// </summary>
  DriversPhoneNumberIsMissing = 8,

  /// <summary>
  /// Driver's phone number is invalid.
  /// </summary>
  DriversPhoneNumberIsInvalid = 9,

  /// <summary>
  /// Tractor Number is missing.
  /// </summary>
  TractorNumberIsMissing = 10,

  /// <summary>
  /// Tractor Number is invalid.
  /// </summary>
  TractorNumberIsInvalid = 11,

  /// <summary>
  /// Trailer Number is missing.
  /// </summary>
  TrailerNumberIsMissing = 12,

  /// <summary>
  /// Trailer Number is invalid.
  /// </summary>
  TrailerNumberIsInvalid = 13,

  /// <summary>
  /// TrackingMethodType = 5 (GpsAggregtor); TrackingStatusType = 3 (Error / Setup needed) || Unknown; TrackingIdentifierType = 0 (None);
  /// </summary>
  GpsAggregtorErrorIdentifierMissing = 14,

  /// <summary>
  /// TrackingMethodType = 1 (CarrierApi); TrackingStatusType = 3 (Error / Setup needed); TrackingIdentifierType = 0 (None);
  /// </summary>
  CarrierApiErrorSetupNeeded = 15,

  /// <summary>
  /// TrackingMethodType = 1 (CarrierApi); TrackingStatusType = 2 (Warning / Not Tracking); TrackingIdentifierType = 0 (None);
  /// </summary>
  CarrierApiWarningNotTracking = 16,

  /// <summary>
  /// TrackingMethodType = 4 (Edi); TrackingStatusType = 3 (Error / Setup needed); TrackingIdentifierType = 0 (None);
  /// </summary>
  EdiErrorSetupNeeded = 17,

  /// <summary>
  /// TrackingMethodType = 4 (Edi); TrackingStatusType = 2 (Warning / Not Tracking); TrackingIdentifierType = 0 (None);
  /// </summary>
  EdiWarningNotTracking = 18,

  /// <summary>
  /// Incompatible Phone
  /// </summary>
  IncompatiblePhone = 19,

  /// <summary>
  /// Invalid Number - Not Trackable
  /// </summary>
  InvalidNumberNotTrackable = 20,

  /// <summary>
  /// Landline Not Trackable
  /// </summary>
  LandlineNotTrackable = 21,

  /// <summary>
  /// Requesting Installation
  /// </summary>
  RequestingInstallation = 22,

  /// <summary>
  /// Stopped Early By Driver
  /// </summary>
  StoppedEarlyByDriver = 23,

  /// <summary>
  /// Denied By Driver
  /// </summary>
  DeniedByDriver = 24,

  /// <summary>
  /// Location Hidden By Driver
  /// </summary>
  LocationHiddenByDriver = 25,

  /// <summary>
  /// Driver Refused Installation
  /// </summary>
  DriverRefusedInstallation = 26,

  /// <summary>
  /// Invalid Truck Number
  /// </summary>
  InvalidTruckNumber = 28,

  /// <summary>
  /// Tracking Now
  /// </summary>
  TrackingNow = 30,

  /// <summary>
  /// Tracking Completed Successfully
  /// </summary>
  TrackingCompletedSuccessfully = 31,

  /// <summary>
  /// Stopped By Creator
  /// </summary>
  StoppedByCreator = 32,

  /// <summary>
  /// Tracking - Waiting For Update
  /// </summary>
  TrackingWaitingForUpdate = 33,

  /// <summary>
  /// Expired Without Location
  /// </summary>
  ExpiredWithoutLocation = 34,

  /// <summary>
  /// Expired - Location Hidden By Drive
  /// </summary>
  ExpiredLocationHiddenByDrive = 35,

  /// <summary>
  /// [other error message not from the list above for macropoint]
  /// </summary>
  MacropointOtherError = 36,

  /// <summary>
  /// Enter driver's phone
  /// </summary>
  EnterDriversPhone = 37,

  /// <summary>
  /// Landline entered
  /// </summary>
  LandlineEntered = 38,

  /// <summary>
  /// Driver has Opt’d Out of SMS
  /// </summary>
  DriverHasOptdOutOfSms = 39,

  /// <summary>
  /// Driver has Opt’d In for SMS
  /// </summary>
  DriverHasOptdInForSms = 40,

  /// <summary>
  /// SMS sent to driver
  /// </summary>
  SmsSentToDriver = 41,

  /// <summary>
  /// Driver has not installed application
  /// </summary>
  DriverHasNotInstalledApplication = 42,

  /// <summary>
  /// Terms have not been accepted
  /// </summary>
  TermsHaveNotBeenAccepted = 43,

  /// <summary>
  /// Communication has not been accepted
  /// </summary>
  CommunicationHasNotBeenAccepted = 44,

  /// <summary>
  /// Load not opened
  /// </summary>
  LoadNotOpened = 45,

  /// <summary>
  /// Location service is off
  /// </summary>
  LocationServiceIsOff = 46,

  /// <summary>
  /// Driver has accepted communication
  /// </summary>
  DriverHasAcceptedCommunication = 47,

  /// <summary>
  /// Driver has accepted terms
  /// </summary>
  DriverHasAcceptedTerms = 48,

  /// <summary>
  /// Driver has location services on
  /// </summary>
  DriverHasLocationServicesOn = 49,

  /// <summary>
  /// Driver installed application
  /// </summary>
  DriverInstalledApplication = 50,

  /// <summary>
  /// Waiting for check-in call
  /// </summary>
  WaitingForCheckInCall = 51,

  /// <summary>
  /// Load opened and waiting for check-in call. No action required
  /// </summary>
  LoadOpenedAndWaitingForCheckInCall = 52,

  /// <summary>
  /// TrackingMethodType = 2 (CarrierApp) | 3 (DriverApp)
  /// Tracking
  /// </summary>
  CarrierDriverAppTracking = 53,

  /// <summary>
  /// If Error message start from "Tracking Error - Trucker Tools tracking failed with the following error: Missing Motor Carrier Number".
  /// </summary>
  TruckerToolErrorPhoneNumberMissing = 54,

  /// <summary>
  /// If Error message start form "Tracking error - Trucker Tools" and != error mentioned above
  /// </summary>
  TruckerToolError = 55,

  /// <summary>
  /// If Error message start from "Tracking Error - Trucker Tools tracking failed with the following error: Missing Motor Carrier Number".
  /// </summary>
  TruckerToolSetupPhoneNumberMissing = 56,

  /// <summary>
  /// If Error message start form "Tracking error - Trucker Tools" and != error mentioned above
  /// </summary>
  TruckerToolSetup = 57,

  /// <summary>
  /// [other error message not from the list above for 2 (CarrierApp) |3 (DriverApp)]
  /// </summary>
  CarrierDriverAppError = 58,

  /// <summary>
  /// Select username from driver info
  /// </summary>
  SelectUserName = 59,

  /// <summary>
  /// GPS device is not available
  /// </summary>
  GPSWarning = 60,

  /// <summary>
  /// GPS missing lat/long
  /// </summary>
  GPSMissingLatLong = 61,

  /// <summary>
  /// Expired without installation
  /// </summary>
  ExpiredWithoutInstallation = 62,

  /// <summary>
  /// Waiting on tracking message update
  /// </summary>
  WaitingOnTrackingMethodUpdate = 63,

  /// <summary>
  /// Undetected
  /// </summary>
  Undetected = 70,

  /// <summary>
  /// Arrival Pickup Exception
  /// </summary>
  ArrivalPickupException = 71,

  /// <summary>
  /// Departure Pickup Exception
  /// </summary>
  DeparturePickupException = 72,

  /// <summary>
  /// Arrival Drop Off Exception
  /// </summary>
  ArrivalDropOffException = 73,

  /// <summary>
  /// Departure Drop Off Exception
  /// </summary>
  DepartureDropOffException = 74,

  /// <summary>
  /// In Transit Exception
  /// </summary>
  InTransitException = 75,

  /// <summary>
  /// Load Arrival Pickup Exception
  /// </summary>
  LoadArrivalPickupException = 76,

  /// <summary>
  /// Load Departure Pickup Exception
  /// </summary>
  LoadDeparturePickupException = 77,

  /// <summary>
  /// Load Arrival Drop Off Exception
  /// </summary>
  LoadArrivalDropOffException = 78,

  /// <summary>
  /// Load Departure Drop Off Exception
  /// </summary>
  LoadDepartureDropOffException = 79,

  /// <summary>
  /// Tracking Problem Resolution in Progress
  /// </summary>
  Pending = 100
}
