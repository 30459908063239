import { ContactPhone } from './contact-phone.model';

export class LoadContact {
  lanId: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  phone: ContactPhoneJSON;
  branch: string;
  isBranch?: boolean;

  constructor(json?: LoadContactJSON) {
    if (json) {
      this.lanId = json.lanId;
      if (!json.firstName && !json.lastName) {
        this.firstName = 'C.H.';
        this.lastName = 'Robinson';
      } else {
        this.firstName = json.firstName;
        this.lastName = json.lastName;
      }
      this.emailAddress = json.emailAddress;
      this.branch = json.branch || 'Not Available';
      this.isBranch = json.isBranch;
      if (json.phone) {
        this.phone = new ContactPhone(json.phone);
      } else {
        this.phone = {number: '1-800-326-9977'};
      }
    }
  }
}
