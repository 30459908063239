import { Division } from 'shared/enums/user/division.enum';
import { User } from '@shared/models/user.model';

/**
 * Represents a carrier division.
 */
export class CarrierDivision {
  public division: string;

  /**
   * Creates a new instance of CarrierDivision.
   * @param divisions - An array of division values.
   */
  constructor(divisions: Division[]) {
    if (divisions.includes(Division.NAST)) {
      this.division = 'NAST';
    } else if (divisions.includes(Division.IMDL)) {
      this.division = 'IMDL';
    } else if (divisions.includes(Division.GF)) {
      this.division = 'GF';
    } else if (divisions.includes(Division.TMC)) {
      this.division = 'TMC';
    } else if (divisions.includes(Division.EST)) {
      this.division = 'EST';
    } else {
      this.division = 'None';
    }
  }

  /**
   * Converts the user array of division values to an array of division names.
   * Then checks for the TMC flag in the rollup carriers and appends the TMC division if found.
   * @param user - A User object.
   * @returns An array of division names.
   */
  static retrieveRollupDivisions(user: User): string[] {
    const stringArr: string[] = [];
    
    if (user.divisions) {
      user.divisions.map(div => 
      {
        const enumName = Division[div];
        if (enumName !== undefined)
          stringArr.push(enumName);
        else
          stringArr.push("Unknown");
      });
    }

    const tmcString = Division[Division.TMC];
    if (user.carrierRollups) {
      if (!stringArr.includes(tmcString) && user.carrierRollups.some(carrier => carrier.isTMC))
        stringArr.push(tmcString);
    }
    
    return stringArr;
  }
}
