import { BaseModel } from 'shared/models/base.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Appointments.Appointment */
export class AppointmentDateRange extends BaseModel<MyLoads.AppointmentDateRangeJSON> {
  readonly startDate?: Date|null;
  readonly startHours?: Date|null;
  readonly endDate?: Date|null;
  readonly endHours?: Date|null;

  constructor(json: MyLoads.AppointmentDateRangeJSON) {
    super(json);

    this.startDate = this.processDate(json.startDate);
    this.startHours = this.processTime(json.startHours);
    this.endDate = this.processDate(json.endDate);
    this.endHours = this.processTime(json.endHours);
  }
}
