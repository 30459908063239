import { StopAddress } from 'shared/models/stop-address.model';
import { BaseDriverLog } from 'shared/models/loads/driver-logs/base-driver-log.model';

export class DriverLog extends BaseDriverLog {

  location: StopAddress;
  isLate: boolean;
  estimatedTimeOfArrival?: string;
  enteredDateTime?: string;
  timeZoneDateTime: {
    displayDateTime?: string,
    timeZoneAbbreviation?: string,
    timeZoneDescription?: string
  };
  constructor(json: DriverLogJSON) {
    super();

    this.notes = json.notes;
    this.estimatedTimeOfArrival = json.estimatedTimeOfArrival;
    this.sourceUser = json.enteredBy;
    this.sourceSystem = json.informationFrom;
    this.logDateTime = this.processDate(json.logDateTime) || new Date();

    this.isLate = Boolean(json.isLate);

    this.location = new StopAddress();
    if (json.location) {
      this.location = new StopAddress(json.location);
    }
    this.timeZoneDateTime = json.timeZoneDateTime;
    this.enteredDateTime = json.enteredDateTime;
  }

  toJson(): DriverLogJSON {
    return {
      notes: this.notes,
      enteredBy: this.sourceUser,
      informationFrom: this.sourceSystem,
      logDateTime: this.convertDateToDateString(this.logDateTime),
      isLate: this.isLate,
      location: this.location.toJson(),
      recordedTemperature: this.recordedTemperature,
      estimatedTimeOfArrival: this.estimatedTimeOfArrival,
      timeZoneDateTime: {
        displayDateTime: this.timeZoneDateTime.displayDateTime,
        timeZoneAbbreviation: this.timeZoneDateTime.timeZoneAbbreviation,
        timeZoneDescription: this.timeZoneDateTime.timeZoneDescription
      },
      enteredDateTime: this.enteredDateTime,
    };
  }

  public setLocation(city, stateProv, country, geolocation?: GeoLocationJSON) {
    this.location = new StopAddress({
      city: city,
      stateProvinceCode: stateProv,
      countryCode: country,
      geolocation
    });
  }


  private getSaveDate() {
    if (!this.logDateTime) {
      this.logDateTime = new Date();
    }
    return this.logDateTime;
  }

  toPostJson(): DriverLogJSON {
    return {
      notes: this.notes,
      enteredBy: this.sourceUser,
      informationFrom: this.sourceSystem,
      logDateTime: this.convertDateToDateString(this.getSaveDate()),
      isLate: this.isLate,
      location: this.location.toJson(),
      recordedTemperature: this.recordedTemperature,
      estimatedTimeOfArrival: this.estimatedTimeOfArrival,
      timeZoneDateTime: {
        displayDateTime: this.timeZoneDateTime.displayDateTime || '',
        timeZoneAbbreviation: this.timeZoneDateTime.timeZoneAbbreviation || '',
        timeZoneDescription: this.timeZoneDateTime.timeZoneDescription || ''
      },
      enteredDateTime: this.enteredDateTime,
    };
  }
}
