import { FormattableAddress } from 'shared/components/formatters/city-state-country.formatter';
import { StopType } from 'shared/enums/stop-type.enum';
import { BaseModel } from 'shared/models/base.model';
import { CapLoadDetailMetrics } from './cap-load-detail-metrics';
import { LoadDetailStopV2 } from './cap-load-detail-stop-v2.model';

export class CapLoadDetailV2 extends BaseModel<CapLoadDetailV2JSON> {
  loadNumber: number;
  bookSequencNumber: number;
  carrierCode: string;
  parentCarrierCode: string;
  firstFinLockDateTime: string;
  metrics: CapLoadDetailMetrics;
  originLocation: FormattableAddress;
  destinationLocation: FormattableAddress;
  pickupStops: Array<LoadDetailStopV2>;
  deliveryStops: Array<LoadDetailStopV2>;
  originStop: LoadDetailStopV2;
  destinationStop: LoadDetailStopV2;
  private readonly _originLocation;
  private readonly _destinationLocation;

  constructor(json?: CapLoadDetailV2JSON) {
    super(json);
    this.originLocation = this.formatAddress(json.origin);
    this.destinationLocation = this.formatAddress(json.destination);
    this._originLocation = json.origin;
    this._destinationLocation = json.destination;
    this.convertStops(json);
    this.setOriginAndDestinationStops(json);
    this.metrics = new CapLoadDetailMetrics(json?.metrics);
  }

  private formatAddress(json: CapLoadDetailV2JSON['origin']): FormattableAddress {
    return {
      city: json?.city,
      stateCode: json?.state,
      country: json?.country,
      postalCode: json?.postalCode,
    };
  }

  private convertStops(json: CapLoadDetailV2JSON) {
    if (json.picks) {
      this.pickupStops = json.picks.map(stop => this.mapLoadDetailStopJsonToModel(stop, StopType.PICKUP));
    }

    if (json.drops) {
      this.deliveryStops = json.drops.map(stop => this.mapLoadDetailStopJsonToModel(stop, StopType.DROP_OFF));
    }
  }

  private mapLoadDetailStopJsonToModel(json: LoadDetailStopJSON, type: StopType) {
    const newStop = new LoadDetailStopV2(json);
    newStop.type = type;
    newStop.loadNumber = this.loadNumber;
    return newStop;
  }

  private setOriginAndDestinationStops(json: CapLoadDetailV2JSON) {
    this.originStop = new LoadDetailStopV2(json?.picks[0]);
    this.destinationStop = new LoadDetailStopV2(json?.drops[json.drops.length - 1]);
  }
}
