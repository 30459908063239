import { BaseModel } from 'shared/models/base.model';

export enum CapStatusType {
  RESTRICTED = 'RESTRICTED',
  BASE = 'BASE',
  CORE = 'CORE',
  KEY = 'KEY',
}

export class CapScore extends BaseModel<CapScoreJSON> {
  startDate: Date;
  endDate: Date;
  programStatus: CapStatusType;
  totalLoads: number;
  hourlyCompliantLoads: number;
  hourlyCompliance: number;
  onTimeCompliantLoads: number;
  onTimeCompliance: number;
  month: string;

  constructor(json?: CapScoreJSON) {
    super(json);
    this.startDate = this.processDate(json.startDate);
    this.endDate = this.processDate(json.endDate);
  }

  public hasDates() {
    return this.startDate != null && this.endDate != null;
  }

  public programStatusResourceKey(): ResourceKey {
    return ['KEY', 'CORE', 'BASE', 'RESTRICTED'].includes(this.programStatus) ? (this.programStatus as ResourceKey) : ('BASE' as ResourceKey);
  }
}
