import { BaseModel } from 'shared/models/base.model';

export class CapLoadDetailMetrics extends BaseModel {
  trackingSuccessExcludedFlag: boolean;
  trackingSuccessExclusionReason: number;
  totalTrackingSuccessPercentage: number;
  trackingSuccessTier: string;

  constructor(json?: CapLoadDetailMetricsJSON) {
    super(json);
    this.totalTrackingSuccessPercentage = this.formatPercentage(json?.totalTrackingSuccessPercentage);
  }

  private formatPercentage(percentage?: number | null): number {
    if (typeof percentage === 'number') {
      return +(percentage * 100).toFixed(1);
    }
    return 0;
  }
}
