import { BaseModel } from 'shared/models/base.model';
import { CarrierCheckItem } from 'shared/models/voucher/carrier-check-item.model';

export class CarrierCheckDetail extends BaseModel {
  checkId: number;
  companyPaid: string;
  checkAmount: number;
  checkNumber: number;
  checkDate: Date;
  items: Array<CarrierCheckItem>;
  isValid: boolean;
  currencyCode: string;

constructor(json) {
  super(json);

  if (json.checkDate) {
    this.checkDate = this.processDate(json.checkDate);
  }
}

  toJson() {
    return {
      ...super.toJson(),
      checkDate: this.convertDateToDateString(this.checkDate),
    };
  }
}
