import { Carrier } from 'shared/models/carrier/carrier.model';
import { BaseModel } from 'shared/models/base.model';

export class BaseLoadBook extends BaseModel {
  public loadNumber: number;
  public sequenceNumber: number;
  public carrier: Carrier;

  constructor(json?: LoadBookJSON) {
    super(json);
    if (json) {
      this.carrier = new Carrier(json.carrier);
    }
  }

  toJson(): LoadBookJSON {
    return {
      loadNumber: this.loadNumber,
      sequenceNumber: this.sequenceNumber,
      carrier: this.carrier.toJson()
    };
  }
}
