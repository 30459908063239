import { memo, useCallback, useEffect, useRef } from 'react';
import { Turnstile, TurnstileInstance } from '@marsidev/react-turnstile'
import { useDispatcher } from 'app/hooks/store/use-dispatcher.hook';
import { registerCaptchaResults } from 'shared/components/turnstile-captcha/captcha.actions';
import { LoaderNames, useLoader } from 'app/hooks/use-loader.hook';
import Container from 'typedi';
import { CaptchaStatus, CaptchaPages, defaultCaptchaKey } from "./types";

export interface CaptchaProps {
  page: CaptchaPages;
  isEnabledForPage: boolean;
  id: string;
}

export const TurnstileCaptcha = ({id, page, isEnabledForPage }: CaptchaProps) => {
  const siteKey = Container.get<string>('appConstants.cloudFlareSiteKey');
  const dispatchCaptchaResults = useDispatcher(registerCaptchaResults)
  const mainLoader = useLoader(LoaderNames.Main);

  const onError = () => dispatchCaptchaResults(CaptchaStatus.ERROR, null, page);
  const onExpiration = () => dispatchCaptchaResults(CaptchaStatus.EXPIRED, null, page);
  const onSuccess = (token: string) => {
    dispatchCaptchaResults(CaptchaStatus.SUCCESS, token, page)
  };
  const onDisabled = () => dispatchCaptchaResults(CaptchaStatus.DISABLED, CaptchaStatus.DISABLED, page);
  const beforeInteractive = () => mainLoader.hide();

  // expire captcha values on component unmount
  useEffect(() => {
    return () => {
      onExpiration();
    }
  }, []);

  useEffect(() => {
    if (!isEnabledForPage) {
      onDisabled();
    }
  }, [isEnabledForPage]);

  if (!isEnabledForPage) {
    return null;
  }

  return (
    <Turnstile
      id={id}
      siteKey={siteKey}
      onError={onError}
      onExpire={onExpiration}
      onSuccess={onSuccess}
      onBeforeInteractive={beforeInteractive}
    />
  )
}


/**
 * @interface Props
 * @description This interface defines the properties for TurnstileCaptchaComponent.
 */
interface Props {
  /**
   * A property that can be used to trigger token reset.
   * The type is `any`, indicating that it can accept any value.
   * This is often used in scenarios where you might pass in a value that will cause the component to reset 
   * base upon events in the parent component.
   * @type {any}
   * @memberof Props
   */
  resetTracker?: any;

  /**
   * A boolean flag indicating whether the component should be rendered or not.
   * When `true`, the component's functionality is active; when `false`, it is disabled.
   * This is useful for controlling the component's behavior with LD flags.
   * @type {boolean}
   * @memberof Props
   */
  isEnabled: boolean;

  /**
   * An optional key that can be used to uniquely identify the component instance and the token.
   * This is the key that will be used to stored and access the token from localstorage.
   * This is particularly useful if you want to isolate your captcha token in localstorage.
   * @type {string}
   * @memberof Props
   */
  captchaKey?: string;

  /**
   * An optional function to call after token is set.
   * @type {Function}
   * @memberof Props
   */
    onSuccessCallback?: () => void;
}

export const TurnstileCaptchaComponent = memo(({ captchaKey, isEnabled, resetTracker, onSuccessCallback }: Props) => {
  const mainLoader = useLoader(LoaderNames.Main);
  const ref = useRef<TurnstileInstance>(null);
  const storageKey = captchaKey ?? defaultCaptchaKey;

  const handleSuccess = useCallback((token: string) => {
    localStorage.setItem(storageKey, token);
    if(onSuccessCallback) onSuccessCallback();
    mainLoader.hide();
  }, [mainLoader]);

  useEffect(() => {
    if (isEnabled) {
      mainLoader.show();
    }
  }, []);

  useEffect(() => {
    return () => {
      localStorage.removeItem(storageKey);
      ref.current?.remove();
    }
  }, []);

  useEffect(() => {
    if (resetTracker) {
      ref.current?.reset();
    }
  }, [resetTracker])

  if (!isEnabled) {
    return null;
  }

  return (
    <Turnstile
      ref={ref}
      id={storageKey}
      options={{ action: storageKey }}
      siteKey={appConstants?.cloudFlareSiteKey ?? ''}
      onError={ref.current?.reset}
      onSuccess={handleSuccess}
      onExpire={ref.current?.reset}
    />
  );
})
