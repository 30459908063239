export const messagesToSuppress = [
 // suppresses SignalR connectivity issue
 'Failed to complete negotiation with the server:',
 `Error: WebSocket closed with status code: 1006 (no reason given)`,

 // suppresses azure maps errors
 'intervention: Ignored attempt to cancel a touchmove event with cancelable=false, for example because scrolling is in progress and cannot be interrupted.',
 'touchZoomRotate.onStart is not a function',
 'Failed to retrieve the style definitions: TypeError: Failed to fetch',
 // suppress console errors from ad blockers
 'UET is not defined',
 'Munchkin is not defined',
 'Prettify fetch failed',
 // Ad-blocker's preventing adobe
 '[alloy] [ActivityCollector] Network request failed',
 'Unable to set BranchIO Identity: Error: Branch SDK initialization failed, so further methods cannot be called Failure Code',

 // ad blocker related errors
 'Identity cookie not found.',
 'Failed to load script https://assets.adobedtm.com/'
];

export const urlsExcludedFromLoadingTimes = [
	'launchdarkly.com',
	'adobedtm.com',
	'adsrvr.org',
	'app.link',
	'bing.com',
	'comm100.com',
	'comm100vue.com',
	'demdex.net',
	'doubleclick.net',
	'everesttech.net',
	'facebook',
	'findmanualsonline.net',
	'fullstory.com',
	'google',
	'googleapis.com',
	'googletagmanager.com',
	'gstatic.com',
	'honey.io',
	'kampyle.com',
	'kaspersky - labs.com',
	'marketo.net',
	'microsoft.com',
	'mktoresp.com',
	'mktoutil.com',
	'omtrdc.net',
	'ringcentral.com'
]