export const PhoneFormats = [
  {
    'internationalDialingCode': '1',
    'dataEntryMask': '(###) ###-####'
  },
  {
    'internationalDialingCode': '33',
    'dataEntryMask': '#########################'
  },
  {
    'internationalDialingCode': '45',
    'dataEntryMask': '#########################'
  },
  {
    'internationalDialingCode': '41',
    'dataEntryMask': '#########################'
  },
  {
    'internationalDialingCode': '44',
    'dataEntryMask': '#########################'
  },
  {
    'internationalDialingCode': '86',
    'dataEntryMask': '#########################'
  },
  {
    'internationalDialingCode': '48',
    'dataEntryMask': '## ### ## ##'
  },
  {
    'internationalDialingCode': '65',
    'dataEntryMask': '########'
  },
  {
    'internationalDialingCode': '66',
    'dataEntryMask': '(##) ###-####'
  },
  {
    'internationalDialingCode': '49',
    'dataEntryMask': '#########################'
  },
  {
    'internationalDialingCode': '31',
    'dataEntryMask': '#########################'
  },
  {
    'internationalDialingCode': '47',
    'dataEntryMask': '#########################'
  },
  {
    'internationalDialingCode': '55',
    'dataEntryMask': '(##) #### ####'
  },
  {
    'internationalDialingCode': '852',
    'dataEntryMask': '########'
  },
  {
    'internationalDialingCode': '91',
    'dataEntryMask': '#########################'
  },
  {
    'internationalDialingCode': '43',
    'dataEntryMask': '#########################'
  }
];
