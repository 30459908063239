import { BaseModel } from 'shared/models/base.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Items.ItemRequiredEquipment */
export class ItemRequiredEquipment extends BaseModel<MyLoads.ItemRequiredEquipmentJSON> {
  readonly chainsCount?: number;
  readonly coilRacks?: number;
  readonly equipmentType: string|null;
  readonly locksCount?: number;
  readonly maxTrailerAge?: number;
  readonly squaresCount?: number;
  readonly stepDeck: string;
  readonly strapsCount?: number;
  readonly tarpType: string|null;
  readonly timbersCount?: number;
}
