import { AnyAction } from 'redux';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ActionsObservable, combineEpics, Epic, StateObservable } from 'redux-observable';
import 'rxjs/add/operator/mergeMap';
import { authEpic } from 'features/security/auth.epics';
import { findLoadsBaseEpic } from './find-loads/find-loads-base.epics';
import { cultureEpic } from 'app/i18n/culture.epics';
import { resourcesEpic } from 'app/i18n/resources.epics';
import { popupsEpic } from 'app/browser/popups.epics';
import { refDataEpic } from 'app/ref-data/ref-data.epics';
import { analyticsEpics } from './analytics.epics';
import { appEpic } from './app.epics';
import { toastEpics } from 'shared/components/toast/toasts.epics';
import { baseTendersEpic } from './tenders/tenders-base.epic';
import { fullStoryEpics } from './fullstory.epics';
import { analyticsV2Epics } from 'features/analytics/redux/analytics-v2.epics';
import { datadogEpics } from './datadog.epics';

type NavCarrierEpic = Epic<AnyAction, AnyAction, NavCarrierState>;

export const combinedEpic = combineEpics(
  authEpic,
  findLoadsBaseEpic,
  cultureEpic,
  resourcesEpic,
  popupsEpic,
  refDataEpic,
  baseTendersEpic,
  fullStoryEpics,
  datadogEpics,
  analyticsEpics,
  appEpic,
  toastEpics,
  analyticsV2Epics,
);

export const epic$ = new BehaviorSubject<NavCarrierEpic>(combinedEpic);

export const rootEpic = (action$: ActionsObservable<AnyAction>, state$: StateObservable<NavCarrierState>) =>
  epic$.mergeMap(epic => epic(action$, state$, null));
