export const ExtendedTypes = [
  {
    'code': 'DD',
    'description': 'Double Drop',
    'value': 'Double Drop'
  },
  {
    'code': 'SD',
    'description': 'Step Deck Equipment',
    'value': 'Step Deck (F)'
  },
  {
    'code': 'B',
    'description': 'Bulk',
    'value': 'Bulk'
  },
  {
    'code': 'SDC',
    'description': 'Step Deck Conestoga',
    'value': 'Step Deck Conestoga'
  },
  {
    'code': 'V',
    'description': 'Van',
    'value': 'Van'
  },
  {
    'code': 'STR',
    'description': 'Straight Truck',
    'value': 'Straight Truck (V)'
  },
  {
    'code': 'TK',
    'description': 'Tanker - Truck',
    'value': 'Tanker - Truck (V)'
  },
  {
    'code': 'P',
    'description': 'Pups',
    'value': 'Pups (V)'
  },
  {
    'code': 'IC',
    'description': 'Intermodal Container',
    'value': 'Intermodal Container (I)'
  },
  {
    'code': 'IT',
    'description': 'Intermodal Trailer',
    'value': 'Intermodal Trailer (I)'
  },
  {
    'code': 'OT',
    'description': 'One Ton',
    'value': 'One Ton (P)'
  },
  {
    'code': '2T',
    'description': 'Two Ton',
    'value': 'Two Ton (T)'
  },
  {
    'code': 'MF',
    'description': 'Mini Float',
    'value': 'Mini Float (T)'
  },
  {
    'code': 'SA',
    'description': 'Single Axle',
    'value': 'Single Axle (T)'
  },
  {
    'code': 'TP',
    'description': 'Tandem Pipe',
    'value': 'Tandem Pipe (F)'
  },
  {
    'code': 'TM',
    'description': 'Tandem Machinery',
    'value': 'Tandem Machinery (F)'
  },
  {
    'code': 'SSD',
    'description': 'Stretch Step Deck',
    'value': 'Stretch Step Deck (F)'
  },
  {
    'code': 'SETT',
    'description': 'Steerable Empty Tank Trailer',
    'value': 'Steerable Empty Tank Trailer (T)'
  },
  {
    'code': 'BLADE',
    'description': 'Blade Trailer',
    'value': 'Blade Trailer (T)'
  },
  {
    'code': 'BARGE',
    'description': 'Barge',
    'value': 'Barge (T)'
  },
  {
    'code': 'VS',
    'description': 'Vessel',
    'value': 'Vessel (VS)'
  },
  {
    'code': 'BEND',
    'description': 'End Dump Truck',
    'value': 'End Dump Truck (B)'
  },
  {
    'code': 'BSTR',
    'description': 'Bolster - Rail',
    'value': 'Bolster - Rail'
  },
  {
    'code': 'BT',
    'description': 'B Train',
    'value': 'B Train (F)'
  },
  {
    'code': 'BTT',
    'description': 'Beam Trailer (T)',
    'value': 'Beam Trailer (T)'
  },
  {
    'code': 'BX50100S',
    'description': 'Boxcar, 100 ton, Single door',
    'value': 'Boxcar, 100 ton, Single door'
  },
  {
    'code': 'BX50100SH',
    'description': 'Boxcar, 100 ton, Single door, High cube',
    'value': 'Boxcar, 100 ton, Single door, High cube'
  },
  {
    'code': 'BX60',
    'description': 'Boxcar',
    'value': 'Boxcar'
  },
  {
    'code': 'BX6070SC',
    'description': 'Boxcar, 70 ton, Single door, Cushion',
    'value': 'Boxcar, 70 ton, Single door, Cushion'
  },
  {
    'code': 'C',
    'description': 'Dry Container',
    'value': 'Dry Container'
  },
  {
    'code': 'CN',
    'description': 'Conestoga',
    'value': 'Conestoga (F)'
  },
  {
    'code': 'CV',
    'description': 'Curtain Van/Tautliner',
    'value': 'Curtain Van/Tautliner (F)'
  },
  {
    'code': 'ET',
    'description': 'Etrac',
    'value': 'Etrac'
  },
  {
    'code': 'DDC',
    'description': 'Double Drop Curtain',
    'value': 'Double Drop Curtain (F)'
  },
  {
    'code': 'DT',
    'description': 'Dump Truck',
    'value': 'Dump Truck'
  },
  {
    'code': 'FA',
    'description': 'Flat Air Ride',
    'value': 'Flat Air Ride (F)'
  },
  {
    'code': 'FC',
    'description': 'Flat Car',
    'value': 'Flat Car'
  },
  {
    'code': 'FO',
    'description': 'Flatbed',
    'value': 'Flatbed'
  },
  {
    'code': 'FS',
    'description': 'Flatbed With Sides ',
    'value': 'Flatbed With Sides (F)'
  },
  {
    'code': 'FV',
    'description': 'Furniture Van',
    'value': 'Furniture Van'
  },
  {
    'code': 'GNDL',
    'description': 'Gondola - Rail',
    'value': 'Gondola - Rail'
  },
  {
    'code': 'HFD',
    'description': 'Heavy Duty Depressed - Rail',
    'value': 'Heavy Duty Depressed - Rail'
  },
  {
    'code': 'HFM',
    'description': 'Heavy Duty Flat - Rail',
    'value': 'Heavy Duty Flat - Rail'
  },
  {
    'code': 'HOP',
    'description': 'Hopper - Rail',
    'value': 'Hopper - Rail'
  },
  {
    'code': 'IR',
    'description': 'Reefer Container',
    'value': 'Reefer Container'
  },
  {
    'code': 'LR',
    'description': 'Landoll/Rollback',
    'value': 'Landoll/Rollback'
  },
  {
    'code': 'MX',
    'description': 'Maxi',
    'value': 'Maxi (F)'
  },
  {
    'code': 'OC',
    'description': 'Ocean Container',
    'value': 'Ocean Container'
  },
  {
    'code': 'PLT',
    'description': 'Plate Trailer',
    'value': 'Plate Trailer'
  },
  {
    'code': 'PT',
    'description': 'Perimeter Trailer (T)',
    'value': 'Perimeter Trailer (T)'
  },
  {
    'code': 'R',
    'description': 'Reefer',
    'value': 'Reefer'
  },
  {
    'code': 'RC',
    'description': 'Reefer Container',
    'value': 'Reefer Container'
  },
  {
    'code': 'RGN',
    'description': 'Removable Gooseneck',
    'value': 'Removable Gooseneck (F)'
  },
  {
    'code': 'SC',
    'description': 'Specialized Container',
    'value': 'Specialized Container'
  },
  {
    'code': 'SDD',
    'description': 'Stretch Double Drop (F)',
    'value': 'Stretch Double Drop (F)'
  },
  {
    'code': 'SDR',
    'description': 'Step Deck with Ramps',
    'value': 'Step Deck with Ramps'
  },
  {
    'code': 'SFD',
    'description': 'Standard Depressed Car - Rail',
    'value': 'Standard Depressed Car - Rail'
  },
  {
    'code': 'SFM',
    'description': 'Standard Flat - Rail',
    'value': 'Standard Flat - Rail'
  },
  {
    'code': 'ST',
    'description': 'Stretch Trailer',
    'value': 'Stretch Trailer (F)'
  },
  {
    'code': 'TKR',
    'description': 'Tanker - Rail',
    'value': 'Tanker - Rail'
  },
  {
    'code': 'UC',
    'description': 'Ultra Cube',
    'value': 'Ultra Cube'
  },
  {
    'code': 'VI',
    'description': 'Insulated',
    'value': 'Insulated'
  },
  {
    'code': 'VV',
    'description': 'Dry Vented Van',
    'value': 'Dry Vented Van'
  },
  {
    'code': 'WK',
    'description': 'Walking Floor',
    'value': 'Walking Floor'
  },
  {
    'code': 'HS',
    'description': 'Flat Hotshot',
    'value': 'Flat Hotshot (F)'
  },
  {
    'code': 'SF',
    'description': 'Specialized Flats',
    'value': 'Specialized Flats (F)'
  },
  {
    'code': 'BDPT',
    'description': 'Dry Pneumatic',
    'value': 'Dry Pneumatic Trailer (B)'
  },
  {
    'code': 'BFGD',
    'description': 'Food Grade Dry Bulk',
    'value': 'Food Grade Dry Bulk (B)'
  },
  {
    'code': 'BHOP',
    'description': 'Hopper - Bulk',
    'value': 'Hopper - Bulk (B)'
  },
  {
    'code': 'BISO',
    'description': 'ISO Tank',
    'value': 'ISO Tank (B)'
  },
  {
    'code': 'BLAT',
    'description': 'Liquid Aluminum Tank Trailer',
    'value': 'Liquid Aluminum Tank Trailer (B)'
  },
  {
    'code': 'BLFGT',
    'description': 'Liquid Food Grade Tank Trailer',
    'value': 'Liquid Food Grade Tank Trailer (B)'
  },
  {
    'code': 'BLFRPT',
    'description': 'Liquid FRP Tank Trailer',
    'value': 'Liquid FRP Tank Trailer (B)'
  },
  {
    'code': 'BLRL',
    'description': 'Liquid Rubber Lined Trailer',
    'value': 'Liquid Rubber Lined Trailer (B)'
  },
  {
    'code': 'BLSS',
    'description': 'Liquid Stainless Steel Trailer',
    'value': 'Liquid Stainless Steel Trailer (B)'
  },
  {
    'code': 'BST',
    'description': 'Sand Trailer (B)',
    'value': 'Sand Trailer (B)'
  },
  {
    'code': 'BVT',
    'description': 'Vacuum Trailer',
    'value': 'Vacuum Trailer (B)'
  },
  {
    'code': 'J',
    'description': 'Dray',
    'value': 'Dray'
  },
  {
    'code': 'H',
    'description': 'Power Only',
    'value': 'Power Only'
  },
  {
    'code': 'STRR',
    'description': 'Straight Truck',
    'value': 'Straight Truck (R)'
  },
  {
    'code': 'CARGO',
    'description': 'Cargo Van',
    'value': 'Cargo Van (V)'
  }
];
