import { HubConnectionBuilder, HubConnectionState, JsonHubProtocol, LogLevel } from '@microsoft/signalr';
import { Container } from 'typedi';
import {
  bookTrackingNotificationReceived,
  offerResultNotificationReceived,
} from 'store/epics/notifications/notifications.actions';
import { CONNECT_SIGNALR } from '../epics/app.actions';
import { updateLoadInformation, updateLoadTrackingStatus } from '@features/my-loads-v2/myLoadsSlice';

const isDev = process.env.NODE_ENV === 'development';
const isTest = process.env.NODE_ENV === 'test';
const notificationsUrl = Container.get<string>('appConstants.realTimeEventsUrl');

const repoPromise = new Promise(resolve => Container.set('user-repository-promise-resolver', resolve));
const usernamePromise = new Promise(resolve => Container.set('username-promise-resolver', resolve));

const accessTokenFactoryFunc = (): string | Promise<string> => {
  const oktaTokens = JSON.parse(localStorage.getItem('okta-token-storage'));
  const idToken = oktaTokens?.idToken?.idToken;
  if (idToken) {
    return idToken;
  } else {
    return Promise.all([repoPromise, usernamePromise]).then(([repo, username]: [any, string]) => {
      return repo
        .getSignedToken(username)
        .map(r => r.token)
        .toPromise();
    });
  }
};

const signalrMiddleware = store => next => action => {
  if (action.type === CONNECT_SIGNALR && !isTest) {
    try {
      const resolve = Container.get<any>('username-promise-resolver');
      const user = store.getState().auth?.user;
      // TODO: maybe we log them out if this is null?
      //if (!user?.username) {

      //}
      resolve(user?.username);

      const options = {
        logMessageContent: isDev,
        logger: isDev ? LogLevel.Debug : LogLevel.Error,
        accessTokenFactory: accessTokenFactoryFunc,
      };


      const logMap = {
        [LogLevel.Information]: console.info,
        [LogLevel.Trace]: console.log,
        [LogLevel.Debug]: console.log,
        [LogLevel.None]: console.log,
        [LogLevel.Error]: console.error,
        [LogLevel.Warning]: console.warn,
        [LogLevel.Critical]: console.error,
      };

      // Customize logging
      const customLogger = (logLevel: LogLevel, message: string) => logMap[logLevel](`WSS: ${message}`);

      // create the connection instance
      // withAutomaticReconnect will automatically try to reconnect
      // and generate a new socket connection if needed
      const connection = new HubConnectionBuilder()
        .withUrl(notificationsUrl + '/api/service', options)
        .withAutomaticReconnect()
        .configureLogging({ log: customLogger })
        .withHubProtocol(new JsonHubProtocol())
        .build();

      // Note: to keep the connection open the serverTimeout should be
      // larger than the KeepAlive value that is set on the server
      // keepAliveIntervalInMilliseconds default is 15000 and we are using default
      // serverTimeoutInMilliseconds default is 30000 and we are using 60000 set below
      connection.serverTimeoutInMilliseconds = 60000;

      connection.on('notification', (response: any) => {
        if (response.hasOwnProperty('offerNegotiationResult')) {
          const offerResultNotification: OfferResultNotification = {
            ...response,
            receivedDate: new Date(),
          };

          store.dispatch(offerResultNotificationReceived(offerResultNotification));
        } else if (response.hasOwnProperty('trackingStatusType')) {
          const bookTrackingStatusNotification: BookTrackingStatusNotification = {
            ...response,
            receivedDate: new Date(),
          };
          store.dispatch(bookTrackingNotificationReceived(bookTrackingStatusNotification));
          store.dispatch(updateLoadTrackingStatus(bookTrackingStatusNotification));
        } else if (
          response.hasOwnProperty('tractorNumber') ||
          response.hasOwnProperty('trailerNumber') ||
          response.hasOwnProperty('contactId') ||
          response.hasOwnProperty('proNumber') ||
          response.hasOwnProperty('licencePlateNumber') ||
          response.hasOwnProperty('shipmentStatus')
        ) {
          store.dispatch(updateLoadInformation(response));
        }
      });

      // Make sure we handle our websocket lifetime events
      connection.onclose(async err => {
        console.warn(`Realtime channel is closed. Message: ${err?.message}`);
        await connection.start();
      });

      connection.onreconnecting(err => {
        console.warn(`Realtime channel is attempting to reconnect from the below error: ${err?.name}`);
      });

      connection.onreconnected(connId => {
        console.info(`Realtime channel has been re-established: ConnId: ${connId}`);
      });

      // Now start and wait for the confirmation
      connection.start().then(() => {
        console.info(`Realtime communication established: ConnectionId: ${connection?.connectionId}`);
      });

      return next(action);
    } catch (err) {
      throw err;
    }
  } else {
    return next(action);
  }
};

export default signalrMiddleware;
