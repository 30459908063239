import axios, { AxiosInstance, InternalAxiosRequestConfig, AxiosResponse } from 'axios';
import { Headers } from "app/globals/constants";
import { environment } from 'environments/environment';
import { User } from 'shared/models/user.model';
import { OktaAuth, OktaAuthIdxOptions } from '@okta/okta-auth-js';
import { getOktaConfig } from '@features/okta/config';
import { AuthAction, DataDogLogger } from '@features/datadog';
import { logout } from '@features/security/auth.actions';

const oktaAuth = new OktaAuth(getOktaConfig() as OktaAuthIdxOptions);

export const getApi = (baseURL: string, needsAuthentication: boolean = true): AxiosInstance => {
  const _this = axios.create({ baseURL });

  //#region Register interceptors
  if (needsAuthentication) _this.interceptors.request.use(c => authMiddleware(c));
  //#endregion

  // Usage in the parent function:
  _this.interceptors.response.use(
    (response: AxiosResponse) => response.data,
    async (error) => await handleErrorResponse(error, _this)
  );

  return _this;
};

const axiosResponseTypeMap = (config: InternalAxiosRequestConfig) => {
  switch (config.responseType) {
    case 'json':
      return 'application/json';

    default:
      return config.responseType;
  }
};

// array of api middleware for requests
// const requestInterceptors = (config: AxiosRequestConfig) => ({authMiddleware(config: any)});
const authMiddleware = async (config: InternalAxiosRequestConfig, apiConfig?: ApiConfig) => {
  const accessToken = await oktaAuth.getOrRenewAccessToken();
  const membershipId = JSON.parse(localStorage.getItem('active-membership'))?.id;

  // auth headers
  const bearerToken = `Bearer ${accessToken}`;
  config.headers[Headers.AUTHORIZATION] = bearerToken;
  config.headers[Headers.MEMBERSHIP_ID] = membershipId;

  // Common
  config.headers[Headers.API_KEY] = environment.apiKey;
  return config;
};

const handleErrorResponse = async (error, axiosInstance) => {
  const originalRequest = error.config;
  // attempt to renew token and retry call if failure is due to 401
  if (error.request.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    return await oktaAuth.getOrRenewAccessToken().then((token) => {
      if (token) {
        return axiosInstance(originalRequest);
      }
      DataDogLogger.trackAction(AuthAction.NoAccessToken, { error });
      logoutUser();
    });
  // if call still fails on 401 for retry then log user out
  } else if (error.request.status === 401 && originalRequest._retry) {
    DataDogLogger.trackAction(AuthAction.AuthenticationFailure, { error });
    logoutUser();
  }
  return Promise.reject(error);
}

const logoutUser = () => {
  const store = require('store').store;
  localStorage.removeItem('active-membership');
  store.dispatch(logout());
}

export interface ApiConfig {
  method: string;
  contentType: string;
  url: string;
  xDate: string;
  key?: string;
}