import { Appointment } from 'shared/models/my-loads/appointments/appointment.model';
import { Note } from 'shared/models/my-loads/notes/note.model';
import { Stop } from 'shared/models/my-loads/stops/stop.model';
import { StopTrailerInformation } from 'shared/models/my-loads/stops/stop-trailer-information.model';
import { AppointmentDateRange } from 'shared/models/my-loads/appointments/appointment-date-range.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Stops.StopDetail */
export class StopDetail extends Stop<MyLoads.StopDetailJSON> {
  readonly appointment: Appointment|null;
  readonly notes: Note[];
  readonly trailerInfo: StopTrailerInformation;
  readonly specialInstructions: string|null;
  readonly suggestedAppointmentDateRange: AppointmentDateRange;

  constructor(json: MyLoads.StopDetailJSON) {
    super(json);

    this.appointment = json.appointment && new Appointment(json.appointment);
    this.notes = json.notes ? json.notes.map(note => new Note(note)) : [];
    this.trailerInfo = new StopTrailerInformation(json.trailerInfo);
    this.suggestedAppointmentDateRange = new AppointmentDateRange(json.suggestedAppointmentDateRange);
  }
}
