import { Container } from 'typedi';
import { ReactElement } from 'react';
import { UUID } from 'angular2-uuid';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { FeedbackToastBody } from 'shared/components/toast/feedback-toast.component';
import { ToastType } from 'shared/enums/enums';
import {
  dismissToast,
  FeedbackToastDispatcher,
  FeedbackToastOptions,
  RawToastDispatcher,
  RawToastOptions,
  ShowToastAction,
  ShowToastOptions,
  ToastActionTypes
} from './toast-interfaces';

function getDefaultToastOptions() {
  return {
    id: UUID.UUID(),
    timeout: Container.get<number>('toasts.defaultTimeout'),
  };
}

export const showToast = (options: ShowToastOptions): ShowToastAction => {
  const defaultToastOptions = getDefaultToastOptions();
  const id = options.id ? options.id : defaultToastOptions.id;
  const timeout = options.timeout ? options.timeout : defaultToastOptions.timeout;
  return {
    type: ToastActionTypes.SHOW_TOAST,
    toast: { ...options, id: id, timeout: timeout }
  };
};

export const actionCreators = {
  raw: (
    body: ReactElement<any>,
    buttons: (ReactElement<any> | ((id: string) => ReactElement<any>))[],
    className,
    rawOptions: RawToastOptions = {}
  ) => showToast({ ...rawOptions, body, buttons, className }),
  dismiss: dismissToast
};

export const feedbackToast = (actions, messages, { buttons, id = UUID.UUID(), timeout, ...options }: FeedbackToastOptions, type) => {
  const body = <FeedbackToastBody type={type} messages={messages} header={options?.header} suppressHeader={options?.suppressHeader} />;
  const defaultButtons = buttons ?? [];
  const className = `feedback ${type.toLowerCase()}`;
  const action = actions.raw(body, [...defaultButtons], className, { id, timeout });
  console.log(action)
  return action?.toast?.id;
};

export class ToastManager {
  public raw: RawToastDispatcher;
  public success: FeedbackToastDispatcher;
  public warn: FeedbackToastDispatcher;
  public info: FeedbackToastDispatcher;
  public error: FeedbackToastDispatcher;
  public dismiss: typeof actionCreators.dismiss;

  constructor(dispatch: Dispatch<AnyAction>) {
    const actions = bindActionCreators(actionCreators, dispatch);
    this.raw = (body, buttons, className, options) => {
      const action = actions.raw(body, buttons, className, options);
      return action.toast.id;
    };
    this.success = (messages, options = {}) => {
      return feedbackToast(actions, messages, options, ToastType.Success);
    };
    this.warn = (messages, options = {}) => {
      return feedbackToast(actions, messages, options, ToastType.Warning);
    };
    this.info = (messages, options = {}) => {
      return feedbackToast(actions, messages, options, ToastType.Info);
    };
    this.error = (messages, options = {}) => {
      return feedbackToast(actions, messages, options, ToastType.Error);
    };
    this.dismiss = actions.dismiss;
  }
}
