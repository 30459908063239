import { useCallback } from 'react';

import { ButtonClickedEvent } from 'shared/models/click-stream/button-clicked-event.model';
import { ButtonType } from 'shared/models/click-stream/button-type.model';
import { useTrackClickAdobe } from 'shared/components/analytics/user-tracking.component';

type ClickEventType =
  React.MouseEvent<HTMLDivElement, MouseEvent>
  | React.MouseEvent<HTMLButtonElement, MouseEvent>
  | React.MouseEvent<HTMLAnchorElement, MouseEvent>;

export const useButtonClickStream = (
  name: string,
  buttonType: ButtonType,
  track: boolean,
  context?: string,
  position?: number,
  url?: string,
  onClick?: (event: ClickEventType) => void) => {

  const trackClickAdobe = useTrackClickAdobe<ButtonClickedEvent>();

  const trackClick = useCallback(
    (e: ClickEventType) => {

        if (onClick) {
          onClick(e);
        }

        if (track) {
          const data = new ButtonClickedEvent({
            context,
            button: {
              name,
              buttonType,
              position,
              url
            }
          });

          trackClickAdobe(name);
        }

      },
    [onClick, context, name, buttonType, position],
  );

  return trackClick;
};
