import { BaseModel } from 'shared/models/base.model';
import { Dimension } from 'shared/models/my-loads/measurements/dimension.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Equipment.Equipment */
export class Equipment extends BaseModel<MyLoads.EquipmentJSON> {
  readonly code: string;
  readonly description: string;
  readonly length: Dimension|null;
  readonly width: Dimension|null;
  readonly height: Dimension|null;

  constructor(json: MyLoads.EquipmentJSON) {
    super(json);

    this.length = json.length && new Dimension(json.length);
    this.width = json.width && new Dimension(json.width);
    this.height = json.height && new Dimension(json.height);
  }
}
