export enum UnitOfMeasure {
  Standard = 0, // US Unit of measure on CHR/NavisphereCarrier.AvailableLoadsAPI
  Metric = 1,
  US = 2
}
export enum TREXUnitOfMeasure {
  Unknown = 0,
  Standard = 1,
  Metric = 2
}
