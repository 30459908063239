import { SetReloadsSearchEvent, ReloadsEventsAction } from '@features/analytics/redux/analytics-v2.actions';
import { Reducer } from 'redux';
import { AnalyticsActionTypes } from '@features/analytics/analytics-action-types';

export interface ReloadEventsState extends SetReloadsSearchEvent {
}

export const initialState: ReloadEventsState = {
  reloads: null,
  primalLoadId: null,
  searchCorrelationId: null,
  callVariation: null
};

export const reloadEvents: Reducer = (state: ReloadEventsState = initialState, action: ReloadsEventsAction): ReloadEventsState => {
  switch (action.type) {
    case AnalyticsActionTypes.SET_RELOADS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
