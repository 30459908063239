import { Reducer } from 'redux';
import { LoginEventsAction } from '../analytics-v2.actions';
import { AnalyticsActionTypes } from '@features/analytics/analytics-action-types';

export interface LoginEventsState {
  loginViewed: boolean;
  loginSubmitted: boolean;
  loginSuccess: boolean;
  loginFailure: boolean;
}

export const initialState: LoginEventsState = {
  loginViewed: false,
  loginSubmitted: false,
  loginSuccess: false,
  loginFailure: false,
};

export const loginEvents: Reducer = (state: LoginEventsState = initialState, action: LoginEventsAction): LoginEventsState => {
  switch (action.type) {
    case AnalyticsActionTypes.SET_LOGIN_VIEW_EVENT:
      return {
        ...state,
        loginViewed: true,
      };
    case AnalyticsActionTypes.SET_LOGIN_SUBMIT_EVENT:
      return {
        ...state,
        loginFailure: false,
        loginSuccess: false,
        loginSubmitted: true,
      };
    case AnalyticsActionTypes.SET_LOGIN_SUCCESS_EVENT:
      return {
        ...state,
        loginSuccess: true,
        loginFailure: false,
      };
    case AnalyticsActionTypes.SET_LOGIN_FAILURE_EVENT:
      return {
        ...state,
        loginFailure: true,
        loginSuccess: false,
      };
    default:
      return state;
  }
};
