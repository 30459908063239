export const BulkExtendedTypes = [
  {
    'code': 'BEND',
    'description': 'End Dump Truck',
    'value': 'End Dump Truck (B)'
  },
  {
    'code': 'BDPT',
    'description': 'Dry Pneumatic',
    'value': 'Dry Pneumatic Trailer (B)'
  },
  {
    'code': 'BFGD',
    'description': 'Food Grade Dry Bulk',
    'value': 'Food Grade Dry Bulk (B)'
  },
  {
    'code': 'BHOP',
    'description': 'Hopper - Bulk',
    'value': 'Hopper - Bulk (B)'
  },
  {
    'code': 'BISO',
    'description': 'ISO Tank',
    'value': 'ISO Tank (B)'
  },
  {
    'code': 'BLAT',
    'description': 'Liquid Aluminum Tank Trailer',
    'value': 'Liquid Aluminum Tank Trailer (B)'
  },
  {
    'code': 'BLFGT',
    'description': 'Liquid Food Grade Tank Trailer',
    'value': 'Liquid Food Grade Tank Trailer (B)'
  },
  {
    'code': 'BLFRPT',
    'description': 'Liquid FRP Tank Trailer',
    'value': 'Liquid FRP Tank Trailer (B)'
  },
  {
    'code': 'BLRL',
    'description': 'Liquid Rubber Lined Trailer',
    'value': 'Liquid Rubber Lined Trailer (B)'
  },
  {
    'code': 'BLSS',
    'description': 'Liquid Stainless Steel Trailer',
    'value': 'Liquid Stainless Steel Trailer (B)'
  },
  {
    'code': 'BST',
    'description': 'Sand Trailer (B)',
    'value': 'Sand Trailer (B)'
  },
  {
    'code': 'BVT',
    'description': 'Vacuum Trailer',
    'value': 'Vacuum Trailer (B)'
  }
];
