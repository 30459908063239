export abstract class AbstractCHRFormControl {
  private parent: CHRFormGroup;

  abstract hasPendingValidation();

  get root() {
    return this.parent ? this.parent.root : this as any;
  }

  getParent(): CHRFormGroup {
    return this.parent;
  }

  setParent(parent: CHRFormGroup) {
    this.parent = parent;
  }
  _updateTreeValidity = () => void 0;
  _registerOnCollectionChange = () => void 0;
}
