import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';

import { REQUEST_FAILURE, REQUEST_START, REQUEST_SUCCESS } from 'store/requests.actions';
import { TenderLoadDetail } from 'shared/models/my-loads/loads/tender-load-detail.model';
import { hideModal, showModal } from 'shared/components/modal/modal.actions';
import { FETCH_TENDERS_QUIET, } from 'store/epics/tenders/tenders-base.actions';
export { FETCH_TENDERS_QUIET_FAILURE, FETCH_TENDERS_QUIET_SUCCESS } from 'store/epics/tenders/tenders-base.actions';

export const FETCH_TENDERS = `${REQUEST_START}:FETCH_TENDERS`;
export const FETCH_TENDERS_SUCCESS = `${REQUEST_SUCCESS}:FETCH_TENDERS`;
export const FETCH_TENDERS_FAILURE = `${REQUEST_FAILURE}:FETCH_TENDERS`;

export const fetchTenders = () => ({type: FETCH_TENDERS});
export const fetchTendersSuccess = (payload: TenderLoadDetail[]) => ({type: FETCH_TENDERS_SUCCESS, payload});
export const fetchTendersFailure = (error: AjaxError) => ({type: FETCH_TENDERS_FAILURE, error});

export const fetchTendersQuiet = () => ({type: FETCH_TENDERS_QUIET});

export const showTendersModal = (name, context?) => showModal(`tender-modal-${name}`, context);
export const hideTendersModal = (name) => hideModal(`tender-modal-${name}`);

export const START_POLLING_TENDERS = 'START_POLLING_TENDERS';
export const STOP_POLLING_TENDERS = 'STOP_POLLING_TENDERS';

export const startPollingTenders = () => ({type: START_POLLING_TENDERS});
export const stopPollingTenders = () => ({type: STOP_POLLING_TENDERS});
