import { BaseModel } from 'shared/models/base.model';

export class Email extends BaseModel {
  public id?: number;
  public emailAddress: string;

  constructor(json: ContactEmailJSON) {
    super(json);
  }
}
