export const NotificationActionTypes = {
  OFFER_RESULT_NOTIFICATION_RECEIVED: 'OFFER_RESULT_NOTIFICATION_RECEIVED',
  BOOK_TRACKING_STATUS_NOTIFICATION_RECEIVED: 'BOOK_TRACKING_STATUS_NOTIFICATION_RECEIVED'
};

export const offerResultNotificationReceived = (offerResultNotification: OfferResultNotification) => ({
  type: NotificationActionTypes.OFFER_RESULT_NOTIFICATION_RECEIVED,
  payload: offerResultNotification
});

export const bookTrackingNotificationReceived = (bookTrackingStatusNotification: BookTrackingStatusNotification) => ({
  type: NotificationActionTypes.BOOK_TRACKING_STATUS_NOTIFICATION_RECEIVED,
  payload: bookTrackingStatusNotification
});
