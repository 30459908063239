export const REGISTER_POPUP = 'REGISTER_POPUP';
export const UNREGISTER_POPUP = 'UNREGISTER_POPUP';

export interface PopupAction {
  type: string;
  name: string;
  window: Window;
}

export const registerPopup = (name: string, window: Window): PopupAction => ({ type: REGISTER_POPUP, name, window });
export const unregisterPopup = (name: string, window: Window): PopupAction => ({ type: UNREGISTER_POPUP, name, window });
