import { UUID } from 'angular2-uuid';
import { Inject, Service } from 'typedi';

@Service()
export class LocalStorageRepository {
  @Inject('parentSessionStorage')
  private parentStorage: { [key: string]: string } | null;
  @Inject('appConstants.appName')
  private storageNamespace;
  private backupStorage = {};

  public set(key: string, value: string) {
    if (this.hasLocalStorage()) {
      localStorage.setItem(this.prepend(key), value);
    }
    this.backupStorage[this.prepend(key)] = value;
  }

  /** Gets item from local storage without prefix */
  public getItem(key: string): string|null {
    if (this.hasLocalStorage()) {
      return localStorage.getItem(key);
    }

    if (this.parentStorage?.[key]) {
      return this.parentStorage[key];
    }

    const value = this.backupStorage[key];
    return value ?? null;
  }

  public get(key: string): string|null {
    return this.getItem(this.prepend(key));
  }

  public remove(key: string) {
    if (this.hasLocalStorage()) {
      return localStorage.removeItem(this.prepend(key));
    }
    delete this.backupStorage[this.prepend(key)];
  }

  clearParentStorage() {
    this.parentStorage = null;
  }

  reloadPage() {
    // This forces JWT token to be cleared out, so a new JWT token is always retrieved on login
    window.location.reload();
  }

  private prepend(key: string): string {
    return `${this.storageNamespace}.${key}`;
  }

  private hasLocalStorage() {
    const testKey = this.prepend(`test-${UUID.UUID()}`);

    try {
      localStorage.setItem(testKey, '1');
      localStorage.removeItem(testKey);
    } catch (e) {
      return false;
    }

    return true;
  }
}
