import { titleCase, cityStateCountryCasing } from 'shared/components/formatters/title-case.formatter';

const CITY = 0b1000;
const STATE = 0b0100;
const COUNTRY = 0b0010;
const POSTAL_CODE = 0b0001;

export enum AddressFormat {
  CityState = CITY | STATE,
  CityStateCountry = CITY | STATE | COUNTRY,
  StateCountry = STATE | COUNTRY,
  City = CITY,
  State = STATE,
  Country = COUNTRY,
  PostalCode = POSTAL_CODE,
  All = CITY | STATE | COUNTRY | POSTAL_CODE
}

export interface FormattableAddress {
  city?: string;
  stateCode?: string;
  stateProvinceCode?: string;
  countryCode?: string;
  country?: string;
  postalCode?: string;
  zipCode?: string;
}

interface Props {
  address: FormattableAddress;
  format?: AddressFormat;
  forceTitleCasing?: boolean;
  cityStateCountryFormat?: boolean;
}

const withCity = (format: AddressFormat) => format & CITY;
const withState = (format: AddressFormat) => format & STATE;
const withCountry = (format: AddressFormat) => format & COUNTRY;
const withPostalCode = (format: AddressFormat) => format & POSTAL_CODE;

export const formatCityStateCountry = (address: FormattableAddress, format: AddressFormat, forceTitleCasing: boolean = false, cityStateCountryFormat: boolean = false) => {
  let responseArray = [];

  if (cityStateCountryFormat) {
    const addressCasing = cityStateCountryCasing(address.city, address.stateCode, address.country);
    return [...responseArray, addressCasing.city, addressCasing.stateCode, addressCasing.country].join(', ');
  }

  if (address.city && withCity(format)) {
    let city = address.city;
    if (forceTitleCasing) {
      // Only force to title case if it's currently in all uppercase.
      const hasLowerCaseRegex = /[a-z]/g
      if (!hasLowerCaseRegex.test(city)) {
        city = titleCase(city);
      }
    }
    responseArray = [...responseArray, city];
  }

  if ((address.stateCode || address.stateProvinceCode) && withState(format) &&
    (address.countryCode !== (address.stateCode || address.stateProvinceCode)) &&
    (address.country !== (address.stateCode || address.stateProvinceCode))) {
    responseArray = [...responseArray, address.stateCode || address.stateProvinceCode];
  }

  if ((address.countryCode || address.country) && withCountry(format)) {
    responseArray = [...responseArray, address.countryCode || address.country];
  }

  if ((address.postalCode || address.zipCode) && withPostalCode(format)) {
    responseArray = [...responseArray, address.postalCode || address.zipCode];
  }

  return responseArray.join(', ');
};

export const CityStateCountryFormatter = ({ address, format = AddressFormat.CityStateCountry, forceTitleCasing, cityStateCountryFormat }: Props) =>
  <span className="city-state-country-formatter">{address == null ? null : formatCityStateCountry(address, format, forceTitleCasing, cityStateCountryFormat)}</span>;
