import React from 'react';
import moment from 'moment-timezone';
import { Container } from 'typedi';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { Subscription } from 'rxjs/Subscription';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';

import { TimeZoneNames } from 'app/globals/constants';
import { ResultSet } from 'shared/models/result-set.model';
import { Translation } from 'shared/components/translation/translation.component';

interface SortOptions {
  reverse?: boolean;
  caseSensitive?: boolean;
}

export namespace Util {
  export function sortByField<SortableItem = {}>(field: keyof SortableItem, options: SortOptions = {}) {
    return function (objectOne: SortableItem, objectTwo: SortableItem) {
      const a = options.caseSensitive ? objectOne[field] : stringLower(objectOne[field]);
      const b = options.caseSensitive ? objectTwo[field] : stringLower(objectTwo[field]);

      if (a < b) {
        return (options.reverse && 1) || -1;
      }
      if (a > b) {
        return (options.reverse && -1) || 1;
      }
      return 0;
    };
  }

  export function stringLower(input) {
    if (typeof (input) === 'string') {
      return input.toLowerCase();
    }
    return input;
  }

  export function sortByPathToField(
    path: string,
    sortReverse = false,
    caseInsensitive = false,
    checkNull = false,
  ) {
    return function (objectOne, objectTwo) {
      const pathArray = path.split('.');
      let propertyOne = pathArray.reduce((currentObj, currentPath) => currentObj && currentObj[currentPath], objectOne);
      let propertyTwo = pathArray.reduce((currentObj, currentPath) => currentObj && currentObj[currentPath], objectTwo);

      if (caseInsensitive) {
        propertyOne = stringLower(propertyOne);
        propertyTwo = stringLower(propertyTwo);
      }

      if (checkNull) {
        if (propertyOne == null) {
          return 1; // move objectOne DOWN
        }
        if (propertyTwo == null) {
          return -1; // move objectOne UP
        }
      }

      if (propertyOne == null || propertyOne < propertyTwo) {
        return sortReverse ? 1 : -1;
      }
      if (propertyTwo == null || propertyOne > propertyTwo) {
        return sortReverse ? -1 : 1;
      }
      return 0;
    };
  }

  export function sortByPathToFieldIsNotNull(
    path: string,
    sortReverse = false,
    caseInsensitive = false
  ) {
    return sortByPathToField(path, sortReverse, caseInsensitive, true);
  }

  export function toResultSet(json: ResultSetJSON<any>, ModelClass): ResultSet<typeof ModelClass> {
    return new ResultSet(json, result => new ModelClass(result));
  }

  export function arraySplice(array: any[], index: number, item: any) {
    return [
      ...array.slice(0, index),
      item,
      ...array.slice(index + 1)
    ];
  }

  export const leftPad = (value: Stringable, desiredLength: number, padChar: string): string => {
    const stringValue = value.toString();

    if (stringValue.length >= desiredLength) {
      return stringValue;
    }

    return new Array(desiredLength - stringValue.length)
      .fill(padChar)
      .concat(stringValue.split(''))
      .join('');
  };
  export const positiveModulo = (value: number, quotient: number) => (value + quotient) % quotient;
}

export const cancelRequest = (subscription?: Subscription) => {
  if (subscription) {
    subscription.unsubscribe();
  }
};

export const cancelSubscription = cancelRequest;

export const isGenericAPIErrorMessage = (errorMessage: string) => errorMessage.includes('¯\\_(ツ)_/¯');
export const isGenericAPIErrorResponse = (error: AjaxError) => error?.response?.message?.includes('¯\\_(ツ)_/¯');

export const getErrorMessage = (error: AjaxError) => {
  if (!error) {
    return null;
  }
  if (error.response) {
    return error.response.exceptionMessage || parseErrorResponse(error);
  }
  return error.message || <Translation resource="AN_UNKNOWN_ERROR_OCCURRED_TRYING_TO_MAKE_THE_REQUE" />;
};

export const parseErrorResponse = (error: AjaxError): JSX.Element => isGenericAPIErrorResponse(error)
  ? <Translation resource="AN_UNKNOWN_ERROR_OCCURRED_TRYING_TO_MAKE_THE_REQUE" />
  : error?.response?.message;

export const loadScriptAsync = <TResult extends any>(url: string, callback?: (result?: TResult) => any, selector?: (window: Window) => TResult) => {
  const d = document,
    t = 'script',
    o = d.createElement(t),
    s = d.getElementsByTagName(t)[0];
  o.src = `//${url}`;
  if (callback) {
    o.addEventListener('load', () => callback(selector && selector(window)), false);
  }
  s ? s.parentNode.insertBefore(o, s) : d.getElementsByTagName('head')[0].appendChild(o);
};

export const scriptLoaderObservable = (url): Observable<void> => {
  const scriptLoaderSubject = new Subject<void>();
  loadScriptAsync(url, () => null, () => scriptLoaderSubject.next());
  return scriptLoaderSubject;
};

export const setDateToMidnight = (date: Date) =>
  moment(date).startOf('day').toDate();

export const extractErrorMessage = (error: AjaxError) =>
  (error.response && (error.response.message || error.response)) || error.message;

export const isLocalDev = () => Container.get('appConstants.buildNumber') === '1.0.0.0';

export const getTimeRemaining = (minutesRemaining: number): string => {
  if (minutesRemaining <= 0) {
    return null;
  }
  if (minutesRemaining === 999999) {
    return 'N/A';
  }
  const days = Math.floor(minutesRemaining / 60 / 24);
  const hours = Math.floor(minutesRemaining / 60) % 24;
  const minutes = Math.floor(minutesRemaining) % 60;
  if (days) {
    return `${days} days ${hours} hr`;
  }

  if (hours) {
    return `${hours} hr ${minutes} min`;
  }

  return `${minutes} min`;
};

export const reverse = (value: string) => value.split('').reverse().join('');

// NOTE: This is not very secure, as it is all client-side.
// It basically just obfuscates values temporarily.
export const weakEncrypt = (value: string, iterations = 5) => {
  for (let i = 0; i < iterations; i++) {
    value = btoa(reverse(value));
  }
  return value;
};

export const weakDecrypt = (value: string, iterations = 5) => {
  for (let i = 0; i < iterations; i++) {
    value = reverse(atob(value));
  }
  return value;
};

export namespace DatetimeUtil {
  export const offsetTimeUnit = 'm'; // In minutes
  export const timeFormatLine = 'HH:mm:ss';

  export const getUTCtoCSTOffset = () => {
    return moment().tz(TimeZoneNames.CST).utcOffset();
  };

  export const convertTimeFromCSTtoUTC = (value: Date) => {
    const offset = -getUTCtoCSTOffset();
    return moment(value).add(offset, offsetTimeUnit).toDate();
  };

  export const convertTimeFromUTCtoCST = (utcDate: Date) => {
    const offset = getUTCtoCSTOffset();
    return moment(utcDate).add(offset, offsetTimeUnit).toDate();
  };

  export const convertTimeFromUTCtoTimeZone = (value: Date, timeZone: TimeZone) => {
    const offset = timeZone ? timeZone.offset : getUTCtoCSTOffset(); // to CST by default
    return moment(value).add(offset, offsetTimeUnit).toDate();
  };

  export const convertTimeFromTimeZoneToUTC = (value: Date, timeZone: TimeZone) => {
    const offset = timeZone ? -timeZone.offset : -getUTCtoCSTOffset(); // from CST by default

    return moment(value).add(offset, offsetTimeUnit).toDate();
  };

  export const convertTimeFromCSTtoTimeZone = (value: Date, timeZone: TimeZone) => {
    return convertTimeFromUTCtoTimeZone(convertTimeFromCSTtoUTC(value), timeZone);
  };

  export const convertTimeFromTimeZoneToCST = (value: Date, timeZone: TimeZone) => {
    return convertTimeFromUTCtoCST(convertTimeFromTimeZoneToUTC(value, timeZone));
  };

  export const convertTimeStringFromTimeZoneToCST = (timeString: String, timeZone?: TimeZone) => {
    const timeMoment = moment(timeString as moment.MomentInput, timeFormatLine);
    const date = timeMoment.toDate();
    const convertedTime = convertTimeFromTimeZoneToCST(date, timeZone);

    return moment(convertedTime).format(timeFormatLine);
  };

  export const convertTimeStringFromCSTToTimeZone = (timeString: String, timeZone?: TimeZone) => {
    const timeMoment = moment(timeString as moment.MomentInput, timeFormatLine);
    const date = timeMoment.toDate();
    const convertedTime = convertTimeFromCSTtoTimeZone(date, timeZone);

    return moment(convertedTime).format(timeFormatLine);
  };

  export const getDateFormatTime = () => {
    const date = new Date();
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().length === 1 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${date.getDate()}`;
  }

  export const clearOffset = (value: moment.Moment) => {
    return moment(value.format('YYYY-MM-DDTHH:mm:ss'));
  }
}

export const getCurrentintialDate = () => {
  return moment(new Date()).format('DD MMMM YYYY');
};

export const handleKeyPressIsNumber = (event: React.KeyboardEvent<HTMLElement>) => {
  const charCode = (event.which) ? event.which : event.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) event.preventDefault();
};

export const carrierAddressTwo = (propData, space) => {
  const addSpace = space === 'spaceRequired' ? ' ' : '';
  if (propData?.address2) {
    return addSpace + propData?.address2;
  } else {
    return;
  }
};
