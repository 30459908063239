export const CurrencyCodes = [
  {
    'code': 'USD',
    'description': 'US Dollar',
    'value': 'USD'
  },
  {
    'code': 'CAD',
    'description': 'Canadian Dollar',
    'value': 'CAD'
  },
  {
    'code': 'EUR',
    'description': 'European Euro',
    'value': 'EUR'
  },
  {
    'code': 'MXN',
    'description': 'Mexican Peso',
    'value': 'MXN'
  },
  {
    'code': 'CHF',
    'description': 'Swiss Franc',
    'value': 'CHF'
  },
  {
    'code': 'GBP',
    'description': 'British Pound',
    'value': 'GBP'
  },
  {
    'code': 'HUF',
    'description': 'Hungarian Forint',
    'value': 'HUF'
  },
  {
    'code': 'CZK',
    'description': 'Czech Koruna',
    'value': 'CZK'
  },
  {
    'code': 'NZD',
    'description': 'New Zealand Dollar',
    'value': 'NZD'
  },
  {
    'code': 'PLN',
    'description': 'Polish Zlotych',
    'value': 'PLN'
  },
  {
    'code': 'ZAR',
    'description': 'South African Rand',
    'value': 'ZAR'
  },
  {
    'code': 'VES',
    'description': 'Venezuelan Bolivar',
    'value': 'VES'
  },
  {
    'code': 'VND',
    'description': 'Vietnamese Dong',
    'value': 'VND'
  },
  {
    'code': 'TWD',
    'description': 'New Taiwan Dollar',
    'value': 'TWD'
  },
  {
    'code': 'TRY',
    'description': 'Turkish New Lira',
    'value': 'TRY'
  },
  {
    'code': 'SEK',
    'description': 'Swedish Krona',
    'value': 'SEK'
  },
  {
    'code': 'SGD',
    'description': 'Singapore Dollar',
    'value': 'SGD'
  },
  {
    'code': 'RUB',
    'description': 'Russian Ruble',
    'value': 'RUB'
  },
  {
    'code': 'NOK',
    'description': 'Norwegian Krone',
    'value': 'NOK'
  },
  {
    'code': 'HKD',
    'description': 'Hong Kong Dollar',
    'value': 'HKD'
  },
  {
    'code': 'INR',
    'description': 'Indian Rupee',
    'value': 'INR'
  },
  {
    'code': 'JPY',
    'description': 'Japanese Yen',
    'value': 'JPY'
  },
  {
    'code': 'CLP',
    'description': 'Chilean Peso',
    'value': 'CLP'
  },
  {
    'code': 'CNY',
    'description': 'Chinese Renminbi',
    'value': 'CNY'
  },
  {
    'code': 'DKK',
    'description': 'Danish Krone',
    'value': 'DKK'
  },
  {
    'code': 'AUD',
    'description': 'Australian Dollar',
    'value': 'AUD'
  },
  {
    'code': 'BRL',
    'description': 'Brazilian Real',
    'value': 'BRL'
  },
  {
    'code': 'AED',
    'description': 'UAE Dirham',
    'value': 'AED'
  },
  {
    'code': 'PEN',
    'description': 'Peruvian Nuevo Sol',
    'value': 'PEN'
  },
  {
    'code': 'THB',
    'description': 'Thai Baht',
    'value': 'THB'
  },
  {
    'code': 'ARS',
    'description': 'Argentinean Peso',
    'value': 'ARS'
  },
  {
    'code': 'MYR',
    'description': 'Malaysian Ringgit',
    'value': 'MYR'
  },
  {
    'code': 'VEF',
    'description': 'Venezuelan Bolivares Fuertes',
    'value': 'VEF'
  },
  {
    'code': 'SAR',
    'description': 'Saudi Riyal',
    'value': 'SAR'
  },
  {
    'code': 'BBD',
    'description': 'Barbados Dollar',
    'value': 'BBD'
  },
  {
    'code': 'BHD',
    'description': 'Bahraini Dinar',
    'value': 'BHD'
  },
  {
    'code': 'BMD',
    'description': 'Bermuda Dollar',
    'value': 'BMD'
  },
  {
    'code': 'BSD',
    'description': 'Bahamian Dollar',
    'value': 'BSD'
  },
  {
    'code': 'ILS',
    'description': 'Israeli Shekel',
    'value': 'ILS'
  },
  {
    'code': 'ISK',
    'description': 'Iceland Krona',
    'value': 'ISK'
  },
  {
    'code': 'KES',
    'description': 'Kenyan Shilling',
    'value': 'KES'
  },
  {
    'code': 'KRW',
    'description': 'South Korean Won',
    'value': 'KRW'
  },
  {
    'code': 'KWD',
    'description': 'Kuwaiti Dinar',
    'value': 'KWD'
  },
  {
    'code': 'OMR',
    'description': 'Omani Rial',
    'value': 'OMR'
  },
  {
    'code': 'PHP',
    'description': 'Philippine Peso',
    'value': 'PHP'
  },
  {
    'code': 'PKR',
    'description': 'Pakistani Rupee',
    'value': 'PKR'
  },
  {
    'code': 'QAR',
    'description': 'Qatar Rial',
    'value': 'QAR'
  },
  {
    'code': 'RON',
    'description': 'Romanian New Leu',
    'value': 'RON'
  },
  {
    'code': 'TTD',
    'description': 'Trinidad Tobago Dollar',
    'value': 'TTD'
  },
  {
    'code': 'UAH',
    'description': 'Ukraine Hryvnia',
    'value': 'UAH'
  },
  {
    'code': 'LKR',
    'description': 'Sri Lanka',
    'value': 'LKR'
  },
  {
    'code': 'XPF',
    'description': 'French Polynesia',
    'value': 'XPF'
  },
  {
    'code': 'UYU',
    'description': 'Uruguayan Peso',
    'value': 'UYU'
  },
  {
    'code': 'COP',
    'description': 'Colombian Peso',
    'value': 'COP'
  },
  {
    'code': 'CRC',
    'description': 'Costa Rican Colon',
    'value': 'CRC'
  },
  {
    'code': 'FJD',
    'description': 'Fijian Dollar',
    'value': 'FJD'
  },
  {
    'code': 'CLF',
    'description': 'Chilean Unidad de Formento',
    'value': 'CLF'
  },
  {
    'code': 'RSD',
    'description': 'Serbian Dinar',
    'value': 'RSD'
  },
  {
    'code': 'BAM',
    'description': 'Bosnia and Herzegovina Convertible Mark',
    'value': 'BAM'
  },
  {
    'code': 'BGN',
    'description': 'Bulgarian Lev',
    'value': 'BGN'
  },
  {
    'code': 'GEL',
    'description': 'Georgian Lari',
    'value': 'GEL'
  },
  {
    'code': 'HRK',
    'description': 'Croatian Kuna',
    'value': 'HRK'
  },
  {
    'code': 'IDR',
    'description': 'Indonesian Rupiah',
    'value': 'IDR'
  },
  {
    'code': 'ALL',
    'description': 'Lek',
    'value': 'ALL'
  },
  {
    'code': 'DZD',
    'description': 'Algerian Dinar',
    'value': 'DZD'
  },
  {
    'code': 'AOA',
    'description': 'Kwanza',
    'value': 'AOA'
  },
  {
    'code': 'AMD',
    'description': 'Armenian Dram',
    'value': 'AMD'
  },
  {
    'code': 'AWG',
    'description': 'Aruban Florin',
    'value': 'AWG'
  },
  {
    'code': 'AZN',
    'description': 'Azerbaijan Manat',
    'value': 'AZN'
  },
  {
    'code': 'BDT',
    'description': 'Taka',
    'value': 'BDT'
  },
  {
    'code': 'BYN',
    'description': 'Belarusian Ruble',
    'value': 'BYN'
  },
  {
    'code': 'BZD',
    'description': 'Belize Dollar',
    'value': 'BZD'
  },
  {
    'code': 'BOB',
    'description': 'Boliviano',
    'value': 'BOB'
  },
  {
    'code': 'BWP',
    'description': 'Pula',
    'value': 'BWP'
  },
  {
    'code': 'BND',
    'description': 'Brunei Dollar',
    'value': 'BND'
  },
  {
    'code': 'KHR',
    'description': 'Riel',
    'value': 'KHR'
  },
  {
    'code': 'KYD',
    'description': 'Cayman Islands Dollar',
    'value': 'KYD'
  },
  {
    'code': 'DOP',
    'description': 'Dominican Peso',
    'value': 'DOP'
  },
  {
    'code': 'EGP',
    'description': 'Egyptian Pound',
    'value': 'EGP'
  },
  {
    'code': 'ETB',
    'description': 'Ethiopian Birr',
    'value': 'ETB'
  },
  {
    'code': 'GMD',
    'description': 'Dalasi',
    'value': 'GMD'
  },
  {
    'code': 'GHS',
    'description': 'Ghana Cedi',
    'value': 'GHS'
  },
  {
    'code': 'GIP',
    'description': 'Gibraltar Pound',
    'value': 'GIP'
  },
  {
    'code': 'GTQ',
    'description': 'Quetzal',
    'value': 'GTQ'
  },
  {
    'code': 'GNF',
    'description': 'Guinean Franc',
    'value': 'GNF'
  },
  {
    'code': 'GYD',
    'description': 'Guyana Dollar',
    'value': 'GYD'
  },
  {
    'code': 'HNL',
    'description': 'Lempira',
    'value': 'HNL'
  },
  {
    'code': 'IQD',
    'description': 'Iraqi Dinar',
    'value': 'IQD'
  },
  {
    'code': 'JMD',
    'description': 'Jamaican Dollar',
    'value': 'JMD'
  },
  {
    'code': 'JOD',
    'description': 'Jordanian Dinar',
    'value': 'JOD'
  },
  {
    'code': 'KZT',
    'description': 'Tenge',
    'value': 'KZT'
  },
  {
    'code': 'KGS',
    'description': 'Som',
    'value': 'KGS'
  },
  {
    'code': 'LBP',
    'description': 'Lebanese Pound',
    'value': 'LBP'
  },
  {
    'code': 'MKD',
    'description': 'Denar',
    'value': 'MKD'
  },
  {
    'code': 'MGA',
    'description': 'Malagasy Ariary',
    'value': 'MGA'
  },
  {
    'code': 'MUR',
    'description': 'Mauritius Rupee',
    'value': 'MUR'
  },
  {
    'code': 'MNT',
    'description': 'Tugrik',
    'value': 'MNT'
  },
  {
    'code': 'MAD',
    'description': 'Moroccan Dirham',
    'value': 'MAD'
  },
  {
    'code': 'MZN',
    'description': 'Mozambique Metical',
    'value': 'MZN'
  },
  {
    'code': 'NAD',
    'description': 'Namibia Dollar',
    'value': 'NAD'
  },
  {
    'code': 'NPR',
    'description': 'Nepalese Rupee',
    'value': 'NPR'
  },
  {
    'code': 'NIO',
    'description': 'Cordoba Oro',
    'value': 'NIO'
  },
  {
    'code': 'NGN',
    'description': 'Naira',
    'value': 'NGN'
  },
  {
    'code': 'PYG',
    'description': 'Guarani',
    'value': 'PYG'
  },
  {
    'code': 'SDG',
    'description': 'Sudanese Pound',
    'value': 'SDG'
  },
  {
    'code': 'SRD',
    'description': 'Surinam Dollar',
    'value': 'SRD'
  },
  {
    'code': 'TND',
    'description': 'Tunisian Dinar',
    'value': 'TND'
  },
  {
    'code': 'UGX',
    'description': 'Uganda Shilling',
    'value': 'UGX'
  },
  {
    'code': 'UZS',
    'description': 'Uzbekistan Sum',
    'value': 'UZS'
  },
  {
    'code': 'YER',
    'description': 'Yemeni Rial',
    'value': 'YER'
  },
  {
    'code': 'ZMW',
    'description': 'Zambian Kwacha',
    'value': 'ZMW'
  },
  {
    'code': 'ZWD',
    'description': 'Zimbabwe Dollar',
    'value': 'ZWD'
  }
];
