import { ClickStreamButton } from 'shared/models/click-stream/click-stream-button.model';
import { BaseModel } from 'shared/models/base.model';

export class ButtonClickedEvent extends BaseModel {
  button: ClickStreamButton;
  context?: string;

constructor(json: ButtonClickedEventJSON) {
    super(json);
  }
}
