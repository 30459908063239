import { BaseModel } from 'shared/models/base.model';
import { Coordinates } from 'shared/models/my-loads/ego/coordinates/coordinates.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Locations.Location */
export class Location extends BaseModel<MyLoads.LocationJSON> {
  readonly city: string;
  readonly county: string|null;
  readonly stateProvinceCode: string|null;
  readonly countryCode: string;
  readonly geolocation: Coordinates|null;

  constructor(json: MyLoads.LocationJSON) {
    super(json);

    this.geolocation = json.geolocation && new Coordinates(json.geolocation);
  }
}
