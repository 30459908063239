import { Container, Service } from 'typedi';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

import { CacheableRepository } from 'app/repositories/cacheable.repository';
import { Countries } from 'shared/data/globalization/countries.data';
import { StatesUS } from 'shared/data/globalization/states-224.data';
import { StatesMX } from 'shared/data/globalization/states-149.data';
import { StatesCA } from 'shared/data/globalization/states-37.data';
import { CurrencyCodes } from 'shared/data/misc/currency-codes.data';
import { IMDLBookTypes } from 'shared/data/my-loads/imdl-book-types.data';
import { LoadTypeFilters } from 'shared/data/my-loads/load-type-filters.data';
import { IMPrograms } from 'shared/data/my-loads/im-programs.data';
import { ContactPositions } from 'shared/data/my-loads/contact-positions.data';
import { EquipmentTypes } from 'shared/data/equipment/equipment-types.data';
import { EquipmentTypesEU } from 'shared/data/equipment/equipment-types-eu.data';
import { CarrierLoadStatuses } from 'shared/data/my-loads/carrier-load-statuses.data';
import { EquipmentType } from 'shared/enums/equipment-type.enum';
import { BulkExtendedTypes } from 'shared/data/equipment/bulk-equipment-types.data';
import { FlatbedExtendedTypes } from 'shared/data/equipment/flatbed-equipment-types.data';
import { ExtendedTypesEU } from 'shared/data/equipment/extended-equipment-types-eu.data';
import { ExtendedTypes } from 'shared/data/equipment/extended-equipment-types.data';
import { PhoneFormats } from 'shared/data/misc/phone-formats.data';
import { GlobalizationCountries } from 'shared/data/globalization/globalization-countries.data';
import { TimeZones } from 'shared/data/misc/timezones.data';

@Service()
export class ReferenceDataRepository extends CacheableRepository {
  baseUrl = '';

  // Locations
  statesByCountry = {
    224: StatesUS.map(state => ({...state, country: Countries.find(country => country.id === 224)})),
    149: StatesMX.map(state => ({...state, country: Countries.find(country => country.id === 149)})),
    37: StatesCA.map(state => ({...state, country: Countries.find(country => country.id === 37)}))
  };

  getCountries(): Observable<SimpleCountryResponse[]> {
    return Observable.of(Countries);
  }

  public getCountryByCode(code: string): Observable<CountryResponse> {
    return Observable.of(Countries.find(country => country.code === code));
  }

  public getCountryByName(name: string): Observable<CountryResponse> {
    return Observable.of(Countries.find(country => country.name === name));
  }

  public getCountryById(id: number): Observable<CountryResponse> {
    return Observable.of(Countries.find(country => country.id === id));
  }

  getStates(countryId: number): Observable<StateResponse[]> {
    const states = this.statesByCountry[countryId];

    return Observable.of(states || []);
  }

  public getStateById(countryId: number, stateId: number): Observable<SimpleStateResponse> {
    return this.getStates(countryId).map(states => states.find(state => state.id === stateId));
  }

  public getStateByCode(countryId: number, stateCode: string): Observable<SimpleStateResponse> {
    return this.getStates(countryId).map(states => states.find(state => state.code === stateCode));
  }

  public getAllStates(): Observable<StateResponse[]> {
    return Observable.of(
      Object.values(this.statesByCountry).reduce((output, source) => output.concat(source), [])
    );
  }

  /* tslint:disable:max-line-length */
  public getLocation(countryCode: string, stateProvinceCode: string, city: string, radius: number = null, geolocation: GeoLocationJSON = null): Observable<LocationSelectGrouping> {
    if (!countryCode) {
      return Observable.of({country: null, stateProv: null, city: null, radius});
    }

    try {
      const country = Countries.find(entry => entry.code === countryCode);
      const states = this.statesByCountry[country?.id] || [];
      const stateProv = states.find(entry => entry.code === stateProvinceCode);

      return Observable.of({
        country,
        stateProv,
        city: {name: city, commonName: city, id: null, state: stateProv, country, ...geolocation},
        radius
      });
    } catch (error) {
      return Observable.of({country: null, stateProv: null, city: null, radius});
    }

  }

  public getCities(countryId: number, stateId = 0, searchPhrase: string): Observable<CityResponse[]> {
    const url = Container.get('apiConfig.interimAPI') + `/Countries/${countryId}/StateProvinces/${stateId}/Cities?CityPartialName=${searchPhrase}`;

    return this.getWithCache(url).mergeMap(cities =>
      this.getCountryById(countryId).mergeMap(country =>
        this.getStateById(countryId, stateId).map(state =>
          cities.map(city => ({...city, country, state}))
        )
      )
    );
  }

  getGlobalizationLocales(): Observable<GlobalizationCountry[]> {
    // response is sometimes empty (not sure why)
    return Observable.of(GlobalizationCountries)
      .map(items => items.map(
        item => ({
          ...item,
          locales: item.locales.map(
            locale =>
              (locale.displayName === 'English' && !locale.locale.includes('en-'))
                ? {...locale, locale: 'en-GB'}
                : locale
          )
        })
      ));
  }

  // Equipment

  getEquipmentTypes(isEU = false): Observable<ReferenceDataJSON[]> {
    return Observable.of(isEU ? EquipmentTypesEU : EquipmentTypes);
  }

  getExtendedEquipmentTypes(isEU: boolean, type?: EquipmentType): Observable<ReferenceDataJSON[]> {
    switch (type) {
      case (EquipmentType.Bulk):
        return Observable.of(BulkExtendedTypes);
      case(EquipmentType.Flatbed):
        return Observable.of(FlatbedExtendedTypes);
      default:
        return Observable.of(isEU ? ExtendedTypesEU : ExtendedTypes);
    }
  }

  // misc

  getCurrencies(): Observable<ReferenceDataJSON[]> {
    return Observable.of(CurrencyCodes);
  }

  public getPhoneFormats(): Observable<PhoneFormatJSON[]> {
    return Observable.of(PhoneFormats);
  }

  getTimeZones(): Observable<TimeZone[]> {
    return Observable.of(TimeZones);
  }

// my-loads
  getIMDLBookTypes(): Observable<ReferenceDataJSON[]> {
    return Observable.of(IMDLBookTypes);
  }

  getLoadTypeFilters(): Observable<ReferenceDataJSON[]> {
    return Observable.of(LoadTypeFilters);
  }

  getIMPrograms(): Observable<ReferenceDataJSON[]> {
    return Observable.of(IMPrograms);
  }

  getContactPositions(): Observable<ReferenceDataJSON[]> {
    return Observable.of(ContactPositions);
  }

  getLoadStatuses(): Observable<ReferenceDataJSON[]> {
    return Observable.of(CarrierLoadStatuses);
  }

}
