import { Inject, Service } from 'typedi';
import { Observable } from 'rxjs/Observable';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { DriverLog } from 'shared/models/loads/driver-logs/driver-log.model';
import { Driver } from 'shared/models/contacts/driver.model';
import { BaseLoad } from 'shared/models/loads/base-load.model';
import { CacheableRepository } from 'app/repositories/cacheable.repository';
import { TenderLoadDetail } from 'shared/models/my-loads/loads/tender-load-detail.model';
import { Tender } from 'shared/models/my-loads/tenders/tender.model';
import { User } from 'shared/models/user.model';
import { Util } from 'app/util/util';

@Service()
export class MyLoadsRepository extends CacheableRepository {
  @Inject('apiConfig.myLoadsAPI')
  protected baseUrl;

  constructor(url?: string) {
    super();
    this.baseUrl = url || apiConfig.myLoadsAPI;
  }

  private sortColumns = {
    LOAD_NUMBER: 1,
    STATUS: 10,
    ORIGIN: 4,
    PICK_UP: 2,
    DESTINATION: 8,
    DELIVERY: 6,
    PICK_UP_NUMBER: 0,
    DELIVERY_NUMBER: 20,
    TRAILER_NUMBER: 19,
    GROUP_ID: 21,
    REFERENCE_NUMBER: 13,
    BOOK_TYPE: 16,
    CARRIER_ID: 12
  };

  public createDriverLog(loadNumber: number, sequenceNumber: number, driverLog: DriverLog): Observable<DriverLog> {
    const driverLogUrl = `/loads/${loadNumber}/books/${sequenceNumber}/driverlogs`;

    return this.post(driverLogUrl, JSON.stringify(driverLog.toPostJson()));
  }

  public getLoadDriverLogs(loadNumber: number, sequenceNumber: number) {
    const driverLogUrl = `/Loads/${loadNumber}/Books/${sequenceNumber}/DriverLogs`;

    return this.get(driverLogUrl)
      .map(logs => logs.map(log => new DriverLog(log)));
  }

  public formatSortParams(config: { sortOrder: string, sortByColumnName: string }): SortParams {
    return {
      SortBy: Number(this.sortColumns[config.sortByColumnName] || 1),
      SortOrder: config.sortOrder
    };
  }

  // noinspection JSMethodCanBeStatic
  public formatPaginationParams(config: { pageNumber: number, pageSize: number }): PaginationParams {
    return {
      PageNumber: Number(config.pageNumber),
      PageSize: Number(config.pageSize)
    };
  }

  public assignDriverToLoad(loadNumber: number, bookSequenceNumber: number, driver: Driver): Observable<void> {
    const driverUrl = `/loads/${loadNumber}/books/${bookSequenceNumber}/driver`;
    return this.put(driverUrl, JSON.stringify(driver));
  }

  public getBaseLoad(loadNumber: number): Observable<BaseLoad> {
    return this.get(`/Loads/${loadNumber}`)
      .map(load => new BaseLoad(load));
  }

  public getRejectionReasons(regionCode: string): Observable<RejectionReason[]> {
    const query = this.generateQueryString({
      category: 'RejectReason',
      'auxiliary.regionCode': regionCode
    });
    return this.get(`/ReferenceData${query}`);
  }

  // TENDERS

  public getTenders(): Observable<TenderLoadDetail[]> {
    return this.get('/TenderedLoads')
      .map(tenders => tenders.sort(Util.sortByField('loadNumber')))
      .map(tenders => tenders.map(tender => new TenderLoadDetail(tender)));
  }

  public getTenderDetails(tender: Tender): Observable<Tender> {
    return this.get(`${this.getTendersBaseUrl(tender)}`)
      .map(json => new TenderLoadDetail(json))
      .map(detail => detail.tenders.find(tndr => tndr.tenderSequenceNumber === tender.tenderSequenceNumber));
  }

  public acceptTender(tender: Tender) {
    const requestBody = {
      loadNumber: tender.loadNumber,
      tenderSequenceNumber: tender.tenderSequenceNumber
    };
    return this.post(`${this.getTendersBaseUrl(tender)}/Acceptance`, requestBody);
  }

  public acceptTenders(tenders: Tender[]) {
    return Observable.forkJoin(tenders.filter(Boolean).map(tender =>
      this.acceptTender(tender).catch((error: AjaxError) => Observable.of(error))
    ));
  }

  public rejectTender(user: User, tender: Tender, rejectionReasonCode: string) {
    const requestBody: TenderRejection = {
      rejectReasonCode: rejectionReasonCode,
      loadNumber: tender.loadNumber,
      tenderSequenceNumber: tender.tenderSequenceNumber,
      regionCode: user.getCarrier().regionCode
    };
    return this.post(`${this.getTendersBaseUrl(tender)}/Rejection`, requestBody);
  }

  public resendTender(tender: Tender) {
    const requestBody = {
      loadNumber: tender.loadNumber,
      loadTenderId: tender.tenderSequenceNumber
    };
    return this.post(`${this.getTendersBaseUrl(tender)}/DeferredAcceptance`, requestBody);
  }

  private getTendersBaseUrl(tender: Tender) {
    return `/TenderedLoads/${tender.loadNumber}/Tenders/${tender.tenderSequenceNumber}`;
  }

  public bookLoad(loadNumber: number, carrierBook: CarrierBookJSON): Observable<void> {
    return this.post(`/loads/${loadNumber}/books`, carrierBook);
  }
}
