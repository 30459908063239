import { Reducer, AnyAction } from 'redux';
import { REGISTER_CAPTCHA_RESULTS } from 'shared/components/turnstile-captcha/captcha.actions';
import { CaptchaStatus, CaptchaPages } from 'shared/components/turnstile-captcha/types';

export type CaptchaValues = {
  status: CaptchaStatus;
  token: string;
};

export interface CaptchaState {
  findLoads: CaptchaValues,
  identityUser: CaptchaValues
}

export const initialState: CaptchaState = {
  findLoads: {
    status: null,
    token: null
  },
  identityUser: {
    status: null,
    token: null
  }
};


export const captcha: Reducer = (state: CaptchaState = initialState, action: AnyAction): CaptchaState => {
  switch (action.type) {
    case REGISTER_CAPTCHA_RESULTS:
      switch (action.payload.page) {
        case CaptchaPages.FIND_LOADS:
          return {
            ...state,
            findLoads: {
              status: action.payload.status,
              token: action.payload.token
            }
          };
      }

    default:
      return state;
  }
};
