import { BaseModel } from 'shared/models/base.model';

export enum CommodityType {
  Customer,
  Chr,
  Agricultural,
  Warehouse,
  Other
}

/** NavisphereCarrier.MyLoads.ServiceModel.Items.ItemCommodityInformation */
export class ItemCommodityInformation extends BaseModel<MyLoads.ItemCommodityInformationJSON> {
  readonly authorityCode: string|null;
  readonly bolNotes: string|null;
  readonly commodityCode: string|null;
  readonly commodityId?: number;
  readonly commodityName: string|null;
  readonly commodityType: CommodityType;
  readonly customerSpecified1: string|null;
  readonly customerSpecified2: string|null;
  readonly description: string|null;
  readonly ediCommodityCode: string|null;
  readonly ediCommodityCodeType: string|null;
  readonly freightClass: string|null;
  readonly loadingType: string|null;
  readonly plu: string|null;
  readonly poNumber: string|null;
  readonly primaryReferenceNum: string|null;
  readonly sku: string|null;
  readonly stcc: string|null;
  readonly value: number;
}
