import React from 'react';
import classNames from 'classnames';
import { AnchorHTMLAttributes, ButtonHTMLAttributes, FunctionComponent } from 'react';

import { Translation } from 'shared/components/translation/translation.component';
import { useButtonClickStream } from 'shared/components/click-stream/use-button-clickstream.hook';
import { ButtonType } from 'shared/models/click-stream/button-type.model';
import { ClickableElementProps } from 'shared/components/click-stream/clickable-element-props';

interface SharedProps {
  btnPrimary?: boolean;
  btnDefault?: boolean;
  btnBlock?: boolean;
  btnClear?: boolean; // use for icon buttons
  mobileResponsive?: boolean;
  className?: string;
  resource?: ResourceKey;
}

interface OnlyButtonProps {
  btnRef?: (ref: HTMLButtonElement) => any;
  btnLink?: boolean;
  type?: string;
}

interface OnlyAnchorProps {
  href?: string;
  target?: string;
}

export type ButtonProps = OnlyButtonProps & SharedProps & ClickableElementProps & ButtonHTMLAttributes<HTMLButtonElement>;
export type AnchorProps = OnlyAnchorProps & SharedProps & ClickableElementProps & AnchorHTMLAttributes<HTMLAnchorElement>;

export const Button: FunctionComponent<ButtonProps> = ({
  resource,
  btnDefault,
  btnPrimary,
  mobileResponsive,
  btnBlock,
  btnLink,
  btnClear, // use for icon buttons
  btnRef,
  className,
  onClick,
  data,
  position,
  type = 'button',
  track,
  children,
  ...props}) => {
    const handleClick = useButtonClickStream(props.id, ButtonType.Button, track, data, position, null, onClick);

  return (
    <button
      {...props}
      type={type}
      ref={btnRef}
      onClick={handleClick}
      className={classNames( className, {
        'btn': !btnLink, // this class must be NOT be applied. This is to align btn-link styles natural Anchor element styles
        'btn-default': btnDefault,
        'btn-primary': btnPrimary,
        'btn-block': btnBlock,
        'btn-link': btnLink,
        'btn-clear': btnClear, // use for icon buttons
        'mobile-responsive': mobileResponsive
      })}>
      {Boolean(resource) && <Translation resource={resource}/>}
      {children}
    </button>
  );
};

/** Creates an Anchor element that is dressed up like a button */
export const AnchorButton: FunctionComponent<AnchorProps> = ({
  resource,
  btnDefault,
  btnPrimary,
  mobileResponsive,
  btnBlock,
  btnClear, // use for icon buttons
  className,
  onClick,
  data,
  position,
  track,
  children,
   ...props}) => {
  const handleClick = useButtonClickStream(props.id, ButtonType.Anchor, track, data, position, props.href, onClick);
  return (
  <a
    {...props}
    onClick={handleClick}
    className={classNames('btn', className, {
      'btn-default': btnDefault,
      'btn-primary': btnPrimary,
      'btn-block': btnBlock,
      'btn-clear': btnClear, // use for icon buttons
      'mobile-responsive': mobileResponsive
    })}>
    {Boolean(resource) && <Translation resource={resource}/>}
    {children}
  </a>);
};
