export enum StopType {
  PICKUP = 0,
  DROP_OFF = 1,
  CrossDock = 2,
  DestinationAirport = 3,
  DestinationPort = 4,
  DestinationRamp = 5,
  DestinationTerminal = 6,
  IntermediaryAirport = 7,
  IntermediaryPort = 8,
  IntermediaryRamp = 9,
  OriginAirport = 10,
  OriginPort = 11,
  OriginRamp = 12,
  OriginTerminal = 13
}
