
export enum PaymentTabLabel {
  WAITING_ON_DOCUMENTS = 'Waiting on Documents',
  PENDING_REVIEW = 'Pending Review',
  APPROVED_FOR_PAYMENT = 'Approved for Payment',
}

export enum PaymentTabValue {
  WAITING_ON_DOCUMENTS = 'waiting-on-documents',
  PENDING_REVIEW = 'pending-review',
  APPROVED_FOR_PAYMENT = 'approved-for-payment',
}

export enum PaymentTabStatus {
  NONE = 0,
  WAITING_ON_DOCUMENTS = 1,
  PENDING_REVIEW = 2,
  APPROVED_FOR_PAYMENT = 3,
}
