import { Action } from 'redux';
import { ActionsObservable, combineEpics, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/delay';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { dismissToast, DismissToastAction, ShowToastAction, ToastActionTypes } from 'shared/components/toast/toast-interfaces';

export declare interface ToastEpic<InputType extends Action, OutputType extends Action, State = any, Deps = any> {
  (action$: ActionsObservable<InputType>, state$?: StateObservable<State>, dependencies?: Deps): Observable<OutputType>;
}

// Listens for new toast dispatches, and if the toast has a timeout, schedules the toast for automatic dismissal
export const addToastTimeoutEpic: ToastEpic<ShowToastAction, DismissToastAction> = action$ =>
  action$.ofType(ToastActionTypes.SHOW_TOAST)
    .filter(({ toast }) => toast.timeout >= 0)
    .mergeMap((action) =>
      Observable.of(action.toast)
        .delay(action.toast.timeout)
        .map(toast => dismissToast(toast.id))
    );

export const toastEpics = combineEpics(addToastTimeoutEpic);
