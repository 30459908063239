import { datadogLogs, Logger, LoggerConfiguration } from '@datadog/browser-logs';
import { loggingConfig } from './config';
import { ActionBase, CarrierAction } from './actions';
import { datadogRum } from '@datadog/browser-rum';

export class DataDogLogger {
	private static isInitialized: boolean = false;
	private static Loggers = {};

	private constructor() {
		// Prevent instantiation
	}

	public static initialize() {
		if (!this.isInitialized) {
			datadogLogs.init(loggingConfig);
			this.isInitialized = true;
		}
	}

	public static trackAction(evt: CarrierAction, context?: ActionBase | any) {
		try {
			datadogRum.addAction(evt, { [evt]: true, ...context });
		} catch (err) {
			console.warn(`Carrier: Failed to track action: '${evt}'`);
		}
	}

	public static logAction(action: string, message: string, context?: Record<string, any>) {
		if (this.isInitialized) {
			datadogLogs.logger.info(message, { action, ...context });
		}
	}

	public static createLogger(
		name: string,
		level: 'debug' | 'info' | 'warn' | 'error' = 'info',
		handler: 'http' | 'console' | 'silent' = 'console'
	): Logger {
		const loggerConfig = {
			level,
			handler,
		} as LoggerConfiguration;

		const _logger = datadogLogs.getLogger(name) || datadogLogs.createLogger(name, loggerConfig);
		this.Loggers[name] = _logger;
		return _logger;
	}

	public static getLogger(name: string): Logger {
		if (this.isInitialized) {
			// check our cache first
			if (this.Loggers[name]) {
				return this.Loggers[name];
			} else {
				this.createLogger(name);
			}
		}
	}
}
