export const ContactPositions = [
  {
    'code': 'AR',
    'description': 'Accounts Receivable',
    'value': 'Accounts Receivable'
  },
  {
    'code': 'DISP',
    'description': 'Dispatcher',
    'value': 'Dispatcher'
  },
  {
    'code': 'Manager',
    'description': 'Manager',
    'value': 'Manager'
  },
  {
    'code': 'DRVR',
    'description': 'Driver',
    'value': 'Driver'
  }
];
