import { User } from 'shared/models/user.model';
import { CarrierDetail } from 'shared/models/carrier/carrier-detail.model';

export type Grant = (user: User, carrier?: CarrierDetail, isACSUser?: boolean) => boolean;

export namespace Grants {
  export const Authenticated: Grant = user => user?.isAuthenticated;
  export const FindLoadsGrant: Grant = user => user?.roles.FindLoads;
  export const MyOffersGrant: Grant = user => user?.roles.FindLoads;
  export const PostTrucksGrant: Grant = user => user?.roles.PostTrucks;
  export const MyLoadsGrant: Grant = user => user?.roles.LoadBoard;
  export const MyShipmentsGrant: Grant = user => user?.roles.MyShipments;
  export const MyLoadDetailsGrant: Grant = user => user && user.roles.LoadBoard;
  export const TendersGrant: Grant = user => user && (user.roles.TMC || user.roles.CHRTenders || user.roles.CHRWTenders);
  export const SpotBidsGrant: Grant = user => user?.roles.SpotBids;
  export const AccountsReceivableGrant: Grant = user => user && (user.roles.AR || user.roles.ARAdmin);
  export const PaymentsGrant: Grant = user => user && (user.roles.AR || user.roles.ARAdmin);
  export const ClaimsGrant: Grant = user => user && (user.roles.AR || user.roles.ARAdmin);
  export const RatesGrant: Grant = user => user?.roles.Rates;
  export const ManageInvoicesGrant: Grant = user => user?.roles.ManageInvoices;
  export const PreferredLanesGrant: Grant = user => user?.roles.PreferredLanes;
  export const AdminGrant: Grant = user => user?.roles.Admin;
  export const ImpersonateAllGrant: Grant = user => user?.roles.ImpersonateAll;
  export const AnnouncementsGrant: Grant = user => user?.roles.Announcements;
  export const DockMgrGrant: Grant = user => user && (user.roles.DockMgr || user.roles.DockMgrAdmin);
  export const TMCReportsGrant: Grant = user => user?.roles.TMCReports;
  export const KeyCarrier: Grant = (user, carrier) => carrier?.capCode === 'KEY';
  export const HasSeenBin: Grant = user => user?.hasSeenBin();
  export const CarrierAdvantageGrant = user => user?.roles.CHRWTenders || user?.roles.CHRTenders;
}
