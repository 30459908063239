export enum CaptchaPages {
  FIND_LOADS = "find-loads",
}

export enum CaptchaStatus {
  SUCCESS = "success",
  ERROR = "error",
  EXPIRED = "expired",
  DISABLED = "disabled"
}

export const CaptchaStorageKeys = {
  emailValidation: 'emailValidation',
  emailVerification: 'emailVerification',
  carrierCodeValidation: 'carrierCodeValidation',
  registrationSubmission: 'registrationSubmission',
  updatePreferredEmail: 'updatePreferredEmail',
  setExistingUserPassword: 'setExistingUserPassword',
  verifyExistingUserEmail: 'verifyExistingUserEmail',
}

  /**
   * The default key that is used to access the token from storage.
   * This is used if you render a single TurnstileCaptchaComponent and use the captcha token once.
   * @type {string}
   */
  export const defaultCaptchaKey = '_captcha';
