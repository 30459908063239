import { BaseModel } from 'shared/models/base.model';

export class TMCAccessorial extends BaseModel<AccessorialRequestJSON> implements TMCAccessorialInterface {
  accessorialRequestId: number;
  stopSequenceNumber: number;
  chargeTypeCode: string;
  rateType: string;
  costPerUnit: number;
  units: number;
  notes: string;

  constructor(json: AccessorialRequestJSON) {
    super(json);
  }
}
