export enum RateType {
  PerMeter = 0,
  WeightMeasure = 1,
  PerKM = 2,
  PerContainer = 3,
  PerChargeWeight = 4,
  PerActualWeight = 5,
  FixedCharge = 'A',
  PerPalletPosition = 'B',
  PerHundredWeight = 'C',
  PerDate = 'D',
  PercentChargeMarkup = 'E',
  FlatRate = 'F',
  PercentGrossFreight = 'G',
  PerHour = 'H',
  ItemRate = 'I',
  PercentNetFreight = 'J',
  PerKG = 'K',
  PerLB = 'L',
  PerMile = 'M',
  PerCBM = 'N',
  StopOffCharge = 'O',
  PerTrailerLength = 'Q',
  PerPiece = 'P',
  PerRoll = 'R',
  PerPalletSkid = 'S',
  PerTon = 'T',
  Volume = 'U',
  CalculatedValue = 'V',
  Tariff = 'W',
  PerHundredDollar = 'X',
  PerYard = 'Y',
}
export const RateTypeCodeToNames = {
  0: 'PerMeter',
  1: 'WeightMeasure',
  2: 'PerKM',
  3: 'PerContainer',
  4: 'PerChargeWeight',
  5: 'PerActualWeight',
  'A': 'FixedCharge',
  'B': 'PerPalletPosition',
  'C': 'PerHundredWeight',
  'D': 'PerDate',
  'E': 'PercentChargeMarkup',
  'F': 'FlatRate',
  'G': 'PercentGrossFreight',
  'H': 'PerHour',
  'I': 'ItemRate',
  'J': 'PercentNetFreight',
  'K': 'PerKG',
  'L': 'PerLB',
  'M': 'PerMile',
  'N': 'PerCBM',
  'O': 'StopOffCharge',
  'Q': 'PerTrailerLength',
  'P': 'PerPiece',
  'R': 'PerRoll',
  'S': 'PerPalletSkid',
  'T': 'PerTon',
  'U': 'Volume',
  'V': 'CalculatedValue',
  'W': 'Tariff',
  'X': 'PerHundredDollar',
  'Y': 'PerYard',
};
