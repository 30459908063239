import moment from 'moment';

import { ValidationFailureReason } from 'shared/enums/validation-failure-reason.enum';
import { NavCarrierFormControl } from 'app/forms/control/form-field';
import { COUNTRIES_WITH_STATES } from 'app/globals/constants';
import { DatetimeUtil } from 'app/util/util';
import { BidType } from 'shared/enums/spot-bid-bid-type.enum';

const MAX_LOAD_NUMBER = 2147483647;

export function createValidator<T>(name: string, validatorFn: TestFn<T>): ValidatorFn<T> {
  return (value: T, formControl?) => ({ [name]: validatorFn(value, formControl) });
}

export type ValidatorGeneratorFn<TGeneratorParam, TFieldValue> = (param: TGeneratorParam) => ValidatorFn<TFieldValue>;

export const create = createValidator;

/**
 * Validates that there is a value
 * @type ValidatorFn<*>
 */
export const required: ValidatorFn<any> = createValidator('required', (value) => (value != null && value !== ''));

/**
 * Validates that the value is equal to the boolean true
 * @type ValidatorFn<boolean>
 */
export const requiredTrue: ValidatorFn<any> = createValidator<boolean>('requiredTrue', (value) => (value === true));

/**
 * Given a function that returns a boolean, will ensure that the field has a value WHEN THAT FUNCTION is true.
 * Used to signify that a field is required when certain other conditions are met.
 * @param {() => boolean} conditionFn
 * @type {(value) => {[key: string]: (value) => boolean}}
 */
export const requiredWhen: ValidatorGeneratorFn<TestFn<void>, any> = (conditionFn) =>
  createValidator<any>('requiredWhen', (value) => conditionFn() ? required(value).required as boolean : true);

/**
 * Validates that value is equal to or greater than minValue
 * @param minValue
 * @returns {(value) => {}}
 */
export const min: ValidatorGeneratorFn<any, any> = (minValue: any) =>
  createValidator<number>('min', (value) => (value == null || value >= minValue));

/**
 * Validates that value is equal to or less than a maxValue
 * @param maxValue
 * @returns {(value) => {}}
 */
export const max: ValidatorGeneratorFn<any, any> = (maxValue: any) =>
  createValidator<number>('max', (value: number) => (value == null || value <= maxValue));

/**
 * Value must be (minimumLength) characters long
 * @param minimumLength
 * @returns {(value) => {}}
 */
export const minLength: ValidatorGeneratorFn<number, any> = (minimumLength: number) =>
  createValidator<string>('minLength', (value: any) => (value != null && value.length != null && value.length >= minimumLength));

/**
* Value must be (minimumLength) characters long or empty
* @param minimumLength
* @returns {(value) => {}}
*/
export const minLengthOrEmpty: ValidatorGeneratorFn<number, any> = (minimumLength: number) =>
  createValidator<string>('minLengthOrEmpty', (value: any) => (!value || (value.length != null && value.length >= minimumLength)));

/**
 * @type ValidatorGeneratorFn<number, string>
 * @param {number} maximumLength
 * @returns ValidatorFn<string>
 */
export const maxLength: ValidatorGeneratorFn<number, any> = (maximumLength: number) =>
  createValidator<string>('maxLength', (value: any) => ((value != null && value.length != null) ? (value.length <= maximumLength) : true));

/**
 * Validates that the field value matches a regex pattern
 * @param {string} name What to name the validator (will show up in a field/form's errors as the error key)
 * @param {RegExp} regex
 * @returns {(value) => {}}
 */
export const pattern = (regex: RegExp, name?: string) => {
  if (regex.global) {
    // global RegExes will toggle between matches and sometimes return false for no good reason.  NO GLOBALS FOR PATTERN MATCHING!
    console.error('form-validators.pattern: Do not use global flag in pattern tests.  Removing global flag.');
    regex = new RegExp(regex.source, regex.flags.replace(/g/g, ''));
  }
  return createValidator<string>(name || 'pattern', (value: string) => value != null ? regex.test(value) : false);
};

/**
 * Validates that the field contains only letters and numbers (no spaces or other special characters)
 * @type ValidatorFn<string>
 */
export const noSpecials: ValidatorFn<string> = pattern(/^[a-zA-Z0-9]*$/, 'noSpecials');

/**
 * Validates that the field contains only approved characters that the irs allows for company/business name submission
 * @type ValidatorFn<string>
 */
export const validIrsName: ValidatorFn<string> = (
  createValidator<string>('validIrsName', (value: string) => value !== null ? new RegExp(/^[a-zA-Z0-9\-& ]*$/).test(value) : true)
);

/**
 * Validates that this field's value matches the value of another (provided) field
 * (For password or email confirmation fields)
 *
 * @param {CHRFormControl} otherField
 * @returns {(value: *) => {[p: string]: boolean}}
 */
export const matchField: ValidatorGeneratorFn<CHRFormControl, any> = (otherField: CHRFormControl) =>
  createValidator<any>('mustMatch', (value: any) => value === otherField.value);

/**
 * Validates that field does not contain the specified value
 *
 * @param {string} value
 * @param {boolean} caseInsensitive if true, tests for value without case sensitivity
 * @returns {ValidatorFn<string>}
 */
export const mustNotContain = (value: string, caseInsensitive?: boolean) =>
  createValidator<string>('mustNotContain', fieldValue => !fieldValue || value === '' || (
    caseInsensitive
      ? !fieldValue.toLowerCase().includes(value.toLowerCase())
      : !fieldValue.includes(value))
  );

/**
 * Validates that this field's value matches the value of another (provided) field
 * (For password or email confirmation fields)
 *
 * @param {CHRFormControl} otherField
 * @returns {(value: *) => {[p: string]: boolean}}
 */
export const mustNotContainFieldValue: ValidatorGeneratorFn<CHRFormControl, string> = (otherField: CHRFormControl) =>
  createValidator<any>('mustNotContain', (value: string) => !value || !otherField.value || value.indexOf(otherField.value) === -1);

/**
 * Checks array of validators.
 * (For when form has conditionally required fields. Ex. required location fields are not needed if load number field is entered)
 *
 * @param ValidatorFn[]
 * @returns boolean
 */
export const conditionalRequiredFields = (validators: ValidatorFn<any>[]) => {
  return createValidator<LocationGroupValues>('conditionalRequiredFields', value => {
    return validators.some(validator => Object.values(validator(value)).includes(true));
  });
}

/**
 * value is a valid domestic or international phone number
 * @type ValidatorFn<string>
 */
export const phoneNumber: ValidatorFn<string> = createValidator<string>('phoneNumber', (value: string) => {
  value = value ? value.trim() : '';
  if (!value.length) {
    return true;
  }
  if (value.indexOf('+') > -1) {
    return internationalPhoneTest(value);
  }

  return (!value.length || (/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(value)));
});

/**
 * @type TestFn<string>
 * @param {string} value
 * @returns {boolean}
 */
const internationalPhoneTest: TestFn<string> = (value: string) => {
  value = value.trim();
  return (value.length === 0 || /^\+[0-9]{1,3}\.[0-9]{4,20}$/.test(value));
};

/**
 * Value is a valid international phone number
 * @type ValidatorFn<string>
 */
export const internationalPhone: ValidatorFn<string> = createValidator<string>('internationalPhone', internationalPhoneTest);

/**
 * Email pattern regex 
 * - Matches the local part of the email (before the @ symbol), which can include letters, digits, dots, underscores, percent signs, plus signs, and hyphens.
 * 
 * - Matches the domain part (after the @ symbol), which can include letters, digits, dots, and hyphens.
 * 
 * - Ensures the domain extension has at least two letters (e.g., .com, .org) and is not just digits.
 * 
 * Valid Examples:
 * - test@example.com: Simple valid email.
 * - user.name+tag+sorting@example.com: Valid email with special characters and tags.
 * - user.name@example.co.uk: Valid email with subdomains and country code top level domain(TLD).
 * - user@subdomain.example.com: Valid email with subdomains.
 * - user123@domain.com: Valid email with numbers in the local part.
 * - user.name@domain.com: Valid email with a dot in the local part.
 * 
 * Invalid Examples:
 * - user@123.123: Invalid as it has numeric TLD.
 * - user@domain: Invalid as there is no TLD.
 * - user@domain.c: Invalid as the TLD is too short.
 * - plainaddress: Invalid as there is no @ symbol.
 * - missingatsign.com: Invalid as there is no @ symbol.
 * - missingdomain@.com: Invalid as there is no domain.
 * - username@domain..com: Invalid as there are consecutive dots in the domain.
 * - username@.com.my: Invalid as the domain part is empty.
 * - @missinglocalpart.com: Invalid as there is no local part.
 */
export const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z]{2,}$/;

/**
 * value is a valid email address
 * @type ValidatorFn<string>
 */
export const email = createValidator<string>('email', value => emailPattern.test(value));

/**
 * Value is numeric (either a number or a string that can be interpreted as a number)
 * @type ValidatorFn<string|number>
 */
export const isNumeric = createValidator<string | number>('isNumeric', (value) => {
  if (value === '' || value === null || value === undefined) {
    // field isn't filled out
    return true;
  }
  switch (typeof value) {
    case 'number':
      // numbers are numeric.
      return true;
    case 'string':
      if (/^-?\d*(\.\d+)?$/g.test(value as string)) {
        // validate fixed digit numbers with decimals, such as 2.00
        return true;
      }
      return (value === (value as string).replace(/[^\-\d\.]*/g, ''));
    default:
      return false;
  }
});

export const validateDateRangeOrder = createValidator('validateOrder',
  ({ start, end }: DateRangeValues) => !(start && end) || end?.isSameOrAfter(start));

export const validateDateRangeMaxLength = (ms: number) => createValidator('validateDateRangeMaxLength',
  ({ start, end }: DateRangeValues) => !(start && end) || Math.abs(start.diff(end)) <= ms);

export const validateDateRangeOrderFromDates: ValidatorFn<{ start: Date, end: Date }> = ({ start, end }) =>
  validateDateRangeOrder({ start: start && moment(start), end: end && moment(end) });

export const validateDateIsGreaterThanToday = (date: Date) =>
  validateDateRangeOrder({ start: moment(DatetimeUtil.getDateFormatTime()), end: date && moment(date) });

export const validateDateRangeComplete = createValidator('validateComplete',
  ({ start, end }: DateRangeValues) => {
    return Boolean(!start || (end && end.isValid()));
  });

export const validateDateRangeCompleteFromDates = ({ start, end }) =>
  validateDateRangeComplete({ start: moment(start), end: moment(end) });

const hasCity = (value: LocationSelectGrouping) => Boolean(value.city && value.city.name !== '');
const hasLocation = (value: LocationSelectGrouping) => Boolean(value.place && value.place.cityName !== '');
const hasLocationV2 = (value: LocationSelectGrouping) => Boolean(value.place && value.place.cityName);
const hasDisplayName = (value: LocationSelectGrouping) => Boolean(value.place && value.place.displayName);
export const countryRequiresState = (country: CountryResponse) => country && COUNTRIES_WITH_STATES.includes(country.code);

/**
 * Test if the location contains a valid city
 * @type {ValidatorFn<LocationSelectGrouping>}
 */
export const cityRequired = createValidator<LocationSelectGrouping>('cityRequired', value => {
  return value.stateProv ? Boolean(value.city && value.city.name !== '') : true;
});

/**
 * Test if the location contains a valid state
 * @type {ValidatorFn<LocationSelectGrouping>}
 */
export const stateRequired = createValidator<LocationSelectGrouping>('stateRequired', value => {
  return hasCity(value) ? Boolean(!countryRequiresState(value.country) || value.stateProv) : true;
});

/**
 * Test if the location contains a valid country
 * @type {ValidatorFn<LocationSelectGrouping>}
 */
export const countryRequired = createValidator<LocationSelectGrouping>('countryRequired', value => {
  return Boolean(value.country);
});

/**
 * Test if the location group contains either a valid origin or destination
 * @type {ValidatorFn<LocationGroupValues>}
 */
export const originOrDestinationRequired = createValidator<LocationGroupValues>('originOrDestination', value => {
  return Boolean(hasCity(value.destination) && (!countryRequiresState(value.destination.country) || value.destination.stateProv))
    || Boolean(hasCity(value.origin) && (!countryRequiresState(value.origin.country) || value.origin.stateProv));
});

export const originOrDestinationLocationRequired = createValidator<LocationGroupValues>('originOrDestinationLocation', value => {
  return Boolean(hasLocation(value.destination)) || Boolean(hasLocation(value.origin));
});

export const originOrDestinationLocationRequiredV2 = createValidator<LocationGroupValues>('originOrDestinationLocationV2', value => {
  return hasDisplayName(value.destination) || hasDisplayName(value.origin);
});

export const loadNumberRequired = createValidator<LocationGroupValues>('loadNumberRequired', value => {
  return Boolean(value.loadNumber);
});

export const loadNumberRequiredV2 = createValidator<LocationGroupValues>('loadNumberRequired', value => {
  return Number(value.loadNumber) && value.loadNumber <= MAX_LOAD_NUMBER;
});

export const originOrDestinationValid: ValidatorFn<any> = createValidator<LocationSelectGrouping>('originOrDestinationValid', value => {
  if (value?.place?.displayName) {
    return Boolean(hasLocationV2(value));
  }

  return true;
});

export const locationRequired: ValidatorFn<any> = createValidator<LocationSelectGrouping>('locationRequired', value => {
    return Boolean(hasLocationV2(value));
});

export const care = (path: string, reason: ValidationFailureReason) =>
  createValidator(ValidationFailureReason[reason], (value, field) => {
    // since validate is called on fields during construction the parent may not have been set yet
    // resulting in the field thinking that it is the root form control
    if (field.root instanceof NavCarrierFormControl) {
      return true;
    }

    return !field.root.hasServerError(path, reason);
  });

export const careRequirements = (path: string) => [
  care(path, ValidationFailureReason.ValueExceedsMaximumLength),
  care(path, ValidationFailureReason.ValueInvalid),
  care(path, ValidationFailureReason.ValueMinimumLengthNotReached),
  care(path, ValidationFailureReason.ValueRequired),
];

// onlyDigits
export const onlyDigitsPattern = new RegExp(/^[0-9\b]+$/);

// alphanumeric
export const alphaNumericPattern = new RegExp(/^[a-zA-Z0-9]*$/);

// ssnNumber
export const validSsnNumber = new RegExp(/^(?!000|666)[0-8][0-9]{2}(?!00)[0-9]{2}(?!0000)[0-9]{4}$/)

export const validEinNumber = new RegExp(/^[1-9]\d?\d{7}$/)

export const usZipValidator = new RegExp(/^\d{5}(-\d{4})?$/)

export const canadianZipValidator = new RegExp(/^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$/)

export const digitsWithHyphen = new RegExp(/^[0-9-\b]+$/);

/**
 * Validates that array contains at least number of elements
 */
export const arrayMinLength: ValidatorGeneratorFn<number, any> = (minLength: number) =>
  createValidator('validateArrayMinLength', (value) => value[minLength - 1] !== undefined);

/**
 * Validates that spot bids costs array contains at least minLength elements
 * Skip validation if ContractedRate selected
 */
export const spotBidCostsMinLength: ValidatorGeneratorFn<{ minLength: number, bidTypeField: CHRFormControl }, any> = ({ minLength, bidTypeField }) =>
  createValidator('validateArrayMinLength', (value) => bidTypeField.value == BidType.ContractedRate ? true : value[minLength - 1] !== undefined);

/**
* Validates that array values in property are unique
*/
export const arrayUniqueValues: ValidatorGeneratorFn<string, any> = (fieldName) =>
  createValidator('validateArrayUniqueValues', (value) => {
    let numberOfRows: number = Object.keys(value).length;
    let values = [];
    type ObjectKey = keyof typeof value;
    for (let i = 0; i < numberOfRows; i++) {
      let fieldValue = value[i][fieldName as ObjectKey]
      if (values.indexOf(fieldValue) === -1) {
        values.push(fieldValue);
      } else {
        return false;
      }
    }
    return true;
  });
