import { BaseModel } from '../base.model';

export class CashAdvanceSecurityCode extends BaseModel {
  constructor(json?: CashAdvanceOptionsJSON) {
    super(json);
  }

  tChekCompany: string;
  securityNumber: string;
  amountCode: string;
}
