import { getApi } from 'lib/http';
import { environment } from 'environments/environment';
import { API_XDATE_FORMAT, Headers } from "app/globals/constants";
import moment from 'moment';
import { HmacSHA512 } from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';
import { Cookie } from 'ng2-cookies';
import { Cookies } from 'app/globals/constants';
import { OktaAuth, OktaAuthIdxOptions } from '@okta/okta-auth-js';
import { getOktaConfig } from '@features/okta/config';

const oktaAuth = new OktaAuth(getOktaConfig() as OktaAuthIdxOptions);
const api = getApi(apiConfig.analyticsAPI);

const generateXDate = () => {
  return moment().locale('en').format(API_XDATE_FORMAT);
};

const analyticsRoute = '/v1/Analytics';

const assembleSignature = ({ method, contentType, url, xDate }) => {
  let signatureArray = [];

  if (method) signatureArray.push(method.toUpperCase());
  if (contentType) signatureArray.push(contentType);

  let processedUrl = url.replace(/^https?:\/\/[^\/]*([^?]*)(.*)?/g, '$1');
  signatureArray.push(processedUrl);

  if (xDate) signatureArray.push(xDate);

  return signatureArray.join('\n');
};

const generateHash = (signature, key) => {
  const hash = HmacSHA512(signature, key);
  const hashInBase64 = Base64.stringify(hash);

  return hashInBase64.replace(/\+/g, encodeURIComponent('+'));
};

const getHeaders = async (user: UserJSON, path: string, contentType: string, method: string) => {
  let headers = {};
  const accessToken = await oktaAuth.getOrRenewAccessToken();
  if (accessToken) {
    const bearerToken = `Bearer ${accessToken}`;
    headers[Headers.AUTHORIZATION] = bearerToken;
  } else {
    const xDate = generateXDate()
    const hash = generateHash(
      assembleSignature({
        method: method,
        contentType: contentType,
        url: path,
        xDate: xDate,
      }),
      user?.hashedPassword || environment.apiKey
    );

    headers[Headers.X_DATE] = xDate;
    const legacyToken = `${user.username ? `${user.username}:${hash}` : hash}`;
    headers[Headers.AUTHORIZATION] = legacyToken;
  }

  // Common
  headers[Headers.CONTENT_TYPE] = 'application/json';
  headers[Headers.API_KEY] = environment.apiKey;
  return headers;
}

async function postFetchEventAsync(event: BaseEventJSON, user: UserJSON) {
  const url = `${apiConfig.analyticsAPI}${analyticsRoute}`;
  const method = 'POST';
  const headers = await getHeaders(user, analyticsRoute, 'application/json', method);
  await fetch(url, {
    method: method,
    headers,
    body: JSON.stringify(event),
  });
}

export async function publishEvent(
  eventType: EventType,
  navCarrierData: NavCarrierData,
  userId: string,
  userEmail: string,
  eventData: SearchSubmitted | LoadSelected | BookAction | OfferAction,
  user?: UserJSON) {
  const baseEvent: BaseEventJSON = {
    eventType: eventType,
    userId: userId,
    userEmail: userEmail,
    visitorId: Cookie.get(Cookies.visitorId),
    route: window.location.pathname,
    createdDateTimeUTC: new Date().toJSON(),
    sourceApplication: 'NavCarrierWeb',
    appVersion: appConstants.buildNumber,
    navCarrierData: navCarrierData,
    eventData: eventData
  }

  try {
    if (eventType === 'Login') {
      await postFetchEventAsync(baseEvent, user);
    } else {
      await api.post(analyticsRoute, baseEvent, { responseType: 'json' });
    }
  }
  catch (e) {
    //intentinally swallow the error as we don't want to break the app if the event fails to publish
  }
}

export default { publishEvent };
