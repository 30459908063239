import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/merge';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/distinctUntilChanged';

import { AbstractCHRFormControl } from 'app/forms/control/abstract-form-control';
import { FormFieldStructure } from 'app/forms/structure/form-field.structure';

export interface FieldValidation {
  validation: { [key: string]: boolean };
  errors: string[];
  valid: boolean;
}
export class NavCarrierFormControl<TValue = any> extends AbstractCHRFormControl implements CHRFormControl {
  private validator: ValidatorFn<any>;
  private _readonly = false;
  private _disabled = false;
  private _touched = false;
  private _dirty = false;
  private _value: TValue;
  private _validation: ValidatorResult;
  private isPendingValidation = true;
  private _errors: string[] = [];
  private _valid: boolean;
  private initialValue;
  readonly _valueChanges = new Subject<TValue>();
  readonly _validationChanges = new Subject<FieldValidation>();
  readonly _disabledChanges = new Subject<boolean>();
  readonly _touchedChanges = new Subject<boolean>();
  readonly changes: Observable<any> = Observable.merge(
    this._valueChanges,
    this._touchedChanges,
    this._validationChanges,
    this._disabledChanges
  ).map(() => this);

  get valueChanges() { return this._valueChanges.distinctUntilChanged(); }
  get touchedChanges() { return this._touchedChanges.distinctUntilChanged(); }
  get disabledChanges() { return this._disabledChanges.distinctUntilChanged(); }
  get validationChanges() { return this._validationChanges.distinctUntilChanged(); }
  get readonly() { return this._readonly; }
  get disabled() { return this._disabled || (!!this.getParent() && this.getParent().disabled); }
  get validation() { return this._validation; }
  get errors() { return this._errors; }
  get valid() { return this._valid; }
  get touched() { return this._touched; }
  get dirty() { return this._dirty; }
  get value() { return this._value; }

  constructor(structure: FormFieldStructure) {
    super();

    const value = structure.value !== undefined ? structure.value : structure.initialValue;

    this.initialValue = value !== undefined ? value : null;
    this._value = value !== undefined ? value : null;
    this.validator = this.reduceValidators(structure.validators);

    this._disabled = structure.disabled || false;
    this._dirty = structure.dirty || false;
    this._touched = structure.touched || false;
    this.setPendingValidation();
  }

  touch() {
    if (this._touched) {
      return;
    }
    this._touched = true;
    this.setPendingValidation();
    this._touchedChanges.next(true);
    if (!this.getParent()) {
      this.validate();
    }
  }

  markAsPristine() {
    this._dirty = false;
    this._touched = false;
    this.setPendingValidation();
    this._touchedChanges.next(false);
    if (!this.getParent()) {
      this.validate();
    }
  }

  reset() {
    if (this.value === this.initialValue && this.dirty === false && this.touched === false) {
      return;
    }
    this.value = this.initialValue;
    this._dirty = false;
    this._touched = false;
    this.setPendingValidation();
    this._touchedChanges.next(false);
    this._valueChanges.next(this.value);
    if (!this.getParent()) {
      this.validate();
    }
  }

  addValidator(validator: ValidatorFn<any>) {
    this.validator = this.reduceValidators([this.validator, validator]);
    this.setPendingValidation();
    this._validationChanges.next(null);
    if (!this.getParent()) {
      this.validate();
    }
  }

  validate() {
    if (!this.hasPendingValidation()) {
      return this._validation;
    }
    this.clearPendingValidation();
    const oldValidation = this._validation;

    this._validation = this.validator(this.value, this);
    this._errors = Object.entries(this._validation).filter(([, value]) => !value).map(([name]) => name);
    this._valid = Object.values(this._validation).reduce((oldIsValid, thisIsValid) => Boolean(oldIsValid && thisIsValid), true);

    if (JSON.stringify(oldValidation) !== JSON.stringify(this._validation)) {
      this._validationChanges.next({
        errors: this.errors,
        validation: this.validation,
        valid: this.valid
      });
    }

    return this._validation;
  }
  setPendingValidation(ignored?) {
    this.isPendingValidation = true;
  }

  protected clearPendingValidation() {
    this.isPendingValidation = false;
  }

  hasPendingValidation() {
    return this.isPendingValidation;
  }

  private reduceValidators(validators: ValidatorFn<any>[] = []) {
    return function combination(value, formControl) {
      return validators.reduce((output, validator) => ({ ...output, ...validator(value, formControl) }), {});
    };
  }

  get pristine() {
    return !this.dirty && !this.touched;
  }

  set disabled(value: boolean) {
    if (this._disabled === value) {
      return;
    }
    this._disabled = value;
    this._disabledChanges.next(value);
  }

  set value(value: TValue) {
    if (this._value === value) {
      return;
    }
    this.setValueForced(value);
  }

  set error(error: string) {
    this.errors.push(error);
    this._valid = false;
    this._validationChanges.next({
      errors: this.errors,
      validation: this.validation,
      valid: this.valid
    });
  }

  setValueForced(value: TValue) {
    this._value = value;
    this._touched = true;
    this._dirty = true;
    this.setPendingValidation();
    this._valueChanges.next(value);
    this._touchedChanges.next(true);
    if (!this.getParent()) {
      this.validate();
    }
  }

  setValue(value) {
    this.value = value;
  }

  getInitialValue() {
    return this.initialValue;
  }

  setInitialValue(value) {
    this.initialValue = value;
  }

  hasError(name): boolean {
    if (!this.touched) {
      return false;
    }

    return this.errors.includes(name);
  }

  hasErrors(): boolean {
    if (!this.touched) {
      return false;
    }

    return Boolean(this.errors.length);
  }

  disable(...ignored) {
    this.disabled = true;
  }

  enable(...ignored) {
    this.disabled = false;
  }

  getPath(): string[] {
    let path = [];
    if (this.getParent()) {
      path = [
        ...this.getParent().getPath(),
        this.getParent().getChildKey(this as CHRFormControl)
      ];
    }
    return path;
  }
}
