import { Inject, Service } from 'typedi';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { CacheableRepository } from 'app/repositories/cacheable.repository';

@Service()
export class ResourceRepository extends CacheableRepository {
  protected baseUrl = '';
  public resources: Resources;

  @Inject('appConstants.buildNumber')
  private buildNumber: string;

  public loadResources(locale: string) {
    return this.getResources(locale)
      .catch(this.onInitialResourceLoadFailure(locale));
  }

  private getResources = (locale: string) => // These requests MUST be made with responseType 'text' because 404s return HTML, and break IE if responseType is 'json'
    this.getWithCache(`assets/lang/${locale}.json?build=${this.buildNumber}`, false, 'text')
      .map(res => JSON.parse(res));

  /**
   * After first failure, if it was a 404, try again without the country... e.g. from `en-US` to `en`
   * (this is particularly valuable for Mexico (es-MX) because there is no `es-MX` translation file,
   * but there is an `es` translation file.
   **/
  private onInitialResourceLoadFailure = (locale: string) => (err: AjaxError) =>
      (err.status === 404)
        ? this.getResources(locale.split('-')[0])
          .catch(this.onSecondaryResourceLoadFailure(locale, err))
        : Observable.empty();

  /**
   * If neither of the previous were found, switch to `en-US` default.
   * If that also fails, console.error it.
   **/
  private onSecondaryResourceLoadFailure = (locale: string, err: AjaxError) => (err2: AjaxError) =>
    (err.status === 404)
      ? this.getResources('en-US')
        .catch(err3 => Observable.throw(console.error(`Error loading resources for ${locale}`, err, err2, err3)))
      : Observable.empty();
}
