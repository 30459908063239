import { IS_DEV } from '@app/globals/constants';
import { LogsInitConfiguration } from '@datadog/browser-logs';
import { DefaultPrivacyLevel } from '@datadog/browser-rum';
import { messagesToSuppress, urlsExcludedFromLoadingTimes } from 'features/datadog/exclusions';

// TODO: Move these to app-tokens.js
const applicationId = '34cfae3e-1c21-41c3-9b18-13f39b8946a8';
const clientToken = 'pub09a258796bb2d5c5d186333428ded566';
const site = 'us3.datadoghq.com';
const service = 'navisphere-carrier';

const common = {
 applicationId,
 clientToken,
 site,
 service,
};

export const loggingConfig = {
 clientToken,
 site,
 service,
 forwardErrorsToLogs: true,
 forwardReports: 'all',
 sessionSampleRate: 100,
 silentMultipleInit: true,
} as LogsInitConfiguration;

const sampleRates = {
 sessionSampleRate: IS_DEV ? 100 : 25,
 sessionReplaySampleRate: IS_DEV ? 100 : 40,
 premiumSampleRate: IS_DEV ? 100 : 40,
};

export const dataDogRUMConfig = {
 ...common,
 ...sampleRates,
 env: appConstants.buildEnvironment,
 version: appConstants.buildNumber,
 trackUserInteractions: true,
 trackResources: true,
 trackLongTasks: true,
 silentMultipleInit: true,
 defaultPrivacyLevel: 'mask-user-input' as DefaultPrivacyLevel,
 allowedTracingUrls: [/https:\/\/.*\.chrobinson\.com/, /https:\/\/.*\.navispherecarrier\.com/],
 enableExperimentalFeatures: ['feature_flags'],
 beforeSend: event => {
    if (event.type !== 'error') return true;
    if (event.error?.origin === 'network' || messagesToSuppress.some(msg => event.error.message.includes(msg))) {
     // Ignore errors related to ad blockers or certain third-party messaages
     return false; // stops event from being sent
    }
 },
 excludedActivityUrls: [url => urlsExcludedFromLoadingTimes.some(excludedUrl => url.includes(excludedUrl))],
};
