import { BaseModel } from 'shared/models/base.model';
import { CarrierTier } from 'shared/models/my-loads/loads/load-detail.model';

export class Carrier extends BaseModel<CarrierJSON> {
  public code: string;
  public isTMC: boolean;
  public name: string;
  public regionCode: string;
  public isDray?: boolean;
  public isTrusted?: boolean;
  public carrierTier?: CarrierTier;
  public paymentType?: string;

  constructor(json?: CarrierJSON) {
    super(json);
  }

  toJson(): CarrierJSON {
    return super.toJson();
  }
}
