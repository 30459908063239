import { Action } from 'redux';

import { REQUEST_FAILURE, REQUEST_START, REQUEST_SUCCESS } from 'store/requests.actions';

export const SET_RESOURCES = 'SET_RESOURCES';
export const LOAD_RESOURCES = `${REQUEST_START}:LOAD_RESOURCES`;
export const LOAD_RESOURCES_SUCCESS = `${REQUEST_SUCCESS}:LOAD_RESOURCES`;
export const LOAD_RESOURCES_FAILURE = `${REQUEST_FAILURE}:LOAD_RESOURCES`;

export interface LoadResourcesSuccessAction extends Action {
  resources: Resources;
}
export interface LoadResourcesFailureAction extends Action {
  error: any;
}
export interface LoadResourcesAction extends Action {
  locale: string;
}

export const setResources = (resources: Resources) => ({type: SET_RESOURCES, payload: resources});
export const loadResources = (locale: string) => ({type: LOAD_RESOURCES, locale});
export const loadResourcesSuccess = (resources: Resources) => ({type: LOAD_RESOURCES_SUCCESS, resources});
export const loadResourcesFailure = (error: any) => ({type: LOAD_RESOURCES_FAILURE, error});
