
import { MeasurementType } from 'shared/enums/measurement-type.enum';
import { UnitOfMeasure } from 'shared/enums/unit-of-measure.enum';

export const ConversionMultipliers = {
  Distance: 1.60934,
  Weight: 0.453592,
  Volume: 0.0283168466,
  SmallVolume: 0.0000163871,
  Length: 0.3048,
  SmallLength: 2.54
};

const formatIsStandard = (format: UnitOfMeasure) =>
  format === UnitOfMeasure.US || format === UnitOfMeasure.Standard;

export const convertUnits = (value: number, type: MeasurementType, targetFormat: UnitOfMeasure, sourceFormat = UnitOfMeasure.US): number => {
    if (value == null) {
      return;
    }
    if (!MeasurementType.hasOwnProperty(type) || !ConversionMultipliers.hasOwnProperty(MeasurementType[type]) || targetFormat === sourceFormat) {
      // unable to convert
      return value;
    }

    let returnValue = value; // fail-safe value, returns original

    if (formatIsStandard(sourceFormat) && targetFormat === UnitOfMeasure.Metric) {
      returnValue = value * ConversionMultipliers[MeasurementType[type]];
    } else if (sourceFormat === UnitOfMeasure.Metric && formatIsStandard(targetFormat)) {
      returnValue = value / ConversionMultipliers[MeasurementType[type]];
    }

    return returnValue;
};

export const convertDistanceToMetric = (value: number) => convertUnits(value, MeasurementType.Distance, UnitOfMeasure.Metric, UnitOfMeasure.Standard);
export const convertWeightToMetric = (value: number) => convertUnits(value, MeasurementType.Weight, UnitOfMeasure.Metric, UnitOfMeasure.Standard);
