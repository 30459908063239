import { BaseModel } from 'shared/models/base.model';

export class PreferredLane extends BaseModel<PreferredLaneJSON> {
  preferredLaneId?: number;
  carrierCode: string;
  origin?: PreferredLaneLocationJSON;
  destination?: PreferredLaneLocationJSON;
  originRadiusMiles: number;
  destinationRadiusMiles: number;
  equipmentMode: string;
  specializedEquipmentCode: string;
  minimumTotalWeight: PreferredLaneWeightJSON;
  maximumTotalWeight: PreferredLaneWeightJSON;
  minimumTotalDistance: PreferredLaneDistanceJSON;
  maximumTotalDistance: PreferredLaneDistanceJSON;
  teamLoadsOnly?: boolean;
  emailNotifications: boolean;
  equipmentLength: number;
  createdDateTime: Date;
  updatedDateTime: Date;
  updatedBy: string;
  originStateCodes?: Array<PreferredLaneLocationJSON>;
  destinationStateCodes?: Array<PreferredLaneLocationJSON>;

  constructor(json: PreferredLaneJSON) {
    super(json);
    if (json) {
      this.updatedDateTime = this.processDate(json.updatedDateTime);
      this.createdDateTime = this.processDate(json.createdDateTime);
    }
  }

  toJson(): PreferredLaneJSON {
    return {
      ...super.toJson(),
      updatedDateTime: this.convertDateToDateString(this.updatedDateTime),
      createdDateTime: this.convertDateToDateString(this.createdDateTime),
    };
  }
}

export class UserPreferredLane extends BaseModel<UserPreferredLaneJSON> {
  userPreferredLaneId?: number;
  userId: number;
  preferredLane: PreferredLane;

  constructor(json: UserPreferredLaneJSON) {
    super(json);

    if (json) {
      this.preferredLane = new PreferredLane(json.preferredLane);
    }
  }

  toJson(): UserPreferredLaneJSON {
    return {
      ...super.toJson(),
      preferredLane: this.preferredLane.toJson()
    };
  }

  toSearchCriteria(): AvailableLoadSearchCriteriaJSON {
    return {
      originCountryCode: this.preferredLane.origin ? this.preferredLane.origin.countryCode : null,
      originStateProvinceCode: this.preferredLane.origin ? this.preferredLane.origin.stateProvinceCode : null,
      originCity: this.preferredLane.origin ? this.preferredLane.origin.city : null,
      originRadiusMiles: this.preferredLane.originRadiusMiles,
      originStateCodes: null,
      destinationCountryCode: this.preferredLane.destination ? this.preferredLane.destination.countryCode : null,
      destinationStateProvinceCode: this.preferredLane.destination ? this.preferredLane.destination.stateProvinceCode : null,
      destinationCity: this.preferredLane.destination ? this.preferredLane.destination.city : null,
      destinationStateCodes: null,
      destinationRadiusMiles: this.preferredLane.destinationRadiusMiles,
      pickupStart: null,
      pickupEnd: null,
      weightMax: null,
      teamLoad: null,
      equipmentLengthMax: null,
      mode: this.preferredLane.equipmentMode,
      specializedEquipmentCode: this.preferredLane.specializedEquipmentCode,
    };
  }
}
