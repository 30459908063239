import { BaseModel } from 'shared/models/base.model';
import { SpotBidCharge } from 'shared/models/spot-bids/spot-bid-charge.model';
import { SpotBidPolicyPermission } from 'shared/models/spot-bids/spot-bid-policy-permissions.enum';
import { SpotBidExtendedProperties } from 'shared/models/spot-bids/spot-bid-extended-properties.model';

export class SpotBidProperties extends BaseModel<SpotBidPropertiesJSON> {
  spotBidId: number;
  enteredDate: Date;
  offerId: number;
  costQuoteId: number;
  bidStatus: string;
  miles: number;
  numberOfTrucksRequested: number;
  numberOfTrucksCommitted: number;
  spotBidCharges: SpotBidCharge[];
  accessorialCodes: { code: string; name: string; } [];
  spotBidChargeType: string;
  currencyCode: string;
  isTMCLoad: boolean;
  isContractOnly: boolean;
  isSystemGeneratedCost: boolean;
  customerCode: string;
  customerName: string;
  btCustomerSeqNum: number;
  emailNote: string;
  customerEffectiveDate: Date;
  customerExpirationDate: Date;
  carrierQuoteId: string;
  offerEffectiveDate: Date;
  offerExpirationDate: Date;
  partyPolicyPermissions: {
    customerCode: string;
    partyPolicyPermissionType: SpotBidPolicyPermission
  }[];
  extendedProperties: SpotBidExtendedProperties;

  constructor(json: SpotBidPropertiesJSON) {
    super(json);
    if (json) {
      this.spotBidCharges = json.spotBidCharges.map(charge => new SpotBidCharge(charge));
      this.currencyCode = json.currencyCode.trim();
      this.customerEffectiveDate = this.processDate(json.customerEffectiveDate);
      this.customerExpirationDate = this.processDate(json.customerExpirationDate);
      this.offerEffectiveDate = this.processDate(json.offerEffectiveDate);
      this.offerExpirationDate = this.processDate(json.offerExpirationDate);
      this.enteredDate = this.processDate(json.enteredDate);
      this.extendedProperties = new SpotBidExtendedProperties(json.extendedProperties);
    }
  }

  toJson(): SpotBidPropertiesJSON {
    return Object.assign({}, super.toJson(), {
      customerEffectiveDate: this.convertDateToDateString(this.customerEffectiveDate),
      customerExpirationDate: this.convertDateToDateString(this.customerExpirationDate),
      offerEffectiveDate: this.convertDateToDateString(this.offerEffectiveDate),
      offerExpirationDate: this.convertDateToDateString(this.offerExpirationDate),
      enteredDate: this.convertDateToDateString(this.enteredDate),
      spotBidCharges: this.spotBidCharges.map(charge => charge.toJson()),
      extendedProperties: this.extendedProperties.toJson()
    });
  }
}
