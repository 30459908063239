import { BaseModel } from 'shared/models/base.model';

export class AccessorialCustomer extends BaseModel {
  sendChargesCHRWOnline: boolean;
  postInvoiceCharges: boolean;

  constructor(json: AccessorialCustomerJSON) {
    super(json);
  }

  toJson(): AccessorialCustomerJSON {
    return (Object.assign({}, this)) as any;
  }
}
