import { BaseModel } from 'shared/models/base.model';

export enum DistanceUnit {
  Mile,
  Kilometer
}

/** NavisphereCarrier.MyLoads.ServiceModel.Measurements.Distance */
export class Distance extends BaseModel<MyLoads.DistanceJSON> {
  readonly metric: number;
  readonly metricUom: DistanceUnit;
  readonly standard: number;
  readonly standardUom: DistanceUnit;
}
