import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit'
import { SortOrder } from 'shared/enums/sort-order.enum';

const MY_OFFERS_DEFAULT_SORT_OPTIONS_KEY = 'MY_OFFERS_DEFAULT_SORT_OPTIONS';

const setLocalStorageMyOffersSortOptions = (options: MyOffersSortOptions) => {
  if (typeof (Storage) !== "undefined") {
    window.localStorage.setItem(MY_OFFERS_DEFAULT_SORT_OPTIONS_KEY, JSON.stringify(options));
  }
  return;
}

const defaultSortOptions = {
  sortByColumnName: 'loadNumber',
  sortOrder: SortOrder.DESC
};

const getLocalStorageMyOffersSortOptions = (): MyOffersSortOptions => {
  if (typeof (Storage) !== "undefined") {
    const options = window.localStorage.getItem(MY_OFFERS_DEFAULT_SORT_OPTIONS_KEY);
    return options
      ? JSON.parse(options)
      : defaultSortOptions;
  }

  return defaultSortOptions;
}

export interface MyOffersSortOptions {
  sortByColumnName: string,
  sortOrder: SortOrder
}

export interface UserPreferencesState {
  myOffersSortOptions: MyOffersSortOptions
}

const initialState: UserPreferencesState = {
  myOffersSortOptions: getLocalStorageMyOffersSortOptions()
}

const slice = createSlice({
  name: 'userPreferences',
  initialState,
  reducers: {
    setMyOffersSortOptions: (state, action: PayloadAction<MyOffersSortOptions>) => {
      setLocalStorageMyOffersSortOptions(action.payload);
      return {
        ...state,
        myOffersSortOptions: action.payload
      }
    },
  }
});

export const { setMyOffersSortOptions } = slice.actions;

export default slice.reducer;
