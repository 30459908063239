import { Action } from 'redux';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';

import { ExtendedLoad } from 'shared/models/loads/extended-load.model';

export const FETCH_LOAD_DETAILS = 'FETCH_LOAD_DETAILS';
export const REFRESH_LOAD_DETAILS = 'REFRESH_LOAD_DETAILS';

export const FETCH_LOAD_DETAILS_REQUEST_START = `_{REQUEST_START}_:${FETCH_LOAD_DETAILS}`;
export const FETCH_LOAD_DETAILS_SUCCESS = `_{REQUEST_SUCCESS}_:${FETCH_LOAD_DETAILS}`;
export const FETCH_LOAD_DETAILS_FAILURE = `_{REQUEST_FAILURE}_:${FETCH_LOAD_DETAILS}`;

export const SET_LOAD_DETAILS = 'SET_LOAD_DETAILS';
export interface SetLoadDetailsAction extends Action {
  load: ExtendedLoad|null;
}
export interface FetchLoadDetailsSuccessAction extends Action {
  payload: ExtendedLoad;
}
export interface FetchLoadDetailsFailureAction extends Action {
  err: AjaxError;
}
export interface FetchLoadDetailsAction extends Action {
  id: number;
  bookType: string;
  forceRefresh?: boolean;
}

export const setLoadDetails = (load: ExtendedLoad|null): SetLoadDetailsAction => ({type: SET_LOAD_DETAILS, load});

// tslint:disable-next-line:max-line-length
export const fetchLoadDetails = (id: number, bookType: string, forceRefresh?: boolean) => ({type: FETCH_LOAD_DETAILS_REQUEST_START, id, bookType, forceRefresh});
export const fetchLoadDetailsSuccess = (payload: ExtendedLoad) => ({type: FETCH_LOAD_DETAILS_SUCCESS, payload});
export const fetchLoadDetailsError = (err: AjaxError) => ({type: FETCH_LOAD_DETAILS_FAILURE, err});

export const refreshLoadDetails = () => ({type: REFRESH_LOAD_DETAILS});
