import { Grant, Grants } from '@features/security/grants';
import { User } from 'shared/models/user.model';
import { CarrierDetail } from 'shared/models/carrier/carrier-detail.model';
import { AppRoute } from 'app/routesEnum';

// TODO: remove when user management is 100% 
const RULES: { [key: string]: Grant } = {
  [AppRoute.FIND_LOADS]: Grants.FindLoadsGrant,
  [AppRoute.OFFERS]: Grants.MyOffersGrant,
  [AppRoute.FIND_LOADS_BASE]: Grants.FindLoadsGrant,
  [AppRoute.FIND_LOAD_DETAILS]: Grants.FindLoadsGrant,
  [AppRoute.POST_TRUCKS]: Grants.PostTrucksGrant,
  [AppRoute.MY_LOADS]: Grants.MyLoadsGrant,
  [AppRoute.MY_SHIPMENTS]: Grants.MyShipmentsGrant,
  [AppRoute.MY_SHIPMENT_DETAILS]: Grants.MyShipmentsGrant,
  [AppRoute.MY_LOAD_DETAILS]: Grants.MyLoadDetailsGrant,
  [AppRoute.TENDERS]: Grants.TendersGrant,
  [AppRoute.TENDERS_DEEP_LINK]: Grants.TendersGrant,
  [AppRoute.SPOT_BIDS]: Grants.SpotBidsGrant,
  [AppRoute.ACCOUNTS_RECEIVABLE]: Grants.AccountsReceivableGrant,
  [AppRoute.CLAIMS]: Grants.ClaimsGrant,
  [AppRoute.RATES]: Grants.RatesGrant,
  [AppRoute.MANAGE_INVOICES]: Grants.ManageInvoicesGrant,
  [AppRoute.PREFERRED_LANES]: Grants.PreferredLanesGrant,
  [AppRoute.ADMINISTER_USERS]: Grants.AdminGrant,
  [AppRoute.IMPERSONATE_USER]: Grants.ImpersonateAllGrant,
  [AppRoute.ANNOUNCEMENTS]: Grants.AnnouncementsGrant,
  [AppRoute.DOCK_MANAGER]: Grants.DockMgrGrant,
  [AppRoute.TMC_REPORTS]: Grants.TMCReportsGrant,
  [AppRoute.CARRIER_ADVANTAGE_PROGRAM]: Grants.CarrierAdvantageGrant,
  [AppRoute.DASHBOARD]: Grants.CarrierAdvantageGrant,
  [AppRoute.RESOLVE_MODAL]: Grants.MyLoadsGrant
};

const ALLOWED = () => true;

export const isAuthorized = (user: User, route: AppRoute, carrier?: CarrierDetail, isACSUser: boolean = false) => {
  const rule = RULES[route] || ALLOWED;
  return rule(user, carrier, isACSUser);
};
