import { BaseModel } from '../base.model';

export enum CashAdvanceSourceType {
  EFSFuelCard = 1,
  TChek = 2
}

export class CashAdvanceOptionsSource extends BaseModel {
  constructor(json?: CashAdvanceSourceJSON) {
    super(json);
  }

  readonly cashAdvanceSourceType: CashAdvanceSourceType;
  readonly isEnabled: boolean;
  readonly minimumFee: number;
  readonly percentFee: number;
}
