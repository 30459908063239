export enum EquipmentType {
  // resources keys
  A = 'ALL_TYPES',
  AL = 'ALL_TYPES',
  ALL = 'ALL_TYPES',
  B = 'BULK',
  BO = 'BULK',
  V = 'VAN',
  VO = 'VAN',
  F = 'FLAT_BED',
  FO = 'FLAT_BED',
  L = 'LTL',
  LO = 'LTL',
  R = 'REEFER',
  RO = 'REEFER',
  DF = 'DROPDECK_FLAT',
  FV = 'FURNITURE_VAN',
  FDD =	'DBL_DROP_FLAT',
  IC = 'IMDL_CONTAINER',
  IO = 'IMDL_CONTAINER',
  IT = 'IMDL_TRAILER',
  I = 'IMDL',
  C	= 'CONTAINER',
  U	= 'TAUT_LINER',
  Z = 'OTHER',
  H = 'POWER_ONLY',
  PO = 'POWER_ONLY',
  VH = 'POWER_ONLY_VAN',
  FH = 'POWER_ONLY_FLATBED',
  RH = 'POWER_ONLY_REEFER',
  J = 'DRAY',
  VJ = 'DRAY_VAN',
  RJ = 'DRAY_REEFER',
  OW = 'OCEAN',
  OD = 'HEAVY_WEIGHT_AIR',
  // D = 'DOMESTIC_AIR',

  // enum values
  All = 'A',
  Bulk = 'B',
  Container = 'C',
  // DomesticAir = 'D',
  Flatbed = 'F',
  PowerOnly = 'H',
  IMDL = 'I',
  LTL = 'L',
  Reefer = 'R',
  StandardTautliner = 'U',
  Van = 'V',
  Other = 'Z',
}
