import { BaseModel } from 'shared/models/base.model';
import { StopAddress } from 'shared/models/stop-address.model';
import { LoadSummaryAddress } from 'shared/models/loads/load-summaries/load-summary-address.model';

export abstract class BaseDriverLog extends BaseModel {

  notes: string;
  sourceUser: string;
  sourceSystem: string;
  logDateTime: Date;
  recordedTemperature?: number;

  location?: StopAddress;
  address?: LoadSummaryAddress;
  isLate?: boolean;

  protected constructor() {
    super();
  }
}
