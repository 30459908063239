import { BaseModel } from 'shared/models/base.model';
import { InvoiceLocation } from 'shared/models/invoice/invoice-location.model';
import { InvoiceItem } from 'shared/models/invoice/invoice-item.model';
import { InvoiceReferenceNumber } from 'shared/models/invoice/invoice-reference-number.model';

export class InvoiceDetail extends BaseModel<CarrierInvoiceDetailJSON> {
  invoiceId?: number;
  invoiceNumber: string;

  isPaperInvoice?: boolean;

  proNumber: string;
  carrierCode: string;
  customerCode: string;
  private scac: string;
  loadNumber?: number;

  invoiceStatus: number;

  referenceNumbers: InvoiceReferenceNumber[];

  payerLocation: InvoiceLocation;
  billToLocation: InvoiceLocation;
  shipperLocation: InvoiceLocation;
  consigneeLocation: InvoiceLocation;
  stops: InvoiceLocation[];

  shipDate: Date;
  deliveryDate: Date;
  invoiceDate: Date;
  receivedDate: Date;
  resolvedDate: Date;

  amountDue: number;
  CHRAmount: number;
  currencyCode: string;

  charges: InvoiceItem[];
  accessorials: InvoiceItem[];
  totalChargeAmount: number;
  totalWeight: number;

  notes: string;

  get SCAC() {
    return this.scac;
  }

  constructor(json: CarrierInvoiceDetailJSON) {
    super(json);
    this.referenceNumbers = json.referenceNumbers.map(refNumJSON => new InvoiceReferenceNumber(refNumJSON));

    this.payerLocation = new InvoiceLocation(json.payerLocation);
    this.billToLocation = new InvoiceLocation(json.billToLocation);
    this.shipperLocation = new InvoiceLocation(json.shipperLocation);
    this.consigneeLocation = new InvoiceLocation(json.consigneeLocation);
    this.stops = json.stops?.map(stop => new InvoiceLocation(stop));

    this.shipDate = this.processDate(json.shipDate);
    this.deliveryDate = this.processDate(json.deliveryDate);
    this.invoiceDate = this.processDate(json.invoiceDate);
    this.receivedDate = this.processDate(json.receivedDate);
    this.resolvedDate = this.processDate(json.resolvedDate);

    this.charges = json.charges.map(charge => new InvoiceItem(charge));
    this.accessorials = json.accessorials.map(accessorial => new InvoiceItem(accessorial));
  }

  toJson(): CarrierInvoiceDetailJSON {
    const resolvedDate = this.convertDateToDateString(this.resolvedDate);
    return {
      ...super.toJson(),
      payerLocation: this.payerLocation.toJson(),
      billToLocation: this.billToLocation.toJson(),
      shipperLocation: this.shipperLocation.toJson(),
      consigneeLocation: this.consigneeLocation.toJson(),
      shipDate: this.convertDateToDateString(this.shipDate, true),
      deliveryDate: this.convertDateToDateString(this.deliveryDate, true),
      invoiceDate: this.convertDateToDateString(this.invoiceDate, true),
      receivedDate: this.convertDateToDateString(this.receivedDate, true),
      resolvedDate: resolvedDate === '0001-01-01T00:00:00' ? null : resolvedDate,
      stops: this.stops.map(stop => stop.toJson()),
      charges: this.charges.map(charge => charge.toJson()),
      accessorials: this.accessorials.map(accessorial => accessorial.toJson()),
      referenceNumbers: this.referenceNumbers.map(refNum => refNum.toJson())
    };
  }
}
