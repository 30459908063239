import { LoadSummaryAddress } from 'shared/models/loads/load-summaries/load-summary-address.model';
import { AssignableLoad, Cloneable } from 'shared/components/assign-driver/assign-driver.component';
import { LoadStatus } from 'shared/enums/load-status.enum';
import { BaseModel } from 'shared/models/base.model';
import { BookType } from 'shared/enums/book-type.enum';
import { LoadType } from 'shared/enums/load-type.enum';
import { Driver } from 'shared/models/contacts/driver.model';
import { TrackingMessageType } from 'shared/enums/tracking-message-type.enum';
import { insideOfAppMessageCodes, outsideOfAppMessageCodes } from 'pages/my-loads/constants';

export class LoadSummary extends BaseModel implements AssignableLoad, Cloneable {
  carrierID: string;
  expectedPickupDate: Date;
  deliverByDate: Date;
  origin: LoadSummaryAddress;
  destination: LoadSummaryAddress;
  status: LoadStatusJSON;
  bookType: string;
  carrierContact?: string;
  containerNumber?: string;
  deliverNumber?: string;
  driverFirstName?: string;
  driverLastName?: string;
  imdlDate: Date;
  loadType: number;
  pickupNumber: string;
  proNumber: string;
  referenceNumber: string;
  referenceNumberDescription: string;
  trailerNumber?: string;
  tractorNumber?: string;
  weight?: string;
  pallets: number;
  pieces: number;
  trackingMessageCode: TrackingMessageType;

  private billToRefNumber?: string;
  private bookSeqNum: number;
  private groupID: number;
  private loadNumber: number;

  get billToReferenceNumber(): string {
    return this.billToRefNumber;
  }
  set billToReferenceNumber(value: string) {
    this.billToRefNumber = value;
  }

  get bookSequenceNumber(): number {
    return this.bookSeqNum;
  }
  set bookSequenceNumber(value: number) {
    this.bookSeqNum = value;
  }

  set number(value: number) {
    this.loadNumber = value;
  }
  get number(): number {
    return this.loadNumber;
  }
  get groupId(): number {
    return this.groupID;
  }
  set groupId(value: number) {
    this.groupID = value;
  }

  constructor(json: LoadSummaryJSON) {
    super(json);
    if (json) {
      this.expectedPickupDate = this.processDate(json.expectedPickupDate);
      this.deliverByDate = this.processDate(json.deliverByDate);
      this.imdlDate = this.processDate(json.imdlDate);

      if (json.origin) {
        this.origin = new LoadSummaryAddress(json.origin);
      }

      if (json.destination) {
        this.destination = new LoadSummaryAddress(json.destination);
      }
      if (json.referenceNumberDescription == null) {
        this.referenceNumberDescription = '';
      }
    }
    if (!this.status) {
      this.status = {code: 0};
    }
  }

  clone() {
    return new LoadSummary(this.toJson());
  }

  isDelivered(): boolean {
    return this.status.code === LoadStatus.Delivered;
  }

  isTendered(): boolean {
    return this.status.code === LoadStatus.Tendered;
  }

  assignDriver(driver: Driver) {
    this.driverFirstName = driver.firstName;
    this.driverLastName = driver.lastName;
  }

  updateTrailerNumber(trailerNumber: string) {
    this.trailerNumber = trailerNumber;
  }

  updateTractorNumber(tractorNumber: string) {
    this.tractorNumber = tractorNumber;
  }

  updateProNumber(proNumber: string) {
    this.proNumber = proNumber;
  }

  clearTrackingStatus() {
    this.trackingMessageCode = TrackingMessageType.Pending;
  }

  isTrackingActionNeeded(): boolean {
    return outsideOfAppMessageCodes.indexOf(this.trackingMessageCode) !== -1 || insideOfAppMessageCodes.indexOf(this.trackingMessageCode) !== -1;
  }

  getDriverFullName(): string {
    return (this.driverFirstName + ' ' + this.driverLastName).trim();
  }

  getStatus(): number {
    return this.status.code;
  }

  getReferenceNumbers(): ReferenceDataJSON[] {
    const numbers = this.referenceNumberDescription.split('|');
    let referenceNumbers: ReferenceDataJSON[] = [];
    let description: string;

    numbers.forEach((item, index) => {
      if (index % 2 === 0) {
        if (index !== (numbers.length - 1)) {
          description = item;
        }
      } else {
        referenceNumbers.push({description: description, code: item});
      }
    });

    if (this.proNumber) {
      referenceNumbers = [{description: 'Pro Number', code: this.proNumber}, ...referenceNumbers];
    }

    return referenceNumbers;
  }

  toJson(): LoadSummaryJSON {
    const response: any = Object.assign({}, this);
    return Object.assign(response, {
      expectedPickupDate: this.convertDateToDateString(this.expectedPickupDate),
      deliverByDate: this.convertDateToDateString(this.deliverByDate),
      imdlDate: this.convertDateToDateString(this.imdlDate),
      origin: this.origin.toJson(),
      destination: this.destination.toJson(),
    });
  }

  getExpectedPickupTime(): Date {
    if (this.bookType === BookType.IntermodalDelivery || this.bookType === BookType.IntermodalCrosstown) {
      return;
    }
    if (this.bookType === BookType.IntermodalPickup) {
      if (this.imdlDate) {
        return this.imdlDate;
      }
    }
    return this.expectedPickupDate;
  }

  getExpectedDeliveryTime(): Date {
    if (this.bookType === BookType.IntermodalPickup || this.bookType === BookType.IntermodalCrosstown) {
      return;
    }
    if (this.bookType === BookType.IntermodalDelivery) {
      if (this.imdlDate) {
        return this.imdlDate;
      }
    }
    return this.deliverByDate;
  }

  isInternational() {
    return this.loadType === LoadType.International;
  }
}
