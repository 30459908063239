import { useCallback } from 'react';
import { DataDogLogger } from '../DataDogLogger';
import { ActionBase, CarrierAction } from '../actions';

/**
 * Custom hook to use the trackAction method from DataDogLogger
 * @returns 
 * 
 * @example 
    const trackAction = useAction();

    const handleButtonClick = () => {
        const action: DDAuthAction = 'USER_CLICKED_BUTTON';
        const context: ActionBase = { userName: 'joeybalognia', userId: '123', email: 'joe@email.net' };
        trackAction(action, context);
    };
 */
export const useAction = () => {
	// Ensure DataDogLogger is initialized
	if (!DataDogLogger['isInitialized']) {
		DataDogLogger.initialize();
	}

	// Define the trackAction method to be used in React components
	const trackAction = useCallback((evt: CarrierAction, context?: ActionBase | any) => {
		DataDogLogger.trackAction(evt, context);
	}, []);

	return trackAction;
};
