import { LoadSummary } from 'features/my-loads-v2/types';

export const updateItemInLoadsList = (listToUpdate: LoadSummary[], updatedItem: any, indexToUpdate: number): LoadSummary[] => {
 if (indexToUpdate !== -1) {
  listToUpdate = listToUpdate.map((item, index) => {
   if (index === indexToUpdate) {
    return {
     ...item,
     ...updatedItem,
    };
   }
   return item;
  });
 }
 return listToUpdate;
};

export const getIndexToUpdate = (listToUpdate: LoadSummary[], shipmentId: any, bookId: any): number => {
 return bookId || bookId == 0
  ? listToUpdate.findIndex(item => item.loadNumber == shipmentId && item.bookId == bookId)
  : listToUpdate.findIndex(item => item.loadNumber == shipmentId);
};

export const getUpdatedLoadListResult = (listToUpdate: LoadSummary[], state: any) => {
 return {
  ...state,
  loadsList: listToUpdate,
 };
};

export const scrollToActiveLoad = (loadNumber: number, bookId: string) => {
 const loadHTMLId = document.getElementById(`load-card-${loadNumber}_${bookId}`);
 const mainContent = document.getElementById('main-content');

 if (loadHTMLId && mainContent) {
  requestAnimationFrame(() => {
   const pageTop = mainContent.scrollTop;
   const pageBottom = pageTop + mainContent.clientHeight;
   const elementTop = loadHTMLId.offsetTop;
   const elementBottom = elementTop + loadHTMLId.scrollHeight;
   const elementHigher = elementTop < pageTop;
   const elementLower = elementBottom > pageBottom;
   const marginOffset = 25;
   if (elementHigher || elementLower) {
    const offset = elementHigher ? elementTop - pageTop - marginOffset : elementBottom - pageBottom + marginOffset;
    mainContent.scrollBy({ top: offset, behavior: 'smooth' });
   }
  });
 }
};
