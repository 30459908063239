export class StopAddress {
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  county?: string;
  country?: string;          // "United States"
  stateCode?: string;         // "MN"
  stateProvinceCode?: string;         // "MN"
  countryCode: string;       // "US"
  geolocation?: GeoLocationJSON;

  constructor(json?: StopAddressJSON) {
    if (json) {
      this.address1 = json.line1;
      this.address2 = json.line2;
      this.city = json.city;
      this.county = json.county;
      this.stateCode = json.stateProvinceCode;
      this.zipCode = json.zipCode;
      this.country = json.country;
      this.countryCode = json.countryCode;
      this.geolocation = json.geolocation;
    }

    if (!this.geolocation) {
      this.geolocation = {latitude: 0, longitude: 0};
    }
  }

  toJson(): StopAddressJSON {
    return {
      line1: this.address1,
      line2: this.address2,
      city: this.city,
      county: this.county,
      stateProvinceCode: this.stateCode,
      zipCode: this.zipCode,
      country: this.country || this.countryCode,
      countryCode: this.countryCode,
      geolocation: this.geolocation
    };
  }

  toLoadSummaryAddressJson() {
    return {
      streetName: this.address1,
      street2: this.address2,
      cityName: this.city,
      county: this.county,
      stateCode: this.stateCode,
      zipcode: this.zipCode,
      country: this.country
    };
  }
}
