/**
 * User registration actions for observability
 */
export enum RegistrationAction {
 EmailVerified = 'Registration.EmailVerified',
 EmailVerificationFailed = 'Registration.EmailVerificationFailed',
 EmailTaken = 'Registration.EmailTaken',
 SetCulture = 'Registration.SetCulture',
 OktaPasswordSet = 'Registration.OktaPasswordSet',
 Complete = 'Registration.Complete',
 Error = 'Registration.Error',
}
