export const isDetailView = (state = 0, action) => {
  switch (action.type) {

    case 'REGISTER_DETAIL_VIEW':
      return state + 1;
    case 'UNREGISTER_DETAIL_VIEW':
      return state - 1;
    default:
      return state;
  }
}
