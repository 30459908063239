import { CapLoadDetail } from 'shared/models/cap/cap-load-detail.model';
import { BaseModel } from 'shared/models/base.model';

export class CapLoadDetails extends BaseModel {
  loadDetails: Array<CapLoadDetail>;

  constructor(json?) {
    super(json);
    this.loadDetails = json?.map(detail => new CapLoadDetail(detail));
  }
}
