import { Container, Service } from 'typedi';

@Service()
export class MedalliaTokenRepository {
  constructor() {
    window['medallia_params'] = window['medallia_params'] || {
      environment: Container.get('appConstants.buildEnvironment')
    }
  }

  private get storage() {
    return window['medallia_params'];
  }

  public set(key: keyof typeof medallia_params, value: string|number) {
    this.storage[key] = value;
  }

  public get(key: string): string {
    return this.storage[key] ?? null;
  }

  public remove(key: string) {
    delete this.storage[key];
  }

  clearAll() {
    window['medallia_params'] = {environment: Container.get('appConstants.buildEnvironment')}
  }
}
