// Types - only for analytic events
export enum AnalyticsActionTypes {
  // Login
  SET_LOGIN_VIEW_EVENT = 'SET_LOGIN_VIEW_EVENT',
  SET_LOGIN_SUBMIT_EVENT = 'SET_LOGIN_SUBMIT_EVENT',
  SET_LOGIN_SUCCESS_EVENT = 'SET_LOGIN_SUCCESS_EVENT',
  SET_LOGIN_FAILURE_EVENT = 'SET_LOGIN_FAILURE_EVENT',
  // Search
  SET_SUBMIT_SEARCH_EVENT = 'SET_SUBMIT_SEARCH_EVENT',
  SET_SEARCH_RESULTS_EVENT = 'SET_SEARCH_RESULTS_EVENT',
  SET_INITIAL_SEARCH_CORRELATION_ID = 'SET_INITIAL_SEARCH_ID',
  SET_RECOMMENDED_LOAD_LIST_VIEWED_EVENT = 'SET_RECOMMENDED_LOAD_LIST_VIEWED_EVENT',
  SET_RECENT_SEARCH_SELECTION_EVENT = 'SET_RECENT_SEARCH_SELECTION_EVENT',
  // Sort
  SET_SORT_ACTION_EVENT = 'SET_SORT_ACTION_EVENT',
  // Reloads
  SET_RELOADS = 'SET_RELOADS_ACTION_EVENT',
  SET_LD_USER_IDENTIFIED = 'SET_LD_USER_IDENTIFIED',
  // Cash Advance
  SET_CASH_ADVANCE_LOAD = 'SET_CASH_ADVANCE_LOAD',
  SET_CASH_ADVANCE_OPTIONS = 'SET_CASH_ADVANCE_OPTIONS',
  SET_CASH_ADVANCE_SUBMIT_EVENT = 'SET_CASH_ADVANCE_SUBMIT_EVENT',
  SET_CASH_ADVANCE_SUCCESS_EVENT = 'SET_CASH_ADVANCE_SUCCESS_EVENT',
  SET_CASH_ADVANCE_ERROR_EVENT = 'SET_CASH_ADVANCE_ERROR_EVENT',
  SET_CASH_ADVANCE_RESET = 'SET_CASH_ADVANCE_RESET',
  SET_CASH_ADVANCE_REQUEST_EVENT = 'SET_CASH_ADVANCE_REQUEST_EVENT',
  SET_CASH_ADVANCE_CONTINUE_EVENT = 'SET_CASH_ADVANCE_CONTINUE_EVENT',
  SET_CASH_ADVANCE_REVIEW_CONTINUE_EVENT = 'SET_CASH_ADVANCE_REVIEW_CONTINUE_EVENT',
  SET_CASH_ADVANCE_VIEW_SUCCESS_EVENT = 'SET_CASH_ADVANCE_VIEW_SUCCESS_EVENT',
  SET_CASH_ADVANCE_VIEW_ERROR_EVENT = 'SET_CASH_ADVANCE_VIEW_ERROR_EVENT',
  SET_CASH_ADVANCE_VIEW_RESET = 'SET_CASH_ADVANCE_VIEW_RESET',
}
