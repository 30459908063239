import { BaseModel } from 'shared/models/base.model';
import { CapAdvantageScore } from 'shared/models/cap/cap-advantage-score.model';

export class CarrierCapAdvantageScores extends BaseModel {
  carrierCode: string;
  trendingScore: CapAdvantageScore;
  currentScore: CapAdvantageScore;

  constructor(json?: CarrierCapAdvantageScoresJSON) {
    super(json);

    this.trendingScore = new CapAdvantageScore(json.trendingScore);
    this.currentScore = new CapAdvantageScore(json.currentScore);
  }
}
