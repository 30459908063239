import { BaseModel } from 'shared/models/base.model';
import { CertificationType, CertificationStatus } from 'shared/enums/enums';

export class CarrierCertification extends BaseModel<CarrierCertificationJSON> implements CarrierCertificationInterface {
  type: CertificationType;
  description: string;
  isQualified: boolean;
  certification: CarrierCertificate;

  constructor(json: CarrierCertificationJSON) {
    super(json);
    if (json) {
      this.certification = new CarrierCertificate(json.certification);
    }
  }

  toJson(): CarrierCertificationJSON {
    return {
      ...super.toJson(),
      certification: this.certification.toJson()
    };
  }
}

export class CarrierCertificate extends BaseModel<CarrierCertificateJSON> implements CarrierCertificateInterface {
  status: CertificationStatus;
  expirationDateTime: Date;
  effectiveDateTime: Date;
  registrationNumber: string;

  constructor(json: CarrierCertificateJSON) {
    super(json);
    if (json) {
      this.expirationDateTime = this.processDate(json.expirationDateTime);
      this.effectiveDateTime = this.processDate(json.effectiveDateTime);
    }
  }

  toJson(): CarrierCertificateJSON {
    return {
      ...super.toJson(),
      expirationDateTime: this.convertDateToDateString(this.expirationDateTime),
      effectiveDateTime: this.convertDateToDateString(this.effectiveDateTime),
    };
  }
}

