import React from 'react';
import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { Translation } from 'shared/components/translation/translation.component';

interface OwnProps {
  required?: boolean;
  className?: string;
  resource?: ResourceKey;
}

type Props = {[key: string]: any} & OwnProps;

export const Label: FunctionComponent<Props> = ({resource, required, className, ...props}) =>
  <label {...props} className={classNames(className, {required})}>
    {Boolean(resource) && <Translation resource={resource}/>}
    {props.children}
  </label>;
