import { ExtendedLoad } from 'shared/models/loads/extended-load.model';
import { SpotBidPolicyPermission } from 'shared/models/spot-bids/spot-bid-policy-permissions.enum';
import { SpotBidProperties } from 'shared/models/spot-bids/spot-bid-properties.model';
import { SpotBidCharge } from 'shared/models/spot-bids/spot-bid-charge.model';
import { RateType } from 'shared/enums/rate-type.enum';

export class SpotBidDetail extends ExtendedLoad {
  spotBidProperties: SpotBidProperties;
  chrContact: SpotBidContactJSON;
  webContacts: SpotBidContactJSON[];
  weightTix: boolean;
  dropTrailer: boolean;

  hasPermission(permission: SpotBidPolicyPermission) {
    if (!this.spotBidProperties || !this.spotBidProperties.partyPolicyPermissions) {
      return false;
    }
    const permissions = this.spotBidProperties.partyPolicyPermissions;

    return Boolean(permissions.find(policy => policy.partyPolicyPermissionType === permission));
  }

  hasTrucksRequested() {
    return (
      this.spotBidProperties.numberOfTrucksRequested != null
      && Number(this.spotBidProperties.numberOfTrucksRequested) > 0
    );
  }

  constructor(json: SpotBidDetailsJSON) {
    super(json);
    this.spotBidProperties = new SpotBidProperties(json.spotBidProperties);
  }

  hasContractedRate(): boolean {
    return this.spotBidProperties.costQuoteId !== 0;
  }

  get customRateCodes(): { code: string; name: string; } [] {
    return this.hasContractedRate()
      ? this.spotBidProperties.accessorialCodes
        .filter(accessorial => accessorial.code !== '400')
      : this.spotBidProperties.accessorialCodes
        .filter(accessorial => accessorial.code === '400');

  }

  get firstCustomRateCode(): {code: string; name: string} {
    return this.customRateCodes[0];
  }

  isContractOnly(): boolean {
    return this.spotBidProperties.isContractOnly;
  }

  get contractCharge(): SpotBidCharge {
    if (!this.hasContractedRate()) {
      throw new Error('Spot bid does not have a contract associated with it.');
    }
    return this.spotBidProperties.spotBidCharges.find(charge => charge.rateCode === '400' || charge.rateCode === '405');
  }

  getContractRateType(): RateType {
    if (this.hasContractedRate()) {
      return this.contractCharge.getRateType();
    }
    return RateType.FlatRate;
  }

  getContractedRatePerUnit(): number {
    if (this.hasContractedRate()) {
      return this.contractCharge.getRatePerUnit();
    }
  }

  getContractedRate(): number {
    if (this.hasContractedRate()) {
      return this.contractCharge.getTotalRate();
    }
  }

  getContractUnits(): number {
    if (this.hasContractedRate()) {
      return this.contractCharge.getUnits();
    }
    return 1;
  }

  hasExistingOffer(): boolean {
    return Boolean(this.getExistingCharges().length);
  }

  getExistingCharges(): SpotBidCharge[] {
    return this.spotBidProperties.spotBidCharges.filter(charge => charge.chargeId !== -1);
  }

  getExistingOfferTotal(): number {
    return this.getExistingCharges().reduce((acc, charge) => acc + Number(charge.getTotalRate()), 0);
  }

}
