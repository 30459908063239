import { UserPermission } from 'shared/enums/user/user-permission.enum';

export class Roles {
  public CarrierTasks = false;
  public FindLoads = false;
  public PostTrucks = false;
  public FreightAlerts = false;
  public MyLoads = false;
  public LoadBoard = false;
  public TMC = false;
  public TMCReports = false;
  public CHRTenders = false;
  public CHRWTenders = false;
  public SpotBids = false;
  public Rates = false;
  public AR = false;
  public ARAdmin = false;
  public ManageInvoices = false;
  public PreferredLanes = false;
  public EmailProfile = false;
  public CarrierProfile = false;
  public Admin = false;
  public Impersonate = false;
  public ImpersonateAll = false;
  public CashAdvanceAdmin = false;
  public TMCLogo = false;
  public Announcements = true;
  public DockMgr = false;
  public DockMgrAdmin = false;
  public IsInternal = false;
  public MyShipments = false;

  constructor(permissions: UserPermission[]) {
    permissions.forEach((permission: UserPermission) => {
      const role = UserPermission[permission];

      if (role) {
        this[role] = true;
      }
    });
  }
}
