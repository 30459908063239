import { LDClient } from 'launchdarkly-js-client-sdk';
import { Action, AnyAction } from 'redux';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { LOCATION_CHANGE, LocationChangeAction, push, RouterAction } from 'connected-react-router';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import { Container } from 'typedi';

import * as a from './find-loads-base.actions';
import { AppRoute } from 'app/routesEnum';
import { NavCarrierEpic } from '../../nav-carrier-epic.interface';
import { AvailableLoadSearchType } from 'shared/enums/available-loads/search-type.enum';
import {
  generateAvailableLoadsQueryString,
  generateAvailableLoadsReloadsQueryString,
  parseAvailableLoadsQueryString
} from 'providers/available-load-query-string.provider';
import { setSearchType } from 'shared/find-loads/redux/find-loads.actions';

type FindLoadsEpic<OutputAction extends Action = AnyAction> = NavCarrierEpic<OutputAction, null>;

// Given a location change, if it is to /find-loads?{search criteria} then redirect to /find-loads/single or /find-loads/multi
const processIncompleteFindLoadsURLChanges: FindLoadsEpic<AnyAction> = (action$, state$) =>
  action$.ofType(LOCATION_CHANGE)
    .filter(({payload}: LocationChangeAction) => payload.location.pathname === AppRoute.FIND_LOADS_BASE)
    .map(({payload}) => parseAvailableLoadsQueryString(payload.location.search))
    .map(criteria =>
      (criteria?.destinationStateCodes)
            ? AvailableLoadSearchType.MULTI
            : AvailableLoadSearchType.SINGLE
    )
    .mergeMap(searchType =>
      ActionsObservable.from([
        setSearchType(searchType),
        push(`${AppRoute.FIND_LOADS_BASE}/${searchType}${state$.value.router.location.search}`)
      ])
    );

// Given a location change, set the search type in the store
const setSearchTypeOnRouteChanges: FindLoadsEpic<AnyAction> = (action$) =>
  action$.ofType<LocationChangeAction>(LOCATION_CHANGE)
    .filter(({payload}) => payload.location.pathname !== AppRoute.FIND_LOADS_BASE)
    .filter(({payload}) => payload.location.pathname.startsWith(AppRoute.FIND_LOADS_BASE))
    .map(({payload}) => payload.location.pathname.split('/').pop())
    .map(searchType => setSearchType(searchType as AvailableLoadSearchType));

export const pushAvailableLoadsSearchLoadNumberEpic: FindLoadsEpic<AnyAction> = action$ => {

  return action$.ofType(a.PUSH_AVAILABLE_LOAD_SEARCH_lOAD_NUMBER_URL)
    .map(({criteria}) => criteria && {
      criteria,
      loadNumber: criteria.loadNumber
    })
    .map(({loadNumber}) => push(`/find-loads/single?loadNumber=${loadNumber}`));
};

export const pushViewAllPostBookReloadsEpic: FindLoadsEpic = action$ =>
  action$.ofType(a.PUSH_VIEW_ALL_POST_BOOK_RELOADS_URL)
    .filter(() => Container.get<LDClient>('LD_CLIENT').allFlags()['post-book-reloads'])
    .map(({criteria}) => push(`/find-loads/reloads${generateAvailableLoadsReloadsQueryString(criteria)}`));

/**
 * This will update the search criteria in the URL, which will dispatch a LOCATION_CHANGE action to redux
 * The retrieveSearchParamsFromQueryStringEpic listens to this LOCATION_CHANGE and dispatches PROCESS_AVAILABLE_LOADS_SEARCH_PARAMS
 * The find-loads.epic will handle this action to convert the query string back into a search criteria object (weee) and dispatch PROCESS_INCOMING_CRITERIA
 * The processIncomingCriteriaEpic will derive the search type (SINGLE, MULTI) and dispatch APPLY_FIND_LOADS_SEARCH
 * The APPLY_FIND_LOADS_SEARCH action is listened to by three different epics. One to set the search type in state, one to set the search critiera in state, and one to call the available loads api.
 */
export const pushAvailableLoadsSearchURLEpic: FindLoadsEpic<RouterAction> = action$ => {
  return action$.ofType(a.PUSH_AVAILABLE_LOAD_SEARCH_URL)
    .map(({criteria}) => criteria && {
      criteria,
      searchType: criteria.destinationStateCodes ? AvailableLoadSearchType.MULTI : AvailableLoadSearchType.SINGLE
    })
    .mergeMap(async ({criteria, searchType}) => ({queryString: await generateAvailableLoadsQueryString(criteria), searchType}))
    .map(({queryString, searchType}) => push(`/find-loads/${searchType}${queryString}`));
}

// All find loads epics
export const findLoadsBaseEpic: FindLoadsEpic = (action$, state$) => combineEpics(
  processIncompleteFindLoadsURLChanges,
  pushAvailableLoadsSearchLoadNumberEpic,
  pushAvailableLoadsSearchURLEpic,
  setSearchTypeOnRouteChanges,
  pushViewAllPostBookReloadsEpic,
)(action$, state$, null);
