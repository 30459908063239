import { BaseModel } from 'shared/models/base.model';

interface Employee {
  branchCode: string;
  branchName: string;
  branchPhone: string;
  contactName: string;
  email: string;
  firstName: string;
  isActive: boolean;
  lanId: string;
  lastName: string;
  workPhone: string;
}

interface VoucherRate {
  rateCode: string;
  currencyCode: string;
  type: string;
  description: string;
  units: number;
  perUnit: number;
  extendedRate: number;
}

interface VoucherTransaction {
  checkNumber: number;
  companyPaid: string;
  amount: number;
  currencyCode: string;
  paidDate: Date;
  type: string;
  notes: string;
}

export class CarrierVoucherDetail extends BaseModel {
  voucherDocumentId: number;
  voucherId: number;
  loadNumber: number;
  carrierCode: string;
  companyReferenceNumber: string;
  contact: Employee;
  rates: VoucherRate[];
  payments: VoucherTransaction[];
  adustments: VoucherTransaction[];

  constructor(json) {
    super(json);

    if (!this.rates) {
      this.rates = [];
    }

    if (!this.payments) {
      this.payments = [];
    }

    if (!this.adustments) {
      this.adustments = [];
    }
  }

  toJson() {
    return {
      ...super.toJson()
    };
  }
}
