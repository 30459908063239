import { BaseModel } from 'shared/models/base.model';

export class LoadProblemLog extends BaseModel {
  enteredBy?: string;
  enteredDateTime?: Date;
  notes?: string;

  private closed?: boolean;

  get isClosed(): boolean {
    return this.closed;
  }
  set isClosed(value: boolean) {
    this.closed = value;
  }

  constructor(json?: LoadProblemLogJSON) {
    super(json);
    if (json?.enteredDateTime) {
      this.enteredDateTime = this.processDate(json.enteredDateTime);
    } else {
      this.enteredDateTime = new Date();
    }
  }

  toJson(): LoadProblemLogJSON {
    return {
      closed: this.closed,
      enteredBy: this.enteredBy,
      enteredDateTime: this.convertDateToDateString(this.enteredDateTime),
      notes: this.notes
    };
  }
}
