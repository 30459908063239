export const StatesUS = [
  {
    'code': 'AA',
    'id': 1079,
    'name': 'Armed Forces Americas'
  },
  {
    'code': 'AE',
    'id': 1080,
    'name': 'Armed Forces Europe, Canada, Middle East, Africa'
  },
  {
    'code': 'AK',
    'id': 911,
    'name': 'Alaska'
  },
  {
    'code': 'AL',
    'id': 912,
    'name': 'Alabama'
  },
  {
    'code': 'AP',
    'id': 1081,
    'name': 'Armed Forces Pacific'
  },
  {
    'code': 'AR',
    'id': 913,
    'name': 'Arkansas'
  },
  {
    'code': 'AS',
    'id': 914,
    'name': 'American Samoa (see also separate entry under AS)'
  },
  {
    'code': 'AZ',
    'id': 915,
    'name': 'Arizona'
  },
  {
    'code': 'CA',
    'id': 916,
    'name': 'California'
  },
  {
    'code': 'CO',
    'id': 917,
    'name': 'Colorado'
  },
  {
    'code': 'CT',
    'id': 918,
    'name': 'Connecticut'
  },
  {
    'code': 'DC',
    'id': 919,
    'name': 'District of Columbia'
  },
  {
    'code': 'DE',
    'id': 920,
    'name': 'Delaware'
  },
  {
    'code': 'FL',
    'id': 921,
    'name': 'Florida'
  },
  {
    'code': 'GA',
    'id': 922,
    'name': 'Georgia'
  },
  {
    'code': 'GU',
    'id': 923,
    'name': 'Guam (see also separate entry under GU)'
  },
  {
    'code': 'HI',
    'id': 924,
    'name': 'Hawaii'
  },
  {
    'code': 'IA',
    'id': 925,
    'name': 'Iowa'
  },
  {
    'code': 'ID',
    'id': 926,
    'name': 'Idaho'
  },
  {
    'code': 'IL',
    'id': 927,
    'name': 'Illinois'
  },
  {
    'code': 'IN',
    'id': 928,
    'name': 'Indiana'
  },
  {
    'code': 'KS',
    'id': 929,
    'name': 'Kansas'
  },
  {
    'code': 'KY',
    'id': 930,
    'name': 'Kentucky'
  },
  {
    'code': 'LA',
    'id': 931,
    'name': 'Louisiana'
  },
  {
    'code': 'MA',
    'id': 932,
    'name': 'Massachusetts'
  },
  {
    'code': 'MD',
    'id': 933,
    'name': 'Maryland'
  },
  {
    'code': 'ME',
    'id': 934,
    'name': 'Maine'
  },
  {
    'code': 'MI',
    'id': 935,
    'name': 'Michigan'
  },
  {
    'code': 'MN',
    'id': 936,
    'name': 'Minnesota'
  },
  {
    'code': 'MO',
    'id': 937,
    'name': 'Missouri'
  },
  {
    'code': 'MP',
    'id': 938,
    'name': 'Northern Mariana Islands (see also separate entry MP)'
  },
  {
    'code': 'MS',
    'id': 939,
    'name': 'Mississippi'
  },
  {
    'code': 'MT',
    'id': 940,
    'name': 'Montana'
  },
  {
    'code': 'NC',
    'id': 941,
    'name': 'North Carolina'
  },
  {
    'code': 'ND',
    'id': 942,
    'name': 'North Dakota'
  },
  {
    'code': 'NE',
    'id': 943,
    'name': 'Nebraska'
  },
  {
    'code': 'NH',
    'id': 944,
    'name': 'New Hampshire'
  },
  {
    'code': 'NJ',
    'id': 945,
    'name': 'New Jersey'
  },
  {
    'code': 'NM',
    'id': 946,
    'name': 'New Mexico'
  },
  {
    'code': 'NV',
    'id': 947,
    'name': 'Nevada'
  },
  {
    'code': 'NY',
    'id': 948,
    'name': 'New York'
  },
  {
    'code': 'OH',
    'id': 949,
    'name': 'Ohio'
  },
  {
    'code': 'OK',
    'id': 950,
    'name': 'Oklahoma'
  },
  {
    'code': 'OR',
    'id': 951,
    'name': 'Oregon'
  },
  {
    'code': 'PA',
    'id': 952,
    'name': 'Pennsylvania'
  },
  {
    'code': 'PR',
    'id': 953,
    'name': 'Puerto Rico (see also separate entry under PR)'
  },
  {
    'code': 'RI',
    'id': 954,
    'name': 'Rhode Island'
  },
  {
    'code': 'SC',
    'id': 955,
    'name': 'South Carolina'
  },
  {
    'code': 'SD',
    'id': 956,
    'name': 'South Dakota'
  },
  {
    'code': 'TN',
    'id': 957,
    'name': 'Tennessee'
  },
  {
    'code': 'TX',
    'id': 958,
    'name': 'Texas'
  },
  {
    'code': 'UM',
    'id': 959,
    'name': 'U.S. Minor Outlying Islands (cf. separate entry UM)'
  },
  {
    'code': 'UT',
    'id': 960,
    'name': 'Utah'
  },
  {
    'code': 'VA',
    'id': 961,
    'name': 'Virginia'
  },
  {
    'code': 'VI',
    'id': 962,
    'name': 'Virgin Islands of the U.S. (see also separate entry VI)'
  },
  {
    'code': 'VT',
    'id': 963,
    'name': 'Vermont'
  },
  {
    'code': 'WA',
    'id': 964,
    'name': 'Washington'
  },
  {
    'code': 'WI',
    'id': 965,
    'name': 'Wisconsin'
  },
  {
    'code': 'WV',
    'id': 966,
    'name': 'West Virginia'
  },
  {
    'code': 'WY',
    'id': 967,
    'name': 'Wyoming'
  }
];
