import { Action } from 'redux';

export interface CultureCookie {
  locale: string;
  country: string;
  default?: boolean;
}

export interface SetCultureAction extends Action {
  culture: Culture;
}

export interface SaveCultureAction extends Action  {
  culture: Culture;
}

export class Culture {
  constructor(
    readonly country: GlobalizationCountry,
    readonly locale: GlobalizationCultureLanguage,
    readonly isDefault: boolean = false
  ) {}

  toCookie(): CultureCookie {
    return {
      locale: this.locale.locale,
      country: this.country.code
    };
  }
}

export const SET_CULTURE = 'SET_CULTURE';
export const SAVE_CULTURE = 'SAVE_CULTURE';
export const INIT_CULTURE = 'INIT_CULTURE';

export const setCulture = (culture: Culture) => ({type: SET_CULTURE, culture});
export const saveCulture = (culture: Culture) => ({type: SAVE_CULTURE, culture});
export const initCulture = () => ({type: INIT_CULTURE});
