import { Action } from 'redux';
import { combineEpics } from 'redux-observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/ignoreElements';

import * as a from 'features/security/auth.actions';
import { NavCarrierEpic } from '../nav-carrier-epic.interface';

export const setUserData: NavCarrierEpic<never> = action$ =>
  action$.ofType<a.StoreCredentialsAction>(a.STORE_CREDENTIALS)
    .filter(({user}) => Boolean(user?.getCarrier()?.regionCode))
    .filter(() => window['DD_RUM'] != null)
    .do(({user, carrier}) =>
      window['DD_RUM'].setUser({
        id: user.userId.toString(),
        username: user.username,
        carrierCode: carrier.carrierCode,
        name: user.firstName + ' ' + user.lastName,
        companyName: carrier.name,
      })
    )
    .ignoreElements();

export const cleanupUserOnLogoutEpic: NavCarrierEpic<never> = action$ =>
  action$.ofType<Action>(a.LOGOUT)
    .filter(() => window['DD_RUM'] != null)
    .do(() => window['DD_RUM'].clearUser())
    .ignoreElements();

export const datadogEpics = combineEpics(
  setUserData,
  cleanupUserOnLogoutEpic,
);
