import moment from 'moment';

import { BaseModel } from 'shared/models/base.model';

export enum InsuranceType {
  AutoLiability = 1,
  Cargo = 2,
  CargoInternational = 3,
  CargoDomestic = 4
}

export enum InsuranceStatus {
  Valid,
  Expired,
  ExpiresSoon
}

export class CarrierInsurance extends BaseModel<CarrierInsuranceJSON> {
  insuranceExpiration: Date;
  insuranceType: InsuranceType;
  insuranceStatus: InsuranceStatus;
  constructor(json: CarrierInsuranceJSON) {
    super(json);
    if (json.insuranceExpiration) {
      this.insuranceExpiration = this.processDate(json.insuranceExpiration);
    }
  }

  toJson(): CarrierInsuranceJSON {
    return {
      ...super.toJson(),
      insuranceExpiration: this.convertDateToDateString(this.insuranceExpiration)
    };
  }
  get daysUntilExpiration(): number {
    const today = moment().startOf('day');
    return Math.abs(moment(this.insuranceExpiration).diff(today, 'days'));
  }

  get typeOfInsurance(): number {
    return this.insuranceType;
  }
}
