import { useEffect, ReactNode } from 'react';
import { datadogRum } from '@datadog/browser-rum';
import { dataDogRUMConfig } from './';
import { DataDogLogger } from './DataDogLogger';

interface Props extends JSX.IntrinsicAttributes {
	children: ReactNode | ReactNode[] | any;
}

export const DataDogProvider = ({ children }: Props) => {
	const _ctx = datadogRum.getInternalContext();

	useEffect(() => {
		datadogRum.init(dataDogRUMConfig);
	}, []);

	useEffect(() => {
		if (_ctx) {
			DataDogLogger.initialize();
		}
	}, [_ctx]);

	return <>{children}</>;
};
