import { LoadDetail } from 'shared/models/my-loads/loads/load-detail.model';
import { Tender } from 'shared/models/my-loads/tenders/tender.model';
import { Util } from 'app/util/util';

const bySequence = Util.sortByField('tenderSequenceNumber');

/** NavisphereCarrier.MyLoads.ServiceModel.Loads.TenderLoadDetail */
export class TenderLoadDetail extends LoadDetail<MyLoads.TenderLoadDetailJSON> {
  readonly tenders: Tender[];

  constructor(json: MyLoads.TenderLoadDetailJSON) {
    super(json);

    this.tenders = json.tenders.sort(bySequence).map(tender => new Tender(tender));
  }
}
