import { BaseModel } from 'shared/models/base.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Loads.LoadSettings */
export class LoadSettings extends BaseModel<MyLoads.LoadSettingsJSON> {
  readonly isManagedTransportation?: boolean;
  readonly isThirdPartyLoad?: boolean;
  readonly isRegulatedByStf?: boolean;
  readonly allowDateRangeMatchFlag?: boolean;
  readonly hasAutoOpenOverride?: boolean;
  readonly hasDocuments?: boolean;
  readonly isAutoTenderSuspended?: boolean;
  readonly isBlindRequired?: boolean;
  readonly isClaimOnLoad?: boolean;
  readonly isCTPAT?: boolean;
  readonly isDAT?: boolean;
  readonly isEDI?: boolean;
  readonly isGovAccount?: boolean;
  readonly isHazMat?: boolean;
  readonly isLockServiceOfferings?: boolean;
  readonly isLumperReceiptRequired?: boolean;
  readonly isMultiLeg?: boolean;
  readonly isOpenToCoalition?: boolean;
  readonly isOrderItems?: boolean;
  readonly isPaperWorkRequired?: boolean;
  readonly isPooledYN?: boolean;
  readonly isPriority?: boolean;
  readonly isSealRequired?: boolean;
  readonly isTankerEndorsementRequired?: boolean;
  readonly isTeamFlag?: boolean;
  readonly isUnloadReceiptRequired?: boolean;
  readonly isWeb?: boolean;
  readonly isWeightTickets?: boolean;
  readonly minimumLogipoints?: number;
  readonly okToAdvertiseFlag?: boolean;
  readonly isBakerLooeExempt?: boolean;
  readonly reRateValue?: number;
}
