import { Action } from 'redux';
import { Container } from 'typedi';
import { Cookie } from 'ng2-cookies';
import { combineEpics } from 'redux-observable';
import { push, RouterAction } from 'connected-react-router';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/mapTo';

import * as a from '@features/security/auth.actions';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import { CacheableRepository } from 'app/repositories/cacheable.repository';
import { REQUEST_FAILURE, RequestFailureAPIErrorAction } from 'store/requests.actions';
import { LocalStorageRepository } from 'app/repositories/local-storage.repository';

export const logoutOnUnauthorizedErrorsEpic: NavCarrierEpic<Action> = (action$, state$) =>
  action$
    .filter(action => action.type.indexOf(REQUEST_FAILURE) === 0)
    .filter((action: RequestFailureAPIErrorAction) => action.error && (action.error.status === 401))
    .filter(() => state$.value.auth.isAuthenticated)
    .mapTo(a.logout());

export const redirectOnLogoutEpic: NavCarrierEpic<RouterAction> = action$ =>
  action$.ofType<Action>(a.LOGOUT)
    .mapTo(push('/login'));

export const clearCacheOnLogout: NavCarrierEpic<never> = action$ =>
  action$.ofType<Action>(a.LOGOUT)
    .do(() => CacheableRepository.clearCache())
    .ignoreElements();

export const clearMaintenanceDismissalOnLogout: NavCarrierEpic<never> = action$ =>
  action$.ofType<Action>(a.LOGOUT)
    .do(() => Cookie.delete('maintenance-dismissal'))
    .ignoreElements();

export const clearParentStorageOnLogout: NavCarrierEpic<never> = action$ =>
  action$.ofType<Action>(a.LOGOUT)
    .do(() => Container.get(LocalStorageRepository).clearParentStorage())
    .ignoreElements();

export const reloadPageOnLogout: NavCarrierEpic<never> = action$ =>
  action$.ofType<Action>(a.LOGOUT)
    .do(() => Container.get(LocalStorageRepository).reloadPage())
    .ignoreElements();

export const logoutEpics = combineEpics(
  logoutOnUnauthorizedErrorsEpic,
  clearMaintenanceDismissalOnLogout,
  redirectOnLogoutEpic,
  clearCacheOnLogout,
  clearParentStorageOnLogout,
  reloadPageOnLogout
);
