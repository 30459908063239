import { BaseModel } from 'shared/models/base.model';

export class ExtendedCommodity extends BaseModel {
  referenceNumbers: ReferenceNumberJSON[];
  customerCode: string;
  customerSpecific1: string;
  customerSpecific2: string;
  metersOfTrailer: number;
  volume: number;
  value: number;
  expectedMaxWeight: number;
  expectedMinWeight: number;
  expectedPallets: number;
  expectedPieces: number;
  actualPallets: number;
  actualPieces: number;
  actualWeight: number;
  exactHeight: number;
  exactWidth: number;
  exactLength: number;
  exactLengthInches: number;
  itemClass: number;
  freightClass: string;
  pickStop: number;
  dropStop: number;
  minTemp: number;
  maxTemp: number;
  ok: string;
  packaging: string;
  space: string;
  strapCount: number;
  tarpSize: string;

  private commodity: string;
  private hazMat: boolean;
  private itemNumber: number;
  private temperatureControlled: boolean;
  private hazInfo: string;
  private poNum: string;

  get poNumber(): string {
    return this.poNum;
  }

  set hazMatInfo(value: string) {
    this.hazInfo = value;
  }
  get hazMatInfo(): string {
    return this.hazInfo;
  }

  set isTemperatureControlled(value: boolean) {
    this.temperatureControlled = value;
  }
  get isTemperatureControlled(): boolean {
    return this.temperatureControlled;
  }

  set description(value: string) {
    this.commodity = value;
  }
  get description(): string {
    return this.commodity;
  }

  set isHazMat(value: boolean) {
    this.hazMat = value;
  }
  get isHazMat(): boolean {
    return this.hazMat;
  }

  set commodityNumber(value: number) {
    this.itemNumber = value;
  }
  get commodityNumber(): number {
    return this.itemNumber;
  }

  get totalLength() {
    return (this.exactLength * 12) + this.exactLengthInches;
  }

  get totalVolumeInches() {
    return (this.totalLength * this.exactHeight * this.exactWidth);
  }
  get spaceDisplayValue() {
    return (this.metersOfTrailer || this.space || 0).toLocaleString(undefined, {maximumFractionDigits: 3, minimumFractionDigits: 3});
  }

  constructor(json: ExtendedCommodityJSON) {
    super(json);
  }

  toJson(): ExtendedCommodityJSON {
    return (Object.assign({}, this)) as any;
  }

  getReferenceNumberValue(type: string) {
    if (this.referenceNumbers) {
      const referenceNumber = this.referenceNumbers.find(num => num.code === type);
      return referenceNumber?.value;
    }
  }
}
