import { AnyAction, combineReducers } from 'redux';

const dismissed = (state = false, action: AnyAction) => {
  switch (action.type) {
    case 'DISMISS_MAINTENANCE_NOTIFICATION':
      return true;
    default:
      return false;
  }
};

export const maintenance = combineReducers({dismissed});
