import { BaseModel } from 'shared/models/base.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Stops.StopTrailerInformation */
export class StopTrailerInformation extends BaseModel<MyLoads.StopTrailerInformationJSON> {
  readonly dropCloseDateTime: Date|null;
  readonly dropDateTime: Date|null;
  readonly isDropTrailer: boolean;
  readonly readyCloseDateTime: Date|null;
  readonly readyDateTime: Date|null;

  constructor(json: MyLoads.StopTrailerInformationJSON) {
    super(json);

    this.dropCloseDateTime = this.processDate(json.dropCloseDateTime);
    this.dropDateTime = this.processDate(json.dropDateTime);
    this.readyCloseDateTime = this.processDate(json.readyCloseDateTime);
    this.readyDateTime = this.processDate(json.readyDateTime);
  }
}
