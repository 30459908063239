export default function checkMembership(memberships: Array<Membership> = null) {
 const userId = JSON.parse(localStorage.getItem('okta-token-storage'))?.accessToken?.claims?.userId;
 const currentMembershipSelection = JSON.parse(localStorage.getItem('active-membership'));
 const defaultMembership = memberships?.find(s => s.isDefault) || null;
 const hasOnlyOneMembership = memberships?.length === 1;
 const shouldHideDialog = !!currentMembershipSelection || !!defaultMembership || hasOnlyOneMembership;

 // doesn't have a membership set or one from the database (supplied from the caller)
 const noMemberships = currentMembershipSelection == null || !defaultMembership;
 return { currentMembershipSelection, defaultMembership, userId, hasOnlyOneMembership, shouldHideDialog, noMemberships };
}
