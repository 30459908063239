import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import BusinessIcon from '@mui/icons-material/Business';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';

import { styled } from '@mui/material/styles';
import * as Styles from 'features/permissions/styles';

export interface Props {
  membership: Membership;
  isChecked: boolean;
  handleChange: () => any;
}

// need to manipulate the styles to have label fill width
const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  '& .MuiFormControlLabel-label': {
	width: '100%',
  },
}));

export const CompanySelectRadio = ({ membership, isChecked, handleChange}: Props) => {
  const { t } = useTranslation();

  return(
	<StyledFormControlLabel
	  onChange={handleChange}
	  checked={isChecked}
	  control={<Radio sx={Styles.RadioInput} />}
	  sx={isChecked ? Styles.SelectedRadioButton : Styles.RadioButton}
	  label={
		<Box sx={Styles.LabelContainer}>
		  <Box sx={Styles.LabelTextArea}>
			<Typography variant="subtitle2" sx={isChecked ? Styles.SelectedRadioHeader : {}}>
			  {membership?.attributes?.companyName}
			</Typography>
			<Typography variant="body2">
			  {membership?.companyCode}
			</Typography>
		  </Box>
		  <Box>
			{membership?.isDefault &&
			  <Stack direction='row' spacing={1}>
				<BusinessIcon />
				<Typography variant="subtitle2" sx={isChecked ? Styles.SelectedRadioHeader : {}}>
				  {t('DEFAULT')}
				</Typography>
			  </Stack>
			}
		  </Box>
		</Box>
	  }
	/>
  )
}