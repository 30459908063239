import { ReactElement } from 'react';
import { Action } from 'redux';

export const ToastActionTypes = {
    SHOW_TOAST: 'SHOW_TOAST',
    DISMISS_TOAST: 'DISMISS_TOAST'
};

export interface ShowToastAction extends Action {
    toast: ToastOptions;
}

export interface DismissToastAction extends Action {
    id: string;
}

export type FeedbackToastOptions = Partial<{
    id: string;
    timeout: number;
    buttons: (ReactElement<any> | ((id: string) => ReactElement<any>))[];
    className: string;
    header: ReactElement<any> | string;
    suppressHeader: boolean;
}>;

export interface RawToastOptions {
    id?: string;
    timeout?: number;
}

export interface ShowToastOptions {
    id?: string;
    timeout?: number;
    className?: string;
    buttons?: (ReactElement<any> | ((id: string) => ReactElement<any>))[];
    body: ReactElement<any>;
}

export interface ToastOptions {
    id: string;
    timeout: number;
    className?: string;
    buttons?: (ReactElement<any> | ((id: string) => ReactElement<any>))[];
    body: ReactElement<any>;
}

export const dismissToast = (id: string): DismissToastAction => ({
    type: ToastActionTypes.DISMISS_TOAST,
    id
});

export interface FeedbackToastDispatcher {
    (messages: (ReactElement<any> | string)[], options?: FeedbackToastOptions): string;
}

export interface RawToastDispatcher {
    (
        body: React.ReactElement<any>,
        buttons: (((id: string) => ReactElement<any>) | ReactElement<any>)[],
        className: string,
        rawOptions?: RawToastOptions
    ): string;
}
