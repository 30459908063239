import { createSlice } from '@reduxjs/toolkit';
import { AuthRepository } from 'app/repositories/auth.repository';
import { AuthStatus } from 'features/okta/types';
import { push } from 'connected-react-router';

const auth = new AuthRepository();

//  base urls to help you comfortably assert what environment you can be in.
//  developer debugging VS development environment
const isDev = ['localhost:3000', 'dev.navispherecarrier.com'].includes(window.location.host);

/**
 * @name initialState
 * @type IOkta
 */
const initialState: IOkta = {
 status: AuthStatus.CLOSED,
 isAuthenticated: false,
 logoutWarningVisible: false,
 tokens: {
  accessToken: null,
  idToken: null,
  refreshToken: null,
 },
 error: null,
 preferredEmailAddress: null,
 preferredEmailVerifiedDate: null,
};

const slice = createSlice({
 name: 'okta',
 initialState,
 reducers: {
  loggedIn: (state, { payload }) => {
   return {
    ...state,
    isAuthenticated: true,
    didLogin: true,
    ...payload,
   };
  },
  logout: state => {
   auth.clearRemoteLogin();
   auth.clearUserFromSession();
   return {
    ...state,
    isAuthenticated: false,
    userData: null,
    accessToken: null,
    idToken: null,
    logoutWarningVisible: false,
   };
  },
  toggleLogoutWarning: state => {
   return {
    ...state,
    logoutWarningVisible: !!state.logoutWarningVisible,
   };
  },
  setPreferredEmail: (state, { payload }) => {
   return {
    ...state,
    preferredEmailAddress: payload,
   };
  },
  setPreferredEmailVerifiedDate: (state, { payload }) => {
   return {
    ...state,
    preferredEmailVerifiedDate: payload,
   };
  },
 },
});

export const selectCarrierUser = (state: NavCarrierState): UserJSON => state.okta.carrierUser;

export const setLoggedIn = (userState: any) => (dispatch, store) => {
 console.log('checking in set logged in : ', userState);
 dispatch(slice.actions.loggedIn(userState));
};

export const setUserPreferredEmailInfo = (userPreferredEmailInfo: UserPreferredEmailInfo) => dispatch => {
 dispatch(slice.actions.setPreferredEmail(userPreferredEmailInfo.preferredEmailAddress));
 dispatch(
  slice.actions.setPreferredEmailVerifiedDate(userPreferredEmailInfo.emailVerifiedAt ? new Date(userPreferredEmailInfo.emailVerifiedAt) : null)
 );
};

export const logOut = () => dispatch => {
 dispatch(slice.actions.logout());
 localStorage.removeItem('active-membership');
 dispatch(push('/login'));
};

export default slice.reducer;
