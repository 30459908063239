import { Action, AnyAction } from 'redux';
import { combineEpics } from 'redux-observable';
import { Container } from 'typedi';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/ignoreElements';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

import * as a from '@features/security/auth.actions';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import { CarriersRepository } from 'app/repositories/carriers.repository';
import { SalesRep } from 'shared/models/contacts/sales-rep.model';
import { MedalliaTokenRepository } from 'app/repositories/medallia-token.repository';

type MedalliaEpics<OutputAction extends Action> = NavCarrierEpic<OutputAction, {
  carriersRepo: CarriersRepository;
  medalliaTokenRepo: MedalliaTokenRepository;
}>;

export const setMedalliaTokensEpic: MedalliaEpics<Action> = (action$, state$, {medalliaTokenRepo}) =>
  action$.ofType<a.CombinedUserDataAction>(a.COMPLETE_LOGIN)
    .do(({userJSON}) => medalliaTokenRepo.set('tCode', userJSON?.carrierCode))
    .do(({carrierDetail}) => medalliaTokenRepo.set('carrierName', carrierDetail?.name))
    .do(({userJSON}) => medalliaTokenRepo.set('userId', userJSON?.userID))
    .do(({userJSON}) => medalliaTokenRepo.set('username', userJSON?.username))
    .ignoreElements();

export const clearMedalliaTokensEpic: MedalliaEpics<Action> = (action$, state$, {medalliaTokenRepo}) =>
  action$.ofType(a.LOGOUT)
    .do(() => medalliaTokenRepo.clearAll())
    .ignoreElements();


export const fetchLeadRepEpic: MedalliaEpics<AnyAction> = (action$, state$, {carriersRepo, medalliaTokenRepo}) =>
  action$.ofType(a.FETCH_CARRIER_DETAIL_FOR_USER)
    .mergeMap(({userJSON}) =>
      carriersRepo.getSalesReps(userJSON)
        .map(salesReps => salesReps.find(rep => rep?.isLead))
        .catch(() => Observable.of(null))
    ).filter(Boolean)
    .do((leadRep: SalesRep) => medalliaTokenRepo.set('leadRep', leadRep?.salesperson))
    .ignoreElements();


export const medalliaEpics = (action$, state$) => combineEpics(
  setMedalliaTokensEpic,
  clearMedalliaTokensEpic,
  fetchLeadRepEpic,
)(action$, state$, {
  carriersRepo: Container.get(CarriersRepository),
  medalliaTokenRepo: Container.get(MedalliaTokenRepository)
});
