import { BaseModel } from 'shared/models/base.model';
import { Settings } from 'shared/models/config/settings.model';

export class Configuration extends BaseModel<ConfigurationJSON> {
  readonly settings: Settings;
  constructor(json: ConfigurationJSON) {
    super(json);
    if (json) {
      this.settings = new Settings(json.settings);
    } else {
      this.settings = new Settings();
    }
  }
  get maintenanceStartDate(): Date {
    return this.settings.maintenanceStartDate;
  }
  get maintenanceEndDate(): Date {
    return this.settings.maintenanceEndDate;
  }
}
