import { BaseModel } from 'shared/models/base.model';
import { Phone } from 'shared/models/my-loads/contacts/phone.model';
import { Branch } from 'shared/models/my-loads/contacts/branch.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Contacts.Contact */
export class Contact extends BaseModel<MyLoads.ContactJSON> {
  readonly firstName: string;
  readonly lastName: string;
  readonly isMain?: boolean;
  readonly lanId: string;
  readonly emailAddress: string;
  readonly phone: Phone;
  readonly branch: Branch;

  constructor(json: MyLoads.ContactJSON) {
    super(json);

    this.phone = new Phone(json.phone);
    this.branch = new Branch(json.branch);
  }
}
