import moment from 'moment';
import mapValues from 'lodash.mapvalues';

import { API_DATE_FORMAT } from 'app/globals/constants';

export abstract class BaseModel<JSONType = any> {
  // Highest value for a 4-byte (32-bit) SIGNED integer
  private static FOUR_BYTE_SIGNED_MAX = Math.pow(2, 31);

  // Highest value for 12-byte (96-bit) UNSIGNED number (probably represents an instance)
  private static TWELVE_BYTE_UNSIGNED_MAX = Math.pow(2, 96); // 96-bit unsigned * -1

  static setValues(context, data) {
    if (!data || typeof data !== 'object') {
      return;
    }
    Object.keys(data).forEach((key) => {
      const value = data[key];

      context[key] = value;

      if (Array.isArray(value)) {
        context[key] = value.map(item => BaseModel.setValues({}, item));

      } else if (typeof value === 'object') {
        context[key] = BaseModel.setValues({}, value);

      } else if (typeof (value as any) === 'number') {
        if (value === -BaseModel.FOUR_BYTE_SIGNED_MAX || value === -BaseModel.TWELVE_BYTE_UNSIGNED_MAX || value === -Number.MAX_VALUE) {
          context[key] = null;
        }
      }

    });
    Object.keys(context).forEach(key => {
      if (context.hasOwnProperty(key) && context[key] == null) {
        context[key] = null;
      }
    });
    return context;
  }

  constructor(data?: JSONType) {
    try {
      BaseModel.setValues(this, data);
    } catch (error) {
      console.log(error);
    }
  }

  // noinspection JSMethodCanBeStatic
  protected processDate(date?: Date|string|null): Date|null {
    const cutoffDate = new Date('1975-01-01T00:00:00');
    if (date && moment(date).toDate() > cutoffDate) {
      return moment(date).toDate();
    }
    return null;
  }

  protected phoneNumberManipulation(phoneString): string|null {
    if (phoneString.substring(0, 2) === '+1') { // North America
      if (phoneString.length === 12) {
        return phoneString.replace(/(\d)(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4');
      } else if (phoneString.length === 13) {
        return phoneString.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4');
      } else if (phoneString.length === 14) {
        return phoneString.replace(/(\d{2})(\d{3})(\d{3})(\d{4})/, '$1-$2-$3-$4');
      } else {
        return phoneString;
      }
    } else { // Europe
      return phoneString;
    }
  }

  // noinspection JSMethodCanBeStatic
  protected processTime(date?: Date|string|null): Date|null {
    if (date) {
      return moment(date).toDate();
    }
    return null;
  }

  public toJson(): JSONType {
    return mapValues(this, value => this.normalizeValue(value));
  }

  protected normalizeValue(value) {
    if (value instanceof Date) {
      return this.convertDateToDateString(value);
    }

    if (value instanceof BaseModel) {
      return value.toJson();
    }

    if (value instanceof Array) {
      return value.map(item => this.normalizeValue(item));
    }

    if (value instanceof Object) {
      return mapValues(value, item => this.normalizeValue(item));
    }

    return value;
  }

  protected convertDateToDateString(date: Date, useNullForNullDates?: boolean): string {
    if (!date) {
      return useNullForNullDates ? null : '0001-01-01T00:00:00';
    }
    return moment(date).format(API_DATE_FORMAT);
  }

  protected stripNulls(object: any, recursive?: boolean) {
    Object.keys(object).forEach(key => {
      if (object[key] == null) {
        delete object[key];
      } else if (Array.isArray(object[key]) && recursive) {
        object[key].forEach(item => this.stripNulls(item, recursive));
      } else if (typeof object[key] === 'object' && recursive) {
        this.stripNulls(object[key], recursive);
      }
    });
  }
}
