import { BaseModel } from 'shared/models/base.model';
import { ItemActualInformation } from 'shared/models/my-loads/items/item-actual-information.model';
import { ItemCommodityInformation } from 'shared/models/my-loads/items/item-commodity-information.model';
import { ItemRequiredEquipment } from 'shared/models/my-loads/items/item-required-equipment.model';
import { ItemExpectedInformation } from 'shared/models/my-loads/items/item-expected-information.model';
import { Distance } from 'shared/models/my-loads/measurements/distance.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Items.Item */
export class Item extends BaseModel<MyLoads.ItemJSON> {
  readonly actualInformation: ItemActualInformation|null;
  readonly commodityInformation: ItemCommodityInformation|null;
  readonly customerItemId?: number;
  readonly distance: Distance|null;
  readonly dropSequenceNumber: number;
  readonly expectedInformation: ItemExpectedInformation|null;
  readonly insuranceValue?: number;
  readonly orderId: number;
  readonly itemId: number;
  readonly loadNumber: number;
  readonly originWarehouseId: string|null;
  readonly pickSequenceNumber: number;
  readonly requiredEquipment: ItemRequiredEquipment|null;
  readonly isHazMat: boolean;
  readonly isPalletExchange: boolean;
  readonly factorId?: number;
  readonly rate?: number;

  constructor(json: MyLoads.ItemJSON) {
    super(json);

    this.actualInformation = json.actualInformation && new ItemActualInformation(json.actualInformation);
    this.commodityInformation = json.commodityInformation && new ItemCommodityInformation(json.commodityInformation);
    this.expectedInformation = json.expectedInformation && new ItemExpectedInformation(json.expectedInformation);
    this.requiredEquipment = json.requiredEquipment && new ItemRequiredEquipment(json.requiredEquipment);
    this.distance = json.distance && new Distance(json.distance);
  }
}
