import { Action } from 'redux';
import { Subject } from 'rxjs/Subject';

export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const REGISTER_MODAL = 'REGISTER_MODAL';
export const UNREGISTER_MODAL = 'UNREGISTER_MODAL';

export interface ShowModalAction extends Action {
  name: string;
  context: any;
}
export interface HideModalAction extends Action {
  name: string;
}

export const showModal = (name = 'main', context = {}): ShowModalAction => ({type: SHOW_MODAL, name, context});
export const hideModal = (name = 'main'): HideModalAction => ({type: HIDE_MODAL, name});
export const registerModal = (name: string, visibilityChanges: Subject<boolean>) => ({type: REGISTER_MODAL, name, visibilityChanges});
export const unregisterModal = (name: string) => ({type: UNREGISTER_MODAL, name});
