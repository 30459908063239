import { BaseModel } from 'shared/models/base.model';

export class SpotBidCommodity extends BaseModel<SpotBidExtendedCommodityJSON> {
  commodityDescription: string;
  extendedCubicMeters: number;
  hazMat: string;
  hazMatClass: string;
  hazMatDescription: string;
  hazMatUnNum: string;
  height: number;
  loosePieces: string;
  numberOfUnits: number;
  packagingType: string;
  strapsRequired: string;
  typeOfFreight: string;
  unitCubicMeters: number;
  weight: number;
  width: number;
  private lenghInches: number;

  get lengthInches(): number {
    return this.lenghInches;
  }

  get totalVolumeInches() {
    return (this.lengthInches * this.height * this.width);
  }
}
