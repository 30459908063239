import React from 'react';
import { FunctionComponent, ReactElement, Fragment, useContext } from 'react';

import { ToastResourceContext } from 'shared/components/toast/toast-resource-context';
import { ToastType } from 'shared/enums/enums';
import './feedback-toast.component.scss';

export const ToastHeaderResource: { [key: string]: string } = {
  'Success': 'success',
  'Info': 'info',
  'Error': 'error',
  'Warning': 'warning'
};

interface BodyProps {
  type: ToastType;
  messages: (ReactElement<any> | string)[];
  header?: ReactElement<any> | string;
  suppressHeader?: boolean;
}

export const FeedbackToastBody: FunctionComponent<BodyProps> = ({ type, messages, header, suppressHeader }) => {
  const resources = useContext(ToastResourceContext);
  return (
    <React.Fragment>
      <div className="icon-column">
        <span className="toast-icon" />
      </div>
      <div className="message-column">
        {!suppressHeader && <div className="h6">{header ? header : resources[ToastHeaderResource[type]]}</div>}
        <div className="toast-message">
          {messages.map((message, index) => <Fragment key={index}>{message}{' '}</Fragment>)}
        </div>
      </div>
    </React.Fragment>
  );
};
