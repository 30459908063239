import { IS_EPHEMERAL } from '@app/globals/constants';
import { ICarrierOktaConfig, IOktaEnvs } from './types';
const redirectUri = IS_EPHEMERAL ? window.location.origin : `${window.location.origin}/login/callback`;



// Scopes for Tokens
const scopes = ['openid', 'profile', 'email', 'offline_access'];

const prodDomain = 'https://account.chrobinson.com';
const nonProdDomain = 'https://nonprod-account.chrobinson.com';

// Issuers
const issuers = {
	d: `${nonProdDomain}/oauth2/aus1clanvm9iZRz7n0h8`,
	i: `${nonProdDomain}/oauth2/aus1mp97bzwmJOLKV0h8`,
	t: `${prodDomain}/oauth2/ausrmjuqepLSj2cyX357`,
	p: `${prodDomain}/oauth2/ausrmjnglfdAKElVq357`,
};

/**
 * Common configs for all environments
 * @private
 * @param issuer The Okta Issuer url
 * @returns
 */
const _ = {
	scopes,
	// Redirect Uri should be set when the accessor method is called to we ensure we have `window`
	redirectUri,
	pkce: true,
	useClassicEngine: true,
	logo: '/assets/images/Logo.svg',
	audience: 'auth://navispherecarrier.com',
	tokenManager: {
		autoRenew: true,
	},
	services: {
		autoRenew: false,
		autoRemove: false
	},
};

const getAuthParams = (issuer: string) => ({
	authParams: {
		scopes,
		issuer,
	},
});

const envs: IOktaEnvs = {
	development: {
		..._,
		clientId: '0oa1by55qhn5UyaEv0h8',
		baseUrl: nonProdDomain,
		issuer: issuers.d,
		...getAuthParams(issuers.d),
	} as ICarrierOktaConfig,
	test: {
		..._,
		clientId: '0oa1by55qhn5UyaEv0h8',
		baseUrl: nonProdDomain,
		issuer: issuers.d,
		...getAuthParams(issuers.d),
	} as ICarrierOktaConfig,
	integration: {
		..._,
		clientId: '0oa1by55qhouGGoy10h8',
		baseUrl: nonProdDomain,
		issuer: issuers.i,
		...getAuthParams(issuers.i),
	} as ICarrierOktaConfig,
	training: {
		..._,
		clientId: '0oarmjszxlUuaRrEU357',
		baseUrl: prodDomain,
		issuer: issuers.t,
		...getAuthParams(issuers.t),
	} as ICarrierOktaConfig,
	production: {
		..._,
		clientId: '0oarmjjhvuwYW0zx2357',
		baseUrl: prodDomain,
		issuer: issuers.p,
		...getAuthParams(issuers.p),
	} as ICarrierOktaConfig,
};

/**
 * Interface to retrieve the current environment's Okta configs
 * @public
 * 
 * @example
 * ```tsx
 *     const widget = new OktaSignIn({
      ...getOktaConfig(),
      state
    });

    // the rest of your code
    widget.renderEl(
      { el: widgetRef.current },
    ...
 * ```
 * @param env
 * @returns 
 */
const getOktaConfig = (): ICarrierOktaConfig => {
 const defaultEnv =
  (['dev.navispherecarrier.com', 'localhost:3000'].includes(window.location.host) && 'development') ||
  (['int.navispherecarrier.com'].includes(window.location.host) && 'integration') ||
  (window.location.host.includes('chr8s.io') && 'development') ||
  process.env.NODE_ENV ||
  'production';

 // Need a check here to serve the correct config because ephemeral is built with prod
 let _env = (IS_EPHEMERAL && 'development') || defaultEnv;
 return envs[_env];
};

export { getOktaConfig };
