import { BaseModel } from 'shared/models/base.model';
import { Warehouse } from 'shared/models/my-loads/warehouse/warehouse.model';
import { Weight } from 'shared/models/my-loads/measurements/weight.model';

export enum StopType {
  Pick,
  Drop,
  CrossDock,
  DestinationAirport,
  DestinationPort,
  DestinationRamp,
  DestinationTerminal,
  IntermediaryAirport,
  IntermediaryPort,
  IntermediaryRamp,
  OriginAirport,
  OriginPort,
  OriginRamp,
  OriginTerminal
}

/** NavisphereCarrier.MyLoads.ServiceModel.Stops.Stop */
export abstract class Stop<T extends MyLoads.StopJSON> extends BaseModel<T> {
  readonly stopNumber: number;
  readonly heavyWeight: Weight|null;
  readonly lightWeight: Weight|null;
  readonly sequenceNumber: number;
  readonly stopType: StopType;
  readonly warehouse: Warehouse;
  readonly calculatedArriveByStartDateTime: Date|null;
  readonly calculatedArriveByEndDateTime: Date|null;
  readonly maxWeight: Weight|null;
  readonly actualWeight: Weight|null;
  readonly driverWorkCode: string|null;

  protected constructor(json: T) {
    super(json);

    this.heavyWeight = json.heavyWeight && new Weight(json.heavyWeight);
    this.lightWeight = json.lightWeight && new Weight(json.lightWeight);
    this.maxWeight = json.maxWeight && new Weight(json.maxWeight);
    this.actualWeight = json.actualWeight && new Weight(json.actualWeight);
    this.warehouse = new Warehouse(json.warehouse);

    this.calculatedArriveByStartDateTime = this.processDate(json.calculatedArriveByStartDateTime);
    this.calculatedArriveByEndDateTime = this.processDate(json.calculatedArriveByEndDateTime);
  }

  isPickup() {
    return this.stopType === StopType.Pick;
  }

  isDropoff() {
    return this.stopType === StopType.Drop;
  }
}
