import { useDispatch } from 'react-redux';

import { hideLoader, showLoader } from 'shared/components/loading/loading.actions';
import { useSelector } from 'app/hooks/store/use-selector.hook';
import { LoaderNames } from 'app/globals/constants';

export const useLoader = (name?: string) => {
  const loaderName = name || LoaderNames.Main;
  const dispatch = useDispatch();
  const show = () => dispatch(showLoader(loaderName));
  const hide = () => dispatch(hideLoader(loaderName));
  const visible = useSelector(state => Boolean(state.loading[loaderName]));
  return { show, hide, visible};
};

export { LoaderNames } from 'app/globals/constants';
