export const EquipmentTypes = [
  {
    'code': 'V',
    'description': 'Van',
    'value': 'Van'
  },
  {
    'code': 'R',
    'description': 'Reefer',
    'value': 'Reefer'
  },
  {
    'code': 'F',
    'description': 'Flatbed',
    'value': 'Flatbed'
  },
  {
    'code': 'L',
    'description': 'Refers to a carrier that commonly consolidates more than one shipment on ',
    'value': 'LTL'
  },
  {
    'code': 'B',
    'description': 'Bulk',
    'value': 'Bulk'
  },
  {
    'code': 'Z',
    'description': 'Other',
    'value': 'Other'
  },
  {
    'code': 'H',
    'description': 'Power Only',
    'value': 'Power Only'
  },
  {
    'code': 'J',
    'description': 'Dray',
    'value': 'Dray'
  }
];
