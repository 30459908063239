import { SeverityType } from "@chr/eds-react";
import { TFunction } from "i18next";

export enum snackbarTypes {
  CHANGED_COMPANY_SUCCESS = 1,
  CHANGED_COMPANY_AND_DEFAULT_SUCCESS = 2,
  CHANGED_DEFAULT_COMPANY_SUCCESS = 3,
  CHANGED_COMPANY_ERROR = 4
}

interface snackbarProps {
  snackbarType: snackbarTypes;
  t: TFunction<'translation', undefined>;
  companyName?: string;
  companyCode?: string;
}

export const getSnackbarProps = (snackbarType, t, companyName?, companyCode?) => {

  switch (snackbarType) {
	case snackbarTypes.CHANGED_COMPANY_SUCCESS:
	  return ({
		id: 'updated-company-success',
		message: t('COMPANY_CHANGED_SUCCESSFULLY', {0: companyName, 1: companyCode }),
		severity: 'success' as SeverityType,
	  });
	  break;
	case snackbarTypes.CHANGED_COMPANY_AND_DEFAULT_SUCCESS:
	  return ({
		id: 'updated-company-and-default-success',
		message: t('COMPANY_CHANGED_AND_SET_AS_DEFAULT', { 0: companyName, 1: companyCode }),
		severity: 'success' as SeverityType,
	  });
	  break;
	case snackbarTypes.CHANGED_DEFAULT_COMPANY_SUCCESS:
	  return ({
		id: 'changed-default-company-success',
		message: t('DEFAULT_COMPANY_CHANGED_SUCCESSFULLY'),
		severity: 'success' as SeverityType
	  });
	  break;
	case snackbarTypes.CHANGED_COMPANY_ERROR:
	  return ({
		id: 'changed-company-error',
		message: t('CHANGE_COMPANY_ERROR'),
		severity: 'error' as SeverityType
	  });
	  break;
  }
  
};