import { combineEpics } from "redux-observable";
import * as a from "./analytics-v2.actions";
import * as appLoginAction from 'features/security/auth.actions';

export const setLoginSubmitEpic = (action$: any) =>
  action$.ofType(appLoginAction.LOGIN_WITH_CREDENTIALS)
    .map(a.setLoginSubmitEvent);

export const setLoginSuccessEpic = (action$: any, state$) =>
  action$.ofType(appLoginAction.STORE_CREDENTIALS)
    .filter(() => Boolean(state$.value.auth.loginWithCredentialsPerformed))
    .map(a.setLoginSuccessEvent);

export const setLoginFailureEpic = (action$: any, state$) =>
  action$.ofType(appLoginAction.LOGIN_ERROR)
    .map(a.setLoginFailureEvent);

export const analyticsV2Epics = combineEpics(
  setLoginSubmitEpic,
  setLoginSuccessEpic,
  setLoginFailureEpic,
);
