import { CapLoadDetailV2 } from 'shared/models/cap/cap-load-detail-v2.model';
import { BaseModel } from 'shared/models/base.model';

export class CapLoadDetailsV2 extends BaseModel {
  loadDetails: Array<CapLoadDetailV2>;

  constructor(json?) {
    super(json);
    this.loadDetails = json?.map(detail => new CapLoadDetailV2(detail));
  }
}
