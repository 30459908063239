import { Action } from 'redux';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';

import { User } from 'shared/models/user.model';
import { REQUEST_FAILURE, REQUEST_START, REQUEST_SUCCESS } from '../requests.actions';
import { Configuration } from 'shared/models/config/configuration.model';

export const INIT_APP = 'INIT_APP';
export const COMPLETE_APP_INITIALIZATION = 'COMPLETE_APP_INITIALIZATION';
export const INIT_DEFAULT_COUNTRY = 'INIT_DEFAULT_COUNTRY';
export const SET_DEFAULT_COUNTRY = 'SET_DEFAULT_COUNTRY';

export const GET_SETTINGS = `${REQUEST_START}:GET_SETTINGS`;
export const STORE_SETTINGS = `${REQUEST_SUCCESS}:GET_SETTINGS`;
export const GET_SETTINGS_ERROR = `${REQUEST_FAILURE}:GET_SETTINGS`;

export const CONNECT_SIGNALR = 'CONNECT_SIGNALR';

export interface SetDefaultCountryAction extends Action {
  country: CountryResponse;
}
export interface InitDefaultCountryAction extends Action {
  user: User;
  countries: CountryResponse[];
}

export const initApp = () => ({type: INIT_APP});
export const connectSignalR = () => ({ type: CONNECT_SIGNALR});

export const completeAppInitialization = () => ({type: COMPLETE_APP_INITIALIZATION});

export const initDefaultCountry = (user: User, countries: CountryResponse[]) => ({type: INIT_DEFAULT_COUNTRY, user, countries});
export const setDefaultCountry = (country: CountryResponse): SetDefaultCountryAction => ({type: SET_DEFAULT_COUNTRY, country});

export const initSettings = () => ({type: GET_SETTINGS});
export const storeSettings = (settings: Configuration) => ({type: STORE_SETTINGS, settings});
export const settingsFailure = (error: AjaxError) => ({type: GET_SETTINGS_ERROR, error});
