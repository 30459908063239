export const translate = (resources: Resources, key: ResourceKey, replacements: Stringable[] = []) => {
  const resource = (resources?.[key]) || '';

  return resource === '' ? key : replaceTokens(resource, replacements);
};

const replaceTokens = (resource: string = '', replacements: Stringable[] = []) => {
  let outputString = resource;

  if (replacements?.length) {
    replacements.forEach((arg, index) => {
      outputString = outputString.replace(`{${index}}`, arg.toString());
    });
  }

  return outputString;
};
