import { BaseModel } from 'shared/models/base.model';
import { StopType } from 'shared/enums/stop-type.enum';
import { FormattableAddress } from 'shared/components/formatters/city-state-country.formatter';
import { CapStopMetrics } from './cap-stop-metrics';
import moment from 'moment';

export class LoadDetailStopV2 extends BaseModel<LoadDetailStopJSON> {
  loadNumber: number;
  scheduledArrival: Date;
  arrivedTime: Date;
  enteredTime: Date;
  type: StopType;
  location: FormattableAddress;
  metrics: CapStopMetrics;
  private readonly _location;

  constructor(json?: LoadDetailStopJSON) {
    super(json);
    this.scheduledArrival = this.formatDate(json?.scheduledAppointmentDateTimeUtc);
    this.arrivedTime = this.formatDate(json?.arrivedDateTimeUtc);
    this.enteredTime = this.formatDate(json?.enteredDateTimeUtc);
    this.location = this.formatAddress(json?.location);
    this._location = json?.location;
  }

  private formatAddress(json: LoadDetailStopJSON['location']): FormattableAddress {
    return {
      city: json?.city,
      stateCode: json?.state,
      country: json?.country,
      postalCode: json?.postalCode,
    };
  }

  private formatDate(date?: Date | string | null): Date | null {
    if (date) {
      return new Date(moment.parseZone(date).format('MM/DD/YYYY hh:mm A'));
    }
    return null;
  }
}
