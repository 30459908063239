import { combineEpics } from 'redux-observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/ignoreElements';

import { LOGOUT } from 'features/security/auth.actions';
import { REGISTER_POPUP, UNREGISTER_POPUP } from 'app/browser/popups.actions';

export const popupWindows: { items: { [key: string]: Window } } = {
  items: {}
};

export const registerPopup = action$ =>
  action$.ofType(REGISTER_POPUP)
    .do(action => popupWindows.items[action.name] = action.window)
    .do(action => action.window && (action.window.closer = () => action.window.close()))
    .do(action => action.window && window.addEventListener('beforeunload', action.window.closer))
    .ignoreElements();

export const unregisterPopup = action$ =>
  action$.ofType(UNREGISTER_POPUP)
    .do(action => delete popupWindows.items[action.name])
    .do(action => action.window && window.removeEventListener('beforeunload', action.window.closer))
    .do(action => action.window && delete action.window.closer)
    .ignoreElements();

export const closeAllPopups = action$ =>
  action$.ofType(LOGOUT)
    .do(() => {
      Object.entries(popupWindows.items).forEach(([key, popup]: [string, Window]) => {
        if (popup && !popup.closed) {
          popup.close();
        }
        delete popupWindows.items[key];
      });
    }).ignoreElements();

export const popupsEpic = combineEpics(registerPopup, unregisterPopup, closeAllPopups);
