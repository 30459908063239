export const RadioButton = {
  border: '1px solid #AAAEB0',
  borderRadius: '8px',
  padding: '16px 16px 16px 0px',
  marginTop: '12px',
  width: { xs: '375px', sm: '425px' },
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
};

export const SelectedRadioButton = {
  ...RadioButton,
  background: 'rgba(0, 113, 199, 0.08)',
  border: '2px solid #0071C7',
  color: '#0071C7 !important'
};

export const SelectedRadioHeader = {
  color: '#0071C7'
}

export const RadioInput = {
  '&:hover': {
    backgroundColor: 'transparent',
  },
}

export const LabelContainer = {
  display: 'flex',
  width: '100%',
  flexDirection: 'row',
  justifyContent: 'space-between'
}

export const LabelTextArea = {
  display: 'flex',
  flexDirection: 'column'
}