import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getMemberships,
  getUserProfile,
  putDefaultMembership,
  validateEmailForOrganization,
  postUser,
  getOrganization
} from 'api/userManagement';

export enum CompanySelectionDialogViews {
 SET_DEFAULT = 'set-default',
 CHANGE_COMPANY = 'change-company',
 INVITATION_ACCEPTED = 'invitation-accepted',
}

export const fetchUserProfile = createAsyncThunk<UserProfile, string>(
 'memberships/fetchUserProfile',
 async (userId: number | string, { rejectWithValue }) => {
  try {
   const response = await getUserProfile(userId.toString());
   userManagementSlice.actions.setUserProfile(response);
   return response;
  } catch (error) {
   return rejectWithValue(error);
  }
 }
);

export const fetchMemberships = createAsyncThunk<MembershipsResponse, string>(
 'memberships/fetchMemberships',
 async (userId: number | string, { rejectWithValue }) => {
  try {
   const response = await getMemberships(userId.toString());
   userManagementSlice.actions.setMemberships(response);
   return response;
  } catch (error) {
   return rejectWithValue(error);
  }
 }
);

export const updateDefaultMembership = createAsyncThunk<any, { userId: number | string; membershipId: number | string }>(
 'memberships/updateDefaultMembership',
 async ({ userId, membershipId }, { rejectWithValue }) => {
  try {
   const response = await putDefaultMembership(userId.toString(), membershipId.toString());
   userManagementSlice.actions.setMemberships(membershipId);
   return response;
  } catch (error) {
   return rejectWithValue(error);
  }
 }
);

export const createUser = createAsyncThunk<any, any>('userManagement/createUser', async postUserRequest => {
  return await postUser(postUserRequest);
});

export const fetchOrganization = createAsyncThunk<Organizations, string>('organization/fetchOrganization', async organizationId => {
  return await getOrganization(organizationId);
});

interface UserManagementState {
 userProfile: UserProfile | null;
 memberships: MembershipsResponse | null;
 showCarrierSelectDialog: boolean;
 loading: boolean;
 error: string | null;
 updateStatus: 'FAILED' | 'SUCCESS';
 membershipDialogView:
 | CompanySelectionDialogViews.SET_DEFAULT
 | CompanySelectionDialogViews.CHANGE_COMPANY
 | CompanySelectionDialogViews.INVITATION_ACCEPTED;
 activeMembership: Membership | null;
 organization: Organization | null;
}

const initialState: UserManagementState = {
 userProfile: null,
 memberships: null,
 loading: false,
 error: null,
 updateStatus: null,
 showCarrierSelectDialog: false,
 membershipDialogView: CompanySelectionDialogViews.SET_DEFAULT,
 activeMembership: null,
 organization: null
};

const userManagementSlice = createSlice({
 name: 'user',
 initialState,
 reducers: {
  setUserProfile: (state, { payload }) => ({ ...state, userProfile: payload }),
  setMemberships: (state, { payload }) => ({ ...state, memberships: payload }),
  setActiveMembership: (state, { payload }) => ({ ...state, activeMembership: payload }),
  toggleMembershipDialog: state => {
   return {
    ...state,
    showCarrierSelectDialog: !state.showCarrierSelectDialog,
   };
  },
  setMembershipDialogView: (state, { payload }) => ({ ...state, membershipDialogView: payload }),
  setOrganization: (state, { payload }) => {
   return {
    ...state,
    organization: payload?.organization,
   };
  },
 },
 extraReducers: builder => {
  builder
   .addCase(fetchUserProfile.pending, state => {
    state.loading = true;
    state.error = null;
   })
   .addCase(fetchUserProfile.fulfilled, (state, action) => {
    state.userProfile = action.payload;
    state.loading = false;
   })
   .addCase(fetchUserProfile.rejected, (state, action) => {
    state.error = action.payload as string;
    state.loading = false;
   })
   .addCase(fetchMemberships.pending, state => {
    state.loading = true;
    state.error = null;
   })
   .addCase(fetchMemberships.fulfilled, (state, action) => {
    state.memberships = action.payload as MembershipsResponse;
    state.loading = false;
   })
   .addCase(fetchMemberships.rejected, (state, action) => {
    state.error = action.payload as string;
    state.loading = false;
   })
   .addCase(updateDefaultMembership.pending, state => {
    state.loading = true;
    state.error = null;
    state.updateStatus = null;
   })
   .addCase(updateDefaultMembership.fulfilled, state => {
    state.loading = false;
    state.updateStatus = 'SUCCESS';
   })
   .addCase(updateDefaultMembership.rejected, (state, action) => {
    state.error = action.payload as string;
    state.loading = false;
    state.updateStatus = 'FAILED';
   });
   // Handle createUser
   builder.addCase(createUser.pending, state => {
     state.loading = true;
     state.error = undefined;
   });
   builder.addCase(createUser.fulfilled, state => {
     state.loading = false;
   });
   builder.addCase(createUser.rejected, (state, action) => {
     state.loading = false;
     state.error = action.error.message;
   });
   // Handle fetchOrganization
   builder.addCase(fetchOrganization.pending, state => {
     state.loading = true;
     state.error = null;
   });
   builder.addCase(fetchOrganization.fulfilled, (state, action) => {
     state.loading = false;
     state.organization = action.payload?.organization;
   });
   builder.addCase(fetchOrganization.rejected, (state, action) => {
     state.loading = false;
     state.error = action.error.message;
   });
 },
});

export const setUserProfile = (userProfile: UserProfile) => dispatch => dispatch(userManagementSlice.actions.setUserProfile(userProfile));
export const setMemberships = (memberships: MembershipsResponse) => dispatch => dispatch(userManagementSlice.actions.setMemberships(memberships));
export const toggleMembershipDialog = (show: boolean) => dispatch => dispatch(userManagementSlice.actions.toggleMembershipDialog());
export const setMembershipDialogView = (view: CompanySelectionDialogViews) => dispatch =>
 dispatch(userManagementSlice.actions.setMembershipDialogView(view));
export const setActiveMembership = (membership: Membership) => dispatch => dispatch(userManagementSlice.actions.setActiveMembership(membership));
export const setOrganization = (organization: Organization) => dispatch => {
  dispatch(userManagementSlice.actions.setOrganization(organization));
};
export default userManagementSlice.reducer;
