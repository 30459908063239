import upperFirst from 'lodash.upperfirst';

/**
 * Convert a string to Title Case, while leaving all punctuation intact.
 * lodash.startcase strips all punctuation.
 *
 * @param {string} str {string}
 * @returns {string}
 */
export const titleCase = (str: string) => {
  if (str == null) {
    return null;
  }
  return str.replace(/[a-zA-Z0-9À-ž]*/ig, word => upperFirst(word.toLowerCase()));
};

export const cityStateCountryCasing = (city: string, stateCode: string, country: string) => {

  if (city == null || stateCode == null || country == null) {
    return null;
  }

  var words = city.toLowerCase().split(" ")
  var newWords = words.map((x) => {
    var first = x.charAt(0);
    return `${first.toUpperCase()}${x.slice(1)}`
  })

  city = newWords.join(" ")
  stateCode = stateCode.toUpperCase();
  country = country.toUpperCase();

  return {
    city,
    stateCode,
    country
  }
}
