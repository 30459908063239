import { BaseModel } from 'shared/models/base.model';
import { AccessorialStop } from 'shared/models/my-loads/financials/accessorial-stop.model';
import { VatTax } from 'shared/models/my-loads/financials/vat-tax.model';
import { FinancialLoadProblem } from 'shared/models/my-loads/financials/financial-load-problem.model';
import { AccessorialRate } from 'shared/models/my-loads/financials/accessorial-rate.model';
import { AccessorialWarning } from 'shared/enums/financials/accessorial-warning.enum';
import { StateChange } from 'shared/enums/financials/state-change.enum';

export class Financials extends BaseModel<FinancialsInformationJSON> {
  rates: AccessorialRate[] = [];
  loadProblems: FinancialLoadProblem[] = [];
  totalExtendedRateAmount: number;
  totalRequestedAccessorialAmount: number;
  totalExtendedRateAmountWithVAT: number;
  totalRequestedAccessorialAmountWithVat: number;
  vatTaxes: VatTax[] = [];
  currencyCode: string;
  eligibleAccessorialStops: AccessorialStop[] = [];

  constructor(json: FinancialsInformationJSON) {
    super(json);

    if (json) {
      this.eligibleAccessorialStops = json.eligibleAccessorialStops.map(stop => new AccessorialStop(stop));
      this.loadProblems = json.loadProblems.map(problem => new FinancialLoadProblem(problem));
      this.rates = json.rates.map(rate => new AccessorialRate(rate));
      this.vatTaxes = json.vatTaxes.map(tax => new VatTax(tax));
    }
  }
  getEligibleAccessorialStop(sequenceNumber: number) {
    return this.eligibleAccessorialStops.find(stop => stop.sequenceNumber === sequenceNumber);
  }

  deadlineHasPassed() {
    return (
      this.eligibleAccessorialStops?.length
        ? this.eligibleAccessorialStops[0].accessorialWarning === AccessorialWarning.AccessorialsRequestNotAllowed
        : false
    );
  }

  canRequestAccessorial() {
    return (
      this.eligibleAccessorialStops?.length
        ? !this.eligibleAccessorialStops[0].stateChanges.includes(StateChange.DisableRequestAccessorialButton)
        : false
    );
  }

  toJson(): FinancialsInformationJSON {
    return {
      ...super.toJson(),
      eligibleAccessorialStops: this.eligibleAccessorialStops.map(stop => stop.toJson()),
      rates: this.rates.map(rate => rate.toJson()),
      vatTaxes: this.vatTaxes.map(tax => tax.toJson()),
      loadProblems: this.loadProblems.map(loadProblem => loadProblem.toJson())
    };
  }
}
