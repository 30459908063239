export class APIErrorCode {
  readonly code: string;
  readonly description: string;
  readonly apiName: ErrorApiName;
  readonly codeCategory: ErrorCodeCategory;
  readonly shortCode: string;
  constructor(error: API.ErrorCode) {
    this.code = error.code;
    this.description = error.description;
    this.apiName = error.apiName;
    this.codeCategory = error.category;
    this.shortCode = error.shortCode;
  }
}

export enum ErrorCodeCategory {
  MissingRequiredField = 1,
  InvalidField = 2,
  AuthorizationFailure = 3
}
export enum ErrorApiName {
  NavisphereCarrierFramework = 1,
  NavisphereCarrierCarrierApi = 2,
  NavisphereCarrierFeedbackApiV2 = 3,
  NavisphereCarrierAvailableLoadsApi = 4,
  NavisphereCarrierUserApi = 5,
  NavisphereCarrierCarrierApiV2 = 6,
}
