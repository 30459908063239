import { BaseModel } from 'shared/models/base.model';
import { CapScore } from 'shared/models/cap/cap-score.model';

export class CarrierCapScores extends BaseModel {
  carrierCode: string;
  trendingScore: CapScore;
  currentScore: CapScore;

  constructor(json?: CarrierCapScoresJSON) {
    super(json);

    this.trendingScore = new CapScore(json.trendingScore);
    this.currentScore = new CapScore(json.currentScore);
  }
}
