import { BaseModel } from 'shared/models/base.model';
import { Order } from 'shared/models/my-loads/orders/order.model';
import { Note } from 'shared/models/my-loads/notes/note.model';
import { ItemCommodityInformation } from 'shared/models/my-loads/items/item-commodity-information.model';

/** NavisphereCarrier.MyLoads.ServiceModel.BillTos.BillTo */
export class BillTo extends BaseModel<MyLoads.BillToJSON> {
  readonly customerCode: string;
  readonly customerName: string;
  readonly orders: Order[];
  readonly notes: Note[];
  readonly orderCompany: string|null;
  readonly orderedBy: string|null;
  readonly originalOwningBranchCode: string|null;
  readonly sequenceNumber: number;
  readonly loadNumber: number;

  constructor(json: MyLoads.BillToJSON) {
    super(json);

    this.orders = json.orders.map(order => new Order(order));
    this.notes = json.notes ? json.notes.map(note => new Note(note)) : [];
  }

  get commodities(): ItemCommodityInformation[] {
    const items = this.orders.reduce((result, order) => [...result, ...order.items], []);

    return items.filter(item => item.commodityInformation).map(item => item.commodityInformation);
  }
}
