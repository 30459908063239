import { BaseModel } from 'shared/models/base.model';
import { TenderCostTax } from 'shared/models/my-loads/tenders/tender-cost-tax.model';
import { Util } from 'app/util/util';

const bySequence = Util.sortByField('sequenceNumber');

/** NavisphereCarrier.MyLoads.ServiceModel.Tenders.TenderCost */
export class TenderCost extends BaseModel<MyLoads.TenderCostJSON> {
  readonly costSequenceNumber: number;
  readonly costType: string;
  readonly costDescription: string;
  readonly costPerUnit: number;
  readonly units: number;
  readonly totalCost: number;
  readonly tenderCostTaxes: TenderCostTax[];
  readonly costCode: string;

  constructor(json: MyLoads.TenderCostJSON) {
    super(json);

    this.tenderCostTaxes = json.tenderCostTaxes ? json.tenderCostTaxes.sort(bySequence).map(item => new TenderCostTax(item)) : [];
  }
}
