import { BaseModel } from 'shared/models/base.model';
import { CapLoadDetailsV2 } from './cap-load-details-v2.model';

export class CapDetailsV2 extends BaseModel<CapDetailsV2JSON> {
  results: CapLoadDetailsV2;
  totalResults: number;
  pageSize: number;
  pageIndex: number;
  totalPages: number;

  constructor(json?: CapDetailsV2JSON) {
    super(json);
    this.results = new CapLoadDetailsV2(json?.results);
  }
}
