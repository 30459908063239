import moment from 'moment';

import { Carrier,CarrierDivision } from 'shared/models/carrier';
import { Roles } from './roles.model';
import { TimeFormat } from 'shared/enums/time-format.enum';
import { UnitOfMeasure } from 'shared/enums/unit-of-measure.enum';
import { TimeZones } from 'shared/data/misc/timezones.data';

export class User {
  token: string;
  carrier?: string;
  carrierName?: string;
  carrierCode?: string;
  carrierDivision?: CarrierDivision;
  carrierRollups?: Carrier[];
  contactId: number;
  contactSequenceNumber: number;
  divisions?: number[];
  isDriver?: boolean;
  userId?: number;
  email?: string;
  phone?: string;
  firstName?: string;
  lastName?: string;
  roles?: Roles;
  properties?: {
    announcementVersion?: string;
    carrierCode?: string;
    country?: string;
    culture?: string;
    lastEmailProfileUpdate?: string;
    startPage?: string;
    timeFormat?: TimeFormat;
    timeZone?: TimeZone;
    unitOfMeasure?: UnitOfMeasure;
    disableLoadCheckinUpdate?: boolean;
    disableLoadDropUpdate?: boolean;
    disableLoadPickUpdate?: boolean;
    preferredCurrencyCode?: string;
  };
  groupSettings?: {
    carrierSettings?: {
      hasSeenBINLoads?: boolean;
    }
  };
  username?: string;
  permissions?: number[];
  readonly isAuthenticated;
  newMessageCount = 0;
  acceptedAgreement: boolean;

  private mainCarrier: Carrier;

  constructor(json: UserJSON) {
    this.carrierCode = json.carrierCode;
    this.carrierName = json.carrierName;

    this.contactId = json.contactId;
    this.contactSequenceNumber = json.contactSequenceNumber;

    this.carrierRollups = [];

    if (Array.isArray(json.carrierRollups)) {
      this.carrierRollups = json.carrierRollups.map(carrierJSON => new Carrier(carrierJSON));
    }

    this.email = json.emailAddress;
    this.phone = json.phoneNumber;

    this.isDriver = json.isDriver;

    this.userId = json.userID;
    this.username = json.username;
    this.firstName = json.firstName;
    this.lastName = json.lastName;

    this.properties = {};

    if (json.properties) {
      this.properties = {
        timeFormat: (json.properties.timeFormat === '12' ? TimeFormat.TwelveHour : TimeFormat.TwentyFourHour),
        announcementVersion: json.properties.announcementVersion,
        carrierCode: json.properties.carrierCode,
        country: json.properties.country,
        culture: json.properties.culture,
        lastEmailProfileUpdate: json.properties.lastEmailProfileUpdate,
        startPage: json.properties.startPage,
        disableLoadCheckinUpdate: json.properties.disableLoadCheckinUpdate === 'True',
        disableLoadDropUpdate: json.properties.disableLoadDropUpdate === 'True',
        disableLoadPickUpdate: json.properties.disableLoadPickUpdate === 'True',
        preferredCurrencyCode: json.properties.preferredCurrencyCode
      };
      if (json.properties.unitOfMeasure) {
        this.properties.unitOfMeasure = Number(json.properties.unitOfMeasure);
      }
      if (this.properties.culture) {
        moment.locale(this.properties.culture);
      }
      if (json.properties.timeZone) {
        this.properties.timeZone = { ...TimeZones.find(x => x.id === json.properties.timeZone) }
        if (json.properties.timeZoneOffset) {
          this.properties.timeZone.offset = Number(json.properties.timeZoneOffset)
        }
      }
    }

    this.token = json.hashedPassword;
    this.permissions = json.permissions;
    this.groupSettings = json.groupSettings;
    this.acceptedAgreement = json.acceptedAgreement;

    this.isAuthenticated = Boolean(json.username);

    if (Array.isArray(json.permissions)) {
      this.roles = new Roles(json.permissions);
    } else {
      this.roles = new Roles([]);
    }

    if (Array.isArray(json.divisions)) {
      this.carrierDivision = new CarrierDivision(json.divisions);
      this.divisions = json.divisions;
    } else {
      this.carrierDivision = new CarrierDivision([]);
      this.divisions = [];
    }
  }

  public toJson(): UserJSON {
    return {
      carrierCode: this.carrierCode,
      carrierName: this.carrierName,
      contactId: this.contactId,
      contactSequenceNumber: this.contactSequenceNumber,
      carrierRollups: this.carrierRollups.map(carrier => carrier.toJson()),
      emailAddress: this.email,
      phoneNumber: this.phone,
      isDriver: this.isDriver,
      userID: this.userId,
      username: this.username,
      firstName: this.firstName,
      lastName: this.lastName,
      properties: {
        ...this.properties,
        timeFormat: this.properties.timeFormat != null ? this.properties.timeFormat.toString() : undefined,
        unitOfMeasure: this.properties.unitOfMeasure != null ? this.properties.unitOfMeasure.toString() : undefined,
        timeZone: this.properties.timeZone != null ? this.properties.timeZone.id : undefined,
        disableLoadPickUpdate: this.properties.disableLoadPickUpdate ? 'True' : 'False',
        disableLoadDropUpdate: this.properties.disableLoadDropUpdate ? 'True' : 'False',
        disableLoadCheckinUpdate: this.properties.disableLoadCheckinUpdate ? 'True' : 'False',
        preferredCurrencyCode: this.properties != null ? this.properties.preferredCurrencyCode : undefined
      },
      groupSettings: this.groupSettings,
      hashedPassword: this.token,
      permissions: this.permissions,
      acceptedAgreement: this.acceptedAgreement
    };
  }

  public isEuropean(): boolean {
    if (!this.carrierRollups) {
      return false;
    }
    if (this.getCarrier()) {
      return this.getCarrier().regionCode === 'EU';
    }
    return false;
  }

  public isNorthAmerican(): boolean {
    if (!this.carrierRollups) {
      return true;
    }
    if (this.getCarrier()) {
      return this.getCarrier().regionCode === 'NA';
    }
    return true;
  }

  public getCarrier(): Carrier {
    if (this.carrierRollups) {
      if (!this.mainCarrier) {
        this.mainCarrier = this.carrierRollups.find(carrier => carrier.code === this.carrierCode)
          || this.carrierRollups.find(carrier => carrier.code === this.properties.carrierCode)
          || {} as any;
      }
    }

    return this.mainCarrier;
  }

  public isTmc(): boolean {
    return this?.getCarrier()?.isTMC;
  }

  public getDisplayName(): string {
    return this.firstName + ' ' + this.lastName;
  }

  public getStartPageKey() {
    return this.properties?.startPage;
  }

  getUnitOfMeasure(): UnitOfMeasure {
    return this.properties?.unitOfMeasure;
  }

  isMetric(): boolean {
    return this.getUnitOfMeasure() === UnitOfMeasure.Metric;
  }

  public is12Hour() {
    return this.properties.timeFormat === TimeFormat.TwelveHour;
  }

  hasSeenBin() {
    return this.groupSettings?.carrierSettings?.hasSeenBINLoads;
  }

  getTimeZone(): string {
    return this.properties.timeZone ? this.properties.timeZone.id : undefined;
  }

  getPreferredCurrencyCode(): string {
    return this.properties?.preferredCurrencyCode;
  }
}
