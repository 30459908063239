import { BaseModel } from 'shared/models/base.model';
import { TenderResponse } from 'shared/models/my-loads/tenders/tender-response.model';
import { TenderCost } from 'shared/models/my-loads/tenders/tender-cost.model';
import { CustomizedEquipment } from 'shared/models/my-loads/equipment/customized-equipment.model';
import { DeferredCode } from 'shared/models/my-loads/tenders/deferred-code.enum';
import { Util } from 'app/util/util';

const bySequence = Util.sortByField('costSequenceNumber');

/** NavisphereCarrier.MyLoads.ServiceModel.Tenders.Tender */
export class Tender extends BaseModel<MyLoads.TenderJSON> {
  readonly loadNumber: number;
  readonly carrierCode: string;
  readonly tenderSequenceNumber: number;
  readonly tenderedBy: string|null;
  readonly tenderedDate: Date;
  readonly isAutoTender: boolean;
  readonly isDeferredAccepted: boolean;
  readonly deferredCode: DeferredCode;
  readonly sourceCostQuoteId?: number;
  readonly tenderResponse?: TenderResponse|null;
  readonly stopSequenceNumbers: number[];
  readonly tenderCosts: TenderCost[];
  readonly currencyCode: string;
  readonly expirationDate: Date;
  readonly billToPartyCode: string;
  readonly billToReferenceNumber: string|null;
  readonly customizedEquipment?: CustomizedEquipment|null;
  readonly timeOutInMinutes?: number;
  readonly distance: number;
  readonly totalCost: number;

  constructor(json: MyLoads.TenderJSON) {
    super(json);

    this.stopSequenceNumbers = json.stopSequenceNumbers;
    this.tenderResponse = json.tenderResponse && new TenderResponse(json.tenderResponse);
    this.customizedEquipment = json.customizedEquipment && new CustomizedEquipment(json.customizedEquipment);
    this.tenderCosts = json.tenderCosts ? json.tenderCosts.sort(bySequence).map(item => new TenderCost(item)) : [];

    this.tenderedDate = this.processDate(json.tenderedDate) as Date;
    this.expirationDate = this.processDate(json.expirationDate) as Date;
  }

  toJson(): MyLoads.TenderJSON {
    return {
      ...super.toJson(),
      stopSequenceNumbers: this.stopSequenceNumbers,
      tenderResponse: this.tenderResponse?.toJson(),
      tenderCosts: this.tenderCosts?.map(cost => cost.toJson()),
      customizedEquipment: this.customizedEquipment?.toJson(),
      tenderedDate: this.convertDateToDateString(this.tenderedDate),
      expirationDate: this.convertDateToDateString(this.expirationDate),
    };
  }

  get minutesRemaining() {
    return this.timeOutInMinutes || 0;
  }

  get deferred(): boolean {
    return this.deferredCode === DeferredCode.Rejected || this.deferredCode === DeferredCode.TimedOut;
  }

  get pending(): boolean {
    return Boolean(this.isDeferredAccepted);
  }

  get expired(): boolean {
    return this.minutesRemaining <= 0;
  }

}
