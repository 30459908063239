export const StatesMX = [
  {
    'code': 'AGU',
    'id': 714,
    'name': 'Aguascalientes'
  },
  {
    'code': 'BCN',
    'id': 715,
    'name': 'Baja California'
  },
  {
    'code': 'BCS',
    'id': 716,
    'name': 'Baja California Sur'
  },
  {
    'code': 'CAM',
    'id': 717,
    'name': 'Campeche'
  },
  {
    'code': 'CHH',
    'id': 718,
    'name': 'Chihuahua'
  },
  {
    'code': 'CHP',
    'id': 719,
    'name': 'Chiapas'
  },
  {
    'code': 'CMX',
    'id': 722,
    'name': 'Ciudad de México'
  },
  {
    'code': 'COA',
    'id': 720,
    'name': 'Coahuila'
  },
  {
    'code': 'COL',
    'id': 721,
    'name': 'Colima'
  },
  {
    'code': 'DUR',
    'id': 723,
    'name': 'Durango'
  },
  {
    'code': 'GRO',
    'id': 724,
    'name': 'Guerrero'
  },
  {
    'code': 'GUA',
    'id': 725,
    'name': 'Guanajuato'
  },
  {
    'code': 'HID',
    'id': 726,
    'name': 'Hidalgo'
  },
  {
    'code': 'JAL',
    'id': 727,
    'name': 'Jalisco'
  },
  {
    'code': 'MEX',
    'id': 728,
    'name': 'México'
  },
  {
    'code': 'MIC',
    'id': 729,
    'name': 'Michoacán'
  },
  {
    'code': 'MOR',
    'id': 730,
    'name': 'Morelos'
  },
  {
    'code': 'NAY',
    'id': 731,
    'name': 'Nayarit'
  },
  {
    'code': 'NLE',
    'id': 732,
    'name': 'Nuevo León'
  },
  {
    'code': 'OAX',
    'id': 733,
    'name': 'Oaxaca'
  },
  {
    'code': 'PUE',
    'id': 734,
    'name': 'Puebla'
  },
  {
    'code': 'QUE',
    'id': 735,
    'name': 'Querétaro'
  },
  {
    'code': 'ROO',
    'id': 736,
    'name': 'Quintana Roo'
  },
  {
    'code': 'SIN',
    'id': 737,
    'name': 'Sinaloa'
  },
  {
    'code': 'SLP',
    'id': 738,
    'name': 'San Luis Potosí'
  },
  {
    'code': 'SON',
    'id': 739,
    'name': 'Sonora'
  },
  {
    'code': 'TAB',
    'id': 740,
    'name': 'Tabasco'
  },
  {
    'code': 'TAM',
    'id': 741,
    'name': 'Tamaulipas'
  },
  {
    'code': 'TLA',
    'id': 742,
    'name': 'Tlaxcala'
  },
  {
    'code': 'VER',
    'id': 743,
    'name': 'Veracruz'
  },
  {
    'code': 'YUC',
    'id': 744,
    'name': 'Yucatán'
  },
  {
    'code': 'ZAC',
    'id': 745,
    'name': 'Zacatecas'
  }
];
