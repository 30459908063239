import { Action } from 'redux';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';

import { APIErrorResponse } from 'app/repositories/errors/api-error-response';

export const REQUEST_START = 'REQUEST_START';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const REQUEST_FAILURE = 'REQUEST_FAILURE';

export interface RequestFailureAjaxErrorAction extends Action {
  error: AjaxError;
}
export interface RequestFailureAPIErrorAction extends Action {
  error: APIErrorResponse;
}
