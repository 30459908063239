import { LoadSummaryAddress } from 'shared/models/loads/load-summaries/load-summary-address.model';
import { ExtendedDriverLog } from 'shared/models/loads/driver-logs/extended-driver-log.model';
import { BaseLoadBook } from 'shared/models/loads/load-books/base-load-book.model';
import { Container } from 'typedi';
import { MyLoadsRepository } from 'app/repositories/my-loads.repository';

export class ExtendedLoadBook extends BaseLoadBook {
  acceptedBy: string;
  acceptReject: AcceptReject;
  documents: ExtendedDocumentJSON[];

  actualEmptyAddress: LoadSummaryAddress;
  actualEmptyDateTime: Date;
  expectedEmptyAddress: LoadSummaryAddress;
  expectedEmptyDateTime: Date;
  expectedDeliveryDateTime: Date;
  expectedPickUpDateTime: Date;

  documentIds: number[];
  bookingNumber: number;
  bookStops: {
    bookStopNumber: number;
    bookStopSequence: number;
  }[];
  bookType: string;
  carrierEquipment: {
    attributeFailedRequirement: number;
    height?: number;
    length?: number;
    tractorNumber: string;
    trailerNumber: string;
    type: null;
    typeDescription: string;
    width?: number;
  };
  carrierLoadContact: any;
  rates: LoadBookRateJSON[];
  currencyCode: string;

  driver1FirstName: string;
  driver1LastName: string;
  driver2FirstName: string;
  driver2LastName: string;
  driverCellPhone: string;

  expressLoadNumber: string;
  finLocked: boolean;
  imProgram?: string;
  infoFrom: string;
  name: string;
  proNumber: string;
  proNumberRequiredLength: number;
  rejectedReason: string;
  salesRep: {
    branchCode: string;
    branchName: string;
    branchPhone: string;
    contactName: string;
    email: string;
    firstName: string;
    isActive: boolean;
    lanID: string;
    lastName: string;
    workPhone: string;
  };
  trailerNumber: string;

  private scac: string;
  private bookedBY: string;
  private driverEmpty: string;
  private checkCalls: ExtendedDriverLogJSON[];

  private scheduledEndDate: string;

  set scheduledEndDateTime(date: Date) {
    this.scheduledEndDate = this.convertDateToDateString(date);
  }

  get scheduledEndDateTime(): Date {
    return this.processDate(this.scheduledEndDate);
  }

  /**
   * Standard Carrier Alpha Code™ (SCAC®)
   * A privately controlled US code used to identify road transport companies.
   */
  get SCAC() {
    return this.scac;
  }

  set SCAC(value: string) {
    this.scac = value;
  }

  public driverLogs: ExtendedDriverLog[];

  public driverCheckCalls: ExtendedDriverLog[];

  public addDriverLog(log: ExtendedDriverLog) {
    this.driverLogs = [...this.driverLogs, log];
  }
  public loadDriverCheckCalls(loadNumber){

    const repo = Container.get(MyLoadsRepository);
    if (this.loadNumber) {
      const logObservable = repo.getLoadDriverLogs(loadNumber,     this.sequenceNumber || 0)
      logObservable.subscribe(logs => {
        logs.map(log => new ExtendedDriverLog(log));
        this.driverCheckCalls = logs;
      });
    }
  }

  get bookedBy() {
    return this.bookedBY;
  }

  set bookedBy(value: string) {
    this.bookedBY = value;
  }

  get driverOnWay(): boolean {
    if (this.driverEmpty === 'true') {
      return true;
    }
    if (this.driverEmpty === 'false') {
      return false;
    }
  }

  set driverOnWay(value: boolean) {
    this.driverEmpty = value.toString();
  }

  constructor(json: ExtendedLoadBookJSON) {
    super(json);

    if (json) {
      // dates
      this.actualEmptyDateTime = this.processDate(json.actualEmptyDateTime);
      this.expectedDeliveryDateTime = this.processDate(json.expectedDeliveryDateTime);
      this.expectedEmptyDateTime = this.processDate(json.expectedEmptyDateTime);
      this.expectedPickUpDateTime = this.processDate(json.expectedPickUpDateTime);

      // addresses
      this.actualEmptyAddress = new LoadSummaryAddress(json.actualEmptyAddress);
      this.expectedEmptyAddress = new LoadSummaryAddress(json.expectedEmptyAddress);

      if (json.checkCalls) {
        this.driverLogs = this.checkCalls.map(log => new ExtendedDriverLog(log));
      }
    }
    this.loadDriverCheckCalls(this.loadNumber);
  }

  toJson(): ExtendedLoadBookJSON {
    const response: any = Object.assign({}, this);

    response.checkCalls = this.driverLogs.map(log => log.toJson());
    delete response.driverLogs;

    response.actualEmptyAddress = this.actualEmptyAddress.toJson();
    response.expectedEmptyAddress = this.expectedEmptyAddress.toJson();

    response.actualEmptyDateTime = this.convertDateToDateString(this.actualEmptyDateTime);
    response.expectedDeliveryDateTime = this.convertDateToDateString(this.expectedDeliveryDateTime);
    response.expectedEmptyDateTime = this.convertDateToDateString(this.expectedEmptyDateTime);
    response.expectedPickUpDateTime = this.convertDateToDateString(this.expectedPickUpDateTime);

    return response as ExtendedLoadBookJSON;
  }
}
