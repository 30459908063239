import { BaseModel } from 'shared/models/base.model';
import { Address } from 'shared/models/address/address.model';
import { InvoiceLocationType } from 'shared/models/invoice/invoice-location-type.enum';
import { StopType } from 'shared/enums/stop-type.enum';

const emptyStopLocationJSON: InvoiceLocationJSON = {
  invoiceLocationType: InvoiceLocationType.WarehouseLocation,
  stopType: null,
  stopNumber: null,
  address: {
    companyName: null,
    streetName: null,
    street2: null,
    cityName: null,
    stateCode: null,
    country: null,
    county: null,
    location: null,
    fullLocation: null,
    zipcode: null,
  },
};

export class InvoiceLocation extends BaseModel<InvoiceLocationJSON> {
  stopNumber?: number;
  invoiceLocationType: InvoiceLocationType;
  stopType: StopType;
  address: Address;

  constructor(json?: Partial<InvoiceLocationJSON>) {
    super(json = {
      ...emptyStopLocationJSON,
      ...json
    });
    this.address = new Address(json.address);
  }

  toJson() {
    if (!this.hasCity() && !this.hasCountry()) {
      return null;
    }
    return {
      ...super.toJson(),
      address: this.address.toJson()
    };
  }

  hasCity() {
    return Boolean(this.address.cityName);
  }
  hasCountry() {
    return Boolean(this.address.country);
  }
  isShipper() {
    return this.invoiceLocationType === InvoiceLocationType.ShipperLocation;
  }
  isConsignee() {
    return this.invoiceLocationType === InvoiceLocationType.ConsigneeLocation;
  }
  isWarehouse() {
    return this.invoiceLocationType === InvoiceLocationType.WarehouseLocation;
  }
}
