import { BaseModel } from 'shared/models/base.model';
import { UnitTypeKey } from 'shared/models/unit-type.map';
import { TMCAccessorial } from 'shared/models/my-loads/financials/tmc-accessorial.model';
export class AccessorialRate extends BaseModel<AccessorialRateJSON> implements AccessorialRateInterface {
  chargeId: number;
  loadProblemSequenceNumber: number;
  rateCode: string;
  description: string;
  stopName: string;
  rateType: UnitTypeKey;
  perUnit: number;
  units: number;
  extendedRate: number;
  rateExcludingVAT: number;
  vatPercentage: number;
  totalVAT: number;
  sequenceNumber: number;
  stopSequenceNumber: number;
  authorizationId: string;
  status: 'APPROVED' | 'PENDING' | 'REJECTED' | 'INFO_REQUEST' | 'EDITED' | 'DELETED';
  notes: string;

  get uniqueId() {
    return `${this.chargeId}-${this.loadProblemSequenceNumber}-${this.stopSequenceNumber}`;
  }

  constructor(json: AccessorialRateJSON) {
    super(json);
  }

  isEditable() {
    return this.status &&
      this.status !== 'DELETED' &&
      this.status !== 'APPROVED' &&
      this.status !== 'REJECTED';
  }

  toTMCAccessorial(): TMCAccessorial {
    return new TMCAccessorial({
      accessorialRequestId: this.loadProblemSequenceNumber,
      stopSequenceNumber: this.stopSequenceNumber,
      chargeTypeCode: this.rateCode,
      rateType: this.rateType,
      costPerUnit: this.perUnit,
      notes: this.status !== 'INFO_REQUEST' ? this.notes : null,
      units: this.units,
    });
  }
}
