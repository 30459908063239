import { Action, AnyAction } from 'redux';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { Container } from 'typedi';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/switchMap';
import { replace } from 'connected-react-router';

import * as a from '@features/security/auth.actions';
import { AuthRepository } from 'app/repositories/auth.repository';
import { APIErrorResponse } from 'app/repositories/errors/api-error-response';
import { RequestFailureAPIErrorAction } from 'store/requests.actions';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import { CarriersRepository } from 'app/repositories/carriers.repository';
import { User } from 'shared/models/user.model';
import { hazmatEpics } from '@features/security/auth/hazmat.epics';
import { insuranceEpics } from '@features/security/auth/insurance.epics';
import { completeAppInitialization } from 'store/epics/app.actions';
import { initCulture } from 'app/i18n/culture.actions';
import { termsAndConditionsEpics } from '@features/security/auth/terms-and-conditions.epics';
import { medalliaEpics } from '@features/security/auth/medallia.epics';
import { AppRoute } from '@app/routesEnum';
import { publishLoginEvent } from 'app/repositories/analytics.repository';
import { AuthAction, DataDogLogger } from '@features/datadog';

type LoginEpic<OutputAction extends Action> = NavCarrierEpic<
 OutputAction,
 {
  auth: AuthRepository;
  carriersRepo: CarriersRepository;
 }
>;

//LOGIN_WITH_CREDENTIALS => LOAD_USER_DATA_SUCCESS || LOGIN_ERROR
export const loginWithCredentialsEpic: LoginEpic<AnyAction> = (action$, state$, { auth }) =>
 action$.ofType<a.LoginWithCredentialsAction>(a.LOGIN_WITH_CREDENTIALS).switchMap(({ username, password }) =>
  auth
   .loginWithCredentials(username, password)
   .map(user => {
    if (!user.isMfaLite) {
     publishLoginEvent(user);
    }
    DataDogLogger.trackAction(AuthAction.IsHmac);
    return a.loadUserDataSuccess(user);
   })
   .catch((err: AjaxError) => {
    auth.clearRemoteLogin();
    return ActionsObservable.of(a.loginError(err));
   })
 );

export const loginWithOTPEpic: LoginEpic<AnyAction> = (action$, state$, { auth }) =>
  action$.ofType<a.LoginWithOTPAction>(a.LOGIN_WITH_OTP)
    .switchMap(({ token }) =>
      auth.loginWithOTP(token)
        .map(user => {
          DataDogLogger.trackAction(AuthAction.IsHmac);
          publishLoginEvent(user);
          return a.loadUserDataSuccess(user);
        }).catch((err: AjaxError) => {
          auth.clearRemoteLogin();
          return ActionsObservable.of(a.loginError(err));
        })
    );


export const loadUserDataSuccessEpic: LoginEpic<AnyAction> = (action$, state$, { auth }) =>
  action$.ofType<a.LoadUserDataSuccessAction>(a.LOAD_USER_DATA_SUCCESS)
    .do(auth.clearRemoteLogin)
    .mergeMap(
      ({ userJSON }) => userJSON.isMfaLite ?
        ActionsObservable.of(replace(`${AppRoute.LOGIN_OTP}?email=${userJSON.emailAddress}&id=${userJSON.userID}`))
        : (userJSON.carrierCode?.startsWith('V') || userJSON.carrierCode === 'TMPCARRIER')
          ? ActionsObservable.of(completeAppInitialization(), a.verifyTermsAndConditionsStatus(userJSON, null))
          : ActionsObservable.of(a.fetchCarrierForUser(userJSON))
    );

export const fetchCarrierDetailsEpic: LoginEpic<a.CombinedUserDataAction | RequestFailureAPIErrorAction> = (action$, state$, { carriersRepo }) =>
  action$.ofType(a.FETCH_CARRIER_DETAIL_FOR_USER)
    .mergeMap(({ userJSON }) =>
      carriersRepo.getCarrierForUser(userJSON.carrierCode, userJSON.username, userJSON.hashedPassword)
        .map(carrierDetail => a.fetchCarrierForUserSuccess(userJSON, carrierDetail))
        .catch((error: APIErrorResponse) => ActionsObservable.of(a.fetchCarrierForUserError(error)))
    );

export const storeCredentialsOnLoginEpic: LoginEpic<a.StoreCredentialsAction> = action$ =>
  action$.ofType<a.CompleteLoginAction>(a.COMPLETE_LOGIN)
    .map(({ userJSON, carrierDetail }) => a.storeCredentials(new User(userJSON), userJSON.hashedPassword, carrierDetail));

export const processPendingPostLoginActionsEpic: LoginEpic<AnyAction> = (action$, state$) =>
  action$.ofType<a.StoreCredentialsAction>(a.STORE_CREDENTIALS)
    .map(() => state$.value.auth.postLoginActionQueue)
    .mergeMap(pendingActions => ActionsObservable.from([...pendingActions, a.clearPostLoginQueue()]));

export const loginEpics = (action$, state$, { auth }) =>
 combineEpics(
  loginWithCredentialsEpic,
  loginWithOTPEpic,
  loadUserDataSuccessEpic,
  fetchCarrierDetailsEpic,
  medalliaEpics,
  insuranceEpics,
  hazmatEpics,
  termsAndConditionsEpics,
  storeCredentialsOnLoginEpic,
  processPendingPostLoginActionsEpic
 )(action$, state$, {
  auth,
  carriersRepo: Container.get(CarriersRepository),
 });
