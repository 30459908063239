import { ContactPosition } from 'shared/enums/contact-position.enum';
import { ContactDepartment } from 'shared/enums/contact-department.enum';
import { BaseModel } from 'shared/models/base.model';

import { Email } from './email.model';
import { ContactPhone } from './contact-phone.model';

export class Contact extends BaseModel {
  firstName: string;
  lastName: string;
  isPrimary: boolean;
  position: ContactPosition;
  department: ContactDepartment;
  id: number;
  icon: string;
  isAppUser = false;
  contactSequenceNumber: number;
  defaultEmail: Email;
  defaultPhone: ContactPhone;
  webUserId: string;
  isEditable: boolean;

  constructor(json?: ContactJSON) {
    super(json);

    if (json) {
      if (json.defaultEmail) {
        this.defaultEmail = new Email(json.defaultEmail);
      } else {
        this.defaultEmail = new Email({emailAddress: ''});
      }
      if (json.defaultPhone) {
        this.defaultPhone = new ContactPhone(json.defaultPhone);
      } else {
        this.defaultPhone = new ContactPhone({id: 0, dialingCode: '', number: ''});
      }

      this.defaultEmail.id = this.defaultEmail.id || 0;
      this.defaultPhone.id = this.defaultPhone.id || 0;
    }
    if (!this.position) {
      this.position = ContactPosition.Driver;
    }
    if (!this.department) {
      this.department = ContactDepartment.Driver;
    }
  }

  toJson(): ContactJSON {
    const response: any = Object.assign({}, this);

    if (this.defaultEmail) {
      response.defaultEmail = this.defaultEmail.toJson();
    }
    if (this.defaultPhone) {
      response.defaultPhone = this.defaultPhone.toJson();
    }

    this.stripNulls(response, true);

    return response;
  }

  getFullName() {
    return `${this.firstName} ${this.lastName}`;
  }
}
