import { BaseModel } from 'shared/models/base.model';
import { InvoiceStatus } from 'pages/manage-invoices/invoice-status.enum';

export class Invoice extends BaseModel<CarrierInvoiceJSON> {
  carrierInvoiceId: number;
  carrierScac: string;
  proNumber: string;
  carrierCode: string;
  loadNumber: number;
  customer: {
    name: string;
    customerCode: string
  };
  invoiceAmount: number;
  chrAmount: number;
  differenceAmount: number;
  shipperLocation: {
    city: string;
    stateProvinceCode: string;
    country: string;
    postalCode: string
  };
  consigneeLocation: {
    city: string;
    stateProvinceCode: string;
    country: string;
    postalCode: string
  };
  shipDateTime: Date;
  currencyCode: string;
  invoiceStatus: InvoiceStatus;
  hasUnviewedNotes: boolean;
  lastCheckNumber: string;
  customerCheckNumber: string;
  customerPaidDateTime: Date;
  receivedDateTime: Date;
  resolvedDateTime: Date;
  paymentApprovedDate: Date;
  paymentStatus: string;

  constructor(json: CarrierInvoiceJSON) {
    super (json);
    this.processDate(json.customerPaidDateTime);
    this.processDate(json.shipDateTime);
    this.processDate(json.receivedDateTime);
    this.processDate(json.resolvedDateTime);
    this.processDate(json.paymentApprovedDateTime);
  }

  toJson(): CarrierInvoiceJSON {
    return {
      ...super.toJson(),
      customerPaidDateTime: this.convertDateToDateString(this.customerPaidDateTime),
      shipDateTime: this.convertDateToDateString(this.shipDateTime),
      receivedDateTime: this.convertDateToDateString(this.receivedDateTime),
      resolvedDateTime: this.convertDateToDateString(this.resolvedDateTime),
      paymentApprovedDateTime: this.convertDateToDateString(this.paymentApprovedDate)
    };
  }
}
