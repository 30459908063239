import { Action, AnyAction } from 'redux';
import { combineEpics } from 'redux-observable';
import { Container } from 'typedi';
import { NavCarrierEpic } from '../../nav-carrier-epic.interface';
import { UPDATE_CARRIER_FOR_USER, updateCarrierForUserFailure } from './carrier.actions';
import { saveCarrier } from 'features/security/auth.actions';
import { CarriersRepository } from 'app/repositories/carriers.repository';
import { APIErrorResponse } from 'app/repositories/errors/api-error-response';
import { Observable } from 'rxjs/Observable';

type CarrierEpic<OutputAction extends Action> = NavCarrierEpic<OutputAction, {
  carriersRepo: CarriersRepository;
}>;

export const updateCarrierDetailsEpic: CarrierEpic<AnyAction> = (action$, state$, { carriersRepo }) =>
	action$.ofType(UPDATE_CARRIER_FOR_USER)
		.filter(() => state$.value.auth.isAuthenticated)
		.mergeMap(() =>
			carriersRepo.getCarrierForUser(state$.value.auth?.user?.carrierCode, state$.value.auth?.user?.username, state$.value.auth?.user?.token)
				.map(carrierDetail => saveCarrier(carrierDetail))
				.catch((res: APIErrorResponse) => Observable.of(updateCarrierForUserFailure(res)))
		);

export const carrierEpics = (action$, state$) => combineEpics(
  updateCarrierDetailsEpic
)(action$, state$, {
  carriersRepo: Container.get(CarriersRepository)
});
