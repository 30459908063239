import { BaseModel } from 'shared/models/base.model';

export enum CapStatusType {
  RESTRICTED = 'RESTRICTED',
  BASE = 'BASE',
  CORE = 'CORE',
  KEY = 'KEY',
}

export class CapAdvantageScore extends BaseModel<CapAdvantageScoreJSON> {
  programStatus: CapStatusType;
  month: string;
  startDateTime: Date;
  endDateTime: Date;
  onTimePercentage: number;
  trackingPercentage: number;

  constructor(json?: CapAdvantageScoreJSON) {
    super(json);
    this.startDateTime = this.processDate(json.startDateTime);
    this.endDateTime = this.processDate(json.endDateTime);
  }

  public hasDates() {
    return this.startDateTime != null && this.endDateTime != null;
  }

  public programStatusResourceKey(): ResourceKey {
    return ['KEY', 'CORE', 'BASE', 'RESTRICTED'].includes(this.programStatus) ? (this.programStatus as ResourceKey) : ('BASE' as ResourceKey);
  }
}
