export const ExtendedTypesEU = [
  {
    'code': 'T',
    'description': '(Standard) Tautliner',
    'value': '(Standard) Tautliner'
  },
  {
    'code': 'TIL',
    'description': '(Standard) Tilt Trailer',
    'value': '(Standard) Tilt Trailer'
  },
  {
    'code': 'BX',
    'description': 'Box',
    'value': 'Box'
  },
  {
    'code': 'CAR',
    'description': 'Car Carrier',
    'value': 'Car Carrier'
  },
  {
    'code': 'COIL',
    'description': 'Coiler',
    'value': 'Coiler'
  },
  {
    'code': 'TIP',
    'description': 'Dump / Tipper Trailer',
    'value': 'Dump / Tipper Trailer'
  },
  {
    'code': 'MT',
    'description': 'Mega Tautliner',
    'value': 'Mega Tautliner'
  },
  {
    'code': 'MTIL',
    'description': 'Mega Tilt Trailer',
    'value': 'Mega Tilt Trailer'
  },
  {
    'code': 'PT',
    'description': 'Parcel Truck',
    'value': 'Parcel Truck'
  },
  {
    'code': 'DDF',
    'description': 'Refrigerated (Double) Dropdeck',
    'value': 'Refrigerated (Double) Dropdeck'
  },
  {
    'code': 'SIL',
    'description': 'Silo',
    'value': 'Silo'
  },
  {
    'code': 'STK',
    'description': 'Small Truck',
    'value': 'Small Truck'
  },
  {
    'code': 'TNK',
    'description': 'Tank',
    'value': 'Tank'
  },
  {
    'code': 'TCOI',
    'description': 'Tilt Coiler',
    'value': 'Tilt Coiler'
  },
  {
    'code': 'TR',
    'description': 'Tractor',
    'value': 'Tractor'
  },
  {
    'code': 'RD',
    'description': 'Road Train / Jumbo',
    'value': 'Road Train / Jumbo'
  },
  {
    'code': 'FAT',
    'description': 'Flatbed Any Type',
    'value': 'Flatbed Any Type'
  },
  {
    'code': 'IMDLR',
    'description': 'Intermodal Reefer',
    'value': 'Intermodal Reefer'
  }
];
