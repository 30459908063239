import { BaseModel } from 'shared/models/base.model';
import { RateType } from 'shared/enums/rate-type.enum';

export class SpotBidCharge extends BaseModel<SpotBidChargeJSON> {
  chargeId: number;
  rateCode: string;
  rateDescription: string;
  rateType: RateType;
  cost: number;
  units: number;
  ratePerUnit: number;
  minCharge: number;

  constructor(json: SpotBidChargeJSON) {
    super(json);
    this.rateDescription = json.rateDescription.trim();
  }

  toJson(): SpotBidChargeJSON {
    return Object.assign({}, this);
  }

  private meetsMinimumCharge() {
    const calculatedRate = this.ratePerUnit * this.units;
    return (this.minCharge === 0 || calculatedRate >= this.minCharge);
  }

  getRateType(): RateType {
    return this.meetsMinimumCharge() ? this.rateType : RateType.FlatRate;
  }

  getRatePerUnit() {
    return (this.meetsMinimumCharge() ? this.ratePerUnit : this.minCharge);
  }

  getTotalRate() {
    return (this.meetsMinimumCharge() ? (this.ratePerUnit * this.units) : this.minCharge);
  }

  getUnits() {
    return this.meetsMinimumCharge() ? this.units : 1;
  }
}
