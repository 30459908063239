import { RecentSearchSelectionEvent, SearchEventsAction, SubmitSearchEvent, SearchResultsEvent } from '../analytics-v2.actions';
import { AvailableLoadSummary } from 'shared/models/loads/load-summaries/available-load-summary.model';
import { Reducer } from 'redux';
import { AnalyticsActionTypes } from '@features/analytics/analytics-action-types';
import { UUID } from 'angular2-uuid';

export enum SearchSource {
  FIND_LOADS_LOAD_NUMBER_SEARCH = 'load number - find loads search',
  FIND_LOADS_OD_SEARCH = 'origin/destination search - find loads search',
  FIND_LOADS_RELOADS = 'reloads - find loads search',
}

export interface SearchEventsState {
  isButtonClick: boolean,
  searchCriteria: AvailableLoadSearchCriteriaJSON;
  recommendedLoads: AvailableLoadSummary[];
  searchResults: AvailableLoadSummary[];
  searchCorrelationId: string;
  pageSize: number;
  pageNumber: number;
  subsetOfResultsCount: number;
  totalVisibleResults: number;
  source: SearchSource;
  isOriginRecentSearch: boolean;
  isDestinationRecentSearch: boolean;
}

export const initialState: SearchEventsState = {
  isButtonClick: false,
  recommendedLoads: null,
  searchCorrelationId: null,
  searchCriteria: null,
  searchResults: null,
  pageSize: 0,
  pageNumber: 0,
  subsetOfResultsCount: 0,
  totalVisibleResults: 0,
  source: null,
  isOriginRecentSearch: false,
  isDestinationRecentSearch: false,
};

function getSearchSource(searchCriteria: AvailableLoadSearchCriteriaJSON): SearchSource {
  if (searchCriteria?.reloadsCriteria) {
    return SearchSource.FIND_LOADS_RELOADS;
  }

  return searchCriteria.loadNumber ? SearchSource.FIND_LOADS_LOAD_NUMBER_SEARCH : SearchSource.FIND_LOADS_OD_SEARCH;
}

export const searchEvents: Reducer = (state: SearchEventsState = initialState, action: SearchEventsAction): SearchEventsState => {
  switch (action.type) {
    case 'GET_SUGGESTED_LOADS' as AnalyticsActionTypes:
    case AnalyticsActionTypes.SET_INITIAL_SEARCH_CORRELATION_ID:
      return {
        ...state,
        searchCorrelationId: UUID.UUID(),
      };
    case AnalyticsActionTypes.SET_SUBMIT_SEARCH_EVENT:
      const { searchCorrelationId, searchCriteria, isButtonClick } = action.payload as SubmitSearchEvent;
      const searchId = searchCorrelationId ?? state.searchCorrelationId ?? UUID.UUID();
      const source = getSearchSource(searchCriteria);
      return {
        ...state,
        isButtonClick: isButtonClick ?? false,
        searchCriteria,
        source,
        searchCorrelationId: searchId,
        recommendedLoads: null,
        searchResults: null,
        pageSize: 0,
        pageNumber: 0,
        subsetOfResultsCount: 0,
        totalVisibleResults: 0,
      };
    case AnalyticsActionTypes.SET_SEARCH_RESULTS_EVENT:
      const results = action.payload as SearchResultsEvent;
      return {
        ...state,
        recommendedLoads: null,
        searchResults: results.searchResults,
        pageSize: results.pageSize ?? 0,
        pageNumber: results.pageNumber ?? 0,
        subsetOfResultsCount: results.subsetOfResultsCount ?? 0,
        totalVisibleResults: results.totalVisibleResults ?? 0,
      };
    case AnalyticsActionTypes.SET_RECOMMENDED_LOAD_LIST_VIEWED_EVENT:
      const recommendedResults = action.payload as SearchResultsEvent;
      return {
        ...state,
        searchCriteria: null,
        searchResults: null,
        source: null,
        recommendedLoads: recommendedResults.recommendedLoads,
        pageSize: recommendedResults.pageSize ?? 0,
        pageNumber: recommendedResults.pageNumber ?? 0,
        subsetOfResultsCount: recommendedResults.subsetOfResultsCount ?? 0,
        totalVisibleResults: recommendedResults.totalVisibleResults ?? 0,
      };
    case AnalyticsActionTypes.SET_RECENT_SEARCH_SELECTION_EVENT:
      const { isRecentSearchSelection, locationField } = action.payload as RecentSearchSelectionEvent;
      return {
        ...state,
        isOriginRecentSearch: locationField === 'ORIGIN' ? isRecentSearchSelection : state.isOriginRecentSearch,
        isDestinationRecentSearch: locationField === 'DESTINATION' ? isRecentSearchSelection : state.isDestinationRecentSearch,
      };
    default:
      return state;
  }
};
