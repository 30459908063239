import { BaseModel } from 'shared/models/base.model';
import { StopType } from 'shared/enums/stop-type.enum';
import { FormattableAddress } from 'shared/components/formatters/city-state-country.formatter';

export class LoadDetailStop extends BaseModel<LoadDetailStopJSON> {
  loadNumber: number;
  scheduledArrival: Date;
  onTimeCompliant: boolean;
  arrivedTime: Date;
  enteredTime: Date;
  excludedStopReason: number;
  excludedStop: boolean;
  type: StopType;
  location: FormattableAddress;
  private readonly _location;

  constructor(json?: LoadDetailStopJSON) {
    super(json);
    this.scheduledArrival = this.processDate(json?.bookAssignedApptLocal);
    this.arrivedTime = this.processDate(json?.arrivedDatetimeLocal);
    this.enteredTime = this.processDate(json?.enteredDatetimeLocal);
    this.location = this.formatAddress(json?.location);
    this._location = json?.location;
  }

  private formatAddress(json: LoadDetailStopJSON['location']): FormattableAddress {
    return {
      city: json?.city,
      stateCode: json?.state,
      country: json?.country,
      postalCode: json?.postalCode
    };
  }
}
