import { BaseModel } from 'shared/models/base.model';
import { Item } from 'shared/models/my-loads/items/item.model';
import { LoadOrderKey } from 'shared/models/my-loads/orders/load-order-key.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Orders.Order */
export class Order extends BaseModel<MyLoads.OrderJSON> {
  readonly items: Item[];
  readonly orderId: number;
  readonly orderReferenceNumber: string|null;
  readonly customerOrderNumber: string|null;
  readonly sourceSystem: string|null;
  readonly enteredBy: string|null;
  readonly enteredDate: Date|null;
  readonly isPartial?: boolean;
  readonly customerCode: string|null;
  readonly customerOrderServiceNumber: number;
  readonly associatedLoads: LoadOrderKey[];

  constructor(json: MyLoads.OrderJSON) {
    super(json);

    this.items = json.items ? json.items.map(item => new Item(item)) : [];
    this.associatedLoads = json.associatedLoads.map(item => new LoadOrderKey(item));

    this.enteredDate = this.processDate(json.enteredDate);
  }
}
