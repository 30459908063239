export const LoadTypeFilters = [
  {
    'code': '0',
    'description': 'CHRW & TMC',
    'value': 'CHRW & TMC'
  },
  {
    'code': '1',
    'description': 'CHRW',
    'value': 'CHRW'
  },
  {
    'code': '2',
    'description': 'TMC',
    'value': 'TMC'
  },
  {
    'code': '3',
    'description': 'IMDL',
    'value': 'IMDL'
  }
];
