import { BaseModel } from 'shared/models/base.model';
import { LoadItemDimension } from 'shared/models/my-loads/measurements/load-item-dimension.model';
import { ItemTemperatureRequirements } from 'shared/models/my-loads/items/item-temperature-requirements.model';
import { Weight } from 'shared/models/my-loads/measurements/weight.model';
import { Dimension } from 'shared/models/my-loads/measurements/dimension.model';
import { Volume } from 'shared/models/my-loads/measurements/volume.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Items.ItemExpectedInformation */
export class ItemExpectedInformation extends BaseModel<MyLoads.ItemExpectedInformationJSON> {
  readonly shouldCheckWeight: boolean;
  readonly dimension: LoadItemDimension|null;
  readonly dimensionWeight: Weight|null;
  readonly maximumWeight: Weight|null;
  readonly minimumWeight: Weight|null;
  readonly orderedSpace: Dimension|null;
  readonly palletPositions?: number;
  readonly pallets?: number;
  readonly palletsExactFlag: boolean;
  readonly palletsOnOrder?: number;
  readonly pieces?: number;
  readonly hasExactPieces: boolean;
  readonly piecesOnOrder?: number;
  readonly piecesPerPallet?: number;
  readonly stackablePalletsFlag: boolean;
  readonly temperatureRequirements: ItemTemperatureRequirements|null;
  readonly volume: Volume|null;
  readonly volumePerPallet: Volume|null;
  readonly volumePerPiece: Volume|null;
  readonly weightEmptyPallet: Weight|null;
  readonly weightExactFlag: boolean;
  readonly weightPerPallet: Weight|null;
  readonly weightPerPiece: Weight|null;
  readonly packagingCode: string|null;

  constructor(json: MyLoads.ItemExpectedInformationJSON) {
    super(json);

    this.dimension = json.dimension && new LoadItemDimension(json.dimension);
    this.dimensionWeight = json.dimensionWeight && new Weight(json.dimensionWeight);
    this.maximumWeight = json.maximumWeight && new Weight(json.maximumWeight);
    this.minimumWeight = json.minimumWeight && new Weight(json.minimumWeight);
    this.orderedSpace = json.orderedSpace && new Dimension(json.orderedSpace);
    this.temperatureRequirements = json.temperatureRequirements && new ItemTemperatureRequirements(json.temperatureRequirements);
    this.volume = json.volume && new Volume(json.volume);
    this.volumePerPallet = json.volumePerPallet && new Volume(json.volumePerPallet);
    this.volumePerPiece = json.volumePerPiece && new Volume(json.volumePerPiece);
    this.weightEmptyPallet = json.weightEmptyPallet && new Weight(json.weightEmptyPallet);
    this.weightPerPallet = json.weightPerPallet && new Weight(json.weightPerPallet);
    this.weightPerPiece = json.weightPerPiece && new Weight(json.weightPerPiece);
  }
}
