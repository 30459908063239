import { BaseModel } from 'shared/models/base.model';
import { Weight } from 'shared/models/my-loads/measurements/weight.model';
import { LoadDistance } from 'shared/models/my-loads/loads/load-distance.model';
import { Equipment } from 'shared/models/my-loads/equipment/equipment.model';
import { LoadSettings } from 'shared/models/my-loads/loads/load-settings.model';

export enum LoadStatus {
  FinOnly = 5,
  Ordered = 10,
  Consolidated = 12,
  WebLocked = 15,
  Assigned = 20,
  Tendered = 25,
  Booked = 30,
  CarrDirect = 40,
  CheckedIn = 50,
  WayBilled = 52,
  BookedComplete = 55,
  Loading = 60,
  ArrivedTerm = 68,
  ReadyDepart = 69,
  PickedUp = 70,
  DepartTerm = 71,
  OnRail = 72,
  DepartPort = 73,
  ArrivedPort = 74,
  InTransit = 75,
  ArrivedRamp = 76,
  RailComplete = 77,
  PortComplete = 78,
  TermComplete = 79,
  Unloading = 80,
  CarrierReleased = 82,
  Delivered = 90
}

export enum TmcRule {
  Broker,
  Tmc,
  Transfer
}

export enum UnitOfMeasure {
  Unknown,
  Standard,
  Metric
}

export enum Mode {
  None = 0x0, // None
  A = 0x1,    // NA - International Air
  B = 0x2,    // BOTH - Bulk
  D = 0x4,    // NA - Domestic Air
  F = 0x8,    // BOTH - Flatbed
  I = 0x10,   // NA - Intermodal
  L = 0x20,   // NA - LTL / EU - Less Than Truckload
  O = 0x40,   // BOTH -Shipper's Agent
  P = 0x80,   // BOTH - Parcel
  S = 0x100,  // NA - Ocean
  R = 0x200,  // NA - Reefer / EU - Refrigerated
  T = 0x400,  // NA - Rail
  U = 0x800,  // EU ONLY - Taut / Tilt Liner
  V = 0x1000, // NA - Van / EU - Box
  Y = 0x2000, // BOTH - Fin Only
  M = 0x4000, // BOTH - intermodel
  W = 0x8000, // EU - Ocean
  C = 0x10000, // Customs
  H = 0x20000, // NA = Power Only
  J = 0x40000, // NA = Dray
}

/** NavisphereCarrier.MyLoads.ServiceModel.Loads.LoadBase */
export abstract class LoadBase<T extends MyLoads.LoadBaseJSON> extends BaseModel<T> {
  readonly loadNumber: number;
  readonly distance?: number;
  readonly weight: Weight|null;
  readonly distanceSettings: LoadDistance|null;
  readonly equipment: Equipment|null;
  readonly specializedEquipment: Equipment|null;
  readonly readyDate: Date|null;
  readonly deliverByDate: Date|null;
  readonly mode: Mode;
  readonly regionCode: string;
  readonly settings: LoadSettings;
  readonly source: string|null;
  readonly sourceUom: UnitOfMeasure;
  readonly status: LoadStatus;
  readonly tmcRule: TmcRule;

  protected constructor(json: T) {
    super(json);

    this.weight = json.weight && new Weight(json.weight);
    this.distanceSettings = json.distanceSettings && new LoadDistance(json.distanceSettings);
    this.equipment = json.equipment && new Equipment(json.equipment);
    this.specializedEquipment = json.specializedEquipment && new Equipment(json.specializedEquipment);
    this.settings = new LoadSettings(json.settings);

    this.readyDate = this.processDate(json.readyDate);
    this.deliverByDate = this.processDate(json.deliverByDate);
  }

  get isTMCLoad() {
    return this.tmcRule === TmcRule.Tmc;
  }

  get isIntermodal() {
    return this.mode & (Mode.I | Mode.M);
  }
}
