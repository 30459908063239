import { SortAction, SortEventsAction } from '../analytics-v2.actions';
import { Reducer } from 'redux';
import { AnalyticsActionTypes } from '@features/analytics/analytics-action-types';

export interface SortActionEventsState extends SortAction { }

export const initialState: SortActionEventsState = {
  sortBy: null,
  sortOrder: null,
  sortedResults: null,
};

export const sortActionEvents: Reducer = (state: SortActionEventsState = initialState, action: SortEventsAction): SortActionEventsState => {
  switch (action.type) {
    case AnalyticsActionTypes.SET_SORT_ACTION_EVENT:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
