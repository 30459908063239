import { BaseModel } from 'shared/models/base.model';

export class Cost extends BaseModel {
  costPerUnit: number;
  costType: string;

  constructor(json: CostJSON) {
    super(json);

    this.costPerUnit = parseFloat(json.costPerUnit);
  }

  isNumeric() {
    return !Number.isNaN(this.costPerUnit);
  }
}
