import { BaseModel } from 'shared/models/base.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Appointments.Appointment */
export class Appointment extends BaseModel<MyLoads.AppointmentJSON> {
  readonly scheduledStartDateTime: Date|null;
  readonly scheduledEndDateTime: Date|null;
  readonly requestedDateTime: Date|null;
  readonly scheduleRequiredCode: string|null;
  readonly scheduledWithFirstName: string|null;
  readonly scheduledWithLastName: string|null;

  constructor(json: MyLoads.AppointmentJSON) {
    super(json);

    this.scheduledStartDateTime = this.processDate(json.scheduledStartDateTime);
    this.scheduledEndDateTime = this.processDate(json.scheduledEndDateTime);
    this.requestedDateTime = this.processDate(json.requestedDateTime);
  }
}
