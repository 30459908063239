import { BaseModel } from 'shared/models/base.model';

export class CarrierVoucher extends BaseModel {
  voucherDocumentId: number;
  voucherId: number;
  loadNumber: number;
  invoiceNumber: number;
  carrierCode: string;
  loadActivityDate: Date;
  billingStatus: BillingStatusJSON;
  releasedDate: Date;
  dueDate: Date;
  freightRate: number;
  advanceReceivedAmount: number;
  adjustmentAmount: number;
  remainingBalance: number;
  totalPaidAmount: number;
  paidDate: Date;
  paidBy: string;
  checkNumber: number;
  checkId: number;
  isTmcLoad: boolean;
  mode: string;
  currencyCode: string;
  trailerNumber: string;
  proNumber: string;
  bookSequenceNumber: number;
  status: string;

  constructor(json?) {
    super(json);

    if (json.carrierCode) {
      this.carrierCode = json.carrierCode.trim();
    }

    if (json.loadActivityDate) {
      this.loadActivityDate = this.processDate(json.loadActivityDate);
    }

    if (json.releasedDate) {
      this.releasedDate = this.processDate(json.releasedDate);
    }

    if (json.dueDate) {
      this.dueDate = this.processDate(json.dueDate);
    }

    if (json.paidDate) {
      this.paidDate = this.processDate(json.paidDate);
    }
  }

  toJson() {
    return {
      ...super.toJson(),
      loadActivityDate: this.convertDateToDateString(this.loadActivityDate),
      releasedDate: this.convertDateToDateString(this.releasedDate),
      dueDate: this.convertDateToDateString(this.dueDate),
      paidDate: this.convertDateToDateString(this.paidDate)
    };
  }

}
