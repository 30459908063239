import { PhoneType } from 'shared/enums/phone-type.enum';
import { BaseModel } from 'shared/models/base.model';

export class ContactPhone extends BaseModel<ContactPhoneJSON> {
  public id?: number;
  public phoneType?: number;
  public dialingCode?: string;
  public number: string;
  public extension: string;

  constructor(json?: ContactPhoneJSON) {
    super(json);

    if (!json) {
      return;
    }

    if (!json.phoneType) {
      this.phoneType = PhoneType.GeneralPhone;
    }
  }

  toString(): string {
    return Number(this.dialingCode) === 1 ? this.number : `+${this.dialingCode}.${this.number}`;
  }

  toJson(): ContactPhoneJSON {
    return {
      id: this.id,
      phoneType: this.phoneType || PhoneType.MobilePhone,
      number: this.number?.replace(/[^\d]/g, ''),
      dialingCode: this.dialingCode,
      extension: this.extension
    };
  }
}
