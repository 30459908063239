export enum StateChange {
  None = 0,

  /// Action: Set the 'Save' button to readonly, preventing a user from submitting a load problem.
  DisableSaveButton = 1,

  /// Action: Set 'Charge Per Hour' to readonly.
  ChargePerHourReadonly = 2,

  /// Action: Set the 'Cancel' button to readonly.
  DisableCancelButton = 3,

  /// Action: Set 'Free Time' to readonly.
  FreeTimeReadonly = 4,

  /// Action: Set 'Detention Start Time' to null and make the field readonly.
  StartTimeReadonly = 5,

  /// Action: Disable the 'Request Accessorial' button
  DisableRequestAccessorialButton = 6
}
