export enum ContactPosition {
  AdministrativeAssistant = 1,
  AccountingManager = 2,
  AccountsPayable = 3,
  AccountsReceivable = 4,
  Auditor = 5,
  Billing = 6,
  ChiefExecutiveOfficer = 7,
  ChiefFinancialOfficer = 8,
  ChiefInformationOfficer = 9,
  ClaimsOveragesShortagesAndDamage = 10,
  Clerk = 11,
  ClaimsManager = 12,
  ClaimsOperations = 13,
  Controller = 14,
  ChiefOperatingOfficer = 15,
  CustomerServiceRepresentative = 16,
  DirectorOfFinance = 17,
  Director = 18,
  Dispatcher = 19,
  EdiAnalyst = 20,
  ExecutiveVicePresident = 21,
  ExecutiveVicePresidentOperations = 22,
  ExecutiveVicePresidentSupplyChain = 23,
  InsuranceOperations = 24,
  InformationSystems = 25,
  ITManager = 26,
  LegalCounsel = 27,
  LoadPlanner = 28,
  Manager = 29,
  InformationsSystems = 30,
  OperationsManager = 31,
  Operations = 32,
  Owner = 33,
  PaymentServices = 34,
  Purchasing = 35,
  PurchasingManager = 36,
  President = 37,
  RatesContactPerson = 38,
  SafetyOperations = 39,
  SalesRepresentative = 40,
  SalesManager = 41,
  TrafficManager = 42,
  TransportationManager = 43,
  TerminalManager = 44,
  Treasurer = 45,
  VicePresident = 46,
  VicePresidentSourcing = 47,
  WarehousePerson = 48,
  WarehouseManager = 49,
  WarehouseOperations = 50,
  WarehouseReceiving = 51,
  WarehouseShipping = 52,
  Driver = 53,
  International = 54,
  Member = 55,
  Officer = 56,
  ProductionManager = 59,
  LogisticsManager = 60,
  NightDispatch = 61,
  WeekendDispatch = 62,
  Scorecard = 63,
  NightAndWeekendDispatch = 64,
  SupplyChain = 65
}
