import { BaseModel } from 'shared/models/base.model';

export enum DimensionUnit {
  Foot,
  Centimeter,
  Meter,
  Inch,
  Yard
}

/** NavisphereCarrier.MyLoads.ServiceModel.Measurements.Dimension */
export class Dimension extends BaseModel<MyLoads.DimensionJSON> {
  readonly metric: number;
  readonly metricUom: DimensionUnit;
  readonly standard: number;
  readonly standardUom: DimensionUnit;
}
