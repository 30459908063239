import { BaseModel } from 'shared/models/base.model';

export enum TemperatureUnit {
  Fahrenheit,
  Celsius
}

/** NavisphereCarrier.MyLoads.ServiceModel.Measurements.Temperature */
export class Temperature extends BaseModel<MyLoads.TemperatureJSON> {
  readonly metric: number;
  readonly metricUom: TemperatureUnit;
  readonly standard: number;
  readonly standardUom: TemperatureUnit;
}
