import { BaseModel } from 'shared/models/base.model';
import { MdmReferenceData } from 'shared/models/my-loads/reference-data/mdm-reference-data.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Equipment.CustomizedEquipment */
export class CustomizedEquipment extends BaseModel<MyLoads.CustomizedEquipmentJSON> {
  readonly id: number;
  readonly name: string;
  readonly length: number;
  readonly width: number;
  readonly measurementSystem: MdmReferenceData|null;
  readonly equipmentMode: string;

  constructor(json: MyLoads.CustomizedEquipmentJSON) {
    super(json);

    this.measurementSystem = json.measurementSystem && new MdmReferenceData(json.measurementSystem);
  }
}
