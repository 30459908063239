import { combineReducers } from 'redux';

import { LOGIN_ERROR, LOGOUT } from '@features/security/auth.actions';
import { Configuration } from 'shared/models/config/configuration.model';
import { COMPLETE_APP_INITIALIZATION, GET_SETTINGS_ERROR, SET_DEFAULT_COUNTRY, SetDefaultCountryAction, STORE_SETTINGS } from './app.actions';
import { isDetailView } from './detail-view/detail-view.reducer';

export const defaultCountry = (state = null, action): CountryResponse => {
  switch (action.type) {
    case SET_DEFAULT_COUNTRY:
      return (action as SetDefaultCountryAction).country;
    case LOGOUT:
    case LOGIN_ERROR:
      return null;
    default:
      return state;
  }
};

export const configuration = (state: Configuration = null, action): Configuration => {
  switch (action.type) {
    case STORE_SETTINGS:
      return action.settings;
    case GET_SETTINGS_ERROR:
      return null;
    default:
      return state;
  }
};

export const initialized = (state = false, action): boolean => {
  switch (action.type) {
    case COMPLETE_APP_INITIALIZATION:
    case LOGOUT:
      return true;
    default:
      return state;
  }
};

export const app = combineReducers({
  defaultCountry,
  initialized,
  configuration,
  isDetailView
});
