import { BaseModel } from 'shared/models/base.model';

export enum VolumeUnit {
  CubicFoot,
  CubicMeter,
  Liter,
  Pint,
  PintImperial,
  Gallon,
  GallonImperial,
  CubicCentimeter
}

/** NavisphereCarrier.MyLoads.ServiceModel.Measurements.Volume */
export class Volume extends BaseModel<MyLoads.VolumeJSON> {
  readonly metric: number;
  readonly metricUom: VolumeUnit;
  readonly standard: number;
  readonly standardUom: VolumeUnit;
}
