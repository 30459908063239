import moment from 'moment';

import { BaseModel } from 'shared/models/base.model';
import { DetentionLoadingCharge } from 'shared/models/my-loads/financials/detention-loading-charge.model';
import { ValidationResult } from 'shared/models/my-loads/financials/validation-result.model';
import { StateChange } from 'shared/enums/financials/state-change.enum';
import { ValidationWarning } from 'shared/enums/financials/validation-warning.enum';
import { AccessorialCode } from 'shared/enums/accessorial-code.enum';

export interface FinancialLoadProblemInterface {
  loadProblemId: number;
  description: string;
  notes: string;
  problemCode: AccessorialCode;
  sequenceNumber: number;
  stopSequenceNumber: number;
  stopName: string;
  status: ResourceKey;
  updatedDateTime: Date;
  totalHours: Date;
  type: string;
  detentionLoading: DetentionLoadingCharge;
  validationResult: ValidationResult;
  hasId(): boolean;
  parseByLine(): RegExpMatchArray | [];
  requestedBy: string;
  requestedDate: string;
  requestedTime: string;
  trimmedNotes: string;
  toJson(): FinancialLoadProblemJSON;
  hasStateChange(stateChange: StateChange): boolean;
  hasWarning(warning: ValidationWarning): boolean;
}
export class FinancialLoadProblem extends BaseModel<FinancialLoadProblemJSON> implements FinancialLoadProblemInterface {
  loadProblemId: number;
  description: string;
  notes: string;
  problemCode: AccessorialCode;
  sequenceNumber: number;
  stopSequenceNumber: number;
  stopName: string;
  status: ResourceKey;
  updatedDateTime: Date;
  totalHours: Date;
  type: string;
  detentionLoading: DetentionLoadingCharge;
  validationResult: ValidationResult;

  hasId() {
    return this.loadProblemId != null;
  }

  parseByLine() {
    return this.notes?.match(/^(.*)\n?.\[(\w+) by (\w+) on (\d?\d\/\d?\d\/\d\d\d\d) at (\d?\d:\d\d( [AP]M)?)]$/) || [];
  }

  get requestedBy() {
    const [, trimmedNotes, state, username, date, time] = this.parseByLine();
    return username;
  }

  get requestedDate() {
    const [, trimmedNotes, state, username, date, time] = this.parseByLine();
    return date;
  }

  get requestedTime() {
    const [, trimmedNotes, state, username, date, time] = this.parseByLine();
    return time;
  }

  get trimmedNotes() {
    const [, trimmedNotes, state, username, date, time] = this.parseByLine();
    // For some reason, the API HTML Encodes characters like the apostrophe, so it looks like `&#39;` instead of `'`
    // We need to decode those.
    return (trimmedNotes || '').replace(/&#(\d+);/g, function (match, dec) {
      return String.fromCharCode(dec);
    });
  }

  constructor(json: FinancialLoadProblemJSON) {
    super(json);

    if (json) {
      this.updatedDateTime = this.processDate(json.updatedDateTime);
      this.detentionLoading = new DetentionLoadingCharge(json.detentionLoading);
      this.validationResult = new ValidationResult(json.validationResult);

      if (json.totalHours) {
        const [hours, minutes] = json.totalHours.split(':');
        this.totalHours = moment().hours(Number(hours)).minutes(Number(minutes)).toDate();
      }
    }
  }

  toJson(): FinancialLoadProblemJSON {
    return {
      ...super.toJson(),
      updatedDateTime: this.convertDateToDateString(this.updatedDateTime),
      totalHours: this.totalHours && moment(this.totalHours).format('HH:mm'),
      detentionLoading: this.detentionLoading?.toJson(),
      validationResult: this.validationResult?.toJson(),
    };
  }

  hasStateChange(stateChange: StateChange) {
    return this.validationResult &&
      this.validationResult.hasStateChange(stateChange);
  }

  hasWarning(warning: ValidationWarning) {
    return this.validationResult &&
      this.validationResult.hasWarning(warning);
  }
}
