import { BaseModel } from 'shared/models/base.model';

export class Address extends BaseModel<AddressJSON> {
  streetName: string;
  street2: string;
  cityName: string;
  stateCode: string;
  zipcode: string;
  country: string;
  county: string;
  location: string;
  fullLocation: string;
  companyName: string;

  constructor(json?: AddressJSON) {
    super(json);
  }

  get zipCode() {
    return this.zipcode;
  }
  set zipCode(zipCode: string) {
    this.zipcode = zipCode;
  }
}
