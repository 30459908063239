import { AnyAction } from 'redux';

import { LOAD_RESOURCES_SUCCESS, LoadResourcesSuccessAction } from 'app/i18n/resources.actions';

export const resources = (state = {} as Resources, action: AnyAction) => {
  switch (action.type) {
    case (LOAD_RESOURCES_SUCCESS):
      return (action as LoadResourcesSuccessAction).resources;
    default:
      return state;
  }
};
