/**
 * User migration actions for observability
 */
export enum MigrationAction {
 EmailVerified = 'Migration.EmailVerified',
 EmailVerificationFailed = 'Migration.EmailVerificationFailed',
 EmailTaken = 'Migration.EmailTaken',
 OktaPasswordSet = 'Migration.OktaPasswordSet',
 UseExistingEmail = 'Migration.UseExistingEmail',
 ChangedPreferredEmail = 'Migration.ChangedPreferredEmail',
}
