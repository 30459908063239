// Redux middleware function
const AuthMiddleware = store => next => action => {
  // Dispatch the action immediately
  next(action);
  //console.log('Inspecting Action:')
  //console.dir(action)

};

export default AuthMiddleware;
