import { LocationInformation } from '@features/analytics/hooks/use-location-information.hook';
import { locationInformationEvents } from '@features/analytics/redux/reducers/location-information.reducer';
import { sortActionEvents, SortActionEventsState } from './sort-action-events.reducer';
import { loginEvents, LoginEventsState } from './login-events.reducer';
import { combineReducers } from 'redux';
import { UserEngagement, UserEngagementState } from './user-engagement.reducer';
import { searchEvents, SearchEventsState } from './search-events.reducer';
import { reloadEvents, ReloadEventsState } from '@features/analytics/redux/reducers/reload-events.reducer';
import { ldUserIdentityReducer, LdUserIdentityState } from './ld-user.reducer';
import { CashAdvanceEventsState, cashAdvanceEvents } from './cash-advance-events.reducer';

export interface AnalyticsV2State {
  login: LoginEventsState;
  UserEngagement: UserEngagementState;
  search: SearchEventsState;
  sort: SortActionEventsState;
  reload: ReloadEventsState;
  ldUser: LdUserIdentityState;
  locationInformation: LocationInformation;
  cashAdvance: CashAdvanceEventsState
}

export const analytics = combineReducers({
    UserEngagement,
    login: loginEvents,
    search: searchEvents,
    sort: sortActionEvents,
    reload: reloadEvents,
    ldUser: ldUserIdentityReducer,
    locationInformation: locationInformationEvents,
    cashAdvance: cashAdvanceEvents
});
