import { BaseModel } from '../base.model';

export class CashAdvance extends BaseModel {
  constructor(json?: CashAdvanceJSON) {
    super(json);
  }

  cashAdvanceId?: number;
  sourceType: number;
  totalAmount: number;
  carrierCode: string;
  requestedBy: string;
  sourceCurrencyCode?: string;
  branchCode?: string;
  requestedDate?: Date;
  status?: string;
  vendorSecurityCode?: string;
  vendorSecurityNumber?: string;
  remainingAmount?: number;
  cashedDate?: Date;
  expirationDate?: Date;
}
