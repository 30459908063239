export enum PhoneType {
  GeneralFax = 1,
  GeneralPhone = 2,
  LegalFax = 3,
  LegalPhone = 4,
  SecondaryPhone = 5,
  WorkPhone = 6,
  Beeper = 7,
  CarPhone = 8,
  DispatchPhone = 9,
  HomePhone = 10,
  MobilePhone = 11,
  Smartphone = 12
}
