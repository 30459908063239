import { LocationInformation } from '@features/analytics/hooks/use-location-information.hook';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { Reducer } from 'redux';

export const initialState: LocationInformation = {
  pageName: { prior: null, current: null },
  pageUrl: { prior: null, current: null }
};

export const locationInformationEvents: Reducer = (
  state: LocationInformation = initialState,
  action: LocationChangeAction
): LocationInformation => {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return {
        pageName: { prior: state.pageName.current, current: `${action.payload.location.pathname}` },
        pageUrl: { prior: state.pageUrl.current, current: `${action.payload.location.pathname}${action.payload.location.hash}${action.payload.location.search}` }
      };
    }
  }

  return state;
};
