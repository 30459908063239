import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { translate } from './translate';

export interface OwnProps {
  resource: ResourceKey | string;
  replacements?: any[];
  id?: string
}

type Props = OwnProps;
type Stringable = string | number | boolean | { toString(): string };

export const Translation: React.FunctionComponent<Props> = ({ resource: key, replacements, id }): ReactElement<any> => {
  const resources = useSelector<NavCarrierState, Resources>(state => state.resources);
  return <React.Fragment>{translate(resources, key as ResourceKey, replacements)}</React.Fragment>;
};

export const useTranslate = () => {
  const resources = useSelector<NavCarrierState, Resources>(state => state.resources);
  return (key: ResourceKey, replacements: Stringable[] = []) => translate(resources, key, replacements);
};

export interface JSXOwnProps {
  resource: ResourceKey;
  replacements?: (JSX.Element | (() => JSX.Element))[];
  id?: string;
}

type JSXProps = JSXOwnProps;
/**
 * Translation component which can take JSX replacements
 *
 * This was specifically designed for a resource that needs to replace a `{0}` with another resource, but can be used anywhere that
 * the replacements need to be JSX
 *
 * @usage <TranslationJSX resource="SOME_RESOURCE_WITH_REPLACEMENTS" replacements={[<Translation resource="EMAIL_US"/>]}/>
 */
export const TranslationJSX: React.FunctionComponent<JSXProps> = ({ resource: key, replacements = [], id = "" }): ReactElement<any> => {
  const resources = useSelector<NavCarrierState, Resources>(state => state.resources);
  const templateString = (resources?.[key]) || '';
  return (
    <span id={id} className="translation-jsx-component">
      {templateString.split(/{\d}/g).map((templatePart, index) => {
        const replacement = replacements[index - 1];
        return (
          <React.Fragment key={`${index}`}>{
            Boolean(replacement)
              ? (typeof replacement === 'function' ? replacement() : replacement)
              : null}{templatePart}
          </React.Fragment>
        );
      }
      )}
    </span>
  );
};
