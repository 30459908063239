import { BaseModel } from 'shared/models/base.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Tenders.TenderCostTax */
export class TenderCostTax extends BaseModel<MyLoads.TenderCostTaxJSON> {
  readonly tenderCostTaxId: number;
  readonly tenderCostId: number;
  readonly sequenceNumber: number;
  readonly taxCode: string|null;
  readonly taxDescription: string|null;
  readonly taxPercent: number;
  readonly taxAmount: number;
}
