type Hydrator<T> = (item: any) => T;

const emptyResultSet = {
  results: [],
  totalRecords: 0
};

export class ResultSet<T> {
  results: T[] = [];
  totalRecords = 0;

  constructor(json: ResultSetJSON<any> = emptyResultSet, hydrator?: Hydrator<T>) {
    this.totalRecords = json.totalRecords;
    this.results = hydrator ? json.results.map(hydrator) : json.results;
  }
}
