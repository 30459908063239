import { BaseModel } from 'shared/models/base.model';
import { LoadDetailStop } from 'shared/models/cap/cap-load-detail-stop.model';
import { FormattableAddress } from 'shared/components/formatters/city-state-country.formatter';
import { StopType } from 'shared/enums/stop-type.enum';

export class CapLoadDetail extends BaseModel<CapLoadDetailJSON> {
  loadNumber: number;
  bookSequenceNumber: number;
  parentCarrierCode: string;
  parentCarrierName: string;
  childCarrierCode: string;
  childCarrierName: string;
  originLocation: FormattableAddress;
  destinationLocation: FormattableAddress;
  hourlyCompliant: boolean;
  trackingSuccessCompliant: number;
  excludedHourlyMetric: boolean;
  excludedHourlyMetricReason: number;
  percentHoursUpdated: number;
  pickupStops: Array<LoadDetailStop>;
  deliveryStops: Array<LoadDetailStop>;
  originStop: LoadDetailStop;
  destinationStop: LoadDetailStop;
  private readonly _originLocation;
  private readonly _destinationLocation;

  constructor(json?: CapLoadDetailJSON) {
    super(json);
    this.originLocation = this.formatAddress(json.originLocation);
    this.destinationLocation = this.formatAddress(json.destinationLocation);
    this._originLocation = json.originLocation;
    this._destinationLocation = json.destinationLocation;
    this.convertStops(json);
    this.setOriginAndDestinationStops(json);
  }

  private formatAddress(json: CapLoadDetailJSON['originLocation']): FormattableAddress {
    return {
      city: json?.city,
      stateCode: json?.state,
      country: json?.country,
      postalCode: json?.postalCode,
    };
  }

  private convertStops(json: CapLoadDetailJSON) {
    if (json.pickupStops) {
      this.pickupStops = json.pickupStops.map(stop => this.mapLoadDetailStopJsonToModel(stop, StopType.PICKUP));
    }

    if (json.deliveryStops) {
      this.deliveryStops = json.deliveryStops.map(stop => this.mapLoadDetailStopJsonToModel(stop, StopType.DROP_OFF));
    }
  }

  private mapLoadDetailStopJsonToModel(json: LoadDetailStopJSON, type: StopType) {
    const newStop = new LoadDetailStop(json);
    newStop.type = type;
    newStop.loadNumber = this.loadNumber;
    return newStop;
  }

  private setOriginAndDestinationStops(json: CapLoadDetailJSON) {
    this.originStop = new LoadDetailStop(json?.pickupStops[0]);
    this.destinationStop = new LoadDetailStop(json?.deliveryStops[json.deliveryStops.length - 1]);
  }
}
