import { BaseModel } from 'shared/models/base.model';
import { Distance } from 'shared/models/my-loads/measurements/distance.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Loads.LoadDistance */
export class LoadDistance extends BaseModel<MyLoads.LoadDistanceJSON> {
  readonly distance: Distance|null;
  readonly distanceApplicationVersion: string|null;
  readonly isDistanceByPostalCode: boolean;
  readonly distanceApplicationMethod: string|null;

  constructor(json: MyLoads.LoadDistanceJSON) {
    super(json);

    this.distance = json.distance && new Distance(json.distance);
  }
}
