import { AnyAction, Action } from 'redux';
import { ActionsObservable, combineEpics } from 'redux-observable';
import { Container } from 'typedi';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

import * as a from 'app/ref-data/ref-data.actions';
import { User } from 'shared/models/user.model';
import { LOGOUT, STORE_CREDENTIALS } from 'features/security/auth.actions';
import { NavCarrierEpic } from 'store/nav-carrier-epic.interface';
import { ReferenceDataRepository } from 'app/repositories/reference-data.repository';

export interface RefDataEpicDependencies {
  refDataRepo?: ReferenceDataRepository;
  flush?: (action: ActionsObservable<AnyAction>) => ActionsObservable<AnyAction>;
}

type RefDataEpic<OutputAction extends Action> = NavCarrierEpic<OutputAction, RefDataEpicDependencies>;

export const getEquipmentTypesEpic: RefDataEpic<AnyAction> = (action$, state$, {refDataRepo}) =>
  action$.ofType(a.GET_EQUIPMENT_TYPES)
    .mergeMap(({isEU}) =>
      refDataRepo.getEquipmentTypes(isEU)
        .debounceTime(150)
        .map(a.equipmentTypesSucceeded)
        .catch((res: AjaxError) => Observable.of(a.equipmentTypesFailed(res)))
    );

export const getExtendedEquipmentTypesEpic: RefDataEpic<AnyAction> = (action$, state$, {refDataRepo}) =>
  action$.ofType(a.GET_EXTENDED_EQUIPMENT_TYPES)
    .mergeMap(({isEU}) =>
      refDataRepo.getExtendedEquipmentTypes(isEU)
        .debounceTime(150)
        .map(a.extendedEquipmentTypesSucceeded)
        .catch((res: AjaxError) => Observable.of(a.extendedEquipmentTypesFailed(res)))
    );

export const getSpecializedEquipmentTypesEpic: RefDataEpic<AnyAction> = (action$, state$, {refDataRepo}) =>
  action$.ofType<a.GetSpecializedEquipmentTypesAction>(a.GET_SPECIALIZED_EQUIPMENT_TYPES)
    .mergeMap(({isEU, equipment_type}) =>
      refDataRepo.getExtendedEquipmentTypes(isEU, equipment_type)
        .debounceTime(150)
        .map((items) => items.filter((item => item.code))) // clear out the weird NULL entry from Bulk types.
        .map(a.specializedEquipmentTypesSucceeded)
        .catch((response: AjaxError) => Observable.of(a.specializedEquipmentTypesFailed(response)))
    );

export const prefetchEquipmentTypes: RefDataEpic<AnyAction> = action$ =>
  action$.ofType(STORE_CREDENTIALS)
    .mergeMap(({user}) => {
      const isEU = (user as User).isEuropean();
      return ActionsObservable.of(a.getEquipmentTypes(isEU), a.getExtendedEquipmentTypes(isEU));
    });

export const refDataEpic = (action$, state$) => combineEpics(
  getEquipmentTypesEpic,
  getSpecializedEquipmentTypesEpic,
  getExtendedEquipmentTypesEpic,
  prefetchEquipmentTypes
)(action$, state$, {
  refDataRepo: Container.get(ReferenceDataRepository),
  flush: actions$ => actions$.ofType(LOGOUT),
});
