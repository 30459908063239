import { BaseModel } from 'shared/models/base.model';

export enum NoteType {
  General,
  Invoice,
  OverShortDamaged,
  Line,
  External
}

/** NavisphereCarrier.MyLoads.ServiceModel.Notes.Note */
export class Note extends BaseModel<MyLoads.NoteJSON> {
  readonly type: NoteType;
  readonly value: string;
}
