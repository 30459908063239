import { BaseModel } from 'shared/models/base.model';
import { Location } from 'shared/models/my-loads/locations/location.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Warehouses.Warehouse */
export class Warehouse extends BaseModel<MyLoads.WarehouseJSON> {
  readonly code: string;
  readonly name: string;
  readonly location: Location;
  readonly openDateTime: Date|null;
  readonly closeDateTime: Date|null;

  constructor(json: MyLoads.WarehouseJSON) {
    super(json);

    this.location = new Location(json.location);

    this.openDateTime = this.processDate(json.openDateTime);
    this.closeDateTime = this.processDate(json.closeDateTime);
  }
}
