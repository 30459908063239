import { BaseModel } from 'shared/models/base.model';
import { Weight } from 'shared/models/my-loads/measurements/weight.model';
import { Volume } from 'shared/models/my-loads/measurements/volume.model';
import { Dimension } from 'shared/models/my-loads/measurements/dimension.model';

/** NavisphereCarrier.MyLoads.ServiceModel.Items.ItemActualInformation */
export class ItemActualInformation extends BaseModel<MyLoads.ItemActualInformationJSON> {
  readonly approvedBy: string|null;
  readonly netWeight: Weight|null;
  readonly palletPositions?: number;
  readonly pallets?: number;
  readonly pieces?: number;
  readonly volume: Volume|null;
  readonly weight: Weight|null;
  readonly length: Dimension|null;
  readonly width: Dimension|null;
  readonly height: Dimension|null;

  constructor(json: MyLoads.ItemActualInformationJSON) {
    super(json);

    this.netWeight = json.netWeight && new Weight(json.netWeight);
    this.volume = json.volume && new Volume(json.volume);
    this.weight = json.weight && new Weight(json.weight);
    this.length = json.length && new Dimension(json.length);
    this.width = json.width && new Dimension(json.width);
    this.height = json.height && new Dimension(json.height);
  }
}
