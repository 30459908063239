import { BaseModel } from '../base.model';

export enum CashAdvanceSourceOldType {
  EFSFuelCard = 1,
  TChek = 2
}

export class CashAdvanceOptionsSourceOld extends BaseModel {
  constructor(json?: CashAdvanceSourceOldJSON) {
    super(json);
  }

  readonly sourceType: CashAdvanceSourceOldType;
}
