import { Reducer } from 'redux';
import { LdUserIdentityAction } from '@features/analytics/redux/analytics-v2.actions';
import { AnalyticsActionTypes } from '@features/analytics/analytics-action-types';

export interface LdUserIdentityState {
  isIdentified: boolean;
}

export const initialState: LdUserIdentityState = {
  isIdentified: false,
};

export const ldUserIdentityReducer: Reducer = (state: LdUserIdentityState = initialState, action: LdUserIdentityAction): LdUserIdentityState => {
  switch (action.type) {
    case AnalyticsActionTypes.SET_LD_USER_IDENTIFIED:
      return {
        ...state,
        isIdentified: action.payload,
      };
    default:
      return state;
  }
};
