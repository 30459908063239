import React from 'react';
import { AnchorHTMLAttributes } from 'react';

import { Translation } from 'shared/components/translation/translation.component';
import { useButtonClickStream } from 'shared/components/click-stream/use-button-clickstream.hook';
import { ButtonType } from 'shared/models/click-stream/button-type.model';
import { ClickableElementProps } from 'shared/components/click-stream/clickable-element-props';

interface Props extends ClickableElementProps, AnchorHTMLAttributes<HTMLAnchorElement> {
  resource?: ResourceKey;
}

/** Creates an instrumentable (opt-in) anchor tag */
export const Anchor: React.FC<Props> = ({
  resource,
  onClick,
  data,
  position,
  track,
  children,
  ...props }) => {
  const handleClick = useButtonClickStream(props.id, ButtonType.Anchor, track, data, position, props.href, onClick);

  return (
    <a
      {...props}
      onClick={handleClick}
    >
      {Boolean(resource) && <Translation resource={resource} />}
      {children}
    </a>
  );
};
